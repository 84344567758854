import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, TextField, Typography, CircularProgress, Alert, Modal } from '@mui/material';
import theme from 'theme';
import { changePassword } from 'storage/auth/duck';
import { trackEvent } from 'storage/tracking/duck';


interface ChangePasswordModalProps {
  open: boolean;
  closeModal: () => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const dispatch = useDispatch();
  const error = useSelector<IStore, IAuthState['error']>((state) => state.auth.error);
  const user = useSelector<IStore, IAuthState['user']>((state) => state.auth.user);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [resetPasswordTimeout, setResetPasswordTimeout] = useState(0);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const errorTimeoutRef = useRef<number | undefined>()

  /*
  * Get confirm token from URL parameters
  */
  useEffect(() => {
    if (!loading && !error && passwordChangeRequested) {
      setPasswordChanged(true)
    }
  }, [loading])

  /*
  * Handle error message timeout
  */
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        setPasswordChanged(false);
        setPasswordChangeRequested(false);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }

  }, [error])

  useEffect(() => {
    if (errorMessage && !error) {
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        setPasswordChangeRequested(false);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }

  }, [errorMessage])

  /*
  * Refresh state if reset password email was sent
  */
  useEffect(() => {
    if (passwordChanged) {
      let timeout = 0;
      const interval = window.setInterval(() => {
        timeout += 0.23
        setResetPasswordTimeout(timeout)
      }, 10)
      setNewPassword("");
      setNewPasswordConfirm("");
      window.setTimeout(() => {
        window.clearInterval(interval);
        setResetPasswordTimeout(0);
        setPasswordChanged(false);
        setPasswordChangeRequested(false);
        props.closeModal();
      }, 5000);
    }
  }, [passwordChanged])

  const handleChangePassword = () => {
    if (!oldPassword || !newPassword || !newPasswordConfirm) {
      setErrorMessage("Please fill in all fields");
    } else if (newPassword !== newPasswordConfirm) {
      setErrorMessage("Passwords do not match");
    } else if (newPassword.length < 8 && newPasswordConfirm.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
    } else {
      dispatch(trackEvent({ namespace: 'Change password', predicate: 'User menu', payload: { user: user?.email } }))
      dispatch(changePassword({ oldPassword, newPassword, }));
      setPasswordChangeRequested(true);
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={user?.is_password_expired ? undefined : props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {passwordChanged && !loading && !error ?
        <Card sx={{
          padding: "2rem",
          borderStyle: 'solid',
          borderBottomWidth: '2px',
          borderImage: `linear-gradient(to right, ${theme.palette.text.primary}, ${theme.palette.text.primary} ${resetPasswordTimeout}%, transparent ${resetPasswordTimeout}%, transparent) 0 1 100%`
        }}>
          <CardContent>
            <Stack gap={2}>
              <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem" }}>
                Your password has been changed
              </Typography>
            </Stack>
          </CardContent>
        </Card> :
        <Card sx={{ padding: "2rem", maxWidth: '32rem' }}>
          <CardContent>
            <Stack gap={2}>
              <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
                {user?.is_password_expired ? 'Your password is outdated. You must change your password.' : 'Change password'}
              </Typography>
              <TextField
                label="Old password"
                value={oldPassword}
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
                onSubmit={handleChangePassword}
                disabled={loading}
                autoFocus
              />
              <TextField
                label="New password"
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                onSubmit={handleChangePassword}
                disabled={loading}
              />
              <TextField
                label="Confirm new password"
                value={newPasswordConfirm}
                type="password"
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                onSubmit={handleChangePassword}
                disabled={loading}
              />
            </Stack>
            <ul>
              <li>
                <Typography>Your password can&apos;t be too similar to your other personal information.</Typography>
              </li>
              <li>
                <Typography>Your password must contain at least 8 characters.</Typography>
              </li>
              <li>
                <Typography>Your password can&apos;t be a commonly used password.</Typography>
              </li>
              <li>
                <Typography>Your password can&apos;t be entirely numeric</Typography>
              </li>
            </ul>
          </CardContent>
          <CardActions>
            <Button variant="contained" sx={{ margin: 'auto' }} onClick={handleChangePassword}>
              Change Password {loading &&
                <CircularProgress sx={{
                  height: "1rem !important",
                  width: "1rem !important",
                  marginLeft: "1rem"
                }} />
              }
            </Button>
          </CardActions>
          {errorMessage &&
            <Alert
              severity='error'
              variant='filled'
              sx={{ position: 'fixed', bottom: '0.5rem', left: '50%', transform: 'translate(-50%)', zIndex: 100 }}
              onClick={() => setErrorMessage(undefined)}
            >
              {errorMessage}
            </Alert>
          }
        </Card>
      }
    </Modal>
  );
};

export default ChangePasswordModal;
