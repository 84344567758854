/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosInstance } from 'axios'
import qs from "qs";
import packageJson from '../../../../package.json';
// import cookie from 'cookie';


const baseUrl = (window.location.origin.includes(":3000") ? window.location.origin.replace("3000", "8000") : window.location.origin) + packageJson.homepage + "/";

const api: AxiosInstance = Axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params: Record<string, any>) => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    }
  },
});


export default api;
