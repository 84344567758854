import React, { FC, useEffect, useState, KeyboardEvent } from 'react';
import './WorkorderList.css';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { INotesState, Recipient } from 'storage/notes/models';
import { IAppState, Proposal, Workorder } from 'storage/app/models';
import { saveWorkorderNote, getRecipients } from 'storage/notes/duck';
import theme from 'theme';
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  Stack,
  Grid,
  Box,
  Tooltip,
  IconButton,
  Menu,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WorkorderItem from './WorkorderItem';
import ActionSwitch from 'components/ActionSwitch/ActionSwitch';
import { updateBotShutOff, updateWorkorder } from 'storage/app/duck';
import { gainExperience } from 'storage/auth/duck';
// import LinkIcon from '@mui/icons-material/Link';
// import AttachmentIcon from '@mui/icons-material/Attachment';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import { trackEvent } from 'storage/tracking/duck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import NotesModal from './NotesModal';
import DetailsModal from './DetailsModal';
import LinkedWorkordersModal from './LinkedWorkordersModal';
import AttachmentsModal from './AttachmentsModal';

interface IndexedRecipient extends Recipient {
  index: number;
}

interface WorkorderFullscreenItemProps {
  workorder: Workorder;
  index: number;
}

const WorkorderFullscreenItem: FC<WorkorderFullscreenItemProps> = ({
  workorder,
  index,
}) => {
  const dispatch = useDispatch();
  const metadata = useSelector<IStore, IAppState['metadata']>(
    (state) => state.app.metadata,
  );
  const expConstants = useSelector<
    IStore,
    IAppState['metadata']['xp_multipliers']
  >((state) => state.app.metadata.xp_multipliers);
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const notes = useSelector<IStore, INotesState>((state) => state.notes);
  const woRecipients = notes.recipients[workorder.id.toString()]
    ? notes.recipients[workorder.id.toString()]
    : [];
  const [selectedRecipients, setSelectedRecipients] = useState<
    IndexedRecipient[]
  >([]);
  const [viewAllNotesModalOpen, setViewAllNotesModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [linkedWorkordersModalOpen, setLinkedWorkordersModalOpen] =
    useState(false);
  const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [botShutOffTooltip, setBotShutOffTooltip] = useState('Start bot');
  const [proposalMenuOpen, setProposalMenuOpen] = useState(false);
  const [loadingRecipients, setLoadingRecipients] = useState(false);
  const [recipientEmailFromInput, setRecipientEmailFromInput] =
    useState<Recipient['email']>('');
  const proposalMenuAnchorRef = React.useRef(null);

  useEffect(() => {
    setBotShutOffTooltip(
      user?.bot_admin
        ? workorder?.is_bot_shut_off
          ? 'Bot paused'
          : 'Bot started'
        : workorder?.is_bot_shut_off
        ? "Bot paused (you don't have permission to manage)"
        : "Bot started (you don't have permission to manage)",
    );
    // user?.email === workorder.bot_shut_off_user ?
    //   'Start bot' :
    //   `Bot paused by ${workorder.bot_shut_off_user}` :
    // 'Pause bot')
  }, [workorder?.is_bot_shut_off]);

  useEffect(() => {
    if (woRecipients.length > 0) {
      dispatch(
        trackEvent({
          namespace: 'Get workorder recipient',
          predicate: 'WO fullscreen',
          value: workorder?.id.toString(),
          payload: { recipients: woRecipients.map((r) => r.email) },
        }),
      );
      const defaultRecipients = woRecipients.reduce(
        (acc: IndexedRecipient[], r: Recipient, index) => {
          if (r.recipient_type === 2) {
            acc.push({ ...r, index: index });
          }
          return acc;
        },
        [],
      );
      woRecipients
        .filter((r) => r.recipient_type === 2)
        .map((r, index) => `${index}_${r.email}`);
      setSelectedRecipients(defaultRecipients);
      const el = document.querySelector(
        `#recipients-outlined-${index}`,
      ) as HTMLElement;
      if (loadingRecipients && el) {
        el?.focus();
      }
      setLoadingRecipients(false);
    }
  }, [woRecipients]);

  const handleGetRecipients = () => {
    if (woRecipients.length === 0) {
      dispatch(getRecipients(workorder.id));
      setLoadingRecipients(true);
    }
  };

  const handleAddNote = () => {
    if (newNote && expConstants) {
      const recipients = selectedRecipients.map(
        (r: IndexedRecipient) => r.email,
      );
      const gainExp = () => dispatch(gainExperience(expConstants.note));
      dispatch(
        saveWorkorderNote({
          workorderId: workorder.id,
          recipients: recipients,
          note: newNote,
          gainExp: gainExp,
          setNewNote: setNewNote,
        }),
      );
      dispatch(
        trackEvent({
          namespace: 'Add note',
          predicate: 'WO fullscreen',
          value: workorder.id.toString(),
          payload: { note: newNote, recipients: recipients },
        }),
      );
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      handleAddNote();
    }
  };

  const handleInputFocus = () => {
    handleGetRecipients();
  };

  const handleCheckBotShutOff = () => {
    if (user && workorder) {
      dispatch(
        updateBotShutOff({
          workorderId: workorder.id,
          value: !workorder.is_bot_shut_off,
          user: !workorder.is_bot_shut_off ? user.email : undefined,
        }),
      );
      dispatch(
        trackEvent({
          namespace: 'Shut off bot',
          predicate: 'WO fullscreen',
          value: workorder.id.toString(),
          payload: { value: !workorder.bot_shut_off },
        }),
      );
    }
  };

  const handleCheckWO = () => {
    if (user && workorder) {
      dispatch(
        updateWorkorder({
          workorderId: workorder.id,
          data: { ...workorder, selected: !workorder.selected },
        }),
      );
    }
  };

  const handleRecipientOpen = () => {
    handleGetRecipients();
    dispatch(
      trackEvent({
        namespace: 'Open recipient dropdown',
        predicate: 'WO fullscreen',
        value: workorder.id.toString(),
      }),
    );
  };

  return (
    <>
      <Stack
        justifyContent="center"
        sx={{ backgroundColor: theme.palette.background.paper, mb: '0.25rem' }}>
        <Checkbox
          color="secondary"
          sx={{ height: '100%', borderRadius: 'unset' }}
          checked={
            workorder.selected === undefined ? false : workorder.selected
          }
          onClick={handleCheckWO}
        />
      </Stack>
      <WorkorderItem workorder={workorder} index={index} isFullscreen />
      <Stack alignItems="center" direction="row" width="100%">
        <Stack
          direction="row"
          alignItems="center"
          minWidth="30rem"
          height="calc(100% - 0.25rem)"
          marginBottom="0.25rem"
          sx={{ backgroundColor: theme.palette.background.paper }}>
          <Grid
            height="100%"
            borderRight={`0.25rem solid ${theme.palette.background.default}`}>
            <ActionSwitch
              tooltip={botShutOffTooltip}
              tooltipPlacement="right"
              checked={!workorder?.is_bot_shut_off ? true : false}
              backgroundColor={
                !workorder?.is_bot_shut_off
                  ? theme.palette.actions.userWorkingOnIt
                  : '#8D8D8D'
              }
              icon={
                <SmartToyIcon
                  sx={{
                    color: !workorder?.is_bot_shut_off
                      ? theme.palette.actions.workingOnIt
                      : '#8D8D8D',
                  }}
                />
              }
              havePermission={user?.bot_admin ? true : false}
              onClick={user?.bot_admin ? handleCheckBotShutOff : undefined}
              style={{
                height: '50%',
                borderBottom: `0.125rem solid ${theme.palette.background.default}`,
              }}
            />
            <Tooltip
              placement="right"
              title={
                workorder?.proposals
                  ? `View proposals (${workorder?.proposals.length})`
                  : 'No proposal'
              }
              arrow>
              <Box
                ref={proposalMenuAnchorRef}
                justifyContent="center"
                alignItems="center"
                style={{
                  display: 'flex',
                  height: '50%',
                  borderTop: `0.125rem solid ${theme.palette.background.default}`,
                }}>
                {workorder?.proposals && workorder?.proposals.length > 0 && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={
                        !(
                          workorder?.proposals &&
                          workorder?.proposals.length > 0
                        )
                      }
                      onClick={() => setProposalMenuOpen(true)}
                      sx={{
                        minWidth: '3.5rem',
                        height: '2.5rem',
                        fontSize: '0.75rem',
                        borderRadius: '2rem',
                        '&.Mui-disabled': { color: theme.palette.text.primary },
                      }}>
                      <RequestPageIcon />
                    </Button>
                    <Menu
                      anchorEl={proposalMenuAnchorRef.current}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      sx={{
                        padding: '0',
                      }}
                      onClose={() => setProposalMenuOpen(false)}
                      open={proposalMenuOpen}>
                      {workorder?.proposals.map(
                        (proposal: Proposal, index: number) => (
                          <MenuItem
                            key={index}
                            component="a"
                            target={'_blank'}
                            href={`${metadata.sc_portal_url}/sc/Proposal/ProposalsRfp/ViewProposals?ProposalIids=${proposal.proposal_id}`}>
                            Review proposal {proposal.proposal_number} (
                            {proposal.proposal_status})
                          </MenuItem>
                        ),
                      )}
                    </Menu>
                  </>
                )}
              </Box>
            </Tooltip>
          </Grid>
          <Stack
            height="100%"
            width="100%"
            justifyContent="space-between"
            padding="0.25rem 0.5rem">
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography sx={{ fontSize: '1rem' }}>
                {workorder.priority}
              </Typography>
              <Stack flexDirection="row">
                {/* TODO: Move Linked WO and Attachments to detains modal */}
                {/*workorder.have_attachments &&
                  <Tooltip title="Attachments" placement="top" arrow>
                    <IconButton size="small" onClick={() => setAttachmentsModalOpen(true)} sx={{ p: 0, mr: '0.5rem' }}>
                      <AttachmentIcon />
                    </IconButton>
                  </Tooltip>
                */}
                {/*(workorder.linked_work_orders_original?.length > 0 || workorder.linked_work_orders_follow_up?.length > 0) &&
                  <Tooltip title="Linked work orders" placement="top" arrow>
                    <IconButton size="small" onClick={() => setLinkedWorkordersModalOpen(true)} sx={{ p: 0, mr: '0.5rem' }}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                */}
                <Tooltip title="Open in Service Channel" placement="top" arrow>
                  <IconButton
                    size="small"
                    href={`${metadata.sc_portal_url}/sc/wo/Workorders/index?id=${workorder.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ p: 0 }}>
                    <img
                      src={ServiceChannelIcon}
                      width="24"
                      height="24"
                      alt="Service Channel"
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack spacing={1} direction="row">
              <Stack spacing={1} sx={{ width: '55%' }}>
                <Stack>
                  <Stack direction="row" mb="0.4rem">
                    <PlaceIcon
                      fontSize="small"
                      sx={{ marginRight: '0.25rem', width: '1rem' }}
                    />
                    <Typography sx={{ fontSize: '0.75rem', mt: '0.125rem' }}>
                      {workorder.club_address} {workorder.club_city},{' '}
                      {workorder.club_state} {workorder.club_zip}
                    </Typography>
                  </Stack>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setDetailsModalOpen(true)}
                    fullWidth>
                    View Details
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <Box>
                  <Stack direction="row" mb="0.125rem">
                    <PersonIcon
                      fontSize="small"
                      sx={{ marginRight: '0.25rem', width: '1rem' }}
                    />
                    <Typography sx={{ fontSize: '0.75rem', mt: '0.125rem' }}>
                      <span style={{ fontWeight: 800 }}></span>{' '}
                      {workorder.club_manager_name}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <PhoneIcon
                      fontSize="small"
                      sx={{ marginRight: '0.25rem', width: '1rem' }}
                    />
                    <Stack>
                      {workorder.club_manager_cell_phone && (
                        <Typography
                          sx={{ fontSize: '0.75rem', mt: '0.125rem' }}>
                          <span style={{ fontWeight: 800 }}>Manager:</span>{' '}
                          {workorder.club_manager_cell_phone}
                        </Typography>
                      )}
                      {workorder.club_phone_number && (
                        <Typography
                          sx={{ fontSize: '0.75rem', mt: '0.125rem' }}>
                          <span style={{ fontWeight: 800 }}>Club:</span>{' '}
                          {workorder.club_phone_number}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          height="calc(100% - 0.25rem)"
          marginBottom="0.25rem"
          width="100%"
          marginLeft="0.25rem"
          padding="0.5rem"
          sx={{ backgroundColor: theme.palette.background.paper }}>
          <TextField
            minRows={3.45}
            multiline
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            size="small"
            inputProps={{ placeholder: 'Action required' }}
            InputProps={{
              style: { backgroundColor: theme.palette.background.paper },
            }}
            sx={{
              height: '100%',
              maxHeight: '100%',
              width: '100%',
              zIndex: newNote ? 300 : 'unset',
            }}
          />
          <Stack
            justifyContent="space-between"
            height="100%"
            minWidth="16rem"
            maxWidth="16rem"
            marginLeft="0.5rem"
            sx={{ position: 'relative' }}>
            <Autocomplete
              multiple
              id={`recipients-outlined-${index}`}
              options={[
                ...(recipientEmailFromInput
                  ? [
                      {
                        index: woRecipients.length + selectedRecipients.length,
                        email: recipientEmailFromInput,
                      } as IndexedRecipient,
                    ]
                  : []),
                ...woRecipients.map((recipient, index) => ({
                  ...recipient,
                  index: index,
                })),
              ]}
              value={selectedRecipients}
              onChange={(e, value) => {
                setSelectedRecipients(value);
                setRecipientEmailFromInput('');
              }}
              getOptionLabel={(option) => option.email}
              isOptionEqualToValue={(option, value) =>
                option.index === value.index
              }
              filterSelectedOptions
              limitTags={0}
              onInputChange={(_, value) => setRecipientEmailFromInput(value)}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.index}>
                  {option.email}
                </MenuItem>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      onClick: () => {
                        const el = document.querySelector(
                          `#recipients-outlined-${index}`,
                        ) as HTMLElement;
                        if (el) el?.parentElement?.parentElement?.click();
                      },
                    }}
                    placeholder="Recipients"
                  />
                );
              }}
              onOpen={handleRecipientOpen}
              loading={notes.loadingRecipients}
              sx={{
                '& .MuiAutocomplete-inputRoot': {
                  paddingTop: '0.1rem',
                  paddingBottom: '0.1rem',
                },
                position: 'absolute',
                right: 0,
                left: 0,
                zIndex: 1000 - index,
                backgroundColor: theme.palette.background.paper,
              }}
            />
            {/*<Select
              multiple
              id="recipients-outlined"
              displayEmpty={true}
              renderValue={(selected) => loadingRecipients ? 'Loading...' : selected.length > 0 ?
                selected.length === 1 ? selected[0].split('_')[1] :
                  selected.reduce((acc: string, curr: string) => acc ? `${acc}, ${curr.split('_')[1]}` : curr.split('_')[1], '') :
                'Recipient(s)'}
              value={selectedRecipients}
              size='small'
              onOpen={handleGetRecipients}
              onChange={(e) => setSelectedRecipients(e.target.value as string[])}
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                mb: '0.5rem',
                maxWidth: '100%',
                '& svg': { color: theme.palette.primary.main },
                '& ul': { minHeight: '4rem' }
              }}
              startAdornment={
                selectedRecipients.length > 0 && (
                  <CancelIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedRecipients([])
                    }}
                    sx={{
                      cursor: 'pointer',
                      ml: '-.5rem',
                      mr: '.25rem'
                    }}
                  />
                )
              }
            >
              {woRecipients.length === 0 &&
                <MenuItem value='loading' disabled sx={{ height: '4rem' }}>
                  <Spinner isVisible={woRecipients.length === 0} dimBackground />
                </MenuItem>
              }
              {woRecipients.map((recipient, index) =>
                <MenuItem key={index} value={`${index}_${recipient.email}`}>
                  {recipient.email}
                </MenuItem>
              )}
            </Select>*/}
            <Stack
              direction="row"
              width="100%"
              height="50%"
              sx={{ marginTop: '3rem' }}>
              <Button
                size="small"
                fullWidth
                onClick={() => setViewAllNotesModalOpen(true)}
                sx={{ textTransform: 'unset' }}>
                View all notes
              </Button>
              <Tooltip
                title={
                  !user?.note_sender &&
                  "You don't have permission to send notes"
                }
                placement="left"
                arrow>
                <span style={{ width: '100%', display: 'flex', flexGrow: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    onClick={handleAddNote}
                    disabled={!user?.note_sender}
                    sx={{
                      color: !user?.note_sender
                        ? '#d0d0d0 !important'
                        : undefined,
                    }}>
                    Submit
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {viewAllNotesModalOpen && (
        <NotesModal
          open={viewAllNotesModalOpen}
          workorderId={workorder.id}
          onClose={() => setViewAllNotesModalOpen(false)}
        />
      )}
      {detailsModalOpen && (
        <DetailsModal
          open={detailsModalOpen}
          workorder={workorder}
          onClose={() => setDetailsModalOpen(false)}
        />
      )}
      {attachmentsModalOpen && (
        <AttachmentsModal
          open={attachmentsModalOpen}
          workorder={workorder}
          onClose={() => setAttachmentsModalOpen(false)}
        />
      )}
      {linkedWorkordersModalOpen && (
        <LinkedWorkordersModal
          open={linkedWorkordersModalOpen}
          workorder={workorder}
          onClose={() => setLinkedWorkordersModalOpen(false)}
        />
      )}
    </>
  );
};

export default WorkorderFullscreenItem;
