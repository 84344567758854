import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAppState } from 'storage/app/models';
import { selectFilters, updateFilters } from 'storage/app/duck';
import UndoIcon from './UndoIcon';
import RedoIcon from './RedoIcon';
import { trackEvent } from 'storage/tracking/duck';


type FilterHistory = Partial<IAppState['filters']>;


const TimeTravel: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState<boolean>(true);
  const [filterHistory, setFilterHistory] = useState<FilterHistory[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);
  const filterHistoryRef = useRef<FilterHistory[]>([]);
  const historyIndexRef = useRef<number>(0);
  const isUndoDisabled = historyIndex === 0;
  const isRedoDisabled = filterHistory.length === 0 || historyIndex === filterHistory.length - 1;

  useEffect(() => {
    document.addEventListener('keydown', keypressListener);
    return () => document.removeEventListener('keydown', keypressListener)
  }, [])

  /*
   *  Updates the filter history when the filters change
   */
  useEffect(() => {
    if (shouldUpdateHistory) {
      if (historyIndex < filterHistory.length - 1) {
        const historySlice = filterHistory.slice(0, historyIndex + 1);
        setFilterHistory([...historySlice, filters])
        setHistoryIndex(historySlice.length)
      } else {
        setFilterHistory([...filterHistory, filters])
        setHistoryIndex(filterHistory.length)
      }
    } else {
      setShouldUpdateHistory(true)
    }
  }, [filters])

  useEffect(() => {
    filterHistoryRef.current = filterHistory;
    historyIndexRef.current = historyIndex;
  }, [filterHistory, historyIndex])

  const keypressListener = (event: KeyboardEvent) => {
    const target = event.target as HTMLElement;
    if (event.ctrlKey && target.localName !== "textarea" && target.localName !== "input") {
      if (event.key === 'Z' && event.shiftKey) {
        handleRedo("Ctrl + Shift + Z");
      } else if (event.key === 'z') {
        handleUndo("Ctrl + Z");
      } else if (event.key === 'y') {
        handleRedo("Ctrl + Y");
      }
    }
  }

  const handleUndo = (predicate?: string) => {
    const previousFilter = filterHistoryRef.current[historyIndexRef.current - 1];
    if (previousFilter) {
      setShouldUpdateHistory(false)
      setHistoryIndex(historyIndexRef.current - 1)
      dispatch(updateFilters(previousFilter));
      dispatch(trackEvent({ namespace: 'Undo filters', predicate: predicate ? predicate : 'Filter bar' }))
    };
  }

  const handleRedo = (predicate?: string) => {
    const nextFilter = filterHistoryRef.current[historyIndexRef.current + 1];
    if (nextFilter) {
      setShouldUpdateHistory(false)
      setHistoryIndex(historyIndexRef.current + 1)
      dispatch(updateFilters(nextFilter));
      dispatch(trackEvent({ namespace: 'Undo filters', predicate: predicate ? predicate : 'Filter bar' }))
    };
  }

  return (
    <Stack id="timetravel" direction='row'>
      <Tooltip title='Go back' placement='bottom' arrow>
        <span>
          <Button
            onClick={() => handleUndo()}
            disabled={isUndoDisabled}
            sx={{
              height: '100%',
              textTransform: 'none',
              color: '#fff',
              fontSize: '1rem',
              '&.MuiButton-root:hover': {
                backgroundColor: 'background.default'
              },
              '&.Mui-disabled': {
                color: 'background.lightest'
              }
            }}>
            <UndoIcon disabled={isUndoDisabled} />
          </Button>
        </span>
      </Tooltip>
      <Tooltip title='Go forward' placement='bottom' arrow>
        <Stack>
          <Button
            onClick={() => handleRedo()}
            disabled={isRedoDisabled}
            sx={{
              height: '100%',
              textTransform: 'none',
              color: '#fff',
              fontSize: '1rem',
              '&.MuiButton-root:hover': {
                backgroundColor: 'background.default'
              },
              '&.Mui-disabled': {
                color: 'background.lightest'
              }
            }}>
            <RedoIcon disabled={isRedoDisabled} />
          </Button>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default TimeTravel;
