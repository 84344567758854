import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';

interface RedoIconProps {
  disabled: boolean
}

const RedoIcon: FC<RedoIconProps> = (props: RedoIconProps) => {

  const color = props.disabled ? '#7F8F9F' : '#fff'

  return (
    <SvgIcon width="6.8973mm" height="7.892mm" viewBox="0 0 6.8973 7.892" fill={color}>
      <g transform="translate(-119.63 -55.263)" stroke={color} strokeMiterlimit="4.2">
        <path d="m124.53 57.801h-2.1828c-3.155 0-3.3158 5.0541 0 5.0541h2.1828" fill="none" strokeWidth=".6" />
        <path d="m126.18 57.797-2.5948-2.0913v4.1826z" strokeWidth=".42383" />
      </g>
    </SvgIcon>
  )
}

export default RedoIcon