import React, { FC } from 'react';


interface TimeAgoProps {
  date: Date;
  hideHours?: boolean;
}

const TimeAgo: FC<TimeAgoProps> = ({ date, hideHours }) => {
  const now = new Date();
  const dateDiff = (date.getTime() - now.getTime()) / 1000;
  const daysDiff = dateDiff / 3600 / 24;
  const days = Math.trunc(daysDiff);
  const restDiff = daysDiff - days;
  const hoursDiff = restDiff * 24;
  const hours = Math.trunc(hoursDiff);
  const isFuture = daysDiff > 0 || hoursDiff > 0;
  const dayLabel = Math.abs(days) === 1 ? "day" : "days";
  const hourLabel = Math.abs(hours) === 1 ? "hour" : "hours";
  const dayString = `${Math.abs(days)} ${dayLabel}`
  const hourString = `${Math.abs(hours)} ${hourLabel}`
  if (isFuture) {
    if (days === 0) {
      if (hours === 0) {
        return (
          <>in less than an hour</>
        )
      }
      return (
        <>in {hourString}</>
      )
    }
    return (
      <>in {dayString} {hideHours ? undefined : hourString}</>
    )
  } else {
    if (days === 0) {
      if (hours === 0) {
        return (
          <>less than an hour ago</>
        )
      }
      return (
        <>{hourString} ago</>
      )
    }
    return (
      <>{dayString} {hideHours ? undefined : hourString} ago</>
    )
  }
}

export default TimeAgo;