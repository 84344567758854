// Models
import { IBaseState } from 'lib/redux/models'
import { Dispatch } from 'react'
import { Step } from 'react-joyride'
import { AnyAction } from 'redux'

export enum ETutorialActionTypes {
  START = 'TUTORIAL/START',
  STOP = 'TUTORIAL/STOP',
  NEXT_STEP = 'TUTORIAL/NEXT_STEP',
  PREVIOUS_STEP = 'TUTORIAL/PREVIOUS_STEP',
}

// Data

export interface TutorialStep extends Step {
  callbackFn?: (dispatch: Dispatch<AnyAction>) => void
}

export interface NewFeatureStep extends Step {
  id?: number
  disable_beacon?: Step['disableBeacon']
  callback_fn?: {
    [key: string]: string
  }
}

// State

export interface ITutorialState extends IBaseState {
  running: boolean
  steps: TutorialStep[]
  stepIndex: number
}