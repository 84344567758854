import api from 'lib/axios/api';
import { IActionSendFeedback, IActionTrackEvent } from 'storage/tracking/models';
import { isTablet } from 'utils/utils';


/**
 * Save trackable event to the database
 */
export const trackEventService = async (request: IActionTrackEvent["payload"]): Promise<boolean> => {
  const timestamp = new Date().getTime();
  const ipad = isTablet();
  const response = await api.post(`users/activity/`, {tablet_mode: ipad, ...request, timestamp});
  return response.status === 200;
};


/**
 * Save user feedback to the database
 */
export const sendFeedbackService = async (request: IActionSendFeedback["payload"]): Promise<boolean> => {
  const timestamp = new Date().getTime();
  const response = await api.post(`users/feedback/`, { namespace: 'Feedback', predicate:'Map', value: request, timestamp });
  return response.status === 200;
};
