// Models
import { IAction } from 'lib/redux/models'
import { EAlertsActionTypes, IActionSetAlerts, IActionAcknowledge, IActionSetAcknowledged, IActionSetHighlight, IAlertsState, Alert, IActionGetNextAlerts, IActionAppendAlerts } from '../models'

// ACTION TYPES
export const Types = {
  FAILURE: EAlertsActionTypes.FAILURE,
  FULFILL: EAlertsActionTypes.FULFILL,
  REQUEST: EAlertsActionTypes.REQUEST,
  GET_ALERTS: EAlertsActionTypes.GET_ALERTS,
  SET_ALERTS: EAlertsActionTypes.SET_ALERTS,
  GET_NEXT_ALERTS: EAlertsActionTypes.GET_NEXT_ALERTS,
  APPEND_ALERTS: EAlertsActionTypes.APPEND_ALERTS,
  ACKNOWLEDGE: EAlertsActionTypes.ACKNOWLEDGE,
  SET_ACKNOWLEDGED: EAlertsActionTypes.SET_ACKNOWLEDGED,
  SET_HIGHLIGHT: EAlertsActionTypes.SET_HIGHLIGHT,
}

// INITIAL STATE
const initialState: IAlertsState = {
  alerts: [],
  alertIds: [],
  pageNumber: 1,
  hasNextPage: true,
  totalAlerts: 0,
}

// REDUCER
export default (
  state: IAlertsState = initialState,
  action?: IAction,
): IAlertsState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case Types.SET_ALERTS:
      return haveNewAlerts(state, action.payload.alerts) || state.totalAlerts === 0 ? {
        ...state,
        alerts: action.payload.alerts,
        alertIds: action.payload.alerts.map((alert: Alert) => alert.id),
        totalAlerts: action.payload.totalAlerts,
        hasNextPage: action.payload.hasNextPage,
        pageNumber: 1,
      } : {
        ...state,
      }
    case Types.APPEND_ALERTS:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          ...action.payload.alerts.filter((alert: Alert) => !state.alertIds.includes(alert.id))
        ],
        alertIds: [
          ...state.alertIds,
          ...action.payload.alerts.filter((alert: Alert) => !state.alertIds.includes(alert.id)).map((alert: Alert) => alert.id)
        ],
        hasNextPage: action.payload.hasNextPage,
        pageNumber: state.pageNumber + 1,
      }
    case Types.SET_ACKNOWLEDGED:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => 
          alert.id !== action.payload.alertId
        ),
        alertIds: state.alertIds.filter((alertId) => 
          alertId !== action.payload.alertId
        ),
        totalAlerts: state.totalAlerts - 1,
      };
    case Types.SET_HIGHLIGHT:
      return {
        ...state,
        alerts: state.alerts.map((alert) => {
          if (alert.id === action.payload.alertId) {
            return {
              ...alert,
              highlighted: action.payload.highlighted,
            }
          }
          return alert
        }),
      }
    default:
      return state
  }
}

const haveNewAlerts = (state: IAlertsState, newAlerts: Alert[]): boolean => {
  if (newAlerts.some((newAlert) => !state.alertIds.includes(newAlert.id))) {
    return true
  } else if (newAlerts.some((newAlerts) => 
    state.alerts.find((alert) => alert.id === newAlerts.id)?.updatedAt.toISOString() !== newAlerts.updatedAt.toISOString())
  ){
    return true
  }
  return false
}

// ACTIONS
export const failure = (payload: string): IAction => ({
  type: Types.FAILURE,
  payload,
})

export const fulfill = (): IAction => ({
  type: Types.FULFILL,
})

export const request = (): IAction => ({
  type: Types.REQUEST,
})

export const getAlerts = (): IAction => {
  return {
    type: Types.GET_ALERTS,
  };
};

export const setAlerts = (payload: IActionSetAlerts["payload"]): IAction => {
  return {
    type: Types.SET_ALERTS,
    payload,
  };
};

export const getNextAlerts = (payload: IActionGetNextAlerts["payload"]): IAction => {
  return {
    type: Types.GET_NEXT_ALERTS,
    payload,
  };
};

export const appendAlerts = (payload: IActionAppendAlerts["payload"]): IAction => {
  return {
    type: Types.APPEND_ALERTS,
    payload,
  };
};

export const acknowledge = (payload: IActionAcknowledge["payload"]): IAction => {
  return {
    type: Types.ACKNOWLEDGE,
    payload,
  };
}

export const setAcknowledged = (payload: IActionSetAcknowledged["payload"]): IAction => {
  return {
    type: Types.SET_ACKNOWLEDGED,
    payload,
  };
};

export const setHighlighted = (payload: IActionSetHighlight["payload"]): IAction => {
  return {
    type: Types.SET_HIGHLIGHT,
    payload,
  };
};

export const actions = {
  failure,
  fulfill,
  request,
}
