import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { IAppState, Workorder } from 'storage/app/models';
import { INotesState, Note, Recipient } from 'storage/notes/models';
import { toggleNotesPanelOpen } from 'storage/app/duck';
import { getWorkorderNotes, saveWorkorderNote } from 'storage/notes/duck';
import {
  Stack,
  Typography,
  Box,
  Autocomplete,
  TextField,
  MenuItem,
  Button,
  Paper,
  Tooltip,
  CircularProgress,
  Portal,
  Checkbox,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { red } from '@mui/material/colors';
import { Interweave } from 'interweave';
import theme from 'theme';
import { gainExperience } from 'storage/auth/duck';
import { trackEvent } from 'storage/tracking/duck';
import SearchIcon from '@mui/icons-material/Search';
import Spinner from 'components/Spinner/Spinner';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import TimeAgo from './TimeAgo';
import { isTablet } from 'utils/utils';

interface IndexedRecipient extends Recipient {
  index: number;
}

export const RenderNote: FC<{ note: Note; fullWidth?: boolean }> = ({
  note,
  fullWidth,
}) => {
  const noteData =
    typeof note.noteData === 'string'
      ? note.noteData
      : note.noteData?.toString();
  const ipad = isTablet();
  return note ? (
    <div className="workorder-note" key={note.id}>
      {typeof note !== 'string' && (
        <Paper
          sx={{
            p: 2,
            position: 'relative',
            maxWidth: fullWidth ? '100%' : '30rem',
          }}>
          <Typography
            mb={1}
            sx={{
              paddingRight: '2.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}>
            {note.noteData && <Interweave content={noteData} />}
          </Typography>
          <Stack
            direction={ipad ? 'column' : 'row'}
            width="100%"
            justifyContent="space-between"
            alignItems={ipad ? 'flex-start' : 'flex-end'}>
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', mr: '1rem' }}>
              {note.number ? `#${note.number} - ` : ''}
              {new Date(note.createdDate).toLocaleString()} (
              <TimeAgo date={new Date(note.createdDate)} />)
            </Typography>
            <Typography variant="caption">
              {note.createdBy ? note.createdBy : ''}
            </Typography>
          </Stack>
          {note.actionRequired && (
            <Tooltip title="Action required">
              <Paper
                sx={{
                  position: 'absolute',
                  height: '2rem',
                  width: '2rem',
                  top: '1rem',
                  right: '1rem',
                  padding: '0.25rem',
                  borderRadius: '0.25rem',
                  backgroundColor: red[500],
                }}>
                <WarningAmberIcon sx={{ margin: 0 }} />
              </Paper>
            </Tooltip>
          )}
        </Paper>
      )}
      {typeof note === 'string' && <p>{note}</p>}
    </div>
  ) : (
    <div key={Math.random().toString()}>
      <Paper sx={{ p: 2 }}>
        <Typography mb={1}>Loading...</Typography>
        <Typography variant="caption">...</Typography>
      </Paper>
    </div>
  );
};

interface WorkorderNotesProps {
  workorderData: Workorder;
}

const WorkorderNotes: FC<WorkorderNotesProps> = ({ workorderData }) => {
  const dispatch = useDispatch();
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const workorderId = useSelector<IStore, IAppState['filters']['workorder']>(
    (state) => state.app.filters.workorder,
  );
  const isPanelOpen = useSelector<IStore, IAppState['isNotesPanelOpen']>(
    (state) => state.app.isNotesPanelOpen,
  );
  const notes = useSelector<IStore, INotesState>((state) => state.notes);
  const woNotes = notes.data[workorderData.id.toString()]
    ? notes.data[workorderData.id.toString()]
    : [];
  const woRecipients = notes.recipients[workorderData.id.toString()]
    ? notes.recipients[workorderData.id.toString()]
    : [];
  const expConstants = useSelector<
    IStore,
    IAppState['metadata']['xp_multipliers']
  >((state) => state.app.metadata.xp_multipliers);
  const [newNote, setNewNote] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState<
    IndexedRecipient[]
  >([]);
  const actionRequiredRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [filteredWoNotes, setFilteredWoNotes] = useState<Note[]>([]);
  const [recipientEmailFromInput, setRecipientEmailFromInput] =
    useState<Recipient['email']>('');
  const [sendNoteToSelf, setSendNoteToSelf] = useState<boolean>();
  const ipad = isTablet();

  useEffect(() => {
    dispatch(getWorkorderNotes({ workorderId: workorderData.id }));
  }, [workorderId]);

  useEffect(() => {
    const recipients = woRecipients.map((recipient, index) => ({
      ...recipient,
      index,
    })) as IndexedRecipient[];
    setSelectedRecipients(recipients.filter((r) => r.recipient_type === 2));
  }, [woRecipients]);

  /*useEffect(() => {
    if (workorderData.working_on_it_user && user?.email) {
      if (workorderData.working_on_it_user === user?.email) {
        setSendNoteToSelf(true)
      }
    }
  }, [workorderData.working_on_it_user])*/

  const handleAddNote = () => {
    if (workorderData && newNote && expConstants) {
      const recipients = selectedRecipients.map((recipient) => recipient.email);
      if (sendNoteToSelf && user?.email) {
        recipients.push(user.email);
      }
      const gainExp = () => gainExperience(expConstants.note);
      dispatch(
        saveWorkorderNote({
          workorderId: workorderData.id,
          recipients: recipients,
          note: newNote,
          gainExp: gainExp,
          setNewNote: setNewNote,
        }),
      );
      dispatch(
        trackEvent({
          namespace: 'Add note',
          predicate: 'WO details',
          value: workorderData.id.toString(),
          payload: {
            workorder_id: workorderData.id,
            recipients: recipients,
            note: newNote,
          },
        }),
      );
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'Enter') {
      handleAddNote();
    }
  };

  const handleRecipientOpen = () => {
    const recipients = woRecipients.map((recipient) => recipient.email);
    dispatch(
      trackEvent({
        namespace: 'Open recipient dropdown',
        predicate: 'WO details',
        value: workorderData.id.toString(),
        payload: { recipients: recipients },
      }),
    );
  };

  const handleOpenPanel = () => {
    dispatch(toggleNotesPanelOpen());
  };

  const renderTooltipTitle = (note: Note) => {
    const date = new Date(note.createdDate);
    const noteContent =
      typeof note.noteData === 'string'
        ? note.noteData
        : note.noteData?.toString();
    return (
      <>
        <strong>{note.createdBy}</strong>
        <br />
        <Interweave
          content={
            noteContent?.substring(0, 210) +
            (noteContent && noteContent.length > 210 ? '...' : '')
          }
        />
        <br />
        <strong>
          #{note.number} - {date.toLocaleString()} (
          <TimeAgo date={new Date(note.createdDate)} />)
        </strong>
      </>
    );
  };

  useEffect(() => {
    const filteredNotes = woNotes.filter((note) => {
      if (typeof note.noteData === 'string') {
        return note.noteData.toLowerCase().includes(search?.toLowerCase());
      } else if (typeof note.noteData === 'number') {
        return note.noteData.toString().includes(search?.toLowerCase());
      } else {
        return false;
      }
    });
    setFilteredWoNotes(filteredNotes);
  }, [search, woNotes]);

  return (
    <Stack className="workorder-notes" sx={{ pt: '1rem' }}>
      <Tooltip
        title={woNotes.length > 0 && renderTooltipTitle(woNotes[0])}
        placement="top"
        arrow>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenPanel}
          fullWidth
          endIcon={
            isPanelOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )
          }>
          {woNotes
            ? isPanelOpen
              ? `Hide all notes (${woNotes.length})`
              : `View all notes (${woNotes.length})`
            : 'Notes'}
          {notes.loadingNotes && (
            <CircularProgress
              sx={{
                color: theme.palette.background.default,
                height: '1rem !important',
                width: '1rem !important',
                marginLeft: '1rem',
              }}
            />
          )}
        </Button>
      </Tooltip>
      {user?.note_sender && (
        <Stack ref={actionRequiredRef} spacing={1} sx={{ pt: '1rem' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}>
            <Typography variant="h6" component="h3" fontWeight="bold">
              Action Required
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography>Send a Copy to Myself</Typography>
              <Checkbox
                checked={sendNoteToSelf}
                onChange={(e) => setSendNoteToSelf(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{ color: theme.palette.secondary.main }}
              />
            </Stack>
          </Stack>
          <TextField
            minRows={4}
            multiline
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Autocomplete
            multiple
            id="tags-outlined"
            options={[
              ...(recipientEmailFromInput
                ? [
                    {
                      index: woRecipients.length + selectedRecipients.length,
                      email: recipientEmailFromInput,
                    } as IndexedRecipient,
                  ]
                : []),
              ...woRecipients.map((recipient, index) => ({
                ...recipient,
                index: index,
              })),
            ]}
            value={selectedRecipients}
            onChange={(e, value) => {
              setSelectedRecipients(value);
              setRecipientEmailFromInput('');
            }}
            getOptionLabel={(option) => option.email}
            isOptionEqualToValue={(option, value) =>
              option.index === value.index
            }
            filterSelectedOptions
            limitTags={2}
            onInputChange={(e) =>
              setRecipientEmailFromInput((e.target as HTMLInputElement).value)
            }
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.index}>
                {option.email}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Recipients" />
            )}
            componentsProps={{
              popper: { popperOptions: { placement: 'top' } },
            }}
            onOpen={handleRecipientOpen}
            disabled={notes.loadingRecipients}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddNote}
            fullWidth>
            Submit
          </Button>
        </Stack>
      )}
      {isPanelOpen && (
        <Portal container={() => document.querySelector('#side-panel')}>
          <Stack
            sx={{
              position: 'absolute',
              top: '0',
              left: ipad ? '26rem' : '32rem',
              width: ipad ? '28rem' : '42rem',
              bottom: 0,
              p: '0.5rem',
              pl: '0.25rem',
              zIndex: 100,
              backgroundColor: theme.palette.background.default,
            }}>
            <TextField
              placeholder="Search notes"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              fullWidth
              autoFocus={!ipad}
            />
            <Box
              sx={{
                overflow: 'auto',
                mt: '0.5rem',
                mr: ipad ? '-0.5rem' : 'unset',
              }}>
              {filteredWoNotes?.length > 0 &&
                filteredWoNotes.map((note, index) => (
                  <RenderNote key={index} note={note} fullWidth />
                ))}
              {notes.loadingNotes && !filteredWoNotes?.length && (
                <Spinner isVisible dimBackground />
              )}
              {!notes.loadingNotes && !filteredWoNotes?.length && (
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                  }}>
                  No notes found
                </Typography>
              )}
            </Box>
            <Tooltip title={'Hide notes panel'} placement="right" arrow>
              <Button
                variant="contained"
                onClick={handleOpenPanel}
                sx={{
                  position: 'absolute',
                  top: '0.75rem',
                  left: ipad ? '28rem' : '42rem',
                  minWidth: '2rem',
                  width: '2rem',
                  height: '3rem',
                  zIndex: 90,
                  backgroundColor: theme.palette.background.light,
                  color: 'white',
                }}>
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Portal>
      )}
    </Stack>
  );
};

export default WorkorderNotes;
