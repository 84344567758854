/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from 'reselect';
import IStore, { IAction } from 'lib/redux/models';
import {
  isNotEmpty,
  isDataInitialized,
  getDefaultCallDate,
  updateWorkorderValues,
  regionMarkersFromNestedClubs,
  regionClubsFromNestedClubs,
  marketClubsFromNestedClubs,
  calculateDateRange,
  safeGetObjectValue,
} from 'utils/utils';
import {
  AppData,
  AggregationLevel,
  EAppActionTypes,
  IAppState,
  AppFilters,
  WorkorderAggregate,
  NestedGeojson,
  Workorder,
  AppPanelFilters,
} from '../models';
import heatmapData from '../../../assets/data/heatmap.json';
import { StringItem } from 'components/FilterBar/FilterMenu';

// ACTION TYPES
export const Types = {
  FAILURE: EAppActionTypes.FAILURE,
  WARNING: EAppActionTypes.WARNING,
  FULFILL: EAppActionTypes.FULFILL,
  REQUEST: EAppActionTypes.REQUEST,
  CLEAR_STATE: EAppActionTypes.CLEAR_STATE,
  GET_METADATA: EAppActionTypes.GET_METADATA,
  SET_METADATA: EAppActionTypes.SET_METADATA,
  GET_GEOJSON: EAppActionTypes.GET_GEOJSON,
  SET_GEOJSON: EAppActionTypes.SET_GEOJSON,
  GET_LOCATIONS: EAppActionTypes.GET_LOCATIONS,
  SET_LOCATIONS: EAppActionTypes.SET_LOCATIONS,
  SET_DATA: EAppActionTypes.SET_DATA,
  GET_AGGREGATE_DATA: EAppActionTypes.GET_AGGREGATE_DATA,
  GET_WORKORDER_DATA: EAppActionTypes.GET_WORKORDER_DATA,
  GET_WORKORDERS_CSV: EAppActionTypes.GET_WORKORDERS_CSV,
  GET_ALL_WORKORDERS_DATA: EAppActionTypes.GET_ALL_WORKORDERS_DATA,
  PAGINATE_WORKORDER_DATA: EAppActionTypes.PAGINATE_WORKORDER_DATA,
  APPEND_WORKORDER_DATA: EAppActionTypes.APPEND_WORKORDER_DATA,
  GET_HEATMAP_DATA: EAppActionTypes.GET_HEATMAP_DATA,
  SELECT_HEATMAP_CELL: EAppActionTypes.SELECT_HEATMAP_CELL,
  SET_ZOOM: EAppActionTypes.SET_ZOOM,
  SET_LAT: EAppActionTypes.SET_LAT,
  SET_LNG: EAppActionTypes.SET_LNG,
  SET_FILTER: EAppActionTypes.SET_FILTER,
  SET_REGION: EAppActionTypes.SET_REGION,
  SET_MARKET: EAppActionTypes.SET_MARKET,
  SET_CLUB: EAppActionTypes.SET_CLUB,
  SET_DATE_RANGE: EAppActionTypes.SET_DATE_RANGE,
  SET_CUSTOM_DATE_RANGE: EAppActionTypes.SET_CUSTOM_DATE_RANGE,
  SET_CALL_DATE: EAppActionTypes.SET_CALL_DATE,
  GET_WORKORDER: EAppActionTypes.GET_WORKORDER,
  SET_WORKORDER: EAppActionTypes.SET_WORKORDER,
  SET_ACTIVE_WORKORDER: EAppActionTypes.SET_ACTIVE_WORKORDER,
  SET_SORT_FIELD: EAppActionTypes.SET_SORT_FIELD,
  SET_SORT_ASC: EAppActionTypes.SET_SORT_ASC,
  SET_MAP: EAppActionTypes.SET_MAP,
  SET_IS_MAP_LOADED: EAppActionTypes.SET_IS_MAP_LOADED,
  SET_HOVERED_FILL: EAppActionTypes.SET_HOVERED_FILL,
  SET_HOVERED_AGGREGATE: EAppActionTypes.SET_HOVERED_AGGREGATE,
  SET_HOVERED_WORKORDER: EAppActionTypes.SET_HOVERED_WORKORDER,
  CLEAR_FILTERS: EAppActionTypes.CLEAR_FILTERS,
  SET_SHOW_CROSSHAIR: EAppActionTypes.SET_SHOW_CROSSHAIR,
  TOGGLE_WORKING_ON_IT: EAppActionTypes.TOGGLE_WORKING_ON_IT,
  BATCH_UPDATE_WORKING_ON_IT: EAppActionTypes.BATCH_UPDATE_WORKING_ON_IT,
  TOGGLE_ESCALATED: EAppActionTypes.TOGGLE_ESCALATED,
  BATCH_UPDATE_ESCALATED: EAppActionTypes.BATCH_UPDATE_ESCALATED,
  TAKE_OWNERSHIP: EAppActionTypes.TAKE_OWNERSHIP,
  UPDATE_WORKORDER: EAppActionTypes.UPDATE_WORKORDER,
  UPDATE_BOT_SHUT_OFF: EAppActionTypes.UPDATE_BOT_SHUT_OFF,
  SET_BOT_SHUT_OFF: EAppActionTypes.SET_BOT_SHUT_OFF,
  BATCH_UPDATE_BOT_SHUT_OFF: EAppActionTypes.BATCH_UPDATE_BOT_SHUT_OFF,
  GET_SCOREBOARD_DATA: EAppActionTypes.GET_SCOREBOARD_DATA,
  UPDATE_FILTERS: EAppActionTypes.UPDATE_FILTERS,
  SET_WORKORDER_LIST_FULLSCREEN: EAppActionTypes.SET_WORKORDER_LIST_FULLSCREEN,
  TOGGLE_MAP_SELECTION: EAppActionTypes.TOGGLE_MAP_SELECTION,
  GET_FILTER_OPTIONS: EAppActionTypes.GET_FILTER_OPTIONS,
  SET_FILTER_OPTIONS: EAppActionTypes.SET_FILTER_OPTIONS,
  TOGGLE_SIDE_PANEL_OPEN: EAppActionTypes.TOGGLE_SIDE_PANEL_OPEN,
  TOGGLE_NOTES_PANEL_OPEN: EAppActionTypes.TOGGLE_NOTES_PANEL_OPEN,
  TOGGLE_LINKED_WO_PANEL_OPEN: EAppActionTypes.TOGGLE_LINKED_WO_PANEL_OPEN,
  GET_LINKED_WORKORDERS: EAppActionTypes.GET_LINKED_WORKORDERS,
  SET_LINKED_WORKORDERS: EAppActionTypes.SET_LINKED_WORKORDERS,
  TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN:
    EAppActionTypes.TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN,
  GET_POTENTIAL_DUPLICATES: EAppActionTypes.GET_POTENTIAL_DUPLICATES,
  SET_POTENTIAL_DUPLICATES: EAppActionTypes.SET_POTENTIAL_DUPLICATES,
  TOGGLE_ATTACHMENTS_PANEL_OPEN: EAppActionTypes.TOGGLE_ATTACHMENTS_PANEL_OPEN,
  GET_ATTACHMENTS: EAppActionTypes.GET_ATTACHMENTS,
  SELECT_USER_VIEW: EAppActionTypes.SELECT_USER_VIEW,
  SELECT_TEAM_VIEW: EAppActionTypes.SELECT_TEAM_VIEW,
  GET_BSI_CONFIG_PARAMS: EAppActionTypes.GET_BSI_CONFIG_PARAMS,
};

// INITIAL STATE
export const defaultCenter: [number, number] = [
  -100.39327200908463, 36.1845556529244,
];
export const defaultZoom = 3.5;

// Temporary date values for testing
const lastUpdate = new Date();
lastUpdate.setHours(lastUpdate.getHours() - 1);

export const initialState: IAppState = {
  metadata: {
    version: undefined,
    bsi_updated_date: undefined,
    sc_portal_url: undefined,
    xp_multipliers: undefined,
    bot_status: undefined,
  },
  initialized: false,
  isMapLoaded: false,
  zoom: defaultZoom,
  lat: defaultCenter[1],
  lng: defaultCenter[0],
  filters: {
    region: undefined,
    market: undefined,
    club: undefined,
    importance: undefined,
    urgency: undefined,
    trades: undefined,
    status: ['OPEN', 'IN PROGRESS'],
    bsi: [],
    nte: undefined,
    workingOnIt: undefined,
    escalated: undefined,
    notOperationalOnly: undefined,
    potentialDuplicateOnly: undefined,
    workingOnItUser: undefined,
    escalatedUser: undefined,
    noActivitySince: undefined,
    equipmentTypes: undefined,
    dateRange: { name: 'All available dates', value: 'all' },
    callDate: {
      start: undefined,
      end: undefined,
    },
    workorder: undefined,
  },
  filtersOptions: {
    trades: [],
    tradeGroups: [],
    providers: [],
    categories: [],
    equipmentTypes: [],
    extendedStatus: [],
    problemDescription: [],
  },
  panelFilters: {},
  nestedClubs: [],
  regionMarkets: {},
  regionClubs: {},
  marketClubs: {},
  data: {
    aggregates: [],
    workorders: [],
    totalWorkorders: undefined,
    heatmap: heatmapData,
    scoreboard: undefined,
  },
  sortField: 'combined_index',
  sortAsc: false,
  page: 1,
  pageSize: 50,
  showCrosshair: false,
  loadingMetadata: false,
  loadingGeojson: false,
  loadingAggregates: false,
  loadingWorkorders: false,
  loadingHeatmap: false,
  loadingScoreboard: false,
  loadingCSV: false,
  isFullscreen: false,
  isSidePanelOpen: true,
  isNotesPanelOpen: false,
  isLinkedWOPanelOpen: false,
  isPotentialDuplicatesPanelOpen: false,
  isAttachmentPanelOpen: false,
  isMapSelectionActive: false,
};

// REDUCER
export default (
  state: IAppState = initialState,
  action?: IAction,
): IAppState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case Types.WARNING:
      return {
        ...state,
        warning: action.payload,
      };
    case Types.FULFILL: {
      const loadingKey = action.payload || 'loading';
      return {
        ...state,
        [loadingKey]: false,
      };
    }
    case Types.REQUEST: {
      const loadingKey = action.payload || 'loading';
      return {
        ...state,
        [loadingKey]: true,
        error: undefined,
        warning: undefined,
      };
    }
    case Types.CLEAR_STATE:
      return {
        ...initialState,
        filters: state.filters,
        filtersOptions: state.filtersOptions,
        panelFilters: state.panelFilters,
        nestedClubs: state.nestedClubs,
        regionMarkets: state.regionMarkets,
        regionClubs: state.regionClubs,
        marketClubs: state.marketClubs,
        metadata: state.metadata,
      };
    case Types.SET_METADATA:
      return {
        ...state,
        metadata: {
          ...action.payload,
          loaded: true,
        },
      };
    case Types.SET_GEOJSON:
      return {
        ...state,
        initialized: isDataInitialized(state.data),
        geojson: action.payload,
      };
    case Types.SET_LOCATIONS:
      return {
        ...state,
        nestedClubs: action.payload,
        regionMarkets: regionMarkersFromNestedClubs(action.payload),
        regionClubs: regionClubsFromNestedClubs(action.payload),
        marketClubs: marketClubsFromNestedClubs(action.payload),
      };
    case Types.SET_DATA:
      return {
        ...state,
        initialized: state.geojson ? true : false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case Types.APPEND_WORKORDER_DATA: {
      const workorders = state.data.workorders
        ? [...state.data.workorders, ...action.payload]
        : action.payload;
      return {
        ...state,
        page: state.page + 1,
        data: {
          ...state.data,
          workorders,
        },
      };
    }
    case Types.SELECT_HEATMAP_CELL: {
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...(action.payload
            ? action.payload
            : { importance: undefined, urgency: undefined }),
        },
      };
    }
    case Types.SET_ZOOM:
      return {
        ...state,
        zoom: action.payload,
      };
    case Types.SET_LAT:
      return {
        ...state,
        lat: action.payload,
      };
    case Types.SET_LNG:
      return {
        ...state,
        lng: action.payload,
      };
    case Types.SET_REGION:
      return {
        ...state,
        filters: {
          ...state.filters,
          region: action.payload?.length > 0 ? action.payload : undefined,
          market: undefined,
          club: undefined,
          workorder: undefined,
        },
        page: 1,
      };
    case Types.SET_MARKET: {
      /**
       * Find markets corresponding regions and set the region filter
       * too if it is not already set.
       */
      let regionFilter: string[] | undefined = [];
      if (isNotEmpty(action.payload) && !state.filters.region) {
        state.nestedClubs?.forEach((r) => {
          r.markets.forEach((m) => {
            if (
              action.payload.includes(m.market_id) &&
              regionFilter?.indexOf(r.region_name) === -1
            ) {
              regionFilter = [...regionFilter, r.region_name];
            }
          });
        });
      } else {
        regionFilter = state.filters.region;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          region: regionFilter,
          market: action.payload?.length > 0 ? action.payload : undefined,
          club: undefined,
          workorder: undefined,
        },
        page: 1,
      };
    }
    case Types.SET_CLUB: {
      let regionFilter: string[] | undefined = [];
      if (isNotEmpty(action.payload) && !state.filters.region) {
        state.nestedClubs?.forEach((r) => {
          r.markets.forEach((m) => {
            if (
              m.locations
                .map((c) => action.payload.includes(c.store_id))
                .some((v) => v)
            ) {
              if (regionFilter?.indexOf(r.region_name) === -1) {
                regionFilter = [...regionFilter, r.region_name];
              }
            }
          });
        });
      } else {
        regionFilter = state.filters.region;
      }
      let marketFilter: number[] | undefined = [];
      if (isNotEmpty(action.payload) && !state.filters.market) {
        state.nestedClubs?.forEach((r) => {
          r.markets.forEach((m) => {
            if (
              m.locations
                .map((c) => action.payload.includes(c.store_id))
                .some((v) => v)
            ) {
              if (marketFilter?.indexOf(m.market_id) === -1) {
                marketFilter = [...marketFilter, m.market_id];
              }
            }
          });
        });
      } else {
        marketFilter = state.filters.market;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          region: regionFilter,
          market: marketFilter,
          club: action.payload?.length > 0 ? action.payload : undefined,
          workorder: undefined,
        },
        page: 1,
      };
    }
    case Types.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
        page: 1,
      };
    case Types.SET_DATE_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: action.payload,
          callDate: calculateDateRange(action.payload, state.filters.callDate),
        },
        page: 1,
      };
    case Types.SET_CALL_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          callDate: {
            ...state.filters.callDate,
            ...action.payload,
          },
        },
        page: 1,
      };
    case Types.SET_WORKORDER:
      return {
        ...state,
        filters: {
          ...state.filters,
          workorder: action.payload,
        },
        isNotesPanelOpen: false,
        isFullscreen: false,
      };
    case Types.SET_ACTIVE_WORKORDER:
      return {
        ...state,
        data: {
          ...state.data,
          activeWorkorder: action.payload,
        },
      };
    case Types.SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        page: 1,
      };
    case Types.SET_SORT_ASC:
      return {
        ...state,
        sortAsc: action.payload,
        page: 1,
      };
    case Types.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case Types.SET_IS_MAP_LOADED:
      return {
        ...state,
        isMapLoaded: action.payload,
      };
    case Types.SET_HOVERED_FILL:
      return {
        ...state,
        hoveredFill: action.payload,
      };
    case Types.SET_HOVERED_AGGREGATE:
      return {
        ...state,
        hoveredAggregate: action.payload,
      };
    case Types.SET_HOVERED_WORKORDER:
      return {
        ...state,
        hoveredWorkorder: action.payload,
      };
    case Types.CLEAR_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          activeWorkorder: undefined,
        },
        filters: {
          ...state.filters,
          ...(action.payload?.keepGeoFilter
            ? {}
            : { region: undefined, market: undefined, club: undefined }),
          workorders: undefined,
          trades: undefined,
          tradeGroups: undefined,
          providers: undefined,
          categories: undefined,
          status: ['OPEN', 'IN PROGRESS'],
          bsi: undefined,
          nte: undefined,
          workingOnIt: undefined,
          escalated: undefined,
          notOperationalOnly: undefined,
          potentialDuplicateOnly: undefined,
          missedETA: undefined,
          maintenanceRepairOnly: undefined,
          workingOnItUser: undefined,
          escalatedUser: undefined,
          noActivitySince: undefined,
          equipmentTypes: undefined,
          extendedStatus: undefined,
          problemDescription: undefined,
          dateRange: { name: 'All available dates', value: 'all' },
          callDate: getDefaultCallDate(state.metadata.bsi_updated_date),
          importance: undefined,
          urgency: undefined,
          workorder: undefined,
        },
        isFullscreen: false,
      };
    case Types.SET_SHOW_CROSSHAIR:
      return {
        ...state,
        showCrosshair: action.payload,
      };
    case Types.UPDATE_WORKORDER: {
      return {
        ...state,
        data: {
          ...state.data,
          ...updateWorkorderValues(
            action.payload.workorderId,
            action.payload.data,
            state.data,
          ),
        },
      };
    }
    case Types.SET_BOT_SHUT_OFF: {
      let activeWorkorder = state.data.activeWorkorder;
      let workorders = state.data.workorders;
      if (state.data.activeWorkorder?.id === action.payload.workorderId) {
        activeWorkorder = {
          ...state.data.activeWorkorder,
          id: action.payload.workorderId,
          is_bot_shut_off: action.payload.value,
          bot_shut_off_user: action.payload.user,
        };
      }
      if (workorders) {
        workorders = [...workorders];
        const update = workorders.find(
          (w) => w.id === action.payload.workorderId,
        );
        if (update) {
          update.is_bot_shut_off = action.payload.value;
          update.bot_shut_off_user = action.payload.user;
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          workorders,
          activeWorkorder,
        },
      };
    }
    case Types.UPDATE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }
    case Types.SET_WORKORDER_LIST_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: action.payload,
      };
    }
    case Types.TOGGLE_MAP_SELECTION: {
      return {
        ...state,
        isMapSelectionActive: !state.isMapSelectionActive,
      };
    }
    case Types.SET_FILTER_OPTIONS: {
      return {
        ...state,
        filtersOptions: {
          ...action.payload,
        },
      };
    }
    case Types.TOGGLE_SIDE_PANEL_OPEN: {
      return {
        ...state,
        isSidePanelOpen: !state.isSidePanelOpen,
        isNotesPanelOpen: false,
        isLinkedWOPanelOpen: false,
        isAttachmentPanelOpen: false,
        isPotentialDuplicatesPanelOpen: false,
      };
    }
    case Types.TOGGLE_NOTES_PANEL_OPEN: {
      return {
        ...state,
        isNotesPanelOpen: !state.isNotesPanelOpen,
        isLinkedWOPanelOpen: false,
        isAttachmentPanelOpen: false,
        isPotentialDuplicatesPanelOpen: false,
      };
    }
    case Types.TOGGLE_LINKED_WO_PANEL_OPEN: {
      return {
        ...state,
        isLinkedWOPanelOpen: !state.isLinkedWOPanelOpen,
        isNotesPanelOpen: false,
        isAttachmentPanelOpen: false,
        isPotentialDuplicatesPanelOpen: false,
      };
    }
    case Types.TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN: {
      return {
        ...state,
        isPotentialDuplicatesPanelOpen: !state.isPotentialDuplicatesPanelOpen,
        isNotesPanelOpen: false,
        isLinkedWOPanelOpen: false,
        isAttachmentPanelOpen: false,
      };
    }
    case Types.SET_LINKED_WORKORDERS: {
      return {
        ...state,
        data: {
          ...state.data,
          linkedWorkorderOriginal: action.payload.original,
          linkedWorkorderFollowUp: action.payload.followUp,
        },
      };
    }
    case Types.SET_POTENTIAL_DUPLICATES: {
      return {
        ...state,
        data: {
          ...state.data,
          potentialDuplicates: action.payload,
        },
      };
    }
    case Types.TOGGLE_ATTACHMENTS_PANEL_OPEN: {
      return {
        ...state,
        isAttachmentPanelOpen: !state.isAttachmentPanelOpen,
        isNotesPanelOpen: false,
        isLinkedWOPanelOpen: false,
      };
    }
    case Types.SELECT_USER_VIEW: {
      return {
        ...state,
        filters:
          action.payload.dateRange &&
          action.payload.dateRange.value !== 'custom'
            ? {
                ...action.payload,
                callDate: calculateDateRange(
                  action.payload.dateRange,
                  state.filters.callDate,
                ),
              }
            : action.payload,
      };
    }
    case Types.SELECT_TEAM_VIEW: {
      return {
        ...state,
        filters: {
          region: state.filters.region,
          market: state.filters.market,
          club: state.filters.club,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

// ACTIONS

export const failure = (payload: { message: string; data?: any }): IAction => {
  return {
    type: Types.FAILURE,
    payload,
  };
};

export const warning = (payload: { message: string; data?: any }): IAction => {
  return {
    type: Types.WARNING,
    payload,
  };
};

export const fulfill = (payload?: string): IAction => {
  return {
    type: Types.FULFILL,
    payload,
  };
};

export const request = (payload?: string): IAction => {
  return {
    type: Types.REQUEST,
    payload,
  };
};

export const clearState = (): IAction => {
  return {
    type: Types.CLEAR_STATE,
  };
};

export const getMetadata = (): IAction => {
  return {
    type: Types.GET_METADATA,
  };
};

export const setMetadata = (payload: IAppState['metadata']): IAction => {
  return {
    type: Types.SET_METADATA,
    payload,
  };
};

export const getGeojson = (): IAction => {
  return {
    type: Types.GET_GEOJSON,
  };
};

export const setGeojson = (payload: NestedGeojson): IAction => {
  return {
    type: Types.SET_GEOJSON,
    payload,
  };
};

export const getLocations = (): IAction => {
  return {
    type: Types.GET_LOCATIONS,
  };
};

export const setLocations = (payload: IAppState['nestedClubs']): IAction => {
  return {
    type: Types.SET_LOCATIONS,
    payload,
  };
};

export const getAggregateData = (payload: {
  filters: AppFilters;
  aggregationLevel: AggregationLevel;
}): IAction => {
  return {
    type: Types.GET_AGGREGATE_DATA,
    payload,
  };
};

export const getWorkorderData = (payload: {
  filters: AppFilters;
  panelFilters: AppPanelFilters;
  sortParam: string[];
}): IAction => {
  return {
    type: Types.GET_WORKORDER_DATA,
    payload,
  };
};

export const getWorkordersCSV = (payload: {
  filters: AppFilters;
  panelFilters: AppPanelFilters;
  sortParam: string[];
}): IAction => {
  return {
    type: Types.GET_WORKORDERS_CSV,
    payload,
  };
};

export const getAllWorkordersData = (payload: {
  filters: AppFilters;
  sortParam: string[];
}): IAction => {
  return {
    type: Types.GET_ALL_WORKORDERS_DATA,
    payload,
  };
};

export const paginateWorkorderData = (payload: {
  filters: AppFilters;
  sortParam: string[];
}): IAction => {
  return {
    type: Types.PAGINATE_WORKORDER_DATA,
    payload,
  };
};

export const appendWorkorderData = (payload: Workorder[]): IAction => {
  return {
    type: Types.APPEND_WORKORDER_DATA,
    payload,
  };
};

export const getHeatmapData = (payload: { filters: AppFilters }): IAction => {
  return {
    type: Types.GET_HEATMAP_DATA,
    payload,
  };
};

export const selectHeatmapCell = (payload?: {
  importance: number;
  urgency: number;
}): IAction => {
  return {
    type: Types.SELECT_HEATMAP_CELL,
    payload,
  };
};

export const setData = (payload: Partial<AppData>): IAction => {
  return {
    type: Types.SET_DATA,
    payload,
  };
};

export const setZoom = (payload: number): IAction => {
  return {
    type: Types.SET_ZOOM,
    payload,
  };
};

export const setLat = (payload: number): IAction => {
  return {
    type: Types.SET_LAT,
    payload,
  };
};

export const setLng = (payload: number): IAction => {
  return {
    type: Types.SET_LNG,
    payload,
  };
};

export const setRegion = (payload?: string[]): IAction => {
  return {
    type: Types.SET_REGION,
    payload,
  };
};

export const setMarket = (payload?: number[]): IAction => {
  return {
    type: Types.SET_MARKET,
    payload,
  };
};

export const setClub = (payload?: string[]): IAction => {
  return {
    type: Types.SET_CLUB,
    payload,
  };
};

export const setWorkorders = (payload?: number[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'workorders', value: payload },
  };
};

export const setWorkingOnItUser = (payload?: string): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'workingOnItUser', value: payload },
  };
};

export const setEscalatedUser = (payload?: string): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'escalatedUser', value: payload },
  };
};

export const setTrades = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'trades', value: payload },
  };
};

export const setTradeGroups = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'tradeGroups', value: payload },
  };
};

export const setProviders = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'providers', value: payload },
  };
};

export const setCategories = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'categories', value: payload },
  };
};

export const setStatus = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'status', value: payload },
  };
};

export const setBSI = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'bsi', value: payload },
  };
};

export const setNTE = (payload?: StringItem): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'nte', value: payload },
  };
};

export const setWorkingOnItFilter = (payload?: StringItem): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'workingOnIt', value: payload },
  };
};

export const setEscalatedFilter = (payload?: StringItem): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'escalated', value: payload },
  };
};

export const setNotOperationalOnly = (
  payload: AppFilters['notOperationalOnly'],
): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'notOperationalOnly', value: payload },
  };
};

export const setPotentialDuplicateOnly = (
  payload: AppFilters['potentialDuplicateOnly'],
): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'potentialDuplicateOnly', value: payload },
  };
};

export const setMissedETA = (payload: AppFilters['missedETA']): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'missedETA', value: payload },
  };
};

export const setNoActivitySince = (payload?: StringItem): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'noActivitySince', value: payload },
  };
};

export const setMaintenanceRepairOnly = (
  payload: AppFilters['maintenanceRepairOnly'],
): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'maintenanceRepairOnly', value: payload },
  };
};

export const setEquipmentTypes = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'equipmentTypes', value: payload },
  };
};

export const setExtendedStatus = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'extendedStatus', value: payload },
  };
};

export const setProblemDescription = (payload?: string[]): IAction => {
  return {
    type: Types.SET_FILTER,
    payload: { key: 'problemDescription', value: payload },
  };
};

export const setDateRange = (payload?: AppFilters['dateRange']): IAction => {
  return {
    type: Types.SET_DATE_RANGE,
    payload,
  };
};

export const setCallDate = (payload?: AppFilters['callDate']): IAction => {
  return {
    type: Types.SET_CALL_DATE,
    payload,
  };
};

export const getWorkorder = (payload?: {
  id: Workorder['id'];
  filters: AppFilters;
  updateActiveWorkorder?: boolean;
}): IAction => {
  return {
    type: Types.GET_WORKORDER,
    payload,
  };
};

export const setWorkorder = (payload?: number): IAction => {
  return {
    type: Types.SET_WORKORDER,
    payload,
  };
};

export const setActiveWorkorder = (payload?: Workorder): IAction => {
  return {
    type: Types.SET_ACTIVE_WORKORDER,
    payload,
  };
};

export const setSortField = (payload: string): IAction => {
  return {
    type: Types.SET_SORT_FIELD,
    payload,
  };
};

export const setSortAsc = (payload: boolean): IAction => {
  return {
    type: Types.SET_SORT_ASC,
    payload,
  };
};

export const setMap = (payload: maplibregl.Map): IAction => {
  return {
    type: Types.SET_MAP,
    payload,
  };
};

export const setIsMapLoaded = (payload: boolean): IAction => {
  return {
    type: Types.SET_IS_MAP_LOADED,
    payload,
  };
};

export const setHoveredFill = (payload?: WorkorderAggregate): IAction => {
  return {
    type: Types.SET_HOVERED_FILL,
    payload,
  };
};

export const setHoveredAggregate = (payload?: WorkorderAggregate): IAction => {
  return {
    type: Types.SET_HOVERED_AGGREGATE,
    payload,
  };
};

export const setHoveredWorkorder = (payload?: Workorder): IAction => {
  return {
    type: Types.SET_HOVERED_WORKORDER,
    payload,
  };
};

export const clearFilters = (payload?: {
  keepGeoFilter?: boolean;
}): IAction => {
  return {
    type: Types.CLEAR_FILTERS,
    payload,
  };
};

export const setShowCrosshair = (payload: boolean): IAction => {
  return {
    type: Types.SET_SHOW_CROSSHAIR,
    payload,
  };
};

export const toggleWorkingOnIt = (payload: {
  workorderId: number;
}): IAction => {
  return {
    type: Types.TOGGLE_WORKING_ON_IT,
    payload,
  };
};

export const batchUpdateWorkingOnIt = (payload: {
  workorders: number[];
  value: boolean;
}): IAction => {
  return {
    type: Types.BATCH_UPDATE_WORKING_ON_IT,
    payload,
  };
};

export const toggleEscalated = (payload: { workorderId: number }): IAction => {
  return {
    type: Types.TOGGLE_ESCALATED,
    payload,
  };
};

export const batchUpdateEscalated = (payload: {
  workorders: number[];
  value: boolean;
}): IAction => {
  return {
    type: Types.BATCH_UPDATE_ESCALATED,
    payload,
  };
};

export const takeOwnership = (payload: { workorderId: number }): IAction => {
  return {
    type: Types.TAKE_OWNERSHIP,
    payload,
  };
};

export const updateWorkorder = (payload: {
  workorderId: number;
  data: Partial<Workorder>;
}): IAction => {
  return {
    type: Types.UPDATE_WORKORDER,
    payload,
  };
};

export const updateBotShutOff = (payload: {
  workorderId: number;
  value: boolean;
  user?: string;
}): IAction => {
  return {
    type: Types.UPDATE_BOT_SHUT_OFF,
    payload,
  };
};

export const setBotShutOff = (payload: {
  workorderId: number;
  value: boolean;
  user?: string;
}): IAction => {
  return {
    type: Types.SET_BOT_SHUT_OFF,
    payload,
  };
};

export const batchUpdateBotShutOff = (payload: {
  workorders: number[];
  value: boolean;
  user?: string;
}): IAction => {
  return {
    type: Types.BATCH_UPDATE_BOT_SHUT_OFF,
    payload,
  };
};

export const getScoreboardData = (): IAction => {
  return {
    type: Types.GET_SCOREBOARD_DATA,
  };
};

export const updateFilters = (payload: Partial<AppFilters>): IAction => {
  return {
    type: Types.UPDATE_FILTERS,
    payload,
  };
};

export const setWorkorderListFullscreen = (payload: boolean): IAction => {
  return {
    type: Types.SET_WORKORDER_LIST_FULLSCREEN,
    payload,
  };
};

export const toggleMapSelection = (): IAction => {
  return {
    type: Types.TOGGLE_MAP_SELECTION,
  };
};

export const getFilterOptions = (): IAction => {
  return {
    type: Types.GET_FILTER_OPTIONS,
  };
};

export const setFilterOptions = (
  payload: IAppState['filtersOptions'],
): IAction => {
  return {
    type: Types.SET_FILTER_OPTIONS,
    payload,
  };
};

export const toggleSidePanelOpen = (): IAction => {
  return {
    type: Types.TOGGLE_SIDE_PANEL_OPEN,
  };
};

export const toggleNotesPanelOpen = (): IAction => {
  return {
    type: Types.TOGGLE_NOTES_PANEL_OPEN,
  };
};

export const toggleLinkedWOPanelOpen = (): IAction => {
  return {
    type: Types.TOGGLE_LINKED_WO_PANEL_OPEN,
  };
};

export const getLinkedWorkorders = (payload: {
  original: number[];
  followUp: number[];
}): IAction => {
  return {
    type: Types.GET_LINKED_WORKORDERS,
    payload: payload,
  };
};

export const setLinkedWorkorders = (payload: {
  original: Workorder[];
  followUp: Workorder[];
}): IAction => {
  return {
    type: Types.SET_LINKED_WORKORDERS,
    payload: payload,
  };
};
export const togglePotentialDuplicatesPanelOpen = (): IAction => {
  return {
    type: Types.TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN,
  };
};

export const getPotentialDuplicates = (payload: number[]): IAction => {
  return {
    type: Types.GET_POTENTIAL_DUPLICATES,
    payload: payload,
  };
};

export const setPotentialDuplicates = (payload: Workorder[]): IAction => {
  return {
    type: Types.SET_POTENTIAL_DUPLICATES,
    payload: payload,
  };
};

export const toggleAttachmentsPanelOpen = (): IAction => {
  return {
    type: Types.TOGGLE_ATTACHMENTS_PANEL_OPEN,
  };
};

export const getAttachments = (payload: Workorder['id']): IAction => {
  return {
    type: Types.GET_ATTACHMENTS,
    payload: payload,
  };
};

export const selectUserView = (payload: AppFilters): IAction => {
  return {
    type: Types.SELECT_USER_VIEW,
    payload: payload,
  };
};

export const selectTeamView = (payload: AppFilters): IAction => {
  return {
    type: Types.SELECT_TEAM_VIEW,
    payload: payload,
  };
};

export const getBsiConfigParams = (): IAction => {
  return {
    type: Types.GET_BSI_CONFIG_PARAMS,
  };
};

export const actions = {
  failure,
  fulfill,
  request,
};

// SELECTORS
export const selectNestedClubs = (state: IStore) => state.app.nestedClubs;
export const selectRegionMarkets = (state: IStore) => state.app.regionMarkets;
export const selectRegionClubs = (state: IStore) => state.app.regionClubs;
export const selectMarketClubs = (state: IStore) => state.app.marketClubs;
export const selectFilters = (state: IStore) => state.app.filters;
export const selectPanelFilters = (state: IStore) => state.app.panelFilters;
export const selectData = (state: IStore) => state.app.data;
export const selectAggregates = (state: IStore) => state.app.data.aggregates;
export const selectWorkorders = (state: IStore) => state.app.data.workorders;
export const selectTotalWorkorders = (state: IStore) =>
  state.app.data.totalWorkorders;
export const selectFrequentServiceProviders = (state: IStore) =>
  state.app.data.frequentServiceProviders;
export const selectScoreboard = (state: IStore) => state.app.data.scoreboard;
export const selectHoveredWorkorder = (state: IStore) =>
  state.app.hoveredWorkorder;
export const selectHoveredAggregate = (state: IStore) =>
  state.app.hoveredAggregate;
export const selectSortField = (state: IStore) => state.app.sortField;
export const selectSortAsc = (state: IStore) => state.app.sortAsc;
export const selectPage = (state: IStore) => state.app.page;
export const selectPageSize = (state: IStore) => state.app.pageSize;

/**
 * Get the options for the regions dropdown
 */
export const selectRegionOptions = createSelector(
  [selectNestedClubs, selectFilters],
  (nestedClubs) => {
    return nestedClubs?.map((r) => r.region_name).sort() as string[];
  },
);

/**
 * Get the options for the markets dropdown.
 * If a region is selected, only show markets in that region.
 */
export const selectMarketOptions = createSelector(
  [selectNestedClubs, selectFilters, selectRegionMarkets],
  (nestedClubs, filters, regionMarkets) => {
    let markets: number[] = [];
    if (regionMarkets && filters.region) {
      filters.region.forEach((regionFilter) => {
        const region = safeGetObjectValue(regionMarkets, regionFilter);
        if (region) markets = [...markets, ...region];
      });
    } else {
      nestedClubs?.forEach((r) => {
        markets = markets.concat(r.markets.map((m) => m.market_id));
      });
    }
    return markets.sort((a, b) => a - b);
  },
);

/**
 * Get the options for the clubs dropdown.
 * If a region or market is selected, only show clubs in that region or market.
 */
export const selectClubOptions = createSelector(
  [selectNestedClubs, selectFilters, selectRegionClubs, selectMarketClubs],
  (nestedClubs, filters, regionClubs, marketClubs) => {
    let clubs: string[] = [];
    if (regionClubs && filters.region && !filters.market) {
      filters.region.forEach((regionFilter) => {
        const region = safeGetObjectValue(regionClubs, regionFilter);
        if (region) clubs = [...clubs, ...region];
      });
    } else if (marketClubs && filters.region && filters.market) {
      filters.market.forEach((marketFilter) => {
        const market = safeGetObjectValue(marketClubs, marketFilter.toString());
        if (market) clubs = [...clubs, ...market];
      });
    } else {
      nestedClubs?.forEach((r) => {
        r.markets.forEach((m) => {
          clubs = clubs.concat(m.locations.map((c) => c.store_id));
        });
      });
    }
    return clubs.sort();
  },
);

/**
 * Get the level at which the map data is aggregated (Region, Market, or Club).
 * This determined by the most granular active filter.
 */
export const selectAggregationLevel = createSelector(
  selectFilters,
  (filters) => {
    if (isNotEmpty(filters.market)) {
      return AggregationLevel.CLUB;
    } else if (isNotEmpty(filters.region)) {
      return AggregationLevel.MARKET;
    } else {
      return AggregationLevel.REGION;
    }
  },
);

/**
 * Get the aggregate node that relates to the hovered workorder item
 */
export const selectAggregateFromWorkorder = createSelector(
  [selectHoveredWorkorder, selectAggregates],
  (hoveredWorkorder, aggregates) => {
    if (aggregates && hoveredWorkorder) {
      return aggregates.find((d) => {
        if (d.aggregation_level === 'Region') {
          return d.id === hoveredWorkorder.region_id;
        } else if (d.aggregation_level === 'Market') {
          return d.id === hoveredWorkorder.market_id;
        } else if (d.aggregation_level === 'Club') {
          return (
            hoveredWorkorder?.club_id &&
            d.id === parseInt(hoveredWorkorder.club_id, 10)
          );
        }
      });
    } else {
      return;
    }
  },
);

export const selectSortParam = createSelector(
  [selectSortField, selectSortAsc],
  (sortField, sortAsc) => {
    const prefix = sortAsc ? '' : '-';
    if (sortField === 'combined_index') {
      return [`${prefix}${sortField}`];
    } else {
      return [`${prefix}${sortField}`, '-combined_index'];
    }
  },
);
