import React, { CSSProperties, FC } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import MakepathLogo from 'assets/makepath_logo.png';
import { isTablet } from 'utils/utils';

interface ByMakepathProps {
  boxStyles?: CSSProperties
}

const ByMakepath: FC<ByMakepathProps> = ({ boxStyles }) => {
  const ipad = isTablet();

  return (
    <Box
      sx={{
        zIndex: 300,
        opacity: 0.9,
        p: 1,
        backgroundColor: 'background.paper',
        pointerEvents: 'all',
        ...boxStyles
      }}
    >
      <a href="https://makepath.com" target='_blank' rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Typography fontSize={ipad ? "0.8rem" : "small"} paddingBottom="1px" noWrap>created by</Typography>
          <img src={MakepathLogo} alt="Created by makepath" height={ipad ? 20 : 28} style={{ marginBottom: '-0.25rem' }}></img>
        </Stack>
      </a>
    </Box>
  );
};

export default ByMakepath;
