import React, { FC, useEffect, useState } from 'react';
import { Alert, Portal, Typography } from '@mui/material';
import { isTablet } from 'utils/utils';


const Key: FC<{ children: string }> = ({ children }) => {
  return (
    <code style={{
      backgroundColor: '#eee',
      padding: '0.25rem 0.5rem',
      margin: '0 0.25rem',
      borderRadius: '0.5rem',
      fontFamily: 'monospace',
      fontWeight: 'bold',
      fontSize: '1rem',
      color: '#333',
    }}>
      {children}
    </code>
  )
}


const ScaledDisplayNotification: FC = () => {
  const [isScaled, setIsScaled] = useState<boolean>(window.devicePixelRatio !== 1);
  const [shouldDecreaseZoom, setShouldDecreaseZoom] = useState<boolean>(window.devicePixelRatio > 1);
  const [dismissed, setDismissed] = useState<boolean>(false);
  const ipad = isTablet();


  useEffect(() => {
    const handleResize = () => {
      setIsScaled(window.devicePixelRatio !== 1);
      setShouldDecreaseZoom(window.devicePixelRatio >= 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ctrlKey = window.navigator.userAgent.indexOf("Mac") != -1 ? '⌘' : 'CTRL';
  const zoomDirection = shouldDecreaseZoom ? '-' : '+';

  return (
    <Portal container={() => document.querySelector('#stacked-alerts')}>
      {isScaled && !dismissed && !ipad &&
        <Alert onClick={() => setDismissed(true)} severity="info" variant="filled" sx={{ '.MuiAlert-icon': { alignItems: 'center' } }}>
          <Typography>
            For the best experience, change the browser zoom by pressing <Key>{ctrlKey}</Key>+<Key>{zoomDirection}</Key>
          </Typography>
        </Alert>
      }
    </Portal>
  );
}

export default ScaledDisplayNotification;
