

/* eslint-disable security/detect-object-injection */
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { Card, CardContent, Typography, Modal, Box, Button, Accordion, AccordionSummary, AccordionDetails, Stack, Grid, Tooltip, Chip, CardHeader, Divider } from '@mui/material';
import { IAppState } from 'storage/app/models';
import Spinner from 'components/Spinner';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from 'theme';
import { formatNumber } from 'utils/utils';



interface DashboardAccordionProps {
  id: string;
  title: string;
  children: React.ReactNode;
}

const DashboardAccordion: FC<DashboardAccordionProps> = (props) => {
  const { id, title, children } = props;

  return (
    <Accordion
      sx={{
        backgroundColor: theme.palette.background.default,
        "root": { margin: 0 },
        ".MuiAccordion-root.Mui-expanded": { margin: 0 },
        ".MuiAccordionSummary-root": { minHeight: 0 },
        ".MuiAccordionSummary-root.Mui-expanded": { minHeight: 0 },
        ".MuiAccordionSummary-content": { minHeight: 0, margin: '0.75rem 0' },
        ".MuiAccordionSummary-content.Mui-expanded": { minHeight: 0, margin: '0.75rem 0' },
        ".MuiAccordionDetails-root": { padding: '1rem' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
        aria-controls={`bsi-params-${id}-content`}
        id={`bsi-params-${id}-header`}
        sx={{
          backgroundColor: theme.palette.background.light,
          ":hover": { backgroundColor: theme.palette.background.lightest },
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails id={`bsi-params-${id}-content`}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

interface BsiDashboardModalProps {
  open: boolean;
  closeModal: () => void;
}

const BsiDashboardModal: FC<BsiDashboardModalProps> = (props) => {
  const bsiConfigParams = useSelector<IStore, IAppState['data']['bsiConfigParams']>((state) => state.app.data.bsiConfigParams);
  const loading = useSelector<IStore, IAppState['loadingBsiConfigParams']>((state) => state.app.loadingBsiConfigParams);
  const [indexedTrades, setIndexedTrades] = React.useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    if (bsiConfigParams) {
      setIndexedTrades(
        Object.entries(bsiConfigParams.trades).reduce((acc, cur) => {
          const [value, key] = cur;
          if (acc[key]) {
            acc[key] = [...acc[key], value];
            return acc;
          } else {
            return { ...acc, [key]: [value] }
          }
        }, {} as { [key: string]: string[] })
      )
    }
  }, [bsiConfigParams])

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90svh',
        minWidth: '80%',
        maxWidth: '90%',
      }}>
        <CardHeader title={
          <>
            <Typography variant="h4">
              BSI Configuration Values
            </Typography>
            <Typography>
              This page provides an overview of the current configuration values for the Battleship Index (BSI).
            </Typography>
            <Button
              variant='text'
              size='small'
              onClick={props.closeModal}
              sx={{ position: 'absolute', top: '1rem', right: '1rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
            >
              <CloseIcon />
            </Button>
          </>
        } />
        <CardContent sx={{ maxHeight: 'calc(90svh - 6rem)', overflowY: 'auto' }}>
          <Stack spacing={{ md: 6, sm: 1 }} direction={{ md: 'row', sm: 'column' }} padding="0rem 2rem 1rem 2rem">
            <Stack>
              <Typography variant="h6" paddingBottom="0.5rem">
                About BSI
              </Typography>
              <Typography style={{ paddingBottom: '0.3em' }}>
                BSI is calculated by combining different factors for each work order and then ranking all work orders based on those factors.
                The different factors are weighted differently. For example, if a work order has a 2-hour emergency Priority, it will receive a higher BSI score than a 24-day work order.
              </Typography>
              <Typography style={{ paddingBottom: '0.3em' }}>
                BSI is a combined score based on each work order&apos;s importance and urgency.
                The weighted factors below are used to calculate a work order&apos;s importance.
                The urgency is calculated based on a work order&apos;s age, Priority, and whether a check-in event from a Service Provider has been registered.
              </Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack>
              <Typography variant="h6" paddingBottom="0.5rem">
                About the data on this page
              </Typography>
              <Typography style={{ paddingBottom: '0.3em' }}>
                Click on any of the headlines below to see details about how these weights are currently configured.
                In most cases, these weights are multipliers - this means that a value of &quot;1&quot; means that the factor does not influence the BSI score at all.
                The higher the multiplier, the more influence the factor has on the BSI score.
              </Typography>
              <Typography style={{ paddingBottom: '0.3em' }}>
                Admin users have the ability to change those values. Please reach out to your team lead if you have suggestions or questions!
              </Typography>
            </Stack>
          </Stack>
          {!loading && bsiConfigParams ?
            <Box sx={{ p: '0.5rem', pb: '0' }}>
              <DashboardAccordion id="categories" title="Category Weights">
                <Typography style={{ paddingBottom: '0.3em' }}>
                  The weights below use the work order&apos;s Category data. Different Categories have different weights.
                </Typography>
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Weights of &quot;1&quot; means that the Category has no influence on the BSI score.
                  The higher the weight, the higher the work order&apos;s BSI score.
                </Typography>
                <Grid container spacing={2}>
                  {bsiConfigParams.categories.map((param, index) =>
                    <Grid key={index} item xs={3}>
                      <Card >
                        <CardContent style={{ padding: '1rem' }}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6">
                              {param}
                            </Typography>
                            <Typography variant="h6">
                              {bsiConfigParams?.category_weights[param] ? bsiConfigParams?.category_weights[param].toFixed(2) : '0.00'}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </DashboardAccordion>
              <DashboardAccordion id="primary-status" title="Primary Status Weights">
                <Typography style={{ paddingBottom: '0.3em' }}>
                  The weights below use the work order&apos;s Primary Status. Different Statuses have different weights.
                </Typography>
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Weights of &quot;1&quot; means that the Status has no influence on the BSI score.
                  The higher the weight, the higher the work order&apos;s BSI score.
                </Typography>
                <Grid container spacing={2}>
                  {Object.entries(bsiConfigParams.primary_status_weights).map((param, index) =>
                    <Grid key={index} item xs={3}>
                      <Card >
                        <CardContent style={{ padding: '1rem' }}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6">
                              {param[0]}
                            </Typography>
                            <Typography variant="h6">
                              {param[1].toFixed(2)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </DashboardAccordion>
              <DashboardAccordion id="trade-groups" title="Trade Group Weights">
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Battleship groups individual Trades into Trade Groups. Each Trade Group consists of Trades that are related to each other.
                </Typography>
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Battleship then assigns a weight to each Trade Group.
                  In the overview below, you can see which Trades are part of which Trade Group and what the weight of each Trade Group is.
                </Typography>
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Weights of &quot;1&quot; means that the Trades in the Trade Group have no influence on the BSI score.
                  The higher the weight, the higher the work order&apos;s BSI score.
                </Typography>
                <Grid container spacing={2}>
                  {Object.entries(bsiConfigParams.trade_group_weights).map((param, index) =>
                    <Grid key={index} item xs={3}>
                      <Card >
                        <CardContent style={{ padding: '1rem' }}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6">
                              {param[0]}
                            </Typography>
                            <Typography variant="h6" marginLeft="0.5rem">
                              {param[1].toFixed(2)}
                            </Typography>
                          </Stack>
                          <Stack marginTop={indexedTrades[param[0]]?.length && '0.5rem'}>
                            {indexedTrades[param[0]]?.map((trade, index) =>
                              <Typography key={index}>
                                {trade}
                              </Typography>
                            )}
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </DashboardAccordion>
              <DashboardAccordion id="problem-code" title="Problem Code NTE Values">
                <Typography style={{ paddingBottom: '0.3em' }}>
                  Problem Codes work slightly differently than other BSI factors.
                  Instead of assigning a fixed weight to each problem code, Battleship uses
                  a list of expected NTEs for each problem code.
                  When calculating the BSI, Battleship then compares the expected NTE to
                  the work order&apos;s actual NTE.
                  If the actual NTE is higher than the expected NTE, the work order&apos;s BSI score is increased.
                  The higher the difference, the higher the increase in the BSI score.
                </Typography>
                <Typography style={{ paddingBottom: '0.3em' }}>
                  In the overview below, you can see the expected NTE value for each Problem Code.
                </Typography>
                <Stack direction="row" flexWrap="wrap" justifyContent="center" spacing={1} style={{ marginBottom: '-1rem' }}>
                  {Object.entries(bsiConfigParams.problem_code_weights).map((param, index) =>
                    <Chip
                      key={index}
                      label={`${param[0]} |  ${formatNumber(param[1])}`}
                      sx={{ background: theme.palette.background.paper, fontSize: '1rem' }}
                      style={{ marginBottom: '1rem' }}
                    />
                  )}
                </Stack>
              </DashboardAccordion>
              <DashboardAccordion id="boosting" title="Boosting">
                <Typography>
                  Boosting is an additional factor that is applied to the overall BSI of a work order.
                  It is used to increase the BSI of work orders with certain properties.
                </Typography>
                <Grid container spacing={2} sx={{ paddingBottom: '1rem' }}>
                  {bsiConfigParams.bsi_boosting?.map((param, index) =>
                    <Grid item xs={4} key={index} >
                      <Tooltip title={param.is_active ? "This boost is active" : "This boost is not active"} placement="top" arrow>
                        <Card sx={{ background: param.is_active ? theme.palette.background.highlight : 'undefined' }}>
                          <CardContent style={{ padding: '1rem' }}>
                            <Stack spacing={1}>
                              <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h6">
                                  {param.problem_code}
                                </Typography>
                                <Typography variant="h6">
                                  {param.boost ? param.boost.toFixed(2) : '0.00'}
                                </Typography>
                              </Stack>
                              {(param.category || param.function) &&
                                <Stack mt="0.5rem">
                                  {param.category &&
                                    <Typography>
                                      Category: {param.category}
                                    </Typography>
                                  }
                                  {param.function &&
                                    <Typography>
                                      Function: <code>{param.function}</code>
                                    </Typography>
                                  }
                                </Stack>
                              }
                            </Stack>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Typography variant="h6" sx={{ mb: '0.5rem' }}>
                  Top Boosting
                </Typography>
                <Typography>
                  Top Boosting is a mechanism that can be used to temporarily push work orders with certain Asset Types to the top of the BSI list.
                  Work order with the following Asset Types are currently boosted:
                </Typography>
                {Object.entries(bsiConfigParams.bsi_top_boosting).map((entry, index) =>
                  <Chip
                    key={index}
                    label={entry[0]}
                    sx={{ mr: '0.5rem', mb: '0.5rem', fontSize: '1rem' }}
                    style={{ background: entry[1] ? theme.palette.background.highlight : undefined }}
                  />
                )}
              </DashboardAccordion>
            </Box> :
            <Spinner isVisible={loading} />
          }
        </CardContent>
      </Card>
    </Modal>
  );
};

export default BsiDashboardModal;
