// Models
import { IAction } from 'lib/redux/models'
import { ETrackingActionTypes, IActionSendFeedback, IActionTrackEvent, ITrackingState } from '../models'

// ACTION TYPES
export const Types = {
  FAILURE: ETrackingActionTypes.FAILURE,
  FULFILL: ETrackingActionTypes.FULFILL,
  REQUEST: ETrackingActionTypes.REQUEST,
  SUCCESS: ETrackingActionTypes.SUCCESS,
  TRACK_EVENT: ETrackingActionTypes.TRACK_EVENT,
  SEND_FEEDBACK: ETrackingActionTypes.SEND_FEEDBACK,
}

// INITIAL STATE
const initialState: ITrackingState = {
  history: []
}

// REDUCER
export default (
  state: ITrackingState = initialState,
  action?: IAction,
): ITrackingState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case Types.SUCCESS:
      return {
        ...state,
        history: [...state.history, action.payload],
        error: undefined
      }
    default:
      return state
  }
}

// ACTIONS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const failure = (payload: { message: string, data?: any }): IAction => ({
  type: Types.FAILURE,
  payload,
})

export const fulfill = (): IAction => ({
  type: Types.FULFILL,
})

export const request = (): IAction => ({
  type: Types.REQUEST,
})

export const success = (payload: IActionTrackEvent["payload"]): IAction => ({
  type: Types.SUCCESS,
  payload,
})

export const trackEvent = (payload: IActionTrackEvent["payload"]): IAction => {
  return {
    type: Types.TRACK_EVENT,
    payload,
  };
};

export const sendFeedback = (payload: IActionSendFeedback["payload"]): IAction => {
  return {
    type: Types.SEND_FEEDBACK,
    payload,
  };
};

export const actions = {
  failure,
  fulfill,
  request,
  success,
}
