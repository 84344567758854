import React, { FC, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState, UserView } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, TextField, Typography, CircularProgress, Modal, IconButton, Tooltip } from '@mui/material';
import theme from 'theme';
import { deleteUserView, saveUserView } from 'storage/auth/duck';
import { IAppState } from 'storage/app/models';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectUserView } from 'storage/app/duck';
import CloseIcon from '@mui/icons-material/Close';
import { trackEvent } from 'storage/tracking/duck';


const TruncatedLabel: FC<{ label: string, options: string[] }> = ({ label, options }) => {
  const title = options.join(", ")
  if (title.length > 20) {
    return <Tooltip title={title} placement='right'>
      <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
        {label}: {title.slice(0, 20)}...
      </Typography>
    </Tooltip>
  } else {
    return <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
      {label}: {title}
    </Typography>
  }
}


interface SavedUserViewProps {
  view: UserView
  selectUserView: (view: UserView) => void
  deleteUserView?: (e: MouseEvent, id: UserView["id"]) => void
}

export const SavedUserView: FC<SavedUserViewProps> = ({ view, selectUserView, deleteUserView }) => {
  const regionTitle = view.state.region && view.state.region.length > 1 ? `${view.state.region.length} regions` : view.state.region;
  const marketTitle = view.state.market && view.state.market.length > 1 ? `${view.state.market.length} markets` : view.state.market;
  const clubTitle = view.state.club && view.state.club.length > 1 ? `${view.state.club.length} clubs` : view.state.club;
  return (
    <Button
      component={'div'}
      onClick={() => selectUserView(view)}
      sx={{
        textTransform: 'unset',
        paddingBottom: '0.25rem',
        marginBottom: '0.25rem',
        backgroundColor: theme.palette.background.paper,
        ':last-child': {
          borderBottom: 'unset', paddingBottom: 'unset', marginBottom: 'unset'
        }
      }}
    >
      <Stack alignItems='flex-start' width='20rem' padding='0.5rem 2.5rem 0.5rem 0.5rem'>
        <Typography variant="h4" sx={{ fontSize: "1.25rem", mb: '0.25rem' }}>
          {view.description}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
          {regionTitle}{marketTitle && ` | ${marketTitle}`}{clubTitle && ` | ${clubTitle}`}
        </Typography>
        {view.state.dateRange && view.state.dateRange.value !== "custom" ?
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            {view.state.dateRange.name}
          </Typography> :
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            From: {
              view.state.callDate.start && new Date(view.state.callDate.start * 1000).toLocaleDateString()
            } To: {
              view.state.callDate.end && new Date(view.state.callDate.end * 1000).toLocaleDateString()
            }
          </Typography>
        }
        {view.state.trades &&
          <TruncatedLabel label="Trades" options={view.state.trades} />
        }
        {view.state.tradeGroups &&
          <TruncatedLabel label="Trade Groups" options={view.state.tradeGroups} />
        }
        {view.state.providers &&
          <TruncatedLabel label="Providers" options={view.state.providers} />
        }
        {view.state.categories &&
          <TruncatedLabel label="Category" options={view.state.categories} />
        }
        {view.state.status &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Status: {view.state.status.join(", ")}
          </Typography>
        }
        {view.state.bsi &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            BSI: {view.state.bsi.map(bsi => bsi.charAt(0).toUpperCase() + bsi.slice(1)).join(", ")}
          </Typography>
        }
        {view.state.nte &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            NTE: {typeof (view.state.nte.name) === 'string' && view.state.nte.name.replace("NTE ", "")}
          </Typography>
        }
        {view.state.workingOnIt &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Follow up: {view.state.workingOnIt.name}
          </Typography>
        }
        {view.state.escalated &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Escalated: {view.state.escalated.name}
          </Typography>
        }
        {view.state.noActivitySince &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            No activity since {view.state.noActivitySince.name}
          </Typography>
        }
        {view.state.equipmentTypes &&
          <TruncatedLabel label="Equipment" options={view.state.equipmentTypes} />
        }
        {view.state.extendedStatus &&
          <TruncatedLabel label="Equipment Type" options={view.state.extendedStatus} />
        }
        {view.state.notOperationalOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Not operational equipment only
          </Typography>
        }
        {view.state.potentialDuplicateOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Potential duplicates only
          </Typography>
        }
        {view.state.missedETA &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Scheduled date in the past
          </Typography>
        }
        {view.state.maintenanceRepairOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Display Maintenance and Repair
          </Typography>
        }
        {view.state.problemDescription &&
          <TruncatedLabel label="Problem Description" options={view.state.problemDescription} />
        }
      </Stack>
      {deleteUserView &&
        <Tooltip title="Delete this view" placement='top'>
          <IconButton onClick={(e) => deleteUserView(e, view.id)} sx={{ position: 'absolute', bottom: '0.5rem', right: '0.5rem' }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Button>
  )
}


interface UserViewsModalProps {
  open: boolean;
  closeModal: () => void;
}

const UserViewsModal: FC<UserViewsModalProps> = (props) => {
  const dispatch = useDispatch();
  const views = useSelector<IStore, IAuthState['views']>((state) => state.auth.views);
  const filters = useSelector<IStore, IAppState['filters']>((state) => state.app.filters);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  const [viewDescription, setViewDescription] = useState<string>("");


  const handleSelectView = (view: UserView) => {
    dispatch(selectUserView(view.state))
    dispatch(trackEvent({ namespace: 'Select user view', predicate: 'User views', payload: { id: view.id } }))
    props.closeModal()
  }

  const handleSaveUserView = () => {
    setViewDescription("");
    dispatch(trackEvent({ namespace: 'Save user view', predicate: 'User views', payload: { ...filters, description: viewDescription } }))
    dispatch(saveUserView({ state: filters, description: viewDescription }));
  }

  const handleDeleteUserView = (e: MouseEvent, id: UserView["id"]) => {
    e.stopPropagation();
    dispatch(trackEvent({ namespace: 'Delete user view', predicate: 'User views', payload: { id } }))
    dispatch(deleteUserView(id));
  }

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card sx={{ padding: "2rem", position: 'relative', backgroundColor: theme.palette.background.default }}>
        <Button
          variant='text'
          size='small'
          onClick={props.closeModal}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Stack gap={2}>
            <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
              Saved views
            </Typography>
            <Stack sx={{ maxHeight: '50svh', overflow: 'auto', paddingRight: '0.25rem' }}>
              {views.map((view) =>
                <SavedUserView key={view.id} view={view} selectUserView={handleSelectView} deleteUserView={handleDeleteUserView} />
              )}
            </Stack>
            <Typography variant="h3" sx={{ fontSize: '1.25rem', mt: '1rem' }}>Add new View</Typography>
            <TextField
              label="Title"
              value={viewDescription}
              type="password"
              onChange={(e) => setViewDescription(e.target.value)}
              onSubmit={handleSaveUserView}
              disabled={loading}
              minRows={3}
              multiline
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="contained" sx={{ margin: 'auto' }} onClick={handleSaveUserView}>
            Save current view {loading &&
              <CircularProgress sx={{
                color: "white",
                height: "1rem !important",
                width: "1rem !important",
                marginLeft: "1rem"
              }} />
            }
          </Button>
        </CardActions>
      </Card>
    </Modal >
  );
};

export default UserViewsModal;
