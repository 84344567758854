import React, { FC } from 'react';
import Box from "@mui/material/Box";
import { isTablet } from 'utils/utils';


const BoxDivider: FC = () => {

  const ipad = isTablet();

  return (
    <Box
      sx={{
        height: '50%',
        width: '0.5px',
        alignSelf: 'center',
        backgroundColor: 'background.inverted',
        opacity: 0.3,
        ml: ipad ? 0.75 : 2.5,
        mr: ipad ? 0.75 : 2.5,
      }}
    />
  )
}

export default BoxDivider