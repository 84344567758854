/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
// Libraries
import { SagaIterator } from '@redux-saga/types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { Types, actions, setAlerts, setAcknowledged, appendAlerts } from '../duck';
import { getAlertsService, acknowledgeAlertService, GetAlertsServiceResponse } from 'services/alerts';
import { IAction } from 'lib/redux/models';

const { failure, fulfill, request } = actions


/**
 * Saga to fetch alerts for the authenticated user.
 * If successful, the alerts are updated within the alerts store.
 */
export function* getAlertsSaga() {
  yield put(request())
  try {
    const response: GetAlertsServiceResponse = yield call(getAlertsService)
    if (response) {
      yield put(setAlerts({
        alerts: response.alerts,
        totalAlerts: response.count,
        hasNextPage: !!response.next,
      }))
    }
  } catch (error: any) {
    if (error?.response?.detail) {
      yield put(failure(error.response.data.detail))
    } else {
      yield put(failure(error.message))
    }
  } finally {
    yield put(fulfill())
  }
}

/**
 * Saga to fetch the next page of alerts.
 */
export function* getNextAlertsSaga(action: IAction) {
  yield put(request())
  try {
    const response: GetAlertsServiceResponse = yield call(getAlertsService, action.payload.page)

    if (response) {
      yield put(appendAlerts({
        alerts: response.alerts,
        hasNextPage: !!response.next,
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure(error.response.data.detail))
    } else {
      yield put(failure(error.message))
    }
  } finally {
    yield put(fulfill())
  }
}

/**
 * Saga to acknowledge an alert.
 * If successful, the alert is updated within the alerts store.
 */
export function* acknowledgeSaga(action: IAction) {
  yield put(request())
  try {
    const respose: boolean = yield call(acknowledgeAlertService, action.payload.alertId)
    if (respose) {
      yield put(setAcknowledged({
        alertId: action.payload.alertId,
        acknowledged: true,
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure(error.response.data.detail))
    } else {
      yield put(failure(error.message))
    }
  } finally {
    yield put(fulfill())
  }
}

export default function* alertsSagas(): SagaIterator {
  yield all([
    takeLatest(Types.GET_ALERTS, getAlertsSaga),
    takeLatest(Types.GET_NEXT_ALERTS, getNextAlertsSaga),
    takeLatest(Types.ACKNOWLEDGE, acknowledgeSaga),
  ])
}