import api from 'lib/axios/api';
import { IActionBatchSaveWorkorderNote, IActionGetWorkorderNotes, IActionGetWorkorderRecipients, IActionSaveWorkorderNote, NotesResponse, RecipientsResponse } from 'storage/notes/models';


/**
 * Get workorder paginated notes
 */
export const getWorkorderNotesService = async (request: IActionGetWorkorderNotes["payload"]): Promise<NotesResponse["data"]> => {
  const response: NotesResponse = await api.get(`/api/v1/workorders/notes/${request.workorderId}/`);
  return response.data;
};

/**
 * Add new note to workorder.
 */
export const saveWorkorderNoteService = async (request: IActionSaveWorkorderNote["payload"]): Promise<boolean> => {
  const response = await api.post(`/api/v1/workorders/notes/${request.workorderId}/`, {
    note: request.note,
    recipients: request.recipients.reduce((acc, curr) => acc ? `${acc};${curr}` : `${curr}`, ''),
    action_required: true
  });
  return response.status === 204
};

/**
 * Add new note to multiple workorder.
 */
export const batchSaveWorkorderNoteService = async (request: IActionBatchSaveWorkorderNote["payload"]): Promise<boolean> => {
  const response = await api.post(`/api/v1/workorders/notes/`, {
    workorders: request.workorders,
    note: request.note,
    recipient_type: request.recipientType,
    action_required: true
  });
  return response.data
};

/**
 * Get recipients for workorder
 */
export const getWorkorderRecipientsService = async (id: IActionGetWorkorderRecipients["payload"]): Promise<RecipientsResponse["data"]> => {
  const response: RecipientsResponse = await api.get(`/api/v1/workorders/recipients/${id}/`);
  return response.data;
};