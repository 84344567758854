/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../lib/axios/api'
import { IActionChangePassword, IActionResetPasswordConfirm, ILogin, IUser, IActionSetDefaultRegion, IActionSaveUserView, UserView, IActionDeleteUserView, IAuthState, RegionalMetric, RegionRank, TeamView, IActionSaveTeamView, IActionDeleteTeamView, IActionSaveTeamViewAsDefault } from '../../storage/auth/models'
import jwtDecode from 'jwt-decode'


export const loginUserService = async (request: ILogin): Promise<IUser> => {
  const response = await api.post('/users/auth/token/create/', request)
  const refresh = jwtDecode(response.data.refresh) as { [key: string]: any }
  return {
    ...jwtDecode(response.data.access),
    refresh_token: response.data.refresh,
    refresh_exp: refresh.exp,
  } as IUser
};

export const resetPasswordService = async (email: string): Promise<{ [key: string]: any }> => {
  const response = await api.post('/users/password-reset/', { email })
  return response.data
};

export const resetPasswordConfirmService = async (request: IActionResetPasswordConfirm["payload"]): Promise<{ [key: string]: any }> => {
  const response = await api.post(`/users/password-reset/confirm/?token=${request.token}`, { ...request })
  return response.data
};

export const changePasswordService = async (request: IActionChangePassword["payload"]): Promise<{ [key: string]: any }> => {
  const response = await api.put('/users/change-password/', { old_password: request.oldPassword, new_password: request.newPassword })
  return response.data
};

export const refreshTokenService = async (): Promise<IUser> => {
  const response = await api.post('/users/auth/token/refresh/')
  const refresh = jwtDecode(response.data.refresh) as { [key: string]: any }
  return {
    ...jwtDecode(response.data.access),
    refresh_token: response.data.refresh,
    refresh_exp: refresh.exp
  } as IUser
};


export const loginFromTokenService = async (): Promise<IUser> => {
  const response = await api.post('/users/auth/token/refresh/')
  const refresh = jwtDecode(response.data.refresh) as { [key: string]: any }
  return {
    ...jwtDecode(response.data.access),
    refresh_token: response.data.refresh,
    refresh_exp: refresh.exp
  } as IUser
};

export const logoutService = async (): Promise<boolean> => {
  await api.post('/users/auth/logout/')
  return true
};

export const updateDefaultRegionService = async (payload: IActionSetDefaultRegion["payload"]): Promise<boolean> => {
  const response = await api.put('/users/', payload)
  return response.status === 200
};

export const getUserViewsService = async (): Promise<UserView[]> => {
  const response = await api.get('/users/views/')
  return response.data
};

export const saveUserViewService = async (userView: IActionSaveUserView["payload"]): Promise<UserView> => {
  const response = await api.post('/users/views/', userView)
  return response.data
};

export const deleteUserViewService = async (id: IActionDeleteUserView["payload"]): Promise<boolean> => {
  const response = await api.delete(`/users/views/${id}/`)
  return response.status === 204
};

export const getTeamViewsService = async (): Promise<TeamView[]> => {
  const response = await api.get('/users/team-views/')
  return response.data
};

export const saveTeamViewService = async (teamView: IActionSaveTeamView["payload"]): Promise<TeamView> => {
  delete teamView.state.region
  delete teamView.state.market
  delete teamView.state.club
  const response = await api.post('/users/team-views/', teamView)
  return response.data
};

export const deleteTeamViewService = async (id: IActionDeleteTeamView["payload"]): Promise<boolean> => {
  const response = await api.delete(`/users/team-views/${id}/`)
  return response.status === 204
};

export const saveTeamViewAsDefaultService = async (payload: IActionSaveTeamViewAsDefault["payload"]): Promise<boolean> => {
  const response = await api.put(`/users/team-views/${payload?.id}/`, { is_default: payload?.is_default })
  return response.status === 200
};

export const getUserStatsService = async (): Promise<boolean> => {
  const response = await api.get(`/users/xp-stats/`)
  return response.data
};

export const getNewFeaturesService = async (): Promise<boolean> => {
  const response = await api.get(`/users/new-features/`)
  return response.data
};

export const getLeaderboardService = async (): Promise<IAuthState["leaderboards"]> => {
  const responseUser = await api.get(`/users/leaderboard/`)
  const responseRegion = await api.get(`/metrics/call_date_age_region/`)

  const region15DaysData = responseRegion.data
    .map((item: RegionalMetric): RegionRank  => ({
      rank: 0,
      name: item.region,
      value: item.wo_older_than_15_days / item.total
    }))
    .sort((a: RegionRank, b: RegionRank) => a.value - b.value)
    .map((item: RegionRank, index: number) => ({...item, rank: index + 1}))

  const region90DaysData = responseRegion.data
    .map((item: RegionalMetric): RegionRank  => ({
      rank: 0,
      name: item.region,
      value: item.wo_older_than_90_days / item.total
    }))
    .sort((a: RegionRank, b: RegionRank) => a.value - b.value)
    .map((item: RegionRank, index: number) => ({...item, rank: index + 1}))

  return {
    xp: responseUser.data.xp_rank,
    notes: responseUser.data.notes_rank,
    escalated: responseUser.data.escalated_rank,
    workingOnIt: responseUser.data.working_on_it_rank,
    region15Days: region15DaysData,
    region90Days: region90DaysData
  }
};