import React, { FC, useEffect, useState } from 'react';
import IStore from 'lib/redux/models';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from 'storage/app/models';
import './SidePanel.css';
import { formatNumber } from 'utils/utils';
import WorkorderList from 'components/WorkorderList';
import { Box, Typography, Stack, Portal, Button, Tooltip } from '@mui/material';
import WorkorderControls from 'components/WorkorderList/WorkorderControls';
import WorkorderDetail from 'components/WorkorderDetail';
import { selectTotalWorkorders, toggleSidePanelOpen } from 'storage/app/duck';
import Heatmap from "components/Heatmap";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import theme from 'theme';
import { isTablet } from 'utils/utils';


const SidePanel: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(state => state.app.filters);
  const map = useSelector<IStore, IAppState['map']>(state => state.app.map);
  const totalWorkorders = useSelector<IStore>(selectTotalWorkorders) as number;
  const isFullscreen = useSelector<IStore, IAppState['isFullscreen']>(store => store.app.isFullscreen);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const { isSidePanelOpen } = useSelector<IStore, IAppState>(store => store.app);
  const ipad = isTablet();
  const sidePanelWidth = ipad ? '26rem' : '32rem'

  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [isSidePanelOpen]);

  const handleToggleSidePanelOpen = () => {
    dispatch(toggleSidePanelOpen())
    setIsTooltipOpen(false)
  }

  const getLabel = () => {
    if (!filters.region) {
      return 'All Regions';
    } else if (filters.club) {
      if (filters.club.length > 1) {
        return `${filters.club.length} Clubs`;
      } else {
        return `Club ${filters.club}`;
      }
    } else if (filters.market) {
      if (filters.market.length > 1) {
        return `${filters.market.length} Markets`;
      } else {
        return `Market ${filters.market}`;
      }
    } else if (filters.region) {
      if (filters.region.length > 1) {
        return `${filters.region.length} Regions`;
      } else {
        return `${filters.region}`;
      }
    }
  };

  return (
    <Box id="side-panel" className="side-panel" component="aside" sx={{ width: isSidePanelOpen ? sidePanelWidth : '0' }}>
      <Box p={2}>
        {totalWorkorders !== undefined && (
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
          >
            {formatNumber(totalWorkorders)} {totalWorkorders !== 1 ? 'work orders' : 'work order'} in {getLabel()}
          </Typography>
        )}
      </Box>
      <Heatmap />
      {!isFullscreen && <>
        <WorkorderControls />
        <WorkorderList />
      </>}
      <WorkorderDetail />
      {isFullscreen &&
        <Portal container={() => document.querySelector('main')}>
          <Stack id="fullscreen" sx={{ position: 'absolute', top: '0', left: '0', right: '-0.25rem', bottom: '0', zIndex: 150 }}>
            <WorkorderControls />
            <WorkorderList />
          </Stack>
        </Portal>
      }
      {!isFullscreen &&
        <Stack
          sx={{
            position: 'absolute',
            top: '0.5rem',
            left: isSidePanelOpen ? sidePanelWidth : '0',
            zIndex: 90,
          }}>
          <Tooltip
            title={isSidePanelOpen ? "Collapse side-panel" : "Expand side-panel"}
            placement="right"
            open={isTooltipOpen}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            arrow
          >
            <Button
              variant='contained'
              onClick={handleToggleSidePanelOpen}
              sx={{
                minWidth: '2rem',
                width: '2rem',
                height: '3rem',
                zIndex: 90,
                backgroundColor: theme.palette.background.light,
                color: 'white'
              }}>
              {isSidePanelOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
            </Button>
          </Tooltip>
        </Stack>
      }
    </Box>
  );
};

export default SidePanel;
