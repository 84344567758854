import React, { FC, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import {
  IAppState,
  IAppStateDerived,
  WorkorderAggregate,
} from 'storage/app/models';
import {
  selectSortField,
  selectSortAsc,
  setSortField,
  setSortAsc,
  selectPanelFilters,
  getWorkordersCSV,
  selectFilters,
  selectSortParam,
  setWorkorderListFullscreen,
  selectAggregates,
  selectWorkorders,
  updateWorkorder,
  batchUpdateWorkingOnIt,
  batchUpdateEscalated,
  batchUpdateBotShutOff,
  getAllWorkordersData,
  selectTotalWorkorders,
} from 'storage/app/duck';
import Tooltip from '@mui/material/Tooltip';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Modal,
  Chip,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import html2canvas from 'html2canvas';
import SubFilters from 'components/FilterBar/SubFilters';
import theme from 'theme';
import { formatNumber, getFiltersCount, getTitleLabel } from 'utils/utils';
import { trackEvent } from 'storage/tracking/duck';
import { IAuthState } from 'storage/auth/models';
import { isTablet } from 'utils/utils';

const sortOptions = [
  {
    label: 'BSI',
    value: 'combined_index',
  },
  {
    label: 'Urgency',
    value: 'urgency',
  },
  {
    label: 'Importance',
    value: 'importance',
  },
  {
    label: 'NTE',
    value: 'nte',
  },
  {
    label: 'Created Date',
    value: 'created_date',
  },
];

const getSortLabel = (value: string) => {
  return sortOptions.find((o) => o.value === value)?.label;
};

const ExportOptions = () => {
  const dispatch = useDispatch();
  const map = useSelector<IStore, IAppState['map']>((state) => state.app.map);
  const loadingCSV = useSelector<IStore, IAppState['loadingCSV']>(
    (store) => store.app.loadingCSV,
  );
  const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const moreOptionsMenuOpen = Boolean(moreOptionsMenuAnchorEl);
  const [exportingMessage, setExportingMessage] = useState<string | undefined>(
    undefined,
  );
  const [exportingOver3000, setExportingOver3000] = useState<boolean>(false);
  const drawnElementsRef = useRef<number>(0);
  const elementsToDrawRef = useRef<number>(0);
  const totalWorkorders = useSelector<
    IStore,
    IAppState['data']['totalWorkorders']
  >((state) => state.app.data.totalWorkorders);
  const sortParam = useSelector<IStore, IAppStateDerived['sortParam']>(
    selectSortParam,
  );
  const panelFilters = useSelector<IStore, IAppState['panelFilters']>(
    selectPanelFilters,
  );
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);

  const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreOptionsMenuAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsMenuClose = () => {
    setMoreOptionsMenuAnchorEl(null);
  };

  const handleExportMapImage = () => {
    setExportingMessage('Exporting map image...');
    window.setTimeout(async () => {
      await generateExportedImage();
    }, 50);
    dispatch(
      trackEvent({ namespace: 'Export map image', predicate: 'WO list' }),
    );
  };

  const generateExportedImage = async () => {
    drawnElementsRef.current = 0;
    const canvasWithMarkers = document.createElement('canvas');
    const canvas = map?.getCanvas();
    const mapRect = canvas?.getBoundingClientRect();
    canvasWithMarkers.width = canvas?.width || 0;
    canvasWithMarkers.height = canvas?.height || 0;
    const ctx = canvasWithMarkers.getContext('2d');
    const markersEl = document.getElementsByClassName('aggregate-donut');
    elementsToDrawRef.current = markersEl.length + 3; // Market donuts + map + legend + attribution

    map?.once('idle', () => {
      const canvas = map?.getCanvas();
      if (ctx && canvas && mapRect) {
        const mapImagSrc = canvas.toDataURL();
        handleDrawImageIntoCanvas(mapImagSrc, canvasWithMarkers);
        Array.from(markersEl).forEach((marker) => {
          const markerRect = marker.getBoundingClientRect();
          html2canvas(marker as HTMLElement, { backgroundColor: null }).then(
            (markerCanvas) => {
              const markerImageSrc = markerCanvas.toDataURL();
              handleDrawImageIntoCanvas(
                markerImageSrc,
                canvasWithMarkers,
                markerRect.x - mapRect.x,
                markerRect.y - mapRect.y,
              );
            },
          );
        });
        const legendEl = document.getElementById(
          'aggregates-overview',
        ) as HTMLElement;
        const legendRect = legendEl.getBoundingClientRect();
        const mapLegendCanvas = html2canvas(legendEl, {
          backgroundColor: null,
        });
        mapLegendCanvas.then((c) => {
          const imgSrc = c.toDataURL();
          handleDrawImageIntoCanvas(
            imgSrc,
            canvasWithMarkers,
            legendRect.x - mapRect.x,
            legendRect.y - mapRect.y,
          );
        });
        const attributionEl = document.querySelector(
          'details.maplibregl-ctrl-attrib',
        ) as HTMLElement;
        const attributionRect = attributionEl.getBoundingClientRect();
        html2canvas(attributionEl, { backgroundColor: null }).then(
          (attributionCanvas) => {
            const imgSrc = attributionCanvas.toDataURL();
            handleDrawImageIntoCanvas(
              imgSrc,
              canvasWithMarkers,
              attributionRect.x - mapRect.x,
              attributionRect.y - mapRect.y,
            );
          },
        );
        const interval = window.setInterval(() => {
          if (drawnElementsRef.current === elementsToDrawRef.current) {
            handleDownloadImage(canvasWithMarkers);
            setExportingMessage(undefined);
            window.clearInterval(interval);
          }
        }, 1000);
      }
    });
    map?.setCenter(map.getCenter());
    setMoreOptionsMenuAnchorEl(null);
  };

  const handleDrawImageIntoCanvas = (
    imageSrc: string,
    canvas: HTMLCanvasElement,
    x = 0,
    y = 0,
  ) => {
    const image = new Image();
    const dpi = window.devicePixelRatio;
    image.addEventListener('load', () => {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, x * dpi, y * dpi);
        drawnElementsRef.current += 1;
      }
    });
    image.src = imageSrc;
  };

  const handleDownloadImage = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const el = document.createElement('a');
      el.href = canvas.toDataURL();
      el.download = 'Battleship Dashboard.png';
      el.click();
      el.remove();
    }
  };

  const handleExportCSV = () => {
    if (totalWorkorders && totalWorkorders > 3000) {
      setExportingOver3000(true);
    } else {
      dispatch(getWorkordersCSV({ filters, panelFilters, sortParam }));
      dispatch(
        trackEvent({
          namespace: 'Export CSV',
          predicate: 'WO list',
          payload: { over_3000: false },
        }),
      );
    }
    setMoreOptionsMenuAnchorEl(null);
  };

  const handleExportCSVAnyway = () => {
    dispatch(getWorkordersCSV({ filters, panelFilters, sortParam }));
    dispatch(
      trackEvent({
        namespace: 'Export CSV',
        predicate: 'WO list',
        payload: { over_3000: true },
      }),
    );
    setExportingOver3000(false);
  };

  useEffect(() => {
    if (loadingCSV) {
      setExportingMessage('Downloading CSV file...');
    } else {
      setExportingMessage(undefined);
    }
  }, [loadingCSV]);

  return (
    <>
      <Tooltip title="Export data" placement="top" arrow>
        <Button
          variant="contained"
          size="small"
          onClick={handleMoreOptionsMenuClick}
          sx={{ minWidth: 0 }}>
          <MoreVertIcon />
        </Button>
      </Tooltip>
      <Menu
        id="user-menu"
        anchorEl={moreOptionsMenuAnchorEl}
        open={moreOptionsMenuOpen}
        onClose={handleMoreOptionsMenuClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}>
        <Stack sx={{ minWidth: '12rem' }}>
          <MenuItem onClick={handleExportMapImage}>
            <ImageIcon sx={{ mr: '0.5rem' }} /> Export map
          </MenuItem>
          <MenuItem onClick={handleExportCSV}>
            <DescriptionIcon sx={{ mr: '0.5rem' }} /> Export .csv
          </MenuItem>
        </Stack>
      </Menu>
      {exportingMessage && (
        <Alert
          variant="filled"
          severity="info"
          sx={{
            position: 'fixed',
            bottom: '0.5rem',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 100,
          }}>
          {exportingMessage}
        </Alert>
      )}
      {exportingOver3000 && (
        <Modal
          open={exportingOver3000}
          onClose={() => setExportingOver3000(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
          }}>
          <Card>
            <CardContent>
              Your current filter is returning over 3,000 work orders.
              <br />
              Generating the CSV file and downloading the data might take a
              while.
              <br />
              Consider refining your filter to get a smaller result set.
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setExportingOver3000(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExportCSVAnyway}>
                Proceed with download
              </Button>
            </CardActions>
          </Card>
        </Modal>
      )}
    </>
  );
};

const FullscreenHeader = () => {
  const aggregates = useSelector(selectAggregates);
  const [overviewData, setOverviewData] = useState<WorkorderAggregate>();
  const totalWorkorders = useSelector<
    IStore,
    IAppState['data']['totalWorkorders']
  >((state) => state.app.data.totalWorkorders);
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const ipad = isTablet();

  useEffect(() => {
    if (aggregates && aggregates.length > 0) {
      const aggregatedData = aggregates.reduce(
        (acc: WorkorderAggregate, cur) => {
          return {
            ...cur,
            id: 0,
            name: 'Total',
            total_workorders: acc.total_workorders
              ? acc.total_workorders + cur.total_workorders
              : cur.total_workorders,
            low_priority_work_orders: acc.low_priority_work_orders
              ? acc.low_priority_work_orders + cur.low_priority_work_orders
              : cur.low_priority_work_orders,
            medium_priority_work_orders: acc.medium_priority_work_orders
              ? acc.medium_priority_work_orders +
                cur.medium_priority_work_orders
              : cur.medium_priority_work_orders,
            high_priority_work_orders: acc.high_priority_work_orders
              ? acc.high_priority_work_orders + cur.high_priority_work_orders
              : cur.high_priority_work_orders,
            total_nte: (acc.total_nte
              ?
                (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  parseFloat(acc.total_nte as any) +
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  parseFloat(cur.total_nte as any)
                ).toFixed(2)
              : cur.total_nte) as number,
          };
        },
        {} as WorkorderAggregate,
      );
      setOverviewData(aggregatedData);
    } else {
      setOverviewData(undefined);
    }
  }, [aggregates]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      height="100%"
      marginLeft="1rem !important"
      marginRight="auto !important">
      <Box
        sx={{
          height: '85%',
          width: '0.5px',
          alignSelf: 'center',
          backgroundColor: 'background.inverted',
          opacity: 0.3,
        }}
      />
      {!ipad && totalWorkorders !== undefined && (
        <Typography variant="h6" component="h2" fontWeight="bold">
          {formatNumber(totalWorkorders)}{' '}
          {totalWorkorders !== 1 ? 'work orders' : 'work order'} in{' '}
          {getTitleLabel(filters)}
        </Typography>
      )}
      {!ipad && overviewData && (
        <>
          <Box
            sx={{
              height: '85%',
              width: '0.5px',
              alignSelf: 'center',
              backgroundColor: 'background.inverted',
              opacity: 0.3,
            }}
          />
          <Stack direction="row" spacing={1}>
            <Box
              sx={{
                backgroundColor: 'scale.high',
                height: 20,
                width: 20,
                mt: '1px',
              }}></Box>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              {formatNumber(overviewData.high_priority_work_orders)} high BSI
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box
              sx={{
                backgroundColor: 'scale.medium',
                height: 20,
                width: 20,
                mt: '1px',
              }}></Box>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              {formatNumber(overviewData.medium_priority_work_orders)} medium
              BSI
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box
              sx={{
                backgroundColor: 'scale.low',
                height: 20,
                width: 20,
                mt: '1px',
              }}></Box>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              {formatNumber(overviewData.low_priority_work_orders)} low BSI
            </Typography>
          </Stack>
        </>
      )}
      {ipad && (
        <Stack direction="column" alignItems="center" spacing={1}>
          {totalWorkorders !== undefined && (
            <Typography
              variant="h6"
              component="h2"
              fontWeight="bold"
              fontSize="1rem">
              {formatNumber(totalWorkorders)}{' '}
              {totalWorkorders !== 1 ? 'work orders' : 'work order'} in{' '}
              {getTitleLabel(filters)}
            </Typography>
          )}
          {ipad && overviewData && (
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    backgroundColor: 'scale.high',
                    height: 16,
                    width: 16,
                  }}></Box>
                <Typography fontSize="0.85rem" sx={{ whiteSpace: 'nowrap' }}>
                  {formatNumber(overviewData.high_priority_work_orders)} high
                  BSI
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    backgroundColor: 'scale.medium',
                    height: 16,
                    width: 16,
                  }}></Box>
                <Typography fontSize="0.85rem" sx={{ whiteSpace: 'nowrap' }}>
                  {formatNumber(overviewData.medium_priority_work_orders)}{' '}
                  medium BSI
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    backgroundColor: 'scale.low',
                    height: 16,
                    width: 16,
                  }}></Box>
                <Typography fontSize="0.85rem" sx={{ whiteSpace: 'nowrap' }}>
                  {formatNumber(overviewData.low_priority_work_orders)} low BSI
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const SelectAll = () => {
  const dispatch = useDispatch();
  const workorders = useSelector(selectWorkorders);
  const totalWorkorders = useSelector(selectTotalWorkorders);
  const totalSelectedWOs = workorders?.filter((wo) => wo.selected).length;
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const sc_portal_url = useSelector<
    IStore,
    IAppState['metadata']['sc_portal_url']
  >((state) => state.app.metadata.sc_portal_url);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const actionsMenuOpen = actionsMenuAnchorEl ? true : false;
  const selectedWOs = workorders?.filter((wo) => wo.selected);
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const sortParam = useSelector<IStore, IAppStateDerived['sortParam']>(
    selectSortParam,
  );

  useEffect(() => {
    if (totalSelectedWOs && totalSelectedWOs > 0) {
      setAllSelected(false);
    }
  }, [filters]);

  const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchorEl(null);
  };

  const handleActionsSelect = (value: string) => {
    if (selectedWOs) {
      const ids = selectedWOs.map((wo) => wo.id as number);
      if (value === 'setWorkingOnIt') {
        dispatch(batchUpdateWorkingOnIt({ workorders: ids, value: true }));
        dispatch(
          trackEvent({
            namespace: 'Set multiple working on it',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (value === 'removeWorkingOnIt') {
        dispatch(batchUpdateWorkingOnIt({ workorders: ids, value: false }));
        dispatch(
          trackEvent({
            namespace: 'Remove multiple working on it',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (value === 'setEscalated') {
        dispatch(batchUpdateEscalated({ workorders: ids, value: true }));
        dispatch(
          trackEvent({
            namespace: 'Set multiple escalated',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (value === 'removeEscalated') {
        dispatch(batchUpdateEscalated({ workorders: ids, value: false }));
        dispatch(
          trackEvent({
            namespace: 'Remove multiple escalated',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (user?.bot_admin && value === 'activateBots') {
        dispatch(
          batchUpdateBotShutOff({
            workorders: ids,
            value: false,
            user: user?.email,
          }),
        );
        dispatch(
          trackEvent({
            namespace: 'Activate multiple bots',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (user?.bot_admin && value === 'deactivateBots') {
        dispatch(
          batchUpdateBotShutOff({
            workorders: ids,
            value: true,
            user: undefined,
          }),
        );
        dispatch(
          trackEvent({
            namespace: 'Shut off multiple bots',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      } else if (user?.note_sender && value === 'openInSC') {
        dispatch(
          trackEvent({
            namespace: 'Open multiple in Service Channel',
            predicate: 'WO fullscreen',
            payload: ids,
          }),
        );
      }
    }
    setActionsMenuAnchorEl(null);
  };

  const handleSelectAll = (checked: boolean) => {
    if (totalSelectedWOs && totalSelectedWOs > 0) {
      workorders?.forEach((wo) => {
        if (wo.selected === false) return;
        dispatch(
          updateWorkorder({ workorderId: wo.id, data: { selected: false } }),
        );
      });
      setAllSelected(false);
    } else if (checked) {
      if (
        workorders &&
        totalWorkorders &&
        totalWorkorders > workorders?.length
      ) {
        dispatch(getAllWorkordersData({ filters, sortParam }));
      } else {
        workorders?.forEach((wo) => {
          if (wo.selected === checked) return;
          dispatch(
            updateWorkorder({ workorderId: wo.id, data: { selected: true } }),
          );
        });
      }
      setAllSelected(true);
    }
  };

  return (
    <Stack direction="row" alignItems="center" ml="-1rem" mr="0.5rem">
      <Tooltip
        title={
          totalSelectedWOs && totalSelectedWOs > 0
            ? 'Clear selected work orders'
            : 'Select all work orders'
        }
        placement="top"
        arrow>
        <Checkbox
          checked={allSelected}
          sx={{ color: 'white' }}
          onChange={(e) => handleSelectAll(e.target.checked)}
          indeterminate={
            totalSelectedWOs
              ? totalSelectedWOs > 0 && totalSelectedWOs !== workorders.length
              : false
          }
        />
      </Tooltip>
      <Button
        id="multiselect-actions-button"
        variant="contained"
        size="small"
        aria-controls={actionsMenuOpen ? 'multiselect-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={actionsMenuOpen ? 'true' : undefined}
        onClick={handleActionsMenuClick}
        endIcon={
          actionsMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
        }
        sx={{ height: '2rem' }}>
        Select action
      </Button>
      <Menu
        id="multiselect-actions-menu"
        aria-labelledby="multiselect-actions-button"
        anchorEl={actionsMenuAnchorEl}
        open={actionsMenuOpen}
        onClose={handleActionsMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '0.25rem' }}>
        <MenuItem onClick={() => handleActionsSelect('setWorkingOnIt')}>
          Set Follow up
        </MenuItem>
        <MenuItem onClick={() => handleActionsSelect('removeWorkingOnIt')}>
          Remove Follow up
        </MenuItem>
        <MenuItem onClick={() => handleActionsSelect('setEscalated')}>
          Set escalated
        </MenuItem>
        <MenuItem onClick={() => handleActionsSelect('removeEscalated')}>
          Remove escalated
        </MenuItem>
        {user?.bot_admin && (
          <MenuItem onClick={() => handleActionsSelect('activateBots')}>
            Activate bots
          </MenuItem>
        )}
        {user?.bot_admin && (
          <MenuItem onClick={() => handleActionsSelect('deactivateBots')}>
            Deactivate bots
          </MenuItem>
        )}
        {user?.note_sender && (
          <a
            href={`${sc_portal_url}/sc/wo/Workorders/list?id=${selectedWOs
              ?.map((wo) => wo.id as number)
              .join(',')}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'unset' }}
            onClick={() => handleActionsSelect('openInSC')}>
            <MenuItem>Open in Service Channel</MenuItem>
          </a>
        )}
      </Menu>
      <Box
        sx={{
          height: '2.75rem',
          width: '0.5px',
          ml: '1rem',
          alignSelf: 'center',
          backgroundColor: 'background.inverted',
          opacity: 0.3,
        }}
      />
    </Stack>
  );
};

const WorkorderControls: FC = () => {
  const dispatch = useDispatch();
  const sortField = useSelector<IStore, IAppState['sortField']>(
    selectSortField,
  );
  const sortAsc = useSelector<IStore, IAppState['sortAsc']>(selectSortAsc);
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const isFullscreen = useSelector<IStore, IAppState['isFullscreen']>(
    (store) => store.app.isFullscreen,
  );
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const sortMenuOpen = sortMenuAnchorEl ? true : false;
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const filtersCount = getFiltersCount(filters);
  const ipad = isTablet();

  const handleToggleFullscreen = () => {
    dispatch(setWorkorderListFullscreen(!isFullscreen));
    if (!isFullscreen) {
      dispatch(
        trackEvent({ namespace: 'Open fullscreen', predicate: 'WO list' }),
      );
    } else {
      dispatch(
        trackEvent({
          namespace: 'Close fullscreen',
          predicate: 'WO fullscreen',
        }),
      );
    }
  };

  const handleSortMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  const handleSortSelect = (value: string) => {
    dispatch(setSortField(value));
    handleSortMenuClose();
  };

  const toggleSortDirection = () => {
    dispatch(setSortAsc(!sortAsc));
  };

  const handleToggleFilters = () => {
    setFiltersOpen(!filtersOpen);
    dispatch(trackEvent({ namespace: 'Open filters', predicate: 'WO list' }));
  };

  const handleCloseFilters = () => {
    setFiltersOpen(false);
  };

  return (
    <Stack
      id="wo-controls"
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        p: ipad ? 1 : 2,
        flexShrink: 0,
        backgroundColor: theme.palette.background.default,
        transition: '0.3s ease-in-out',
        height: isFullscreen ? '5rem' : 'unset',
      }}>
      {isFullscreen && <SelectAll />}
      <ButtonGroup
        size="small"
        sx={{ marginRight: !isFullscreen ? 'auto !important' : 'unset' }}>
        <Tooltip
          title={sortAsc ? 'Ascending' : 'Descending'}
          placement="top"
          arrow>
          <Button variant="contained" onClick={toggleSortDirection}>
            {sortAsc && <SwitchLeftIcon sx={{ transform: 'rotate(270deg)' }} />}
            {!sortAsc && <SwitchLeftIcon sx={{ transform: 'rotate(90deg)' }} />}
          </Button>
        </Tooltip>
        <Tooltip title={'Change list sorting'} placement="top" arrow>
          <Button
            id="wo-actions-button"
            variant="contained"
            aria-controls={sortMenuOpen ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={sortMenuOpen ? 'true' : undefined}
            onClick={handleSortMenuClick}
            endIcon={
              sortMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
            sx={{ marginLeft: 'auto !important' }}>
            {!ipad ? 'Order by: ' : ''}
            {getSortLabel(sortField)}
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Menu
        id="wo-actions-menu"
        aria-labelledby="wo-actions-button"
        anchorEl={sortMenuAnchorEl}
        open={sortMenuOpen}
        onClose={handleSortMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '0.25rem' }}>
        {sortOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortField}
            onClick={() => handleSortSelect(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {isFullscreen && <FullscreenHeader />}
      {!isFullscreen && <ExportOptions />}
      {!isFullscreen && (
        <Tooltip title="Filter work orders" placement="top" arrow>
          <Button
            variant="contained"
            size="small"
            onClick={handleToggleFilters}
            sx={{ minWidth: 0, textTransform: 'none' }}
            startIcon={<FilterAltIcon />}
            endIcon={
              filtersCount && (
                <Chip
                  label={filtersCount}
                  size="small"
                  variant="outlined"
                  sx={{
                    backgroundColor: filtersCount
                      ? theme.palette.background.highlight
                      : undefined,
                    '.MuiChip-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
              )
            }>
            Filter
          </Button>
        </Tooltip>
      )}
      {filtersOpen && !isFullscreen && (
        <SubFilters
          handleClose={handleCloseFilters}
          filters={filters}
          anchorEl={document.getElementById('side-panel')}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        />
      )}
      <Tooltip title="Fullscreen" placement="top" arrow>
        <Button
          variant="contained"
          size="small"
          id="fullscreen-button"
          onClick={handleToggleFullscreen}
          sx={{
            minWidth: 0,
            marginLeft: isFullscreen ? '1rem !important' : undefined,
          }}>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default WorkorderControls;
