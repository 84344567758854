import { Route } from 'lib/models';
import Login from 'views/Login';
import PasswordReset from 'views/PasswordReset';
import Home from 'views/Home';
import PasswordResetConfirm from 'views/PasswordResetConfirm';

const paths: Route[] = [
  {
    path: '/',
    component: Home,
    private: true
  }, {
    path: '/login/',
    component: Login
  }, {
    path: '/reset-password/',
    component: PasswordReset
  }, {
    path: '/reset-password/confirm/',
    component: PasswordResetConfirm
  },
];

export default paths;
