/* eslint-disable */
import React, { FC, useEffect, useRef, useState } from 'react';
import IStore from 'lib/redux/models';
import { HeatmapCell, IAppState } from 'storage/app/models';
import * as d3 from 'd3';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import theme from 'theme';
import './Heatmap.css';
import Spinner from 'components/Spinner';
import { formatNumber } from 'utils/utils';
import { selectHeatmapCell } from 'storage/app/duck';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { trackEvent } from 'storage/tracking/duck';
import { isTablet } from 'utils/utils';

interface HeatmapProps {
  className?: string,
  x?: number,
  y?: number,
}

const cellValue = "mean"

const Heatmap: FC<HeatmapProps> = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>((state) => state.app.filters);
  const heatmap = useSelector<IStore, IAppState['data']['heatmap']>((state) => state.app.data.heatmap);
  const loading = useSelector<IStore, IAppState['loadingHeatmap']>((state) => state.app.loadingHeatmap);
  const activeWorkorder = useSelector<IStore, IAppState['data']['activeWorkorder']>((state) => state.app.data.activeWorkorder);
  const domain = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const xTickLabels = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const [tooltip, setTooltip] = useState<HeatmapCell>();
  const isSmallScreen = useMediaQuery('(max-width:1500px)');
  const ipad = isTablet();
  const containerWidth = isSmallScreen && !ipad ? 448 : ipad ? 364 : 512;
  const containerHeight = isSmallScreen ? 258 : 296;
  const margin = { top: 10, right: 30, bottom: 35, left: 50 };
  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;
  const totalMaxCount = heatmap ? Math.max(...heatmap.map(cell => cell[cellValue])) : 0
  const maxCountHigh = heatmap ? Math.max(...heatmap.map(cell => (cell.urgencyGroup > 5 && cell.importanceGroup > 5) ? cell[cellValue] : 0)) : 0
  const maxCountMedium = heatmap ? Math.max(...heatmap.map(cell => (cell.urgencyGroup <= 5 && cell.importanceGroup > 5) || (cell.urgencyGroup > 5 && cell.importanceGroup <= 5) ? cell[cellValue] : 0)) : 0
  const maxCountLow = heatmap ? Math.max(...heatmap.map(cell => (cell.urgencyGroup <= 5 && cell.importanceGroup <= 5) ? cell[cellValue] : 0)) : 0
  const filterImportanceRef = useRef<number | undefined>(filters.importance)
  const filterUrgencyRef = useRef<number | undefined>(filters.urgency)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(isSmallScreen ? false : true)

  /**
   * Updates filter refs
   */
  useEffect(() => {
    filterImportanceRef.current = filters.importance
    filterUrgencyRef.current = filters.urgency
  }, [filters.importance, filters.urgency])

  /**
   * Build the color scales
   */
  const colorHigh = d3.scaleLog<string>()
    .range([theme.palette.heatmap.lowest, theme.palette.heatmap.highest])
    .domain([0.01, maxCountHigh ? totalMaxCount : 1])

  const colorMedium = d3.scaleLog<string>()
    .range([theme.palette.heatmap.lowest, theme.palette.heatmap.highest])
    .domain([0.01, maxCountMedium ? totalMaxCount : 1])

  const colorLow = d3.scaleLog<string>()
    .range([theme.palette.heatmap.lowest, theme.palette.heatmap.highest])
    .domain([0.01, maxCountLow ? totalMaxCount : 1])

  const calculateCellColor = (cell: any) => {
    const value = cell[cellValue] === 0 ? cell.count > 0 ? 0.01 : 0 : cell[cellValue]
    if (value === 0) return 'transparent'
    if (cell.importanceGroup > 5 && cell.urgencyGroup > 5) {
      return colorHigh(value);
    } else if (cell.importanceGroup <= 5 && cell.urgencyGroup <= 5) {
      return colorLow(value);
    } else {
      return colorMedium(value);
    }
  }

  /**
   * Build the X scales and axis
   */
  const x = d3.scaleBand<number>()
    .range([0, width])
    .domain(domain)
    .padding(0.01);

  /**
   * Build the Y scales and axis
   */
  const y = d3.scaleBand<number>()
    .range([height, 0])
    .domain(domain)
    .padding(0.01);

  const quadrantXMap = (quadrant: number) => quadrant >= 2 ? 4 : 0
  const quadrantYMap = (quadrant: number) => (quadrant === 1 || quadrant === 3) ? 4 : 0

  const getCellQuadrant = (cell: any) => {
    return cell.importanceGroup > 5 && cell.urgencyGroup > 5 ? 3 :
      (cell.importanceGroup <= 5 && cell.urgencyGroup <= 5) ? 0 :
        cell.importanceGroup > 5 ? 1 :
          2
  }

  const calculateCellX = (cell: any) => {
    const quadrant = getCellQuadrant(cell)
    if (cell.importanceGroup === filters.importance && cell.urgencyGroup === filters.urgency) {
      return (x(cell.urgencyGroup) || 0) + (quadrantXMap(quadrant) - 4)
    } else {
      return (x(cell.urgencyGroup) || 0) + quadrantXMap(quadrant)
    }
  }

  const calculateCellY = (cell: any) => {
    const quadrant = getCellQuadrant(cell)
    if (cell.importanceGroup === filters.importance && cell.urgencyGroup === filters.urgency) {
      return (y(cell.importanceGroup) || 0) - (quadrantYMap(quadrant) + 4) + 2
    } else {
      return (y(cell.importanceGroup) || 0) - quadrantYMap(quadrant) + 2
    }
  }

  useEffect(() => {
    /**
     * Add or select the svg to build the graph.
     * This ensures that the svg is only appended once
     * and isn't duplicated on subsequent renders.
     */

    const svg: any = d3.select('.heatmap-g').node()
      ? d3.select(".heatmap-g")
      : d3.select("#heatmap-container")
        .append("svg")
        .attr('id', 'heatmap')
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr('class', 'heatmap-g')
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const xGroup = d3.select('.x-axis').node()
      ? d3.select('.x-axis')
      : svg.append("g").attr('class', 'x-axis');

    const xAxis = d3.axisBottom(x)
      .tickPadding(7)
      .tickSize(0)
      .tickFormat(x => xTickLabels[x - 1]);

    xGroup
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis)
      .call((g: any) => g.select(".domain").remove());

    const yGroup = d3.select('.y-axis').node()
      ? d3.select('.y-axis')
      : svg.append("g").attr('class', 'y-axis');

    const yAxis = d3.axisLeft(y)
      .tickPadding(7)
      .tickSize(0);

    yGroup
      .call(yAxis)
      .call((g: any) => g.select(".domain").remove());
    const verticalQuadrantLine = d3.select('.vert-quad-line').node()
      ? d3.select('.vert-quad-line')
      : svg.append("line").attr('class', 'vert-quad-line');

    verticalQuadrantLine
      .attr('x1', (x(6) || 0) + 2)
      .attr('x2', (x(6) || 0) + 2)
      .attr('y1', -3)
      .attr('y2', height + 3)
      .style('stroke', theme.palette.text.primary)
      .style('stroke-width', 6);

    const horizontalQuadrantLine = d3.select('.horz-quad-line').node()
      ? d3.select('.horz-quad-line')
      : svg.append("line").attr('class', 'horz-quad-line');

    horizontalQuadrantLine
      .attr('x1', -1)
      .attr('x2', width + 5)
      .attr('y1', (y(5) || 0) - 1)
      .attr('y2', (y(5) || 0) - 1)
      .style('transform', `translate(0, 0)`)
      .style('stroke', theme.palette.text.primary)
      .style('stroke-width', 6);

    /**
     * Add the axis labels
     */
    const xLabelLess = d3.select('.x-label-less').node()
      ? d3.select('.x-label-less')
      : xGroup.append("text").attr('class', 'x-label-less');

    const xLabelMore = d3.select('.x-label-more').node()
      ? d3.select('.x-label-more')
      : xGroup.append("text").attr('class', 'x-label-more');

    xLabelLess
      .text('Less Urgent')
      .style('transform', `translate(2.5rem, 1.875rem)`)
      .style('fill', 'currentColor')
      .style('font-size', '0.85rem');

    xLabelMore
      .text('More Urgent')
      .style('transform', ipad ? `translate(18.5rem, 1.875rem)` : `translate(24.5rem, 1.875rem)`)
      .style('fill', 'currentColor')
      .style('font-size', '0.85rem');

    const yLabelLess = d3.select('.y-label-less').node()
      ? d3.select('.y-label-less')
      : yGroup.append("text").attr('class', 'y-label-less');

    const yLabelMore = d3.select('.y-label-more').node()
      ? d3.select('.y-label-more')
      : yGroup.append("text").attr('class', 'y-label-more');

    yLabelLess
      .text('Less Important')
      .style('transform', `rotate(270deg) translate(-11rem, -1.75rem)`)
      .style('fill', 'currentColor')
      .style('font-size', '0.85rem');

    yLabelMore
      .text('More Important')
      .style('transform', `rotate(270deg) translate(0px, -1.75rem)`)
      .style('fill', 'currentColor')
      .style('font-size', '0.85rem');

    /**
     * Need to explicitly set the font-family for svg text
     */
    svg.selectAll('text')
      .style('font-family', 'Atkinson-Hyperlegible');

    /**
     * Draw rectangles for heatmap cells
     */
    svg.selectAll()
      .data(heatmap, (d: any) => d.urgencyGroup + ':' + d.importanceGroup)
      .join("rect")
      .attr('class', 'cell')
      .attr("x", (d: any) => x(d.urgencyGroup) || 0)
      .attr("y", (d: any) => y(d.importanceGroup) || 0)
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("stroke-width", '2')
      .style("stroke", theme.palette.text.primary)
      .on('mousemove', (e: any, d: any) => {
        d3.select(".tooltip")
          .style("left", `${e.x}px`)
          .style("top", `calc(${e.y}px)`)
        d3.select(".tooltip")
          .style("display", "block")
        setTooltip(d)
      })
      .on('mouseleave', (e: any, d: any) => {
        setTooltip(undefined)
        d3.select(".tooltip")
          .style("display", "none");
      })
      .on('click', (e: any, d: any) => {
        if (d.importanceGroup === filterImportanceRef.current && d.urgencyGroup === filterUrgencyRef.current) {
          dispatch(selectHeatmapCell(undefined))
          dispatch(trackEvent({ namespace: 'Remove heatmap filter', predicate: 'Heatmap' }))
        } else {
          dispatch(selectHeatmapCell({ importance: d.importanceGroup, urgency: d.urgencyGroup }))
          dispatch(trackEvent({ namespace: 'Set heatmap filter', predicate: 'Heatmap', payload: { importance: d.importanceGroup, urgency: d.urgencyGroup } }))
        }
      });
  }, []);

  /**
   * Color the heatmap cells based on the counts in the heatmap data.
   * Update the cells whenever the data changes.
   */
  useEffect(() => {
    if (heatmap) {
      const svg = d3.select(".heatmap-g");
      svg.selectAll('.cell')
        .data(heatmap, (d: any) => d.urgencyGroup + ':' + d.importanceGroup)
        .join('rect')
        .sort((a, b) => a.importanceGroup === filters.importance && a.urgencyGroup === filters.urgency ? 1 : -1)
        .transition()
        .duration(500)
        .style("fill", calculateCellColor);
    }
  }, [heatmap]);

  /*
  * Highliht the selected heatmap cell 
  */
  useEffect(() => {
    if (heatmap) {
      const svg = d3.select(".heatmap-g");
      svg.selectAll('.cell')
        .data(heatmap, (d: any) => d.urgencyGroup + ':' + d.importanceGroup)
        .join('rect')
        .style("stroke-width", (d: any) => d.importanceGroup === filters.importance && d.urgencyGroup === filters.urgency ? 8 : 2)
        .style("stroke", (d: any) => d.importanceGroup === filters.importance && d.urgencyGroup === filters.urgency ? theme.palette.background.highlight : "white")
        .style("width", (d: any) => d.importanceGroup === filters.importance && d.urgencyGroup === filters.urgency ?
          x.bandwidth() + 8 :
          x.bandwidth()
        )
        .style("height", (d: any) => d.importanceGroup === filters.importance && d.urgencyGroup === filters.urgency ?
          y.bandwidth() + 8 :
          y.bandwidth()
        )
        .style("x", cell => `${calculateCellX(cell)}px`)
        .style("y", cell => `${calculateCellY(cell)}px`)
        .sort((a, b) => a.importanceGroup === filters.importance && a.urgencyGroup === filters.urgency ? 1 : -1);
    }
  }, [filters.importance, filters.urgency]);

  const handleClearHeatmapFilter = () => {
    dispatch(selectHeatmapCell(undefined))
    dispatch(trackEvent({ namespace: 'Remove heatmap filter', predicate: 'Heatmap clear button' }))
  }

  return (
    <Box
      id="heatmap-wrapper"
      sx={{
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'background.dark',
      }}
    >
      <Spinner isVisible={loading && !activeWorkorder} dimBackground />
      <Accordion
        onChange={(e, expanded) => setIsAccordionExpanded(expanded)}
        defaultExpanded={!isSmallScreen && !ipad}
        sx={{
          backgroundColor: 'transparent',
          "root": { margin: 0 },
          ".MuiAccordion-root.Mui-expanded": { margin: 0 },
          ".MuiAccordionSummary-root": { minHeight: 0 },
          ".MuiAccordionSummary-root.Mui-expanded": { minHeight: 0 },
          ".MuiAccordionSummary-content": { minHeight: 0, margin: '0.75rem 0' },
          ".MuiAccordionSummary-content.Mui-expanded": { minHeight: 0, margin: '0.75rem 0' },
        }}
      >
        <Tooltip title={isAccordionExpanded ? "Hide heatmap" : "Show heatmap"} placement="top">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls="heatmap-content"
            id="heatmap-header"
            sx={{
              backgroundColor: theme.palette.background.light,
              ":hover": { backgroundColor: theme.palette.background.lightest },
            }}
          >
            <Typography>Work orders by Importance and Urgency</Typography>
          </AccordionSummary>
        </Tooltip>
        <AccordionDetails id="heatmap-container" sx={{ p: 0 }}>
          {loading === false && tooltip && tooltip.count > 0 &&
            <Box className='tooltip' sx={{ backgroundColor: theme.palette.background.dark }}>
              <Typography variant="body2">Mean BSI {formatNumber(tooltip[cellValue])}</Typography>
            </Box>
          }
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ pt: 2, pb: 1, pl: 2, pr: 2 }}
          >
            <Stack direction="row" spacing={1} alignItems="center" justifyContent={'flex-end'} width={'100%'} height="1.25rem">
              {filters.importance && filters.urgency &&
                <Button
                  variant='contained'
                  sx={{
                    lineHeight: '1.25rem',
                    fontSize: '1rem',
                    backgroundColor: theme.palette.background.highlight,
                    paddingX: ipad ? '0.75rem' : 'auto',
                    textTransform: 'none'
                  }}
                  startIcon={<CancelIcon />}
                  onClick={handleClearHeatmapFilter}
                >
                  {ipad ? "Clear filter" : "Remove heatmap filter"}
                </Button>
              }
              <Typography variant="body2" sx={{ ml: 'auto !important' }}>Mean BSI: low</Typography>
              <Box
                sx={{
                  width: '1rem',
                  height: '0.75rem',
                  border: '1px solid white',
                  backgroundColor: theme.palette.heatmap.lowest
                }}
              />
              <Box
                sx={{
                  width: '1rem',
                  height: '0.75rem',
                  border: '1px solid white',
                  margin: '0 !important',
                  backgroundColor: theme.palette.heatmap.low,
                }}
              />
              <Box
                sx={{
                  width: '1rem',
                  height: '0.75rem',
                  border: '1px solid white',
                  margin: '0 !important',
                  backgroundColor: theme.palette.heatmap.med,
                }}
              />
              <Box
                sx={{
                  width: '1rem',
                  height: '0.75rem',
                  border: '1px solid white',
                  margin: '0 !important',
                  backgroundColor: theme.palette.heatmap.high,
                }}
              />
              <Box
                sx={{
                  width: '1rem',
                  height: '0.75rem',
                  border: '1px solid white',
                  margin: '0 !important',
                  backgroundColor: theme.palette.heatmap.highest
                }}
              />
              <Typography variant="body2">high</Typography>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box >
  );
};

export default Heatmap;
