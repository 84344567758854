/* eslint-disable @typescript-eslint/no-explicit-any */
// Models
import { IAction } from 'lib/redux/models'
import {
  ENotesActionTypes,
  IActionGetWorkorderNotes,
  IActionAddWorkorderNote,
  INotesState,
  IActionGetWorkorderRecipients,
  IActionSaveWorkorderNote,
  IActionSetWorkorderRecipients,
  IActionSetWorkorderNotes,
  IActionBatchSaveWorkorderNote
} from '../models'

// ACTION TYPES
export const Types = {
  FAILURE: ENotesActionTypes.FAILURE,
  FULFILL: ENotesActionTypes.FULFILL,
  REQUEST: ENotesActionTypes.REQUEST,
  SUCCESS: ENotesActionTypes.SUCCESS,
  GET_NOTES: ENotesActionTypes.GET_NOTES,
  SET_NOTES: ENotesActionTypes.SET_NOTES,
  ADD_NOTE: ENotesActionTypes.ADD_NOTE,
  SAVE_NOTE: ENotesActionTypes.SAVE_NOTE,
  BATCH_SAVE_NOTE: ENotesActionTypes.BATCH_SAVE_NOTE,
  GET_RECIPIENTS: ENotesActionTypes.GET_RECIPIENTS,
  SET_RECIPIENTS: ENotesActionTypes.SET_RECIPIENTS,
}

// INITIAL STATE
const initialState: INotesState = {
  recipients: {},
  data: {},
  loadingNotes: false,
  loadingRecipients: false,
}

// REDUCER
export default (
  state: INotesState = initialState,
  action?: IAction,
): INotesState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case Types.FULFILL:
      return {
        ...state,
        [action.payload]: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        [action.payload]: true,
        error: undefined,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...action?.payload,
      }
    case Types.SET_NOTES: {
      const parsedNotes = action.payload.notes.map((note: any, index: number) => ({
        id: index + 1,
        number: note.number,
        noteData: note.note,
        createdDate: note.created_date,
        createdBy: note.created_by,
        actionRequired: note.action_required,
      }))
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.workorderId]: parsedNotes,
        }
      }
    }
    case Types.ADD_NOTE: {
      const woNotes = state.data[action.payload.workorderId]
      const id = woNotes ? woNotes.length + 1 : 1
      const note = {
        id: id,
        number: id,
        noteData: action.payload.note,
        createdDate: new Date().toString(),
        createdBy: action.payload.user,
        actionRequired: action.payload?.actionRequired ? action.payload?.actionRequired : true,
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.workorderId]: woNotes ? [note, ...woNotes] : [note],
        }
      }
    }
    case Types.SET_RECIPIENTS:
      return {
        ...state,
        recipients: {
          ...state.recipients,
          [action.payload.workorderId]: action.payload.recipients,
        },
      }
    default:
      return state
  }
}

// ACTIONS
export const failure = (payload: { message: string, data?: any }): IAction => ({
  type: Types.FAILURE,
  payload,
})

export const fulfill = (payload: string): IAction => ({
  type: Types.FULFILL,
  payload
})

export const request = (payload: string): IAction => ({
  type: Types.REQUEST,
  payload
})

export const success = (payload: Partial<INotesState>): IAction => ({
  type: Types.SUCCESS,
  payload,
})

export const getWorkorderNotes = (payload: IActionGetWorkorderNotes["payload"]): IAction => {
  return {
    type: Types.GET_NOTES,
    payload,
  };
};

export const setWorkorderNotes = (payload: IActionSetWorkorderNotes["payload"]): IAction => {
  return {
    type: Types.SET_NOTES,
    payload,
  };
};

export const addWorkorderNote = (payload: IActionAddWorkorderNote["payload"]): IAction => {
  return {
    type: Types.ADD_NOTE,
    payload,
  };
};

export const saveWorkorderNote = (payload: IActionSaveWorkorderNote["payload"]): IAction => {
  return {
    type: Types.SAVE_NOTE,
    payload,
  };
};

export const batchSaveWorkorderNote = (payload: IActionBatchSaveWorkorderNote["payload"]): IAction => {
  return {
    type: Types.BATCH_SAVE_NOTE,
    payload,
  };
};

export const getRecipients = (payload: IActionGetWorkorderRecipients["payload"]): IAction => {
  return {
    type: Types.GET_RECIPIENTS,
    payload,
  };
};

export const setRecipients = (payload: IActionSetWorkorderRecipients["payload"]): IAction => {
  return {
    type: Types.SET_RECIPIENTS,
    payload,
  };
};

export const actions = {
  failure,
  fulfill,
  request,
  success,
}
