/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'storage/app/models';
import {
  setBSI, setCallDate, setCategories, setDateRange, setEscalatedFilter, setEscalatedUser,
  setMissedETA, setNTE, setNotOperationalOnly, setProviders, setStatus, setTradeGroups,
  setTrades, setWorkingOnItFilter, setWorkingOnItUser, setNoActivitySince, setEquipmentTypes, setPotentialDuplicateOnly, setExtendedStatus, setMaintenanceRepairOnly,
  setProblemDescription
} from 'storage/app/duck';
import { Button, Stack, Checkbox, Menu, Typography, MenuProps } from "@mui/material";
import FilterMenu, { StringItem } from "./FilterMenu";
import Chip from "@mui/material/Chip";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import objectHash from 'object-hash';
import theme from 'theme';
import IStore from 'lib/redux/models';
import { calculateDateRange, getDefaultCallDate } from 'utils/utils';
import { trackEvent } from 'storage/tracking/duck';


export const statusOptions: StringItem[] = [
  {
    name: 'OPEN',
    value: 'OPEN'
  },
  {
    name: 'IN PROGRESS',
    value: 'IN PROGRESS'
  },
  {
    name: 'CANCELLED (AGED)',
    value: 'COMPLETED'
  }
];

export const bsiOptions: StringItem[] = [
  {
    name: 'High',
    value: 'high'
  },
  {
    name: 'Medium',
    value: 'medium'
  },
  {
    name: 'Low',
    value: 'low'
  }
];

export const nteOptions: StringItem[] = [
  {
    name: 'NTE > $0',
    value: 'greaterThanZero'
  },
  {
    name: 'NTE = $0',
    value: 'equalsZero'
  }
];

export const workingOnItOptions: StringItem[] = [
  {
    name: 'Active',
    value: 'true'
  },
  {
    name: 'Not active',
    value: 'false'
  }
];

export const escalatedOptions: StringItem[] = [
  {
    name: 'Active',
    value: 'true'
  },
  {
    name: 'Not active',
    value: 'false'
  }
];

export const noActivitySinceOptions: StringItem[] = [
  {
    name: 'a day ago',
    value: dayjs().subtract(1, 'day').unix()
  },
  {
    name: '3 days ago',
    value: dayjs().subtract(3, 'day').unix()
  },
  {
    name: 'a week ago',
    value: dayjs().subtract(1, 'week').unix()
  },
  {
    name: '2 weeks ago',
    value: dayjs().subtract(2, 'week').unix()
  },
  {
    name: 'a month ago',
    value: dayjs().subtract(1, 'month').unix()
  }
];


export const dateOptions: StringItem[] = [
  {
    name: 'All available dates',
    value: 'all'
  },
  {
    name: '0 to 30 days',
    value: '30-days'
  },
  {
    name: '30 to 60 days',
    value: '30-to-60-days'
  },
  {
    name: '60 to 90 days',
    value: '60-to-90-days'
  },
  {
    name: 'More than 90 days',
    value: 'more-than-90-days'
  },
  {
    name: 'All aged work orders',
    value: 'all-aged-workorders'
  },
  {
    name: 'Custom Dates',
    value: 'custom'
  }
];

export const advancedFiltersOptions: StringItem[] = [
  {
    name: 'Not operational equipment only',
    value: 'notOperationalOnly'
  },
  {
    name: 'Potential duplicates only',
    value: 'potentialDuplicateOnly'
  },
  {
    name: 'Scheduled date in the past',
    value: 'missedETA'
  },
  {
    name: 'Display Maintenance/Repair',
    value: 'maintenanceRepairOnly'
  }
];

interface SubFiltersProps {
  filters: IAppState['filters'],
  anchorEl: HTMLElement | null,
  handleClose: (() => void),
  anchorOrigin?: MenuProps["anchorOrigin"],
  transformOrigin?: MenuProps["transformOrigin"],
}

const SubFilters: FC<SubFiltersProps> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IStore) => state.auth.user);
  const metadata = useSelector((state: IStore) => state.app.metadata);
  const {
    trades: tradeOptions,
    tradeGroups: tradeGroupOptions,
    providers: providerOptions,
    categories: categoriesOptions,
    equipmentTypes: equipmentTypeOptions,
    extendedStatus: extendedStatusOptions,
    problemDescription: problemDescriptionOptions,
  } = useSelector((state: IStore) => state.app.filtersOptions);

  const [tradeMenuAnchorEl, setTradeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [tradeFilters, setTradeFilters] = useState<undefined | string[]>(props.filters.trades);

  const [tradeGroupsMenuAnchorEl, setTradeGroupsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [tradeGroupsFilters, setTradeGroupsFilters] = useState<undefined | string[]>(props.filters.tradeGroups);

  const [providerMenuAnchorEl, setProviderMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [providerFilters, setProviderFilters] = useState<undefined | string[]>(props.filters.providers);

  const [categoryMenuAnchorEl, setCategoryMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryFilters, setCategoryFilters] = useState<undefined | string[]>(props.filters.categories);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [statusFilters, setStatusFilters] = useState<undefined | string[]>(props.filters.status);

  const [bsiMenuAnchorEl, setBsiMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [bsiFilters, setBsiFilters] = useState<undefined | string[]>(props.filters.bsi);

  const [nteMenuAnchorEl, setNteMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [nteFilters, setNteFilters] = useState<undefined | StringItem>(props.filters.nte);

  const [workingOnItMenuAnchorEl, setWorkingOnItMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [workingOnItFilters, setWorkingOnItFilters] = useState<undefined | StringItem>(props.filters.workingOnIt);

  const [escalatedMenuAnchorEl, setEscalatedMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [escalatedFilters, setEscalatedFilters] = useState<undefined | StringItem>(props.filters.escalated);

  const [notOperationalOnlyFilter, setNotOperationalOnlyFilter] = useState<boolean | undefined>(props.filters.notOperationalOnly);

  const [potentialDuplicateOnlyFilter, setPotentialDuplicateOnlyFilter] = useState<boolean | undefined>(props.filters.potentialDuplicateOnly);

  const [missedETAFilter, setMissedETAFilter] = useState<boolean | undefined>(props.filters.missedETA);

  const [maintenanceRepairOnlyFilter, setMaintenanceRepairOnlyFilter] = useState<boolean | undefined>(props.filters.maintenanceRepairOnly);

  const [advancedFiltersMenuAnchorEl, setAdvancedFiltersMenuAnchorEl] = useState<null | HTMLElement>(null);
  const hasAdvancedFilter = props.filters.notOperationalOnly || props.filters.potentialDuplicateOnly || props.filters.missedETA || props.filters.maintenanceRepairOnly;
  const [advancedFiltersFilter, setAdvancedFiltersFilter] = useState<undefined | string[]>(
    hasAdvancedFilter ?
      advancedFiltersOptions.map(option => option.value as string)
        .filter(option => props.filters[option as keyof IAppState['filters']])
      : undefined
  );

  const [workingOnItUserFilter, setWorkingOnItUserFilter] = useState<undefined | string>(props.filters.workingOnItUser);

  const [escalatedUserFilter, setEscalatedUserFilter] = useState<undefined | string>(props.filters.escalatedUser);

  const [noActivitySinceMenuAnchorEl, setNoActivitySinceMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [noActivitySinceFilter, setNoActivitySinceFilter] = useState<undefined | StringItem>(props.filters.noActivitySince);

  const [equipmentTypeMenuAnchorEl, setEquipmentTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [equipmentTypeFilter, setEquipmentTypeFilter] = useState<undefined | string[]>(props.filters.equipmentTypes);

  const [extendedStatusMenuAnchorEl, setExtendedStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [extendedStatusFilter, setExtendedStatusFilter] = useState<undefined | string[]>(props.filters.extendedStatus);

  const [problemDescriptionMenuAnchorEl, setProblemDescriptionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [problemDescriptionFilter, setProblemDescriptionFilter] = useState<undefined | string[]>(props.filters.problemDescription);

  const [dateRangeMenuAnchorEl, setDateRangeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRangeFilter, setDateRangeFilter] = useState<StringItem>(props.filters.dateRange);

  const [startDate, setStartDate] = useState<undefined | number>(props.filters.callDate.start);
  const [menuOpenStartDate, setMenuOpenStartDate] = useState<boolean>(false);

  const [endDate, setEndDate] = useState<undefined | number>(props.filters.callDate.end);
  const [menuOpenEndDate, setMenuOpenEndDate] = useState<boolean>(false);

  const tradesChanged = props.filters.trades && tradeFilters ? (objectHash(props.filters.trades) !== objectHash(tradeFilters)) : props.filters.trades !== tradeFilters;
  const tradeGroupsChanged = props.filters.tradeGroups && tradeGroupsFilters ? (objectHash(props.filters.tradeGroups) !== objectHash(tradeGroupsFilters)) : props.filters.tradeGroups !== tradeGroupsFilters;
  const providersChanged = props.filters.providers && providerFilters ? (objectHash(props.filters.providers) !== objectHash(providerFilters)) : props.filters.providers !== providerFilters;
  const categoryChanged = props.filters.categories && categoryFilters ? (objectHash(props.filters.categories) !== objectHash(categoryFilters)) : props.filters.categories !== categoryFilters;
  const statusChanged = props.filters.status && statusFilters ? (objectHash(props.filters.status) !== objectHash(statusFilters)) : props.filters.status !== statusFilters;
  const bsiChanged = props.filters.bsi && bsiFilters ? (objectHash(props.filters.bsi) !== objectHash(bsiFilters)) : props.filters.bsi !== bsiFilters;
  const nteChanged = props.filters.nte && nteFilters ? (objectHash(props.filters.nte) !== objectHash(nteFilters)) : props.filters.nte !== nteFilters;
  const workingOnItChanged = props.filters.workingOnIt && workingOnItFilters ? (objectHash(props.filters.workingOnIt) !== objectHash(workingOnItFilters)) : props.filters.workingOnIt !== workingOnItFilters;
  const escalatedChanged = props.filters.escalated && escalatedFilters ? (objectHash(props.filters.escalated) !== objectHash(escalatedFilters)) : props.filters.escalated !== escalatedFilters;
  const workingOnItUserChanged = props.filters.workingOnItUser !== workingOnItUserFilter;
  const notOperationalOnlyChanged = props.filters.notOperationalOnly !== notOperationalOnlyFilter;
  const potentialDuplicateOnlyChanged = props.filters.potentialDuplicateOnly !== potentialDuplicateOnlyFilter;
  const missedETAChanged = props.filters.missedETA !== missedETAFilter;
  const maintenanceRepairOnlyChanged = props.filters.maintenanceRepairOnly !== maintenanceRepairOnlyFilter;
  const escalatedUserChanged = props.filters.escalatedUser !== escalatedUserFilter;
  const noActivitySinceChanged = props.filters.noActivitySince !== noActivitySinceFilter;
  const equipmentTypeChanged = props.filters.equipmentTypes !== equipmentTypeFilter;
  const extendedStatusChanged = props.filters.extendedStatus !== extendedStatusFilter;
  const problemDescriptionChanged = props.filters.problemDescription !== problemDescriptionFilter;
  const dateRangeChanged = props.filters.dateRange !== dateRangeFilter;
  const startDateChanged = props.filters.callDate.start !== startDate;
  const endDateChanged = props.filters.callDate.end !== endDate;
  const filtersChanged = tradesChanged || tradeGroupsChanged || providersChanged || categoryChanged || statusChanged || bsiChanged || nteChanged ||
    workingOnItChanged || escalatedChanged || notOperationalOnlyChanged || potentialDuplicateOnlyChanged || missedETAChanged || workingOnItUserChanged ||
    escalatedUserChanged || noActivitySinceChanged || equipmentTypeChanged || extendedStatusChanged || dateRangeChanged || startDateChanged || endDateChanged ||
    maintenanceRepairOnlyChanged || problemDescriptionChanged;


  useEffect(() => {
    if (props.filters.callDate.end && endDate) {
      const propsEnd = dayjs(props.filters.callDate.end * 1000)
      const stateEnd = dayjs(endDate * 1000)
      if (propsEnd.diff(stateEnd, "minutes") < 30) {
        setEndDate(props.filters.callDate.end);
      }
    }
  }, [props.filters.callDate.end])


  const handleToggleMenu = (
    event: React.MouseEvent<HTMLElement>,
    element: HTMLElement | null,
    callback: Dispatch<SetStateAction<HTMLElement | null>>
  ) => {
    if (element) {
      callback(null);
    } else {
      callback(event.currentTarget);
    }
  }

  const handleNotOprationalOnlyChange = (value?: boolean) => {
    setNotOperationalOnlyFilter(value);
    dispatch(trackEvent({ namespace: 'Set not operational equipment only filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handlePotentialDuplicateOnlyChange = (value?: boolean) => {
    setPotentialDuplicateOnlyFilter(value);
    dispatch(trackEvent({ namespace: 'Set potential duplicate only filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handleMissedETAChange = (value?: boolean) => {
    setMissedETAFilter(value);
    dispatch(trackEvent({ namespace: 'Set missed ETA filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handleMaintenanceRepairOnlyChange = (value?: boolean) => {
    setMaintenanceRepairOnlyFilter(value);
    dispatch(trackEvent({ namespace: 'Set maintenance repair only filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handleAdvancedFilterChange = (value?: string[]) => {
    const valueHaveOption = (option: string) => value?.some((val) => val === option);
    setAdvancedFiltersFilter(value);
    if (valueHaveOption('notOperationalOnly') && !notOperationalOnlyFilter) {
      handleNotOprationalOnlyChange(true);
    } else if (!valueHaveOption('notOperationalOnly') && notOperationalOnlyFilter) {
      handleNotOprationalOnlyChange(undefined);
    }
    if (valueHaveOption('potentialDuplicateOnly') && !potentialDuplicateOnlyFilter) {
      handlePotentialDuplicateOnlyChange(true);
    } else if (!valueHaveOption('potentialDuplicateOnly') && potentialDuplicateOnlyFilter) {
      handlePotentialDuplicateOnlyChange(undefined);
    }
    if (valueHaveOption('missedETA') && !missedETAFilter) {
      handleMissedETAChange(true);
    } else if (!valueHaveOption('missedETA') && missedETAFilter) {
      handleMissedETAChange(undefined);
    }
    if (valueHaveOption('maintenanceRepairOnly') && !maintenanceRepairOnlyFilter) {
      handleMaintenanceRepairOnlyChange(true);
    } else if (!valueHaveOption('maintenanceRepairOnly') && maintenanceRepairOnlyFilter) {
      handleMaintenanceRepairOnlyChange(undefined);
    }
  }

  const handleWorkingOnItUserChange = (value?: boolean) => {
    if (user && value) {
      setWorkingOnItUserFilter(user.email);
    } else {
      setWorkingOnItUserFilter(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set working on it user filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handleEscalatedUserChange = (value?: boolean) => {
    if (user && value) {
      setEscalatedUserFilter(user.email);
    } else {
      setEscalatedUserFilter(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set escalated user filter', predicate: 'Filter menu', value: value?.toString() }))
  };

  const handleTradesChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setTradeFilters(value);
    } else {
      setTradeFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set trades filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleTradeGroupsChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setTradeGroupsFilters(value);
    } else {
      setTradeGroupsFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set trade groups filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleProvidersChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setProviderFilters(value);
    } else {
      setProviderFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set providers filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleCategoryChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setCategoryFilters(value);
    } else {
      setCategoryFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set category filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleStatusChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setStatusFilters(value);
    } else {
      setStatusFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set status filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleBsiChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setBsiFilters(value);
    } else {
      setBsiFilters(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set BSI filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleNteChange = (option: StringItem) => {
    if (option) {
      setNteFilters(option);
    } else {
      setNteFilters(undefined);
    }
    setNteMenuAnchorEl(null);
    dispatch(trackEvent({ namespace: 'Set NTE filter', predicate: 'Filter menu', payload: option ? { filter: option.value } : undefined }))
  };

  const handleWorkingOnItChange = (option: StringItem) => {
    if (option) {
      setWorkingOnItFilters(option);
    } else {
      setWorkingOnItFilters(undefined);
    }
    setWorkingOnItMenuAnchorEl(null);
    dispatch(trackEvent({ namespace: 'Set Working on it filter', predicate: 'Filter menu', payload: option ? { filter: option.value } : undefined }))
  };

  const handleEscalatedChange = (option: StringItem) => {
    if (option) {
      setEscalatedFilters(option);
    } else {
      setEscalatedFilters(undefined);
    }
    setEscalatedMenuAnchorEl(null);
    dispatch(trackEvent({ namespace: 'Set Escalated filter', predicate: 'Filter menu', payload: option ? { filter: option.value } : undefined }))
  };

  const handleNoActivitySinceChange = (option: StringItem) => {
    if (option) {
      setNoActivitySinceFilter(option);
    } else {
      setNoActivitySinceFilter(undefined);
    }
    setNoActivitySinceMenuAnchorEl(null);
    dispatch(trackEvent({ namespace: 'Set No Activity Since filter', predicate: 'Filter menu', payload: option ? { filter: option } : undefined }))
  };

  const handleEquipmentTypeChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setEquipmentTypeFilter(value);
    } else {
      setEquipmentTypeFilter(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set Equipment filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleExtendedStatusChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setExtendedStatusFilter(value);
    } else {
      setExtendedStatusFilter(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set Extended Status filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleProblemDescriptionChange = (value?: string[]) => {
    if (value && value.length > 0) {
      setProblemDescriptionFilter(value);
    } else {
      setProblemDescriptionFilter(undefined);
    }
    dispatch(trackEvent({ namespace: 'Set Problem Description filter', predicate: 'Filter menu', payload: value ? { filter: value } : undefined }))
  };

  const handleMenuDateChange = (event: React.MouseEvent<HTMLElement>) => {
    if (dateRangeMenuAnchorEl) {
      setDateRangeMenuAnchorEl(null);
    } else {
      setDateRangeMenuAnchorEl(event.currentTarget);
    }
  };

  const handleDateChange = (option: StringItem) => {
    if (option) {
      setDateRangeFilter(option);
      const callDate = calculateDateRange(option, { start: startDate, end: endDate });
      setStartDate(callDate?.start);
      setEndDate(callDate?.end);
      setDateRangeMenuAnchorEl(null);
    }
    dispatch(trackEvent({ namespace: 'Set date range filter', predicate: 'Filter menu', payload: option.value ? { filter: option.value } : undefined }))
  }

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      setDateRangeFilter(dateOptions[6]);
      setStartDate(date.valueOf() / 1000);
      if (menuOpenStartDate) {
        setMenuOpenStartDate(false);
      }
      dispatch(trackEvent({ namespace: 'Set start date filter', predicate: 'Filter menu', payload: { filter: date.toJSON() } }))
    }
  }

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      setDateRangeFilter(dateOptions[6]);
      setEndDate(date.valueOf() / 1000);
      if (menuOpenEndDate) {
        setMenuOpenEndDate(false);
      }
      dispatch(trackEvent({ namespace: 'Set end date filter', predicate: 'Filter menu', payload: { filter: date.toJSON() } }))
    }
  }

  const handleMenuStartDateChange = (value: boolean, params?: any, event?: any) => {
    if (params) {
      const endAdorment: any = params.InputProps?.endAdornment;
      endAdorment.props?.children?.props?.onClick(event)
    }
    setMenuOpenStartDate(value);
  };

  const handleMenuEndDateChange = (value: boolean, params?: any, event?: any) => {
    if (params) {
      const endAdorment: any = params.InputProps?.endAdornment;
      endAdorment.props?.children?.props?.onClick(event)
    }
    setMenuOpenEndDate(value);
  };

  const handleClose = () => {
    props.handleClose();
  }

  const handleClearFilters = () => {
    setTradeFilters(undefined);
    dispatch(setTrades(undefined));

    setTradeGroupsFilters(undefined);
    dispatch(setTradeGroups(undefined));

    setProviderFilters(undefined);
    dispatch(setProviders(undefined));

    setCategoryFilters(undefined);
    dispatch(setCategories(undefined));

    setStatusFilters(undefined);
    dispatch(setStatus(undefined));

    setExtendedStatusFilter(undefined);
    dispatch(setExtendedStatus(undefined));

    setNteFilters(undefined);
    dispatch(setNTE(undefined));

    setWorkingOnItFilters(undefined);
    dispatch(setWorkingOnItFilter(undefined));

    setEscalatedFilters(undefined);
    dispatch(setEscalatedFilter(undefined));

    setBsiFilters(undefined);
    dispatch(setBSI(undefined));

    setNoActivitySinceFilter(undefined);
    dispatch(setNoActivitySince(undefined));

    setEquipmentTypeFilter(undefined);
    dispatch(setEquipmentTypes(undefined));

    setNotOperationalOnlyFilter(undefined);
    dispatch(setNotOperationalOnly(undefined));

    setPotentialDuplicateOnlyFilter(undefined);
    dispatch(setPotentialDuplicateOnly(undefined));

    setMissedETAFilter(undefined);
    dispatch(setMissedETA(undefined));

    setMaintenanceRepairOnlyFilter(undefined);
    dispatch(setMaintenanceRepairOnly(undefined));

    setWorkingOnItUserFilter(undefined);
    dispatch(setWorkingOnItUser(undefined));

    setEscalatedUserFilter(undefined);
    dispatch(setEscalatedUser(undefined));

    setProblemDescriptionFilter(undefined);
    dispatch(setProblemDescription(undefined));

    setDateRangeFilter(dateOptions[0]);
    dispatch(setDateRange(dateOptions[0]));
    const defaultCallDate = getDefaultCallDate(metadata.bsi_updated_date);
    setStartDate(defaultCallDate.start);
    setEndDate(defaultCallDate.end);
    dispatch(setCallDate(defaultCallDate));

    props.handleClose();
    dispatch(trackEvent({ namespace: 'Clear filters', predicate: 'Filter menu' }))
  }

  const handleApplyFilters = () => {
    dispatch(setTrades(tradeFilters));
    dispatch(setTradeGroups(tradeGroupsFilters));
    dispatch(setProviders(providerFilters));
    dispatch(setCategories(categoryFilters));
    dispatch(setStatus(statusFilters));
    dispatch(setBSI(bsiFilters));
    dispatch(setNTE(nteFilters));
    dispatch(setWorkingOnItFilter(workingOnItFilters));
    dispatch(setEscalatedFilter(escalatedFilters));
    dispatch(setNotOperationalOnly(notOperationalOnlyFilter));
    dispatch(setPotentialDuplicateOnly(potentialDuplicateOnlyFilter));
    dispatch(setMissedETA(missedETAFilter));
    dispatch(setMaintenanceRepairOnly(maintenanceRepairOnlyFilter));
    dispatch(setWorkingOnItUser(workingOnItUserFilter));
    dispatch(setEscalatedUser(escalatedUserFilter));
    dispatch(setNoActivitySince(noActivitySinceFilter));
    dispatch(setEquipmentTypes(equipmentTypeFilter));
    dispatch(setExtendedStatus(extendedStatusFilter));
    dispatch(setProblemDescription(problemDescriptionFilter));
    dispatch(setDateRange(dateRangeFilter));
    if (dateRangeFilter.value === "custom") {
      dispatch(setCallDate({ start: startDate, end: endDate }));
    }
    props.handleClose();
    dispatch(trackEvent({ namespace: 'Apply filters', predicate: 'Filter menu' }))
  }

  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
      sx={{
        padding: '0',
        '& .MuiList-root': {
          backgroundColor: theme.palette.background.paper,
        }
      }}
      onClose={handleClose}
      open
    >
      <Stack direction="column" alignItems="space-between" spacing={2} sx={{ padding: '0.5rem 1rem', }}       >
        <Stack direction="column" alignItems="center" spacing={1} >
          <Typography
            variant="h6"
            sx={{ color: '#d4d4d4', fontSize: '1rem', textTransform: 'none', width: '100%', paddingLeft: '1rem' }}
          >
            Filters
          </Typography>
          <Stack width="100%">
            <Stack flexDirection='row' justifyContent="center">
              <FilterMenu
                id="filter-trades"
                anchorEl={tradeMenuAnchorEl}
                value={tradeFilters}
                searchable
                multiple
                clearable
                options={tradeOptions ? tradeOptions : []}
                label={(
                  <>
                    Trade
                    <Chip
                      label={tradeFilters ? tradeFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: tradeFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, tradeMenuAnchorEl, setTradeMenuAnchorEl)}
                onClose={() => setTradeMenuAnchorEl(null)}
                onSelect={(option) => handleTradesChange(option)}
              />
              <FilterMenu
                id="filter-trade-groups"
                anchorEl={tradeGroupsMenuAnchorEl}
                value={tradeGroupsFilters}
                searchable
                multiple
                clearable
                options={tradeGroupOptions ? tradeGroupOptions : []}
                label={(
                  <>
                    Trade Group
                    <Chip
                      label={tradeGroupsFilters ? tradeGroupsFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: tradeGroupsFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, tradeGroupsMenuAnchorEl, setTradeGroupsMenuAnchorEl)}
                onClose={() => setTradeGroupsMenuAnchorEl(null)}
                onSelect={(option) => handleTradeGroupsChange(option)}
              />
              <FilterMenu
                id="filter-providers"
                anchorEl={providerMenuAnchorEl}
                value={providerFilters}
                searchable
                multiple
                clearable
                options={providerOptions ? providerOptions : []}
                label={(
                  <>
                    Provider
                    <Chip
                      label={providerFilters ? providerFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: providerFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, providerMenuAnchorEl, setProviderMenuAnchorEl)}
                onClose={() => setProviderMenuAnchorEl(null)}
                onSelect={(option) => handleProvidersChange(option)}
              />
            </Stack>
            <Stack flexDirection='row' justifyContent="center" mt="0.5rem">
              <FilterMenu
                id="filter-categories"
                anchorEl={categoryMenuAnchorEl}
                value={categoryFilters}
                searchable
                multiple
                clearable
                options={categoriesOptions}
                label={(
                  <>
                    Category
                    <Chip
                      label={categoryFilters ? categoryFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: categoryFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, categoryMenuAnchorEl, setCategoryMenuAnchorEl)}
                onClose={() => setCategoryMenuAnchorEl(null)}
                onSelect={(option) => handleCategoryChange(option)}
              />
              <FilterMenu
                id="filter-status"
                anchorEl={statusMenuAnchorEl}
                value={statusFilters}
                multiple
                clearable
                options={statusOptions}
                label={(
                  <>
                    Status
                    <Chip
                      label={statusFilters ? statusFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: statusFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, statusMenuAnchorEl, setStatusMenuAnchorEl)}
                onClose={() => setStatusMenuAnchorEl(null)}
                onSelect={(option) => handleStatusChange(option)}
              />
              <FilterMenu
                id="filter-extended-status"
                anchorEl={extendedStatusMenuAnchorEl}
                value={extendedStatusFilter}
                multiple
                searchable
                clearable
                options={extendedStatusOptions ? extendedStatusOptions : []}
                label={(
                  <>
                    Extended Status
                    <Chip
                      label={extendedStatusFilter ? extendedStatusFilter.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: extendedStatusFilter ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, extendedStatusMenuAnchorEl, setExtendedStatusMenuAnchorEl)}
                onClose={() => setExtendedStatusMenuAnchorEl(null)}
                onSelect={(option) => handleExtendedStatusChange(option)}
              />
            </Stack>
            <Stack flexDirection='row' justifyContent="center" mt="0.5rem">
              <FilterMenu
                id="filter-nte"
                anchorEl={nteMenuAnchorEl}
                value={nteFilters}
                clearable
                options={nteOptions ? nteOptions : []}
                label={(
                  <>
                    NTE
                    <Chip
                      label={nteFilters && typeof nteFilters.name === 'string' ? nteFilters.name.replace("NTE ", "") : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: nteFilters ? theme.palette.background.highlight : undefined, width: nteFilters ? '3rem' : undefined }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, nteMenuAnchorEl, setNteMenuAnchorEl)}
                onClose={() => setNteMenuAnchorEl(null)}
                onSelect={(option) => handleNteChange(option)}
              />
              <FilterMenu
                id="filter-working-on-it"
                anchorEl={workingOnItMenuAnchorEl}
                value={workingOnItFilters}
                clearable
                options={workingOnItOptions ? workingOnItOptions : []}
                label={(
                  <>
                    Follow up
                    <Chip
                      label={workingOnItFilters && typeof workingOnItFilters.name === 'string' ? workingOnItFilters.name : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: workingOnItFilters ? theme.palette.background.highlight : undefined }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, workingOnItMenuAnchorEl, setWorkingOnItMenuAnchorEl)}
                onClose={() => setWorkingOnItMenuAnchorEl(null)}
                onSelect={(option) => handleWorkingOnItChange(option)}
              />
              <FilterMenu
                id="filter-escalated"
                anchorEl={escalatedMenuAnchorEl}
                value={escalatedFilters}
                clearable
                options={escalatedOptions ? escalatedOptions : []}
                label={(
                  <>
                    Escalated
                    <Chip
                      label={escalatedFilters && typeof escalatedFilters.name === 'string' ? escalatedFilters.name : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: escalatedFilters ? theme.palette.background.highlight : undefined }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, escalatedMenuAnchorEl, setEscalatedMenuAnchorEl)}
                onClose={() => setEscalatedMenuAnchorEl(null)}
                onSelect={(option) => handleEscalatedChange(option)}
              />
            </Stack>
            <Stack flexDirection='row' justifyContent="center" mt="0.5rem">
              <FilterMenu
                id="filter-bsi"
                anchorEl={bsiMenuAnchorEl}
                value={bsiFilters}
                multiple
                clearable
                options={bsiOptions}
                label={(
                  <>
                    BSI
                    <Chip
                      label={bsiFilters ? bsiFilters.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: bsiFilters ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, bsiMenuAnchorEl, setBsiMenuAnchorEl)}
                onClose={() => setBsiMenuAnchorEl(null)}
                onSelect={(option) => handleBsiChange(option)}
              />
              <FilterMenu
                id="filter-no-activity-since"
                anchorEl={noActivitySinceMenuAnchorEl}
                value={noActivitySinceFilter}
                clearable
                options={noActivitySinceOptions}
                label={(
                  <>
                    No activity since
                    <Chip
                      label={noActivitySinceFilter && typeof noActivitySinceFilter.name === 'string' ? noActivitySinceFilter.name : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: noActivitySinceFilter ? theme.palette.background.highlight : undefined }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, noActivitySinceMenuAnchorEl, setNoActivitySinceMenuAnchorEl)}
                onClose={() => setNoActivitySinceMenuAnchorEl(null)}
                onSelect={(option) => handleNoActivitySinceChange(option)}
              />
              <FilterMenu
                id="filter-equipment-types"
                anchorEl={equipmentTypeMenuAnchorEl}
                value={equipmentTypeFilter}
                multiple
                searchable
                clearable
                options={equipmentTypeOptions ? equipmentTypeOptions : []}
                label={(
                  <>
                    Equipment
                    <Chip
                      label={equipmentTypeFilter ? equipmentTypeFilter.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: equipmentTypeFilter ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, equipmentTypeMenuAnchorEl, setEquipmentTypeMenuAnchorEl)}
                onClose={() => setEquipmentTypeMenuAnchorEl(null)}
                onSelect={(option) => handleEquipmentTypeChange(option)}
              />
            </Stack>
            <Stack flexDirection='row' justifyContent="center" mt="0.5rem">
              <FilterMenu
                id="filter-advanced-filters"
                anchorEl={advancedFiltersMenuAnchorEl}
                value={advancedFiltersFilter}
                multiple
                clearable
                options={advancedFiltersOptions ? advancedFiltersOptions : []}
                label={(
                  <>
                    Advanced Filters
                    <Chip
                      label={advancedFiltersFilter && advancedFiltersFilter.length > 0 ? advancedFiltersFilter.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: advancedFiltersFilter && advancedFiltersFilter.length > 0 ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, advancedFiltersMenuAnchorEl, setAdvancedFiltersMenuAnchorEl)}
                onClose={() => setAdvancedFiltersMenuAnchorEl(null)}
                onSelect={(option) => handleAdvancedFilterChange(option)}
              />
              <FilterMenu
                id="filter-problem-description"
                anchorEl={problemDescriptionMenuAnchorEl}
                value={problemDescriptionFilter}
                multiple
                clearable
                options={problemDescriptionOptions ? problemDescriptionOptions : []}
                label={(
                  <>
                    Problem Description
                    <Chip
                      label={problemDescriptionFilter ? problemDescriptionFilter.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: problemDescriptionFilter && problemDescriptionFilter.length > 0 ? theme.palette.background.highlight : undefined, }}
                    />
                  </>
                )}
                onClick={(e) => handleToggleMenu(e, problemDescriptionMenuAnchorEl, setProblemDescriptionMenuAnchorEl)}
                onClose={() => setProblemDescriptionMenuAnchorEl(null)}
                onSelect={(option) => handleProblemDescriptionChange(option)}
              />
            </Stack>
          </Stack>
          <Typography
            variant="h6"
            sx={{ color: '#d4d4d4', fontSize: '1rem', textTransform: 'none', width: '100%', paddingLeft: '1rem', paddingTop: '1rem', borderTop: '1px solid white' }}
          >
            Only my work orders with
          </Typography>
          <Stack direction="row" justifyContent="space-around" width="100%" paddingBottom="0.5rem" >
            <Stack direction="row" justifyContent="flex-start" maxWidth="13rem">
              <Button
                onClick={() => handleWorkingOnItUserChange(!workingOnItUserFilter ? true : false)}
                sx={{ color: 'white', fontSize: '1rem', textTransform: 'none', pr: '1rem', '&:hover': { backgroundColor: theme.palette.background.default } }}
              >
                <Checkbox
                  checked={workingOnItUserFilter ? true : false}
                  sx={{ mb: '0.2rem', color: 'white !important', '&.Mui-checked': { color: `${theme.palette.background.highlight} !important` } }}
                />
                Follow up
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" maxWidth="13rem">
              <Button
                onClick={() => handleEscalatedUserChange(!escalatedUserFilter ? true : false)}
                sx={{ color: 'white', fontSize: '1rem', textTransform: 'none', pr: '1rem', '&:hover': { backgroundColor: theme.palette.background.default } }}
              >
                <Checkbox
                  checked={escalatedUserFilter ? true : false}
                  sx={{ mb: '0.2rem', color: 'white !important', '&.Mui-checked': { color: `${theme.palette.background.highlight} !important` } }}
                />
                Escalated
              </Button>
            </Stack>
          </Stack>
          <Typography
            variant="h6"
            sx={{ color: '#d4d4d4', fontSize: '1rem', textTransform: 'none', width: '100%', marginTop: '0 !important', paddingLeft: '1rem', paddingTop: '1rem', borderTop: '1px solid white' }}
          >
            Work order age (Created Date)
          </Typography>
          <Stack direction="row" justifyContent="space-around" width="100%">
            <FilterMenu
              id="filter-date-range"
              anchorEl={dateRangeMenuAnchorEl}
              value={dateRangeFilter}
              options={dateOptions}
              label={dateRangeFilter.name}
              onClick={handleMenuDateChange}
              onClose={() => setDateRangeMenuAnchorEl(null)}
              onSelect={(option) => handleDateChange(option)}
              style={{ marginRight: 'auto' }}
            />
            {dateRangeFilter.value === "custom" && startDate && <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs.unix(startDate)}
                onChange={handleStartDateChange}
                onClose={() => setMenuOpenStartDate(false)}
                renderInput={(params) =>
                  <Button
                    id="filter-date-start"
                    ref={params.inputRef}
                    onClick={(e) => handleMenuStartDateChange(true, params, e)}
                    sx={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '1rem',
                      minWidth: 'unset',
                      '&.MuiButton-root:hover': {
                        backgroundColor: 'background.default'
                      },
                    }}>
                    <Typography ml={3} component="span" fontWeight='bold' noWrap>
                      Start: {params.inputProps?.value}
                    </Typography>
                    {menuOpenStartDate ? <KeyboardArrowUpIcon sx={{ ml: 0.5 }} /> : <KeyboardArrowDownIcon sx={{ ml: 0.5 }} />}
                  </Button>}
              />
            </LocalizationProvider>}
            {dateRangeFilter.value === "custom" && endDate && <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs.unix(endDate)}
                onChange={handleEndDateChange}
                onClose={() => setMenuOpenEndDate(false)}
                renderInput={(params) =>
                  <Button
                    id="filter-date-end"
                    ref={params.inputRef}
                    onClick={(e) => handleMenuEndDateChange(true, params, e)}
                    sx={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '1rem',
                      minWidth: 'unset',
                      '&.MuiButton-root:hover': {
                        backgroundColor: 'background.default'
                      }
                    }}>
                    <Typography ml={3} component="span" fontWeight='bold' noWrap>
                      End: {params.inputProps?.value}
                    </Typography>
                    {menuOpenEndDate ? <KeyboardArrowUpIcon sx={{ ml: 0.5 }} /> : <KeyboardArrowDownIcon sx={{ ml: 0.5 }} />}
                  </Button>}
              />
            </LocalizationProvider>}
          </Stack>
        </Stack>
        <Stack id="filter-actions" direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <Button
            onClick={handleClearFilters}
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              mr: '0.5rem',
            }}
          >
            Clear all
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              // zIndex: 1500,
              mr: '0.5rem',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApplyFilters}
            variant="contained"
            disabled={!filtersChanged}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              // zIndex: 1500,
              '&.MuiButton-root.Mui-disabled': {
                color: '#c0c0c0'
              }
            }}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Menu>
  )
}

export default SubFilters;
