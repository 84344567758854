import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { Attachment, IAppState, Workorder } from 'storage/app/models';
import { getAttachments, toggleAttachmentsPanelOpen } from 'storage/app/duck';
import {
  Stack,
  Button,
  Tooltip,
  Portal,
  Card,
  Typography,
  Chip,
  Modal,
} from '@mui/material';
import theme from 'theme';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import MovieIcon from '@mui/icons-material/Movie';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { isTablet } from 'utils/utils';

export const RenderAttachment = ({
  name,
  uri,
  description,
  timestamp,
}: Attachment) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileType = name.includes('.')
    ? name.split('.').pop()?.toUpperCase()
    : undefined;
  const fileName = name.includes('.')
    ? name.split('.').slice(0, -1).join('.')
    : name;
  const date = new Date(timestamp);
  const isImage =
    fileType === 'JPG' ||
    fileType === 'PNG' ||
    fileType === 'GIF' ||
    fileType === 'JPEG' ||
    fileType === 'HEIC' ||
    fileType === 'HEIF';
  const isPDF = fileType === 'PDF';
  const isVideo =
    fileType === 'MP4' ||
    fileType === 'MOV' ||
    fileType === 'AVI' ||
    fileType === 'WMV' ||
    fileType === 'HEVC';
  const isDoc =
    fileType === 'DOC' ||
    fileType === 'DOCX' ||
    fileType === 'XLS' ||
    fileType === 'XLSX' ||
    fileType === 'PPT' ||
    fileType === 'PPTX';

  const handleOpenModal = () => {
    if (isImage) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setIsModalOpen(false);
    setIsHovering(false);
    e.stopPropagation();
  };

  return (
    <a
      href={!isImage ? uri : undefined}
      target={!isImage ? '_blank' : undefined}
      rel={!isImage ? 'noreferrer' : undefined}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleOpenModal}
      style={{
        marginBottom: '0.5rem',
        color: 'inherit',
        flexShrink: 0,
        textDecoration: 'none',
        maxWidth: '32rem',
        cursor: 'pointer',
      }}>
      <Card sx={{ margin: 0 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          padding="0.5rem"
          minHeight="5rem"
          position="relative">
          <Stack marginRight="1rem" justifyContent="center" position="relative">
            {isHovering && !isModalOpen && isImage && (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.33)',
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  left: '0',
                  right: '0',
                }}>
                <ZoomInIcon />
              </Stack>
            )}
            {isImage && (
              <img src={uri} alt={fileName} style={{ width: '5rem' }} />
            )}
            {isPDF && (
              <PictureAsPdfIcon style={{ width: '5rem', height: '5rem' }} />
            )}
            {isVideo && <MovieIcon style={{ width: '5rem', height: '5rem' }} />}
            {isDoc && (
              <DescriptionIcon style={{ width: '5rem', height: '5rem' }} />
            )}
            {!isImage && !isPDF && !isVideo && !isDoc && (
              <InsertDriveFileIcon style={{ width: '5rem', height: '5rem' }} />
            )}
          </Stack>
          <Stack alignItems="flex-start" flex="1">
            <Typography paddingRight={fileType && '2.75rem'}>
              {fileName}
            </Typography>
            <Typography sx={{ fontSize: '0.9rem', flexGrow: 1 }}>
              {description}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
                justifySelf: 'end',
                alignSelf: 'flex-end',
              }}>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</Typography>
          </Stack>
          {fileType && (
            <Chip
              label={fileType}
              sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            />
          )}
        </Stack>
      </Card>
      {isImage && (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            onClick={handleCloseModal}
            sx={{ cursor: 'pointer' }}>
            <img
              src={uri}
              alt={fileName}
              style={{ maxWidth: '95%', maxHeight: '95%' }}
            />
          </Stack>
        </Modal>
      )}
    </a>
  );
};

interface WorkorderAttachmentsProps {
  workorderData: Workorder;
}

const WorkorderAttachments: FC<WorkorderAttachmentsProps> = ({
  workorderData,
}) => {
  const dispatch = useDispatch();
  const attachments = useSelector<IStore, Attachment[] | undefined>(
    (state) => state.app.data.attachments,
  );
  const isPanelOpen = useSelector<IStore, IAppState['isAttachmentPanelOpen']>(
    (state) => state.app.isAttachmentPanelOpen,
  );
  const haveAttachments = attachments && attachments.length > 0;
  const ipad = isTablet();
  const panelWidth = ipad ? '26rem' : '32rem';

  useEffect(() => {
    if (workorderData.id) {
      dispatch(getAttachments(workorderData.id));
    }
  }, [workorderData.id]);

  useEffect(() => {
    if (isPanelOpen && attachments?.length === 0) {
      dispatch(toggleAttachmentsPanelOpen());
    }
  }, [attachments]);

  const handleOpenPanel = () => {
    dispatch(toggleAttachmentsPanelOpen());
  };

  return (
    <Stack
      className="workorder-attachments"
      sx={{ pt: haveAttachments ? '1rem' : undefined }}>
      {haveAttachments && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenPanel}
          disabled={!attachments?.length}
          fullWidth
          endIcon={
            attachments?.length > 0 &&
            (isPanelOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            ))
          }
          sx={{
            color: !attachments?.length
              ? `${theme.palette.text.primary} !important`
              : undefined,
          }}>
          {isPanelOpen
            ? `Hide attachments (${attachments?.length})`
            : `View attachments (${attachments?.length})`}
        </Button>
      )}
      {isPanelOpen && (
        <Portal container={() => document.querySelector('#side-panel')}>
          <Stack
            sx={{
              position: 'absolute',
              top: '0',
              left: panelWidth,
              width: panelWidth,
              bottom: 0,
              zIndex: 1001,
              backgroundColor: theme.palette.background.default,
              padding: '0.5rem 0.25rem 0 0.25rem',
            }}>
            <Stack sx={{ overflowY: 'auto', pr: '0.25rem' }}>
              {attachments?.map((attachment: Attachment, index: number) => (
                <RenderAttachment key={index} {...attachment} />
              ))}
            </Stack>
            <Tooltip title={'Hide attachments panel'} placement="right" arrow>
              <Button
                variant="contained"
                onClick={handleOpenPanel}
                sx={{
                  position: 'absolute',
                  top: '0.75rem',
                  left: panelWidth,
                  minWidth: '2rem',
                  width: '2rem',
                  height: '3rem',
                  zIndex: 90,
                  backgroundColor: theme.palette.background.light,
                  color: 'white',
                }}>
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Portal>
      )}
    </Stack>
  );
};

export default WorkorderAttachments;
