import React, { FC, useEffect } from 'react';
import { Route as TRoute } from 'lib/models';
import { Switch, Router, Route } from 'react-router-dom';
import PrivateRoute from '../utils/PrivateRoute';
import paths from './paths';
import { createBrowserHistory } from "history";
import { useDispatch } from 'react-redux';
import { getMetadata } from 'storage/app/duck';
import packageJson from '../../package.json';


export const history = createBrowserHistory({ basename: packageJson.homepage });

const RouterContainer: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Get metadata from backend every 30 seconds
    dispatch(getMetadata());
    const interval = setInterval(() => {
      dispatch(getMetadata());
    }, 30000)
    return () => clearInterval(interval);
  }, [])

  return (
    <Router history={history}>
      <Switch>
        {paths.map((route: TRoute) => {
          if (route.private) {
            return (
              <PrivateRoute
                exact
                component={route.component}
                path={route.path}
                key={route.path}
              />
            );
          }
          return (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={route.path}
            />
          );
        })}
      </Switch>
    </Router >
  );
};

export default RouterContainer;
