import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAppState } from 'storage/app/models';
import { isTablet } from 'utils/utils';

const LastUpdate: FC = () => {
  const { metadata } = useSelector<IStore, IAppState>(store => store.app);
  const lastUpdate = metadata.bsi_updated_date ? new Date(metadata.bsi_updated_date) : null;
  const dateString = lastUpdate ?
    lastUpdate.toLocaleString("en-US", { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) :
    "Static dataset";
  const ipad = isTablet();

  return (
    <Box
      id="last-update"
      sx={{
        zIndex: 2,
        backgroundColor: 'background.paper',
        opacity: 0.9,
        mb: '1.75rem !important',
        p: 1,
      }}
    >
      <Typography fontSize={ipad ? "0.8rem" : "small"}>BSI last updated: {dateString}</Typography>
    </Box>
  );
};

export default LastUpdate;
