import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { IAppState, Workorder } from 'storage/app/models';
import './WorkorderList.css';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHoveredAggregate,
  setActiveWorkorder,
  setHoveredWorkorder,
  setWorkorder,
  toggleEscalated,
  toggleWorkingOnIt,
} from 'storage/app/duck';
import Card from '@mui/material/Card';
import {
  formatDollars,
  getBsiCircleColor,
  getBsiCircleTooltip,
} from 'utils/utils';
import IStore from 'lib/redux/models';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IAuthState } from 'storage/auth/models';
import theme from 'theme';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import ActionSwitch from 'components/ActionSwitch';
import ConstructionIcon from '@mui/icons-material/Construction';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { gainExperience } from 'storage/auth/duck';
import { trackEvent } from 'storage/tracking/duck';
import { isTablet } from 'utils/utils';

interface WorkorderItemProps {
  workorder: Workorder;
  index?: number;
  isFullscreen?: boolean;
}

const WorkorderItem: FC<WorkorderItemProps> = ({
  workorder,
  index,
  isFullscreen,
}) => {
  const dispatch = useDispatch();
  const hoveredAggregate = useSelector<IStore, IAppState['hoveredAggregate']>(
    selectHoveredAggregate,
  );
  const expConstants = useSelector<
    IStore,
    IAppState['metadata']['xp_multipliers']
  >((state) => state.app.metadata.xp_multipliers);
  const activeWorkorder = useSelector<
    IStore,
    IAppState['data']['activeWorkorder']
  >((state) => state.app.data.activeWorkorder);
  const [workingOnItTooltip, setWorkingOnItTooltip] =
    useState('Add to follow up');
  const [shouldGainXP, setShouldGainXP] = useState(false);
  const [escalatedTooltip, setEscalatedTooltip] = useState(
    'Escalate this work order',
  );
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const havePermission = workorder.working_on_it
    ? workorder.working_on_it_user !== user?.email
      ? false
      : true
    : true;
  const trackingPredicate = isFullscreen ? 'WO fullscreen' : 'WO list';
  const ipad = isTablet();

  useEffect(() => {
    setWorkingOnItTooltip(
      workorder.working_on_it
        ? user?.email === workorder.working_on_it_user
          ? 'Follow up'
          : `${workorder.working_on_it_user} is following up`
        : 'Add to follow up',
    );
    if (expConstants && shouldGainXP) {
      dispatch(
        gainExperience(
          workorder.working_on_it
            ? expConstants.working_on_it
            : -expConstants.working_on_it,
        ),
      );
      setShouldGainXP(false);
    }
  }, [workorder.working_on_it_user, workorder.working_on_it]);

  useEffect(() => {
    setEscalatedTooltip(
      workorder.is_escalated
        ? user?.email === workorder.escalated_user
          ? 'Escalated'
          : `${workorder.escalated_user} escalated this work order`
        : 'Escalate this work order',
    );
    if (expConstants && shouldGainXP) {
      dispatch(
        gainExperience(
          workorder.is_escalated
            ? expConstants.escalated
            : -expConstants.escalated,
        ),
      );
      setShouldGainXP(false);
    }
  }, [workorder.escalated_user, workorder.is_escalated]);

  const handleClick = () => {
    dispatch(
      setActiveWorkorder({
        id: workorder.id,
        working_on_it: workorder.working_on_it,
        working_on_it_user: workorder.working_on_it_user,
        is_escalated: workorder.is_escalated,
        escalated_user: workorder.escalated_user,
        is_bot_shut_off: workorder.is_bot_shut_off,
      }),
    );
    dispatch(setWorkorder(workorder.id));
    dispatch(
      trackEvent({
        namespace: 'Select WO',
        predicate: trackingPredicate,
        value: workorder.id.toString(),
      }),
    );
  };

  const handleMouseEnter = () => {
    dispatch(setHoveredWorkorder(workorder));
  };

  const handleMouseLeave = () => {
    dispatch(setHoveredWorkorder(undefined));
  };

  const handleUserActivity = (namespace: string, payloadValue: boolean) => {
    if (user && workorder && expConstants && !activeWorkorder) {
      dispatch(
        trackEvent({
          namespace: namespace,
          predicate: trackingPredicate,
          value: workorder.id.toString(),
          payload: { value: payloadValue },
        }),
      );
    }
  };

  const handleCheckWorkingOnIt = (workorder: Workorder) => {
    if (user && expConstants) {
      setShouldGainXP(true);
      dispatch(toggleWorkingOnIt({ workorderId: workorder.id }));
    }
  };

  const handleCheckEscalated = (workorder: Workorder) => {
    if (user && expConstants) {
      setShouldGainXP(true);
      dispatch(toggleEscalated({ workorderId: workorder.id }));
    }
  };

  const getBackgroundColor = () => {
    const hovAggr = hoveredAggregate;
    if (!hovAggr) {
      return 'background.paper';
    } else if (
      (hovAggr.aggregation_level === 'Region' &&
        hovAggr.id === workorder.region_id) ||
      (hovAggr.aggregation_level === 'Market' &&
        hovAggr.id === workorder.market_id) ||
      (hovAggr.aggregation_level === 'Club' &&
        workorder.club_id &&
        hovAggr.id === parseInt(workorder.club_id, 10)) ||
      (workorder.club_id && hovAggr.clubs?.includes(workorder.club_id)) ||
      // Temporary until geojson is cleaned up
      (!hovAggr.aggregation_level &&
        hovAggr.region &&
        !hovAggr.market &&
        hovAggr.region === workorder.region_name) ||
      (!hovAggr.aggregation_level &&
        hovAggr.region &&
        hovAggr.market &&
        hovAggr.market === workorder.market_id)
    ) {
      return 'background.lightest';
    } else {
      return 'background.paper';
    }
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        minWidth: ipad ? '25.5rem' : '31.25rem',
        width: ipad ? '25.2rem' : '31.25rem',
      }}>
      <Tooltip title="Click for details" placement="top" arrow>
        <Card
          sx={{
            position: 'relative',
            width: '100%',
            m: 0.5,
            mt: 0,
            transition: '0.25s',
            backgroundColor: getBackgroundColor(),
            '&.MuiCard-root:hover': {
              backgroundColor: 'background.lightest',
              cursor: 'pointer',
            },
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}>
          {index !== undefined && (
            <Typography
              sx={{
                position: 'absolute',
                bottom: '0.375rem',
                left: ipad ? 'unset' : '0.625rem',
                right: ipad ? '0.625rem' : 'unset',
                fontSize: '0.7rem',
                opacity: 0.4,
              }}>
              {index + 1}
            </Typography>
          )}
          <Grid
            className="workorder-item"
            container
            spacing={0}
            p={1}
            sx={{ flexShrink: 0, position: 'relative' }}>
            {!ipad ? (
              <Grid
                item
                xs={1.5}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  mt: 1,
                }}>
                <Tooltip
                  title={`${
                    workorder.priority_bin &&
                    getBsiCircleTooltip(workorder.priority_bin)
                  }`}
                  placement="top"
                  arrow>
                  <Box
                    className="circle"
                    sx={{
                      backgroundColor:
                        workorder.priority_bin &&
                        getBsiCircleColor(workorder.priority_bin),
                      boxShadow: (theme) => ({ boxShadow: theme.shadows[5] }),
                    }}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid item sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Tooltip
                  title={`${
                    workorder.priority_bin &&
                    getBsiCircleTooltip(workorder.priority_bin)
                  }`}
                  placement="top"
                  arrow>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      width: '6px',
                      backgroundColor:
                        workorder.priority_bin &&
                        getBsiCircleColor(workorder.priority_bin),
                    }}
                  />
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={ipad ? 12 : 10.5} pl={1} pr={1}>
              <Stack direction="row" spacing={2} mb={0.25} mt={0.5}>
                <Typography
                  variant="h4"
                  noWrap
                  sx={{ fontSize: '1rem', fontWeight: '800' }}>
                  {workorder.trade}
                  {workorder.equipment && workorder.equipment.Area
                    ? ` | ${workorder.equipment.Area}`
                    : null}
                  {workorder.problem_code
                    ? ` | ${workorder.problem_code}`
                    : null}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                mb={0.75}
                justifyContent="space-between">
                <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                  Work Order {workorder.id}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                  Created on{' '}
                  {workorder.created_date &&
                    new Date(workorder.created_date).toLocaleDateString()}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ minWidth: '5rem' }}>
                  <AttachMoneyIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                  <Typography
                    variant="caption"
                    sx={{ fontSize: '0.75rem', mt: '0.125rem' }}>
                    {workorder.nte &&
                      formatDollars(workorder.nte).replace('$', '')}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <PersonIcon
                    fontSize="small"
                    sx={{ mr: '0.25rem', fontSize: '1rem' }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ fontSize: '0.75rem', mt: '0.125rem' }}
                    noWrap
                    maxWidth={ipad ? 60 : 120}>
                    {workorder.provider_name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <PlaceIcon
                    fontSize="small"
                    sx={{ mr: '0.25rem', fontSize: '1rem' }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.75rem',
                      mt: '0.125rem !important',
                      whiteSpace: 'nowrap',
                    }}>
                    Club {workorder.club_id}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="caption"
                  noWrap
                  sx={{ fontSize: '0.75rem', mt: '0.125rem !important' }}>
                  {workorder.equipment_tag && workorder.equipment_type
                    ? `${workorder.equipment_tag} (${workorder.equipment_type})`
                    : 'No asset data'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Tooltip>
      <Card
        sx={{
          m: 0.5,
          mt: 0,
          ml: 0,
          width: '6rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0',
        }}>
        <ActionSwitch
          tooltip={workingOnItTooltip}
          tooltipPlacement="right"
          checked={workorder.working_on_it ? true : false}
          backgroundColor={
            workorder.working_on_it
              ? workorder.working_on_it_user === user?.email
                ? theme.palette.actions.userWorkingOnIt
                : theme.palette.actions.workingOnIt
              : '#8D8D8D'
          }
          icon={
            <ConstructionIcon
              sx={{
                color: workorder.working_on_it
                  ? theme.palette.actions.workingOnIt
                  : '#8D8D8D',
              }}
            />
          }
          havePermission={havePermission}
          onClick={() => handleCheckWorkingOnIt(workorder)}
          onChange={(checked) => handleUserActivity('Working on it', checked)}
          style={{
            borderBottom: `4px solid ${theme.palette.background.default}`,
          }}
        />
        <ActionSwitch
          tooltip={escalatedTooltip}
          tooltipPlacement="right"
          checked={workorder.is_escalated ? true : false}
          backgroundColor={
            workorder.is_escalated
              ? workorder.escalated_user === user?.email
                ? theme.palette.actions.userEscalated
                : theme.palette.actions.escalated
              : '#8D8D8D'
          }
          icon={
            <LocalFireDepartmentIcon
              sx={{
                color: workorder.is_escalated
                  ? theme.palette.actions.escalated
                  : '#8D8D8D',
              }}
            />
          }
          havePermission={havePermission}
          onClick={() => handleCheckEscalated(workorder)}
          onChange={(checked) => handleUserActivity('Escalate', checked)}
        />
      </Card>
    </Grid>
  );
};

export default WorkorderItem;
