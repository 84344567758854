import React, { FC, useEffect } from 'react';
import { INotesState, Note } from 'storage/notes/models';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { getWorkorderNotes } from 'storage/notes/duck';
import { Button, Card, CardContent, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import Spinner from 'components/Spinner';
import { RenderNote } from 'components/WorkorderDetail/WorkorderNotes';
import { safeGetObjectValue } from 'utils/utils';


interface NotesModalProps {
  open: boolean;
  onClose: () => void;
  workorderId: number;
}

const NotesModal: FC<NotesModalProps> = ({ open, onClose, workorderId }) => {
  const dispatch = useDispatch();
  const notes = useSelector<IStore, INotesState['data']>(state => state.notes.data);
  const notesFromCache = safeGetObjectValue(notes, workorderId.toString()) as Note[] | undefined
  const woNotes = notesFromCache ? notesFromCache : [];
  const loading = useSelector<IStore, INotesState['loading']>(state => state.notes.loading);

  useEffect(() => {
    if (open) {
      dispatch(getWorkorderNotes({ workorderId: workorderId }))
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50rem',
        backgroundColor: theme.palette.background.default,
      }}>
        <Button
          variant='text'
          size='small'
          onClick={onClose}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            Work Order {workorderId} notes
          </Typography>
          <Stack spacing={2} maxHeight='80svh' minHeight='10svh' sx={{ overflowY: 'auto' }}>
            {loading && <Spinner isVisible={loading} dimBackground />}
            {woNotes &&
              woNotes.map((note, index) =>
                <RenderNote key={index} note={note} fullWidth />
              )
            }
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default NotesModal;