import React, { FC, useEffect } from 'react';
import { Attachment, IAppState, Workorder } from 'storage/app/models';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import {
  getAttachments,
  getLinkedWorkorders,
  getWorkorder,
} from 'storage/app/duck';
import { RenderAttachment } from 'components/WorkorderDetail/WorkorderAttachments';
import Spinner from 'components/Spinner';
import WorkorderItem from './WorkorderItem';
import { RenderSCLinkedWorkOrder } from 'components/WorkorderDetail/LinkedWorkorders';

interface DetailsModalProps {
  open: boolean;
  onClose: () => void;
  workorder: Workorder;
}

const DetailsModal: FC<DetailsModalProps> = ({ open, onClose, workorder }) => {
  const dispatch = useDispatch();
  const attachments = useSelector<IStore, Attachment[] | undefined>(
    (state) => state.app.data.attachments,
  );
  const haveAttachments = attachments && attachments.length > 0;
  const filters = useSelector<IStore, IAppState['filters']>(
    (state) => state.app.filters,
  );
  const loading = useSelector<IStore, IAppState['loadingLinkedWorkorders']>(
    (state) => state.app.loadingLinkedWorkorders,
  );
  const linkedWorkorderOriginal = useSelector<IStore, Workorder[] | undefined>(
    (state) => state.app.data.linkedWorkorderOriginal,
  );
  const linkedWorkorderFollowUp = useSelector<IStore, Workorder[] | undefined>(
    (state) => state.app.data.linkedWorkorderFollowUp,
  );
  const originalWOs: number[] = workorder.linked_work_orders_original
    ? workorder.linked_work_orders_original.map((wo: string) => parseInt(wo))
    : [];
  const followUpWOs: number[] = workorder.linked_work_orders_follow_up
    ? workorder.linked_work_orders_follow_up.map((wo: string) => parseInt(wo))
    : [];
  const scLinkedWorkorderOriginal = originalWOs.reduce(
    (acc: number[], cur: number) => {
      if (!linkedWorkorderOriginal?.find((wo) => wo.id === cur)) {
        return [...acc, cur];
      }
      return acc;
    },
    [],
  );
  const scLinkedWorkorderFollowUp = followUpWOs.reduce(
    (acc: number[], cur: number) => {
      if (!linkedWorkorderFollowUp?.find((wo) => wo.id === cur)) {
        return [...acc, cur];
      }
      return acc;
    },
    [],
  );
  const linkedWOs = [...originalWOs, ...followUpWOs];

  useEffect(() => {
    if (originalWOs.length > 0 || followUpWOs.length > 0) {
      dispatch(
        getLinkedWorkorders({ original: originalWOs, followUp: followUpWOs }),
      );
    }
  }, [workorder.id, originalWOs.length, followUpWOs.length]);

  useEffect(() => {
    if (workorder.id) {
      dispatch(getAttachments(workorder.id));
      if (filters.callDate.start && filters.callDate.end) {
        dispatch(
          getWorkorder({
            id: workorder.id,
            filters: filters,
            updateActiveWorkorder: true,
          }),
        );
      }
    }
  }, [workorder.id]);

  const equipment = {
    tag: workorder?.equipment_tag,
    type: workorder?.equipment_type,
    id: workorder?.equipment_id,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '65.33rem',
          maxHeight: '92svh',
          backgroundColor: theme.palette.background.default,
          overflowY: 'auto',
        }}>
        <Button
          variant="text"
          size="small"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            color: theme.palette.text.primary,
            minWidth: '2rem',
            height: '2rem',
          }}>
          <CloseIcon />
        </Button>
        <CardContent>
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            Work Order {workorder.id} details
          </Typography>
          <Stack
            spacing={2}
            maxHeight="80svh"
            minHeight="10svh"
            sx={{ overflowY: 'auto' }}>
            <Stack spacing={1} direction="row">
              <Typography>{workorder.primary_status}</Typography>
              <Typography>-</Typography>
              <Typography>{workorder.extended_status}</Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              <Typography>
                <strong>Created:</strong>{' '}
                {workorder.created_date &&
                  new Date(workorder.created_date).toLocaleDateString()}
              </Typography>
              {workorder.call_date && workorder.call_date !== 'nan' && (
                <Typography>
                  <strong>Call Date:</strong>{' '}
                  {new Date(workorder.call_date).toLocaleDateString()}
                </Typography>
              )}
              {workorder.scheduled_date && workorder.scheduled_date !== 'nan' && (
                <Typography>
                  <strong>Scheduled:</strong>{' '}
                  {new Date(workorder.scheduled_date).toLocaleDateString()}
                </Typography>
              )}
              {workorder.completed_date && workorder.completed_date !== 'nan' && (
                <Typography>
                  <strong>Work Date:</strong>{' '}
                  {new Date(workorder.completed_date).toLocaleDateString()}
                </Typography>
              )}
              <Typography>
                <strong>Updated:</strong>{' '}
                {workorder.updated_date &&
                  new Date(workorder.updated_date).toLocaleDateString()}
              </Typography>
            </Stack>
            <Stack spacing={1} sx={{ mt: '1rem' }}>
              <Typography variant="h6" component="h3" fontWeight="bold">
                Description
              </Typography>
              <Typography>{workorder.description}</Typography>
            </Stack>
            {equipment.tag && equipment.type && equipment.id && (
              <Stack spacing={1}>
                <Typography variant="h6" component="h3" fontWeight="bold">
                  Asset
                </Typography>
                <Box>
                  <Link
                    href={`https://www.servicechannel.com/em/assets?AssetId=${equipment.id}`}
                    target="_blank"
                    rel="noreferrer">
                    <Typography
                      color="primary"
                      sx={{ ':hover': { textDecoration: 'underline' } }}>
                      {equipment.tag} ({equipment.type})
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            )}
          </Stack>
          {loading && <Spinner isVisible dimBackground />}
          {linkedWOs.length > 0 && (
            <Stack>
              <Typography
                variant="h6"
                component="h3"
                fontWeight="bold"
                mt="1rem"
                mb="0.5rem">
                Linked Work Orders
              </Typography>
              {originalWOs.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mb: '0.5rem' }}>
                    Original
                  </Typography>
                  <Grid container spacing={1} mb="0.5rem">
                    {scLinkedWorkorderOriginal.map((wo: number, index) => (
                      <Grid item key={index} xs={6}>
                        <RenderSCLinkedWorkOrder key={index} id={wo} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={1}>
                    {linkedWorkorderOriginal?.map((workorder: Workorder) => (
                      <Grid item key={workorder.id} xs={6}>
                        <WorkorderItem workorder={workorder} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {followUpWOs.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mt: '0.5rem', mb: '0.5rem' }}>
                    Follow-up
                  </Typography>
                  <Grid container spacing={1} mb="0.5rem">
                    {scLinkedWorkorderFollowUp.map((wo: number, index) => (
                      <Grid item key={index} xs={6}>
                        <RenderSCLinkedWorkOrder id={wo} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={1}>
                    {linkedWorkorderFollowUp?.map((workorder: Workorder) => (
                      <Grid item key={workorder.id} xs={6}>
                        <WorkorderItem workorder={workorder} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Stack>
          )}
          {haveAttachments && (
            <Stack>
              <Typography
                variant="h6"
                component="h3"
                fontWeight="bold"
                mt="1rem"
                mb="0.5rem">
                Attachments
              </Typography>
              <Grid container spacing={2}>
                {attachments.map((attachment: Attachment, index: number) => (
                  <Grid key={index} item xs={6}>
                    <RenderAttachment {...attachment} />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DetailsModal;
