import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAppState, Workorder } from 'storage/app/models';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import {
  togglePotentialDuplicatesPanelOpen,
  getPotentialDuplicates,
  setData,
} from 'storage/app/duck';
import {
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Portal,
  Card,
} from '@mui/material';
import theme from 'theme';
import Spinner from 'components/Spinner/Spinner';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import WorkorderItem from 'components/WorkorderList/WorkorderItem';
import { isTablet } from 'utils/utils';

export const RenderSCWorkOrder: FC<{ id: number }> = ({ id }) => {
  const metadata = useSelector<IStore, IAppState['metadata']>(
    (state) => state.app.metadata,
  );

  return (
    <Card
      sx={{
        margin: '0 0.25rem 0.25rem 0.25rem',
        padding: '0.5rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Typography margin={0}>Work Order {id}</Typography>
      <Tooltip title="Open in Service Channel" placement="top" arrow>
        <IconButton
          size="small"
          href={`${metadata.sc_portal_url}/sc/wo/Workorders/index?id=${id}`}
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={ServiceChannelIcon}
            width="26"
            height="26"
            alt="Service Channel"
          />
        </IconButton>
      </Tooltip>
    </Card>
  );
};

interface PotentialDuplicatesProps {
  workorderData: Workorder;
}

const PotentialDuplicates: FC<PotentialDuplicatesProps> = ({
  workorderData,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector<IStore, IAppState['loadingPotentialDuplicates']>(
    (state) => state.app.loadingPotentialDuplicates,
  );
  const isPanelOpen = useSelector<
    IStore,
    IAppState['isPotentialDuplicatesPanelOpen']
  >((state) => state.app.isPotentialDuplicatesPanelOpen);
  const potentialDuplicates = useSelector<IStore, Workorder[] | undefined>(
    (state) => state.app.data.potentialDuplicates,
  );
  const potentialDuplicatesIds: number[] = workorderData.potential_duplicates
    ? workorderData.potential_duplicates
    : [];
  const scPotentialDuplicates = potentialDuplicatesIds.reduce(
    (acc: number[], cur: number) => {
      if (!potentialDuplicates?.find((wo) => wo.id === cur)) {
        return [...acc, cur];
      }
      return acc;
    },
    [],
  );
  const ipad = isTablet();
  const panelWidth = ipad ? '26rem' : '32rem';

  useEffect(() => {
    if (
      isPanelOpen &&
      potentialDuplicates?.length === 0 &&
      scPotentialDuplicates.length === 0
    ) {
      dispatch(togglePotentialDuplicatesPanelOpen());
    }
  }, [potentialDuplicates]);

  const handleOpenPanel = () => {
    dispatch(togglePotentialDuplicatesPanelOpen());
  };

  useEffect(() => {
    if (
      potentialDuplicatesIds &&
      potentialDuplicatesIds.length > 0 &&
      isPanelOpen
    ) {
      dispatch(
        setData({
          potentialDuplicates: undefined,
        }),
      );
      dispatch(getPotentialDuplicates(potentialDuplicatesIds));
    } else if (isPanelOpen) {
      dispatch(
        setData({
          potentialDuplicates: undefined,
        }),
      );
      dispatch(togglePotentialDuplicatesPanelOpen());
    }
  }, [workorderData.id, isPanelOpen]);

  return (
    <Stack
      className="potential-duplicates-workorders"
      sx={{ pt: potentialDuplicatesIds?.length ? '1rem' : undefined }}>
      {potentialDuplicatesIds && potentialDuplicatesIds.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenPanel}
          disabled={!potentialDuplicatesIds.length}
          fullWidth
          endIcon={
            potentialDuplicatesIds.length > 0 &&
            (isPanelOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            ))
          }
          sx={{
            color: !potentialDuplicatesIds.length
              ? `${theme.palette.text.primary} !important`
              : undefined,
          }}>
          {isPanelOpen
            ? `Hide potential duplicate work orders (${potentialDuplicatesIds.length})`
            : `View potential duplicate work orders (${potentialDuplicatesIds.length})`}
        </Button>
      )}
      {isPanelOpen && (
        <Portal container={() => document.querySelector('#side-panel')}>
          <Stack
            sx={{
              position: 'absolute',
              top: '0',
              left: panelWidth,
              width: panelWidth,
              bottom: 0,
              zIndex: 1001,
              backgroundColor: theme.palette.background.default,
            }}>
            {loading && <Spinner isVisible dimBackground />}
            <Box
              sx={{
                overflowX: 'hidden',
                p: '0.5rem',
                pl: '0',
                mr: '0.25rem',
              }}>
              {((potentialDuplicates && potentialDuplicates.length) ||
                (scPotentialDuplicates && scPotentialDuplicates.length)) >
                0 && (
                <>
                  <Typography variant="h6" sx={{ ml: '1rem', mb: '0.5rem' }}>
                    Potential Duplicates
                  </Typography>
                  {potentialDuplicates?.map((workorder: Workorder) => (
                    <WorkorderItem key={workorder.id} workorder={workorder} />
                  ))}
                  {scPotentialDuplicates.map((wo: number, index) => (
                    <RenderSCWorkOrder key={index} id={wo} />
                  ))}
                </>
              )}
            </Box>
            <Tooltip
              title={'Hide potential duplicates panel'}
              placement="right"
              arrow>
              <Button
                variant="contained"
                onClick={handleOpenPanel}
                sx={{
                  position: 'absolute',
                  top: '0.75rem',
                  left: panelWidth,
                  minWidth: '2rem',
                  width: '2rem',
                  height: '3rem',
                  zIndex: 90,
                  backgroundColor: theme.palette.background.light,
                  color: 'white',
                }}>
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Portal>
      )}
    </Stack>
  );
};

export default PotentialDuplicates;
