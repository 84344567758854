import React, { FC } from 'react';
import Box from "@mui/material/Box";
import './Spinner.css';
import { CircularProgress } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

interface SpinnerProps {
  isVisible?: boolean
  dimBackground?: boolean
  sx?: SystemStyleObject
  spinnerSx?: SystemStyleObject
}

const Spinner: FC<SpinnerProps> = (props) => {

  return (
    <Box
      className="spinner"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        display: props.isVisible ? 'flex' : 'none',
        backgroundColor: props.dimBackground ? 'rgba(0,0,0,0.25)' : 'transparent',
        ...props.sx
      }}
    >
      <CircularProgress sx={{ ...props.spinnerSx }} />
    </Box>
  )
}

export default Spinner;
