import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import Routes from './routes';
import store from 'lib/redux';
import ThemeProvider from '@mui/system/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';
import ScaledDisplayNotification from 'utils/ScaledDisplayNotification';
import ErrorBoundary from 'utils/ErrorBoundary';


const App: FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:1500px)');

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    if (htmlEl) {
      htmlEl.style.fontSize = isSmallScreen ? '14px' : '16px';
    }
  }, [isSmallScreen])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScaledDisplayNotification />
      <Provider store={store}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
