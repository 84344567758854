import Spinner from 'components/Spinner/Spinner';
import IStore from 'lib/redux/models';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { loginFromToken } from 'storage/auth/duck';
import { IAuthState } from 'storage/auth/models';

interface Props {
  exact?: boolean;
  component: React.FunctionComponent;
  path: string;
}

const PrivateRoute = ({
  exact,
  component: Component,
  path,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const { loggedIn, loggedOut, loading } = useSelector<IStore, IAuthState>((state) => state.auth);

  useEffect(() => {
    if (!loggedIn && !loggedOut) {
      dispatch(loginFromToken())
    }
  }, [loggedIn, loggedOut]);

  const renderComponent = () => {
    if (loggedIn) {
      return (
        <Route
          exact={exact ? exact : false}
          path={path}
          component={Component}
        />
      );
    } else if (loading || loading === undefined) {
      return <Spinner isVisible />;
    }
    return <Redirect to={"/login/"} />;
  };

  return renderComponent();
};

export default PrivateRoute;
