// Models
import { IAction } from 'lib/redux/models'
import { ETutorialActionTypes, ITutorialState } from '../models'
import steps from '../steps'

// ACTION TYPES
export const Types = {
  START: ETutorialActionTypes.START,
  STOP: ETutorialActionTypes.STOP,
  NEXT_STEP: ETutorialActionTypes.NEXT_STEP,
  PREVIOUS_STEP: ETutorialActionTypes.PREVIOUS_STEP,
}


// INITIAL STATE
const initialState: ITutorialState = {
  running: false,
  steps: steps,
  stepIndex: 0,
}

// REDUCER
export default (
  state: ITutorialState = initialState,
  action?: IAction,
): ITutorialState => {
  switch (action?.type) {
    case Types.START:
      return {
        ...state,
        running: true,
      }
    case Types.STOP:
      return {
        ...state,
        running: false,
        stepIndex: 0,
      }
    case Types.NEXT_STEP:
      return {
        ...state,
        stepIndex: state.steps.length - 1 === state.stepIndex ? 0 : state.stepIndex + 1,
        running: state.steps.length - 1 === state.stepIndex ? false : state.running,
      }
    case Types.PREVIOUS_STEP:
      return {
        ...state,
        stepIndex: state.stepIndex >= 1 ? state.stepIndex - 1 : 0,
      }
    default:
      return state
  }
}

// ACTIONS
export const start = (): IAction => ({
  type: Types.START,
})

export const stop = (): IAction => ({
  type: Types.STOP,
})

export const nextStep = (): IAction => ({
  type: Types.NEXT_STEP,
})

export const previousStep = (): IAction => ({
  type: Types.PREVIOUS_STEP,
})
