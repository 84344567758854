import React, { FC, useEffect, useState } from 'react';
import './FilterBar.css';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAppState, IAppStateDerived } from 'storage/app/models';
import { selectClubOptions, selectFilters, selectMarketOptions, selectRegionOptions, setClub, setMarket, setRegion } from 'storage/app/duck';
import { getFiltersCount, isTablet } from 'utils/utils';
import Stack from '@mui/material/Stack';
import theme from 'theme';
import FilterMenu from "./FilterMenu";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubFilters from './SubFilters';
import TimeTravel from './TimeTravel';
import WorkorderFinder from './WorkorderFinder';
import FilterHighlight from './FilterHighlight';
import { trackEvent } from 'storage/tracking/duck';
import BoxDivider from 'components/Navbar/BoxDivider';


const FilterBar: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const regionOptions = useSelector<IStore, IAppStateDerived['regionOptions']>(selectRegionOptions);
  const [currentRegionOptions, setCurrentRegionOptions] = useState(filters.region)
  const marketOptions = useSelector<IStore, IAppStateDerived['marketOptions']>(selectMarketOptions) as number[];
  const [currentMarketOptions, setCurrentMarketOptions] = useState(filters.market)
  const clubOptions = useSelector<IStore, IAppStateDerived['clubOptions']>(selectClubOptions) as string[];
  const [currentClubOptions, setCurrentClubOptions] = useState(filters.club)
  const [regionMenuAnchorEl, setRegionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [marketMenuAnchorEl, setMarketMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [clubMenuAnchorEl, setClubMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const filtersCount = getFiltersCount(filters);
  const ipad = isTablet();

  useEffect(() => {
    setCurrentRegionOptions(filters.region)
  }, [filters.region])

  useEffect(() => {
    setCurrentMarketOptions(filters.market)
  }, [filters.market])

  useEffect(() => {
    setCurrentClubOptions(filters.club)
  }, [filters.club])

  const handleRegionChange = (value?: IAppState['filters']['region']) => {
    setCurrentRegionOptions(value);
  };

  const handleClearRegionFilters = () => {
    setCurrentRegionOptions(undefined);
    dispatch(setRegion(undefined));
    dispatch(trackEvent({ namespace: 'Clear regions', predicate: 'Filter bar' }))
  }

  const handleApplyRegionFilters = () => {
    dispatch(setRegion(currentRegionOptions));
    dispatch(trackEvent({ namespace: 'Set regions', predicate: 'Filter bar', payload: currentRegionOptions ? { filter: currentRegionOptions } : undefined }))
    setRegionMenuAnchorEl(null)
  }

  const handleCancelRegionFilters = () => {
    setCurrentRegionOptions(filters.region);
    setRegionMenuAnchorEl(null)
  }

  const handleMarketChange = (value?: IAppState['filters']['market']) => {
    setCurrentMarketOptions(value);
  };

  const handleClearMarketFilters = () => {
    setCurrentMarketOptions(undefined);
    dispatch(setMarket(undefined))
    dispatch(trackEvent({ namespace: 'Clear markets', predicate: 'Filter bar' }))
  }

  const handleApplyMarketFilters = () => {
    dispatch(setMarket(currentMarketOptions));
    dispatch(trackEvent({ namespace: 'Set markets', predicate: 'Filter bar', payload: currentMarketOptions ? { filter: currentMarketOptions } : undefined }))
    setMarketMenuAnchorEl(null)
  }

  const handleCancelMarketFilters = () => {
    setCurrentMarketOptions(filters.market);
    setMarketMenuAnchorEl(null)
  }

  const handleClubChange = (value?: IAppState['filters']['club']) => {
    setCurrentClubOptions(value);
  };

  const handleClearClubFilters = () => {
    setCurrentClubOptions(undefined);
    dispatch(setClub(undefined));
    dispatch(trackEvent({ namespace: 'Clear clubs', predicate: 'Filter bar' }))
  }

  const handleApplyClubFilters = () => {
    dispatch(setClub(currentClubOptions));
    dispatch(trackEvent({ namespace: 'Set clubs', predicate: 'Filter bar', payload: currentClubOptions ? { filter: currentClubOptions } : undefined }))
    setClubMenuAnchorEl(null)
  }

  const handleCancelClubFilters = () => {
    setCurrentClubOptions(filters.club);
    setClubMenuAnchorEl(null)
  }

  const handleToggleFilters = () => {
    setFiltersOpen(!filtersOpen);
    dispatch(trackEvent({ namespace: 'Open filters', predicate: 'Filter bar' }))
  }

  const handleCloseFilters = () => {
    setFiltersOpen(false);
  }

  const getFilterLabel = (value: string[] | number[] | undefined, type: string) => {
    if (value) {
      if (value.length === 1) {
        if (type === "Region") {
          return `${value[0]}`
        } else {
          return `${type.slice(0, type.length - 1)} ${value[0]}`
        }
      } else {
        return <>
          {type}
          <Chip
            label={value.length}
            size="small"
            variant="outlined"
            sx={{ ml: 1, backgroundColor: theme.palette.background.highlight }}
          />
        </>
      }
    } else {
      return `All ${type}`
    }
  }

  return (
    <Stack
      className="filter-bar"
      direction="row"
      alignItems="stretch"
      alignSelf="stretch"
      sx={{
        height: theme.custom.navbarHeight,
        zIndex: 1000,
      }}
    >
      <WorkorderFinder />
      <BoxDivider />
      <FilterHighlight filters={filters}>
        <FilterMenu
          id="region-menu"
          anchorEl={regionMenuAnchorEl}
          value={currentRegionOptions}
          options={regionOptions.map(option => ({ value: option, name: option }))}
          label={getFilterLabel(filters.region, 'Regions')}
          clearable
          multiple
          onClick={(e) => setRegionMenuAnchorEl(e.currentTarget)}
          onClose={() => setRegionMenuAnchorEl(null)}
          onSelect={(option) => handleRegionChange(option)}
          onClear={handleClearRegionFilters}
          onApply={handleApplyRegionFilters}
          onCancel={handleCancelRegionFilters}
        />
        <FilterMenu
          id="market-menu"
          anchorEl={marketMenuAnchorEl}
          value={currentMarketOptions}
          options={marketOptions.map(option => ({ value: option, name: option }))}
          label={getFilterLabel(filters.market, 'Markets')}
          clearable
          multiple
          searchable
          applyOnEnter
          onClick={(e) => setMarketMenuAnchorEl(e.currentTarget)}
          onClose={() => setMarketMenuAnchorEl(null)}
          onSelect={(option) => handleMarketChange(option)}
          onClear={handleClearMarketFilters}
          onApply={handleApplyMarketFilters}
          onCancel={handleCancelMarketFilters}
        />
        <FilterMenu
          id="club-menu"
          anchorEl={clubMenuAnchorEl}
          value={currentClubOptions}
          options={clubOptions.map(option => ({ value: option, name: option }))}
          label={getFilterLabel(filters.club, 'Clubs')}
          clearable
          multiple
          searchable
          applyOnEnter
          onClick={(e) => setClubMenuAnchorEl(e.currentTarget)}
          onClose={() => setClubMenuAnchorEl(null)}
          onSelect={(option) => handleClubChange(option)}
          onClear={handleClearClubFilters}
          onApply={handleApplyClubFilters}
          onCancel={handleCancelClubFilters}
        />
      </FilterHighlight>
      {!ipad && <BoxDivider />}
      <Button
        id="filters-button"
        onClick={handleToggleFilters}
        sx={{
          textTransform: 'none',
          color: '#fff',
          fontSize: '1rem',
          '&.MuiButton-root:hover': {
            backgroundColor: 'background.default'
          }
        }}
      >
        <Typography ml={3} component="span" fontWeight='bold' noWrap>
          <>
            Filters
            {filtersCount &&
              <Chip
                label={filtersCount}
                size="small"
                variant="outlined"
                sx={{ ml: 1, backgroundColor: filtersCount ? theme.palette.background.highlight : undefined, }}
              />
            }
          </>
        </Typography>
        {!filtersOpen && <KeyboardArrowDownIcon sx={{ ml: 0.5 }} />}
        {filtersOpen && <KeyboardArrowUpIcon sx={{ ml: 0.5 }} />}
      </Button>
      {filtersOpen &&
        <SubFilters
          handleClose={handleCloseFilters}
          filters={filters}
          anchorEl={document.getElementById('filters-button')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      }
      {!ipad && <>
        <BoxDivider />
        <TimeTravel />
      </>}
    </Stack >
  );
};

export default FilterBar;
