/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IAppState,
  NestedClub,
  Workorder,
  WorkorderAggregate,
} from 'storage/app/models';
import * as turf from '@turf/turf';
import { AppData, AppFilters, AggregationLevel } from 'storage/app/models';
import { format } from 'd3-format';
import { Point, FeatureCollection, Polygon, LineString } from 'geojson';
import { Feature } from '@turf/turf';
import { LngLat } from 'maplibre-gl';
import dayjs from 'dayjs';
import { StringItem } from 'components/FilterBar/FilterMenu';

/**
 * Checks if input key is present in the object.
 */
export const safeGetObjectValue = (obj: any, key: string) => {
  if (obj) {
    /* eslint-disable-next-line security/detect-object-injection */
    if (Object.keys(obj).includes(key)) return obj[key];
  }
  return null;
};

/**
 * Check if value should be considered empty.
 * This is important for values that are used for inputs
 * and therefore won't be undefined but will be empty strings or arrays.
 */
export const isEmpty = (value?: string | number | string[] | number[]) => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isMultitouchEnabled = () => {
  if ('ontouchstart' in window || window.navigator.maxTouchPoints > 1) {
    if (
      'TouchEvent' in window &&
      (TouchEvent.length > 1 || 'ontouchend' in window)
    ) {
      return true;
    }
  }
  return false;
};

export const isTablet = () => {
  const isSmallScreen = window.matchMedia('(max-width: 1500px)').matches;
  const iPadAgent = /iPad/i;
  const androidTabletAgent = /Android/i;

  if (
    iPadAgent.test(navigator.userAgent) ||
    androidTabletAgent.test(navigator.userAgent) ||
    isSmallScreen
  ) {
    return true;
  } else {
    return isMultitouchEnabled();
  }
};

export const isNotEmpty = (
  value?:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | { [key: string]: number },
) => {
  if (typeof value === 'boolean') {
    return true;
  } else if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }
  return !isEmpty(value);
};

export const filterToParams = (filters: Record<string, any>, params?: any) => {
  const p: any = params ? params : {};
  let f: string;
  for (f in filters) {
    const filterValue = safeGetObjectValue(filters, f);
    if (isNotEmpty(filterValue)) {
      if (f === 'callDate') {
        p['created_date__gte'] = parseInt(filterValue.start);
        p['created_date__lte'] = parseInt(filterValue.end);
      } else if (f === 'workingOnItUser') {
        p['working_on_it_user'] = filterValue;
      } else if (f === 'escalatedUser') {
        p['escalated_user'] = filterValue;
      } else if (f === 'tradeGroups') {
        p['trade_groups'] = filterValue;
      } else if (f === 'notOperationalOnly') {
        p['not_operational'] = filterValue;
      } else if (f === 'potentialDuplicateOnly') {
        p['potential_duplicates_only'] = filterValue;
      } else if (f === 'missedETA') {
        p['missed_eta'] = filterValue;
      } else if (f === 'maintenanceRepairOnly') {
        p['include_maintenance'] = filterValue;
      } else if (f === 'nte') {
        if (filterValue.value === 'greaterThanZero') {
          p['nte_greater_than_zero'] = true;
        } else if (filterValue.value === 'equalsZero') {
          p['nte_zero'] = true;
        }
      } else if (f === 'workingOnIt') {
        if (filterValue.value !== undefined) {
          p['working_on_it'] = filterValue.value;
        }
      } else if (f === 'escalated') {
        if (filterValue.value !== undefined) {
          p['escalated'] = filterValue.value;
        }
      } else if (f === 'noActivitySince') {
        if (filterValue.value !== undefined) {
          p['no_activity_since'] = filterValue.value;
        }
      } else if (f === 'equipmentTypes') {
        p['equipments'] = filterValue;
      } else if (f === 'extendedStatus') {
        p['extended_status'] = filterValue;
      } else if (f === 'problemDescription') {
        p['problem_descriptions'] = filterValue;
      } else if (f === 'providers') {
        p['providers'] = filterValue.join('\\-/') + '\\-/';
      } else if (f === 'dateRange') {
        // do nothing
      } else {
        /* eslint-disable-next-line security/detect-object-injection */
        p[f] = filterValue;
      }
    }
  }
  return p;
};

export const getFiltersCount = (filters: AppFilters) => {
  const tradeCount = filters.trades ? filters.trades.length : 0;
  const tradeGroupCount = filters.tradeGroups ? filters.tradeGroups.length : 0;
  const categoriesCount = filters.categories ? filters.categories.length : 0;
  const providerCount = filters.providers ? filters.providers.length : 0;
  const statusCount = filters.status ? filters.status.length : 0;
  const bsiCount = filters.bsi ? filters.bsi.length : 0;
  const nteCount = filters.nte ? 1 : 0;
  const workingOnItCount = filters.workingOnIt ? 1 : 0;
  const escalatedCount = filters.escalated ? 1 : 0;
  const notOperationalOnly = filters.notOperationalOnly ? 1 : 0;
  const potentialDuplicateOnly = filters.potentialDuplicateOnly ? 1 : 0;
  const missedETA = filters.missedETA ? 1 : 0;
  const workingOnItUser = filters.workingOnItUser ? 1 : 0;
  const escalatedUser = filters.escalatedUser ? 1 : 0;
  const noActivitySince = filters.noActivitySince ? 1 : 0;
  const maintenanceRepairOnly = filters.maintenanceRepairOnly ? 1 : 0;
  const equipmentType = filters.equipmentTypes
    ? filters.equipmentTypes.length
    : 0;
  const extendedStatus = filters.extendedStatus
    ? filters.extendedStatus.length
    : 0;
  const problemDescription = filters.problemDescription
    ? filters.problemDescription.length
    : 0;
  const filtersCount =
    tradeCount +
    tradeGroupCount +
    providerCount +
    categoriesCount +
    statusCount +
    bsiCount +
    nteCount +
    workingOnItCount +
    escalatedCount +
    notOperationalOnly +
    potentialDuplicateOnly +
    missedETA +
    maintenanceRepairOnly +
    workingOnItUser +
    escalatedUser +
    noActivitySince +
    equipmentType +
    extendedStatus +
    problemDescription;
  return filtersCount ? filtersCount : undefined;
};

export const formatNumber = format(',');

export const formatDollars = format('$,.2f');

export const formatDollarsWhole = format('$,.0f');

export const formatDollarsK = format('~s');

export const getRegionBoundsById = (
  id: string[],
  regions: FeatureCollection,
) => {
  const features = regions.features.filter((d) =>
    id.includes(d.properties?.region),
  );
  return turf.bbox(turf.featureCollection(features));
};

export const getMarketBoundsById = (
  id: number[],
  markets: FeatureCollection,
) => {
  const feature = markets.features.find((d) => d.properties?.market === id);
  return turf.bbox(feature);
};

export const getMarketBoundsFromFeatures = (
  clubs: WorkorderAggregate[],
  market?: Feature<any>,
) => {
  const features = clubs.map((club) =>
    turf.point([club.center_lng, club.center_lat]),
  );
  if (market) {
    const marketPoints = turf
      .coordAll(market)
      .map((coord) => turf.point(coord));
    return turf.bbox(turf.featureCollection([...marketPoints, ...features]));
  } else {
    return turf.bbox(turf.featureCollection(features));
  }
};

export const getAggregationLevel = (currentFilters: AppFilters) => {
  if (isNotEmpty(currentFilters.market)) {
    return AggregationLevel.CLUB;
  } else if (isNotEmpty(currentFilters.region)) {
    return AggregationLevel.MARKET;
  } else {
    return AggregationLevel.REGION;
  }
};

export const getFilterSelector = (filters: AppFilters) => {
  if (isNotEmpty(filters.club)) {
    return 'club';
  } else if (isNotEmpty(filters.market)) {
    return 'market';
  } else if (isNotEmpty(filters.region)) {
    return 'region';
  }
};

export const getTotalWorkorders = (data: WorkorderAggregate[]) => {
  return data.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.total_workorders;
  }, 0);
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isDataInitialized = (data: AppData) => {
  if (data.aggregates && data.workorders) {
    return true;
  } else {
    return false;
  }
};

export const getBsiCircleColor = (value: string) => {
  if (value === 'High') {
    return 'scale.high';
  } else if (value === 'Medium') {
    return 'scale.medium';
  } else {
    return 'scale.low';
  }
};

export const getBsiCircleTooltip = (value: string) => {
  if (value === 'High') {
    return 'High BSI';
  } else if (value === 'Medium') {
    return 'Medium BSI';
  } else {
    return 'Low BSI';
  }
};

export const getFeatureId = (feature: Feature<Point>) => {
  if (feature.id) {
    return feature.id;
  } else if (feature.properties?.id) {
    return feature.properties.id;
  } else if (feature.properties?.cluster_id) {
    return feature.properties.cluster_id;
  }
  return undefined;
};

export const getFeaturesToAdd = (
  renderedFeatures: Feature<Point>[],
  features: Feature<Point>[],
) => {
  if (renderedFeatures && features) {
    const featuresToAdd = renderedFeatures.filter(
      (f) => !features.find((f2) => getFeatureId(f) === getFeatureId(f2)),
    );
    return featuresToAdd;
  }
  return [];
};

export const getFeaturesToUpdate = (
  renderedFeatures: Feature<Point>[],
  features: Feature<Point>[],
) => {
  if (renderedFeatures && features) {
    const featuresToUpdate = renderedFeatures.filter((f) =>
      features.find((f2) => getFeatureId(f) === getFeatureId(f2)),
    );
    return featuresToUpdate;
  }
  return [];
};

export const getFeaturesToRemove = (
  renderedFeatures: Feature<Point>[],
  features: Feature<Point>[],
) => {
  if (renderedFeatures && features) {
    const featuresToUpdate = features.filter(
      (f) =>
        !renderedFeatures.find((f2) => getFeatureId(f) === getFeatureId(f2)),
    );
    return featuresToUpdate;
  }
  return [];
};

export const getDefaultCallDate = (
  lastBsiUpdate?: string,
  keepStartDate?: boolean,
) => {
  if (lastBsiUpdate) {
    const callDate = calculateDateRange(
      { name: '', value: 'all' },
      { start: 0, end: 0 },
    );

    return {
      ...(!keepStartDate ? { start: callDate.start } : {}),
      end: callDate.end,
    };
  } else {
    return {
      start: 1660268600 - 60 * 24 * 60 * 60, // 60 days ago,
      end: 1660268600,
    };
  }
};

export const addPointToLine = (
  feature: Feature<LineString>,
  point: LngLat,
): Feature<LineString> => {
  const coords = feature.geometry.coordinates;
  const lastCoord = coords.slice(0, -1).at(-1);
  if (lastCoord && lastCoord[0] === point.lng && lastCoord[1] === point.lat) {
    return feature;
  }
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [...coords, [point.lng, point.lat]],
    },
  };
};

export const updateLine = (
  feature: Feature<LineString>,
  point: LngLat,
): Feature<LineString> => {
  const coords = feature.geometry.coordinates;
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [...coords.slice(0, -1), [point.lng, point.lat]],
    },
  };
};

export const addPointToPolygon = (
  feature: Feature<Polygon>,
  point: LngLat,
): Feature<Polygon> => {
  const coords = feature.geometry.coordinates[0];
  const lastCoord = coords.slice(0, -1).at(-2);
  if (lastCoord && lastCoord[0] === point.lng && lastCoord[1] === point.lat) {
    return feature;
  }
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [
        [...coords.slice(0, -1), [point.lng, point.lat], coords[0]],
      ],
    },
  };
};

export const updatePolygon = (
  feature: Feature<Polygon>,
  point: LngLat,
): Feature<Polygon> => {
  const coords = feature.geometry.coordinates[0];
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [
        [...coords.slice(0, -2), [point.lng, point.lat], coords[0]],
      ],
    },
  };
};
const updateActiveWorkorder = (
  workorderId: number,
  newValues: Partial<Workorder>,
  activeWorkorder?: Workorder,
): Workorder | undefined => {
  if (activeWorkorder?.id === workorderId) {
    return {
      ...activeWorkorder,
      ...newValues,
    };
  }
  return activeWorkorder;
};

const updateWorkorderInList = (
  workorderId: number,
  newValues: Partial<Workorder>,
  workorders?: Workorder[],
): Workorder[] | undefined => {
  if (workorders) {
    return [...workorders].map((w) => {
      if (w.id === workorderId) {
        w = { ...w, ...newValues };
      }
      return w;
    });
  } else return workorders;
};

export const updateWorkorderValues = (
  workorderId: number,
  newValues: Partial<Workorder>,
  data: AppData,
): Partial<AppData> => {
  const updatedActiveWorkorder = updateActiveWorkorder(
    workorderId,
    newValues,
    data.activeWorkorder,
  );
  const updatedWorkorders = updateWorkorderInList(
    workorderId,
    newValues,
    data.workorders,
  );
  const updatedLinkedOriginalWorkorders = updateWorkorderInList(
    workorderId,
    newValues,
    data.linkedWorkorderOriginal,
  );
  const updatedLinkedFollowUpWorkorders = updateWorkorderInList(
    workorderId,
    newValues,
    data.linkedWorkorderFollowUp,
  );
  const updatedPotentialDuplicates = updateWorkorderInList(
    workorderId,
    newValues,
    data.potentialDuplicates,
  );
  return {
    activeWorkorder: updatedActiveWorkorder,
    workorders: updatedWorkorders,
    linkedWorkorderOriginal: updatedLinkedOriginalWorkorders,
    linkedWorkorderFollowUp: updatedLinkedFollowUpWorkorders,
    potentialDuplicates: updatedPotentialDuplicates,
  };
};

export const getTitleLabel = (filters: AppFilters) => {
  if (!filters.region) {
    return 'All Regions';
  } else if (filters.club) {
    if (filters.club.length > 1) {
      return `${filters.club.length} Clubs`;
    } else {
      return `Club ${filters.club}`;
    }
  } else if (filters.market) {
    if (filters.market.length > 1) {
      return `${filters.market.length} Markets`;
    } else {
      return `Market ${filters.market}`;
    }
  } else if (filters.region) {
    if (filters.region.length > 1) {
      return `${filters.region.length} Regions`;
    } else {
      return `${filters.region}`;
    }
  }
};

export const getLevelFromXP = (xp: number): number => {
  let level = 1;
  let xpPerLevel = 100;
  while (xp >= xpPerLevel) {
    xp -= xpPerLevel;
    xpPerLevel += 100;
    level += 1;
  }
  return level;
};

export const getRemainingXP = (xp: number): number => {
  let xpPerLevel = 100;
  while (xp >= xpPerLevel) {
    xp -= xpPerLevel;
    xpPerLevel += 100;
  }
  return xp;
};

export const regionMarkersFromNestedClubs = (nestedClubs: NestedClub[]) =>
  nestedClubs.reduce(
    (acc: IAppState['regionMarkets'], region: NestedClub) => ({
      ...acc,
      [region.region_name]: region.markets.map((market) => market.market_id),
    }),
    {},
  );

export const regionClubsFromNestedClubs = (nestedClubs: NestedClub[]) =>
  nestedClubs.reduce(
    (acc: IAppState['regionClubs'], region: NestedClub) => ({
      ...acc,
      [region.region_name]: region.markets.reduce(
        (mAcc: string[], market) => [
          ...mAcc,
          ...market.locations.map((l) => l.store_id),
        ],
        [],
      ),
    }),
    {},
  );

export const marketClubsFromNestedClubs = (nestedClubs: NestedClub[]) =>
  nestedClubs.reduce(
    (acc: IAppState['marketClubs'], region: NestedClub) => ({
      ...acc,
      ...region.markets.reduce(
        (mAcc, market) => ({
          ...mAcc,
          [market.market_id]: market.locations.map((l) => l.store_id),
        }),
        {},
      ),
    }),
    {},
  );

export const calculateDateRange = (
  option: StringItem,
  callDate: AppFilters['callDate'],
): AppFilters['callDate'] => {
  const now = dayjs();
  const today = dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  if (option.value === 'all') {
    return {
      start:
        today.set('year', 2020).set('month', 1).set('day', 1).valueOf() / 1000,
      end: now.valueOf() / 1000,
    };
  } else if (option.value === '30-days') {
    return {
      start: today.subtract(30, 'day').valueOf() / 1000,
      end: now.valueOf() / 1000,
    };
  } else if (option.value === '30-to-60-days') {
    return {
      start: today.subtract(60, 'day').valueOf() / 1000,
      end: today.subtract(30, 'day').valueOf() / 1000,
    };
  } else if (option.value === '60-to-90-days') {
    return {
      start: today.subtract(90, 'day').valueOf() / 1000,
      end: today.subtract(60, 'day').valueOf() / 1000,
    };
  } else if (option.value === 'more-than-90-days') {
    return {
      start:
        today.set('year', 2020).set('month', 1).set('day', 1).valueOf() / 1000,
      end: today.subtract(90, 'day').valueOf() / 1000,
    };
  } else if (option.value === 'all-aged-workorders') {
    return {
      start:
        today.set('year', 2020).set('month', 1).set('day', 1).valueOf() / 1000,
      end: today.subtract(30, 'day').valueOf() / 1000,
    };
  } else if (option.value === '6-months') {
    return {
      start: today.subtract(6, 'month').valueOf() / 1000,
      end: now.valueOf() / 1000,
    };
  } else if (option.value === '1-year') {
    return {
      start: today.subtract(1, 'year').valueOf() / 1000,
      end: now.valueOf() / 1000,
    };
  } else if (
    option.value === 'custom-range' &&
    callDate?.start !== undefined &&
    callDate?.end !== undefined
  ) {
    return {
      start: today.subtract(callDate.end, 'day').valueOf() / 1000,
      end: today.subtract(callDate.start, 'day').valueOf() / 1000,
    };
  } else if (callDate === undefined) {
    return {
      start: 0,
      end: 30,
    };
  } else {
    return callDate;
  }
};

export const isScrolledIntoView = (el: HTMLElement | null) => {
  if (!el) return false;
  const { top, bottom } = el.getBoundingClientRect();
  return top >= 0 && bottom <= window.innerHeight;
};
