import React, { FC } from 'react';
import { Map } from 'maplibre-gl';
import CursorIcon from '../../assets/cursor.png';

interface CustomCursorProps {
  map: Map
  isVisible: boolean;
}

const CustomCursor: FC<CustomCursorProps> = (props: CustomCursorProps) => {

  React.useEffect(() => {
    if (props.isVisible) {
      props.map.getCanvas().style.cursor = `url(${CursorIcon}) 12 12, auto`;
    } else {
      props.map.getCanvas().style.cursor = 'grab';
    }
  }, [props.isVisible])

  return null;
};

export default CustomCursor;
