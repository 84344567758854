/* eslint-disable @typescript-eslint/no-explicit-any */
// Models
import { IAction } from 'lib/redux/models'
import { 
  EAuthActionTypes, 
  IActionAddTeamView, 
  IActionAddUserView, 
  IActionChangePassword, 
  IActionDeleteTeamView, 
  IActionDeleteUserView,
  IActionLogout,
  IActionRemoveTeamView,
  IActionRemoveUserView, 
  IActionResetPassword, 
  IActionResetPasswordConfirm,
  IActionSaveTeamView,
  IActionSaveTeamViewAsDefault,
  IActionSaveUserView,
  IActionSetDefaultRegion, 
  IActionSetUserStats, 
  IActionUpdateDefaultRegion, 
  IAuthState, 
  ILogin,
  IUser
} from '../models'
import { getLevelFromXP, getRemainingXP } from 'utils/utils'

// ACTION TYPES
export const Types = {
  FAILURE: EAuthActionTypes.FAILURE,
  FULFILL: EAuthActionTypes.FULFILL,
  REQUEST: EAuthActionTypes.REQUEST,
  SUCCESS: EAuthActionTypes.SUCCESS,
  LOGIN: EAuthActionTypes.LOGIN,
  LOGIN_FROM_TOKEN: EAuthActionTypes.LOGIN_FROM_TOKEN,
  REFRESH_TOKEN: EAuthActionTypes.REFRESH_TOKEN,
  RESET_PASSWORD: EAuthActionTypes.RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM: EAuthActionTypes.RESET_PASSWORD_CONFIRM,
  CHANGE_PASSWORD: EAuthActionTypes.CHANGE_PASSWORD,
  RELEASE_EXPIRED_PASSWORD_LOCK: EAuthActionTypes.RELEASE_EXPIRED_PASSWORD_LOCK,
  UPDATE_REFRESH_TIMEOUT: EAuthActionTypes.UPDATE_REFRESH_TIMEOUT,
  LOGOUT: EAuthActionTypes.LOGOUT,
  UPDATE_DEFAULT_REGION: EAuthActionTypes.UPDATE_DEFAULT_REGION,
  SET_DEFAULT_REGION: EAuthActionTypes.SET_DEFAULT_REGION,
  GET_USER_VIEWS: EAuthActionTypes.GET_USER_VIEWS,
  ADD_USER_VIEW: EAuthActionTypes.ADD_USER_VIEW,
  SAVE_USER_VIEW: EAuthActionTypes.SAVE_USER_VIEW,
  REMOVE_USER_VIEW: EAuthActionTypes.REMOVE_USER_VIEW,
  DELETE_USER_VIEW: EAuthActionTypes.DELETE_USER_VIEW,
  GET_TEAM_VIEWS: EAuthActionTypes.GET_TEAM_VIEWS,
  ADD_TEAM_VIEW: EAuthActionTypes.ADD_TEAM_VIEW,
  SAVE_TEAM_VIEW: EAuthActionTypes.SAVE_TEAM_VIEW,
  REMOVE_TEAM_VIEW: EAuthActionTypes.REMOVE_TEAM_VIEW,
  DELETE_TEAM_VIEW: EAuthActionTypes.DELETE_TEAM_VIEW,
  SET_TEAM_VIEW_AS_DEFAULT: EAuthActionTypes.SET_TEAM_VIEW_AS_DEFAULT,
  SAVE_TEAM_VIEW_AS_DEFAULT: EAuthActionTypes.SAVE_TEAM_VIEW_AS_DEFAULT,
  SORT_TEAM_VIEWS: EAuthActionTypes.SORT_TEAM_VIEWS,
  GET_USER_STATS: EAuthActionTypes.GET_USER_STATS,
  SET_USER_STATS: EAuthActionTypes.SET_USER_STATS,
  GAIN_EXPERIENCE: EAuthActionTypes.GAIN_EXPERIENCE,
  CLEAR_FLOATING_XP: EAuthActionTypes.CLEAR_FLOATING_XP,
  LEVEL_UP: EAuthActionTypes.LEVEL_UP,
  GET_LEADERBOARD: EAuthActionTypes.GET_LEADERBOARD,
  SET_LEADERBOARD: EAuthActionTypes.SET_LEADERBOARD,
  GET_NEW_FEATURES: EAuthActionTypes.GET_NEW_FEATURES,
  SET_NEW_FEATURES: EAuthActionTypes.SET_NEW_FEATURES,
}

// INITIAL STATE
export const initialState: IAuthState = {
  user: undefined,
  views: [],
  teamViews: [],
  loggedIn: false,
  loggedOut: false,
}

// REDUCER
export default (
  state: IAuthState = initialState,
  action?: IAction,
): IAuthState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...action?.payload,
      }
    case Types.RELEASE_EXPIRED_PASSWORD_LOCK:
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          is_password_expired: false,
        } : undefined,
      }
    case Types.UPDATE_REFRESH_TIMEOUT:
      return {
        ...state,
        loggedIn: action.payload ? true : false,
        user: state.user ? {
          ...state.user,
          ...action.payload,
        } : undefined,
      }
    case Types.SET_DEFAULT_REGION:
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          default_region: action.payload.default_region,
          default_market: action.payload.default_market,
        } : undefined,
      }
    case Types.ADD_USER_VIEW:
      return {
        ...state,
        views: [
          ...state.views,
          action.payload,
        ],
      }
    case Types.REMOVE_USER_VIEW:
      return {
        ...state,
        views: state.views.filter((view) => view.id !== action.payload),
      }
    case Types.ADD_TEAM_VIEW:
      return {
        ...state,
        teamViews: [
          ...(action.payload.is_default ? state.teamViews.map(view => ({...view, is_default: false})) : state.teamViews),
          action.payload,
        ],
      }
    case Types.REMOVE_TEAM_VIEW:
      return {
        ...state,
        teamViews: state.teamViews.filter((view) => view.id !== action.payload),
      }
    case Types.SET_TEAM_VIEW_AS_DEFAULT:
      return {
        ...state,
        teamViews: state.teamViews.map((view) => ({
          ...view,
          is_default: view.id === action.payload.id ? action.payload.is_default : false,
        })),
      }
    case Types.SORT_TEAM_VIEWS:
      return {
        ...state,
        teamViews: [...state.teamViews].sort((a, b) => a.is_default ? -1 : b.is_default ? 1 : 0),
      }
    case Types.GAIN_EXPERIENCE:
      if (state.user) {
        const stats =  state.user.stats
        const newDailyXP = stats ? 
          stats.xp_count_daily + action.payload > 0 ? 
          stats.xp_count_daily + action.payload : 0 :
          action.payload
        const newMonthlyXP = stats ? 
          stats.xp_count_monthly + action.payload > 0 ? 
          stats.xp_count_monthly + action.payload : 0 :
          action.payload
        const newLevel = getLevelFromXP(newMonthlyXP)
        return {
          ...state,
          user: {
            ...state.user,
            stats: stats ? {
              ...stats,
                xp_count_daily: newDailyXP,
                xp_count_monthly: newMonthlyXP,
                experience: getRemainingXP(newMonthlyXP),
                level: newLevel,
                next_level: newLevel * 100,
            } : undefined
          },
          newXP: state.newXP ? state.newXP + action.payload : action.payload
        }
      }
      return state
    case Types.CLEAR_FLOATING_XP:
      return {
        ...state,
        newXP: undefined,
      }
    case Types.SET_USER_STATS:
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          stats: {
            ...action.payload,
            level: getLevelFromXP(action.payload.xp_count_monthly),
            experience: getRemainingXP(action.payload.xp_count_monthly),
            next_level: getLevelFromXP(action.payload.xp_count_monthly) * 100,
          },
        } : undefined,
      }
    case Types.SET_LEADERBOARD:
      return {
        ...state,
        leaderboards: action.payload,
      }
    case Types.SET_NEW_FEATURES:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          new_features: action.payload,
        },
      }
    default:
      return state
  }
}

// ACTIONS
export const failure = (payload: { message: string, data?: any }): IAction => ({
  type: Types.FAILURE,
  payload,
})

export const fulfill = (): IAction => ({
  type: Types.FULFILL,
})

export const request = (): IAction => ({
  type: Types.REQUEST,
})

export const success = (payload: Partial<IAuthState>): IAction => ({
  type: Types.SUCCESS,
  payload,
})

export const login = (payload: ILogin): IAction => ({
  type: Types.LOGIN,
  payload,
})

export const loginFromToken = (): IAction => ({
  type: Types.LOGIN_FROM_TOKEN,
})

export const refreshToken = (): IAction => ({
  type: Types.REFRESH_TOKEN,
})

export const resetPassword = (payload: IActionResetPassword["payload"]): IAction => ({
  type: Types.RESET_PASSWORD,
  payload
})

export const resetPasswordConfirm = (payload: IActionResetPasswordConfirm["payload"]): IAction => ({
  type: Types.RESET_PASSWORD_CONFIRM,
  payload
})

export const changePassword = (payload: IActionChangePassword["payload"]): IAction => ({
  type: Types.CHANGE_PASSWORD,
  payload
})

export const releaseExpiredPasswordLock = (): IAction => ({
  type: Types.RELEASE_EXPIRED_PASSWORD_LOCK,
})

export const updateRefreshTimeout = (payload: { refresh_token: string,  exp: number, iat: number }): IAction => ({
  type: Types.UPDATE_REFRESH_TIMEOUT,
  payload
})

export const logout = (payload: IActionLogout["payload"]): IAction => ({
  type: Types.LOGOUT,
  payload
})

export const updateDefaultRegion = (payload: IActionUpdateDefaultRegion["payload"]): IAction => ({
  type: Types.UPDATE_DEFAULT_REGION,
  payload
})

export const setDefaultRegion = (payload: IActionSetDefaultRegion["payload"]): IAction => ({
  type: Types.SET_DEFAULT_REGION,
  payload
})

export const getUserViews = (): IAction => ({
  type: Types.GET_USER_VIEWS,
})

export const addUserView = (payload: IActionAddUserView["payload"]): IAction => ({
  type: Types.ADD_USER_VIEW,
  payload
})

export const saveUserView = (payload: IActionSaveUserView["payload"]): IAction => ({
  type: Types.SAVE_USER_VIEW,
  payload
})

export const removeUserView = (payload: IActionRemoveUserView["payload"]): IAction => ({
  type: Types.REMOVE_USER_VIEW,
  payload
})

export const deleteUserView = (payload: IActionDeleteUserView["payload"]): IAction => ({
  type: Types.DELETE_USER_VIEW,
  payload
})

export const getTeamViews = (): IAction => ({
  type: Types.GET_TEAM_VIEWS,
})

export const addTeamView = (payload: IActionAddTeamView["payload"]): IAction => ({
  type: Types.ADD_TEAM_VIEW,
  payload
})

export const saveTeamView = (payload: IActionSaveTeamView["payload"]): IAction => ({
  type: Types.SAVE_TEAM_VIEW,
  payload
})

export const removeTeamView = (payload: IActionRemoveTeamView["payload"]): IAction => ({
  type: Types.REMOVE_TEAM_VIEW,
  payload
})

export const deleteTeamView = (payload: IActionDeleteTeamView["payload"]): IAction => ({
  type: Types.DELETE_TEAM_VIEW,
  payload
})

export const setTeamViewAsDefault = (payload: IActionSaveTeamViewAsDefault["payload"]): IAction => ({
  type: Types.SET_TEAM_VIEW_AS_DEFAULT,
  payload
})

export const saveTeamViewAsDefault = (payload: IActionSaveTeamViewAsDefault["payload"]): IAction => ({
  type: Types.SAVE_TEAM_VIEW_AS_DEFAULT,
  payload
})

export const sortTeamViews = (): IAction => ({
  type: Types.SORT_TEAM_VIEWS,
})

export const getUserStats = (): IAction => ({
  type: Types.GET_USER_STATS,
})

export const setUserStats = (payload: IActionSetUserStats["payload"]): IAction => ({
  type: Types.SET_USER_STATS,
  payload
})

export const gainExperience = (payload: number): IAction => ({
  type: Types.GAIN_EXPERIENCE,
  payload
})

export const clearFloatingXP = (): IAction => ({
  type: Types.CLEAR_FLOATING_XP
})

export const getLeaderboard = (): IAction => ({
  type: Types.GET_LEADERBOARD
})

export const setLeaderboard = (payload: IAuthState["leaderboards"]): IAction => ({
  type: Types.SET_LEADERBOARD,
  payload
})

export const getNewFeatures = (): IAction => ({
  type: Types.GET_NEW_FEATURES
})

export const setNewFeatures = (payload: IUser["new_features"]): IAction => ({
  type: Types.SET_NEW_FEATURES,
  payload
})

export const actions = {
  failure,
  fulfill,
  request,
  success,
}
