import React, { FC, useEffect, useState, MouseEvent } from 'react';
import { Feature, Point } from 'geojson';
import { PieChart, Pie, Cell } from 'recharts';
import theme from 'theme';
import CursorIcon from '../../assets/cursor.png';

interface DonutSlice {
  name: string,
  value: number
}

interface DonutChartProps {
  id: string,
  className?: string,
  width: number,
  height: number,
  feature: Feature<Point>,
  data: DonutSlice[],
  clusterCount?: number,
  highlight?: boolean,
  onClick: (feature: Feature<Point>) => void,
  onMouseEnter?: (feature: Feature<Point>) => void,
  onMouseLeave?: () => void
  disableHover?: boolean
}

const DonutChart: FC<DonutChartProps> = (props) => {
  const [hovered, setHovered] = useState(false);
  const innerRadius = props.width / 2 - 14;
  const outerRadius = props.width / 2;
  const colors = [theme.palette.scale.low, theme.palette.scale.medium, theme.palette.scale.high];

  useEffect(() => {
    setHovered(props.highlight ? true : false)
  }, [props.highlight])

  const handleMouseEnter = () => {
    if (!props.disableHover) {
      if (props.onMouseEnter) props.onMouseEnter(props.feature);
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!props.disableHover) {
      if (props.onMouseLeave) props.onMouseLeave();
      setHovered(false);
    }
  };

  const handleClick = (e: MouseEvent) => {
    if (!props.disableHover) {
      if (!e.ctrlKey) {
        props.onClick(props.feature);
        e.stopPropagation();
      }
    }
  }

  return (
    <PieChart
      id={props.id}
      className={'aggregate-donut'}
      width={props.width + 32}
      height={props.height + 32}
      style={{
        cursor: props.disableHover || props.feature.properties?.aggregation_level === 'Club' || props.feature.properties?.cluster ?
          'grab' :
          `url(${CursorIcon}) 12 12, auto`
      }}
    >
      <Pie
        data={props.data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
        stroke="none"
        isAnimationActive={false}
      >
        {props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      {props.clusterCount &&
        <g className='club-count' >
          <circle cx={"85%"} cy={"12.5%"} r={14} fill={theme.palette.background.default} >
          </circle>
          <text x={"85%"} y={"16%"} textAnchor="middle" fill="white" fontSize={14}>{props.clusterCount}</text>
        </g>
      }
      <circle
        cx={outerRadius + 16}
        cy={outerRadius + 16}
        r={innerRadius}
        fill={hovered ? theme.palette.background.light : theme.palette.background.default}
        opacity={.8}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseLeave}
        onMouseOver={handleMouseEnter}
        style={{
          cursor: props.disableHover ?
            'grab' :
            `url(${CursorIcon}) 12 12, auto`
        }}
      />
      <text
        x={outerRadius + 16}
        y={outerRadius + 16}
        dy={5}
        textAnchor="middle"
        fill={theme.palette.primary.main}
        fontSize="1rem"
        style={{
          cursor: props.disableHover ?
            'grab' :
            `url(${CursorIcon}) 12 12, auto`,
          pointerEvents: 'none'
        }}
      >
        {props.data.reduce((acc, cur) => acc + cur.value, 0)}
      </text>
    </PieChart>
  );
};

export default DonutChart;
