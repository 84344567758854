// Models
import { IAction, IBaseState } from 'lib/redux/models'
import { AppFilters } from 'storage/app/models'

export enum EAlertsActionTypes {
  FAILURE = 'ALERTS/FAILURE',
  FULFILL = 'ALERTS/FULFILL',
  REQUEST = 'ALERTS/REQUEST',
  GET_ALERTS = 'ALERTS/GET_ALERTS',
  SET_ALERTS = 'ALERTS/SET_ALERTS',
  GET_NEXT_ALERTS = 'ALERTS/GET_NEXT_ALERTS',
  APPEND_ALERTS = 'ALERTS/APPEND_ALERTS',
  ACKNOWLEDGE = 'ALERTS/ACKNOWLEDGE',
  SET_ACKNOWLEDGED = 'ALERTS/SET_ACKNOWLEDGED',
  SET_HIGHLIGHT = 'ALERTS/SET_HIGHLIGHT',
}

// Data
export interface Alert {
  id: number,
  summary: string,
  workorders: AppFilters['workorders'],
  region: AppFilters['region'],
  market: AppFilters['market'],
  club: AppFilters['club'],
  trades: AppFilters['trades'],
  equipmentTypes: AppFilters['equipmentTypes'],
  createdAt: Date,
  updatedAt: Date,
  acknowledged: boolean,
  highlighted?: boolean,
  targetMaximum: number,
  ruleName: string,
  ruleThreshold: number,
  isSecondThreshold: boolean,
  secondRuleThreshold: number,
}

export interface AlertAPI {
  id: number,
  summary: string,
  work_orders: number[],
  region?: string,
  market?: number,
  store_id?: string,
  trades?: string[],
  equipment_tag?: string,
  created_at: string,
  updated_at: string,
  acknowledged: boolean,
  target_maximum: number,
  rule_name: string,
  rule_threshold: number,
  is_second_threshold: boolean,
  second_rule_threshold: number,
}

export interface AlertsAPIResponse {
  count: number,
  next: number | null, 
  previous: number | null,
  results: AlertAPI[], 
}

// Actions

export interface IActionGetAlerts extends IAction {
  type: EAlertsActionTypes.GET_ALERTS
}

export interface IActionGetNextAlerts extends IAction {
  type: EAlertsActionTypes.GET_NEXT_ALERTS
  payload: {
    page: number
  }
}

export interface IActionSetAlerts extends IAction {
  type: EAlertsActionTypes.SET_ALERTS
  payload: {
    alerts: Alert[]
    totalAlerts: number
    hasNextPage: boolean
  }
}

export interface IActionAppendAlerts extends IAction {
  type: EAlertsActionTypes.APPEND_ALERTS
  payload: {
    alerts: Alert[]
    hasNextPage: boolean
  }
}

export interface IActionAcknowledge extends IAction {
  type: EAlertsActionTypes.ACKNOWLEDGE
  payload: {
    alertId: number
  }
}

export interface IActionSetAcknowledged extends IAction {
  type: EAlertsActionTypes.SET_ACKNOWLEDGED
  payload: {
    alertId: number
    acknowledged: boolean
  }
}

export interface IActionSetHighlight extends IAction {
  type: EAlertsActionTypes.SET_HIGHLIGHT
  payload: {
    alertId: number,
    highlighted: boolean
  }
}

// State

export interface IAlertsState extends IBaseState {
  alerts: Alert[]
  alertIds: number[]
  pageNumber: number
  hasNextPage: boolean
  totalAlerts: number
}