import React, { Dispatch } from "react"
import { Typography } from "@mui/material"
import { TutorialStep } from "../models"
import { AnyAction } from "redux"
import { clearFilters, setActiveWorkorder, setWorkorderListFullscreen } from "storage/app/duck"


const steps: TutorialStep[] = [
  {
    target: 'body',
    title: 'Welcome to the Battleship tutorial!',
    content: <Typography>
      The Battleship screen has three main areas that you can interact with. This
      tutorial will walk you through the basic functionalities of each of
      these elements. For a more in-depth overview, see
      the <a href="/docs/" target="_top" style={{ color: '#fff' }}>documentation</a>.
      <br /><br />
      Use the &quot;Next&quot; and &quot;Back&quot; buttons to navigate through the
      tutorial. The &quot;Close&quot; button will end the tutorial.
    </Typography>,
    disableBeacon: true,
    placement: 'center',
    callbackFn: (dispatch: Dispatch<AnyAction>) => {
      dispatch(clearFilters())
      dispatch(setActiveWorkorder(undefined))
      dispatch(setWorkorderListFullscreen(false))
    }
  },
  {
    target: '.MuiAppBar-root',
    title: 'Top Bar',
    content: <Typography>
      The first main element is the <b>Top Bar</b>. Among other things, it contains
      geographical selection tools (Regions, Markets, and Clubs), filters, and a search
      bar.
    </Typography>,
    disableBeacon: true,
  },
  {
    target: '#map',
    title: 'Map Viewer',
    content: <Typography>
      The second main element is the <b>Map Viewer</b>. It displays information about
      work orders on a map. You can also click on elements on this map to explore the
      Region, Market, and Club levels.
    </Typography>,
    placement: 'center',
  },
  {
    target: '#side-panel',
    title: 'Data Panel',
    content: <Typography>
      The third main element is the <b>Data Panel</b>. This is where you see
      details about what you have selected using the Top Bar and Map Viewer.
    </Typography>,
    placement: 'right',
  },
  {
    target: '.homepage',
    content: <Typography>
      Now that we have located the <b>Top Bar</b>, <b>Map Viewer</b>, and <b>Data
        Panel</b>, let&apos;s look at these three elements in more detail.<br /><br />
      First up is the Top Bar and its functionalities.
    </Typography>,
    placement: 'center',
  },
  {
    target: '.filter-bar',
    title: 'Filter Bar',
    content: <Typography>These are the filter bar tools.</Typography>,
  },
  {
    target: '.filter-bar .MuiTextField-root',
    title: 'Work Order Search Field',
    content: <Typography>
      Use this field to search for a specific work order by its ID.
    </Typography>,
  },
  {
    target: '.filter-bar .MuiStack-root button:nth-of-type(1)',
    title: 'Region Selector',
    content: <Typography>
      Use this drop down menu to choose which Region(s) to see information for.
    </Typography>,
  },
  {
    target: '.filter-bar .MuiStack-root button:nth-of-type(2)',
    title: 'Market Selector',
    content: <Typography>
      Use this drop down menu to choose which Market(s) to see information for.
      You can also use a search box to enter a Market number directly.
    </Typography>,
  },
  {
    target: '.filter-bar .MuiStack-root button:nth-of-type(3)',
    title: 'Club Selector',
    content: <Typography>
      Use this drop down menu to choose which Clubs to see information for.
      You can also use a search box to enter a Club number directly, similar to the
      Market Selector.
    </Typography>,
  },
  {
    target: '#filters-button',
    title: 'Filter Box',
    content: <>
      <Typography>
        Use this button to open the filter box. This is where you can set additional
        filters:
      </Typography>
      <ul>
        <li>Filter work orders by Trade, Trade Group, Service Provider, Status, and
          BSI score</li>
        <li>Filter work orders to display only those that you are working on
          or that you have escalated.</li>
        <li>Set a date range to filter work orders by their Created Date</li>
      </ul>
    </>,
  },
  {
    target: '.filter-bar #timetravel',
    title: 'Back/Forward Buttons',
    content: <Typography>
      Use these buttons to navigate through the history of your interactions
      with Battleship. For example, you can use the <i>Back</i> button to undo setting a
      new filter or clicking on a Region on the map.
    </Typography>,
  },
  {
    target: '#user-menu-button',
    title: 'User Menu',
    content: <>
      <Typography>
        Click on this button to open a menu with additional options and settings:
      </Typography>
      <ul>
        <li><b>Help</b>: open the documentation</li>
        <li><b>On-screen tutorial</b>: open this tutorial</li>
        <li><b>Default geography</b>: set the default Region and Market to display when you first open
          Battleship</li>
        <li><b>My views</b>: store and apply sets of filters and geography selections as
          views</li>
        <li><b>Change password</b>: change your password</li>
        <li><b>Logout</b>: log out of Battleship</li>
      </ul>
    </>
    ,
    placement: 'bottom',
  },
  {
    target: '#logo-container',
    title: 'Battleship Logo',
    content: <Typography>
      Click on the Battleship logo to reset all filters to their defaults.
    </Typography>,
  },
  {
    target: '#aggregates-overview',
    title: 'Overview Box',
    content: <Typography>
      This box displays a summary of the work orders that are currently
      displayed in the map. <br /><br />
      This overview also describes the filters that are currently
      active. You can click on any of the filters here and open the filter box to
      adjust the filter.
    </Typography>,
    placement: 'left',
  },
  {
    target: '.map-container .aggregate-donut:nth-of-type(1)',
    title: 'Summary Element',
    content: <Typography>
      Depending on what level you are on (Region, Market, or Club), this
      element provides a summary of what you will see if you click on it. <br /><br />
      Hovering your mouse cursor over a summary element displays additional information.
      Clicking on it drills down to the next lower level.
    </Typography>,
  },
  {
    target: '.maplibregl-ctrl-bottom-right',
    title: 'Map Tools',
    content: <Typography>
      In the bottom right area of the map, you can find additional information and tools
      to interact with the map. Use the + and - buttons to <b>zoom in and out of the
        map</b> (without changing the geographic_levels). You also have the option to draw a
      <b>polygon to select multiple markets or regions</b> and to <b>send a feedback
        message</b> to the Battleship development team.
    </Typography>,
  },
  {
    target: '#last-update',
    title: 'Last Update Status',
    content: <Typography>
      This status indicator tells you when the data in Battleship was last
      updated. Generally, the data in Battleship is updated every five minutes.
    </Typography>,
  },
  {
    target: '#user-stats',
    title: 'Experience Point (XP) Stats',
    content: <Typography>
      This element provides an overview of the Experience Points (XP) you have collected
      throughout the current month. You receive different amounts of XP for different
      actions, such as sending notes or marking work orders as &quot;Working on
      it&quot;.
    </Typography>,
  },
  {
    target: '#side-panel #heatmap-wrapper',
    title: 'Heatmap',
    content: <Typography>
      The heatmap visualizes the number of work orders by Importance and
      Urgency. <br /><br />
      Work orders in the top right quadrant generally have a high BSI score,
      while work orders in the bottom left quadrant generally have a low BSI score.
      <br /><br />
      You can click on any heatmap cell to display only work orders located in that
      cell.
    </Typography>,
    placement: 'right',
  },
  {
    target: '#side-panel #heatmap-header',
    title: 'Hide/Display Heatmap',
    content: <Typography>
      Click on the header of the heatmap to hide or display the heatmap.
      This way, you can create more space for the work order list below. On smaller
      screens, the heatmap is hidden by default.
    </Typography>,
  },
  {
    target: '#side-panel .workorder-list',
    title: 'Work Order List',
    content: <Typography>
      The work order list contains all work orders that match the current
      filters and geographical selection. You can click on any work order to display
      additional details about it in the Data Panel.
    </Typography>,
    placement: 'right',
  },
  {
    target: '#side-panel #wo-controls',
    title: 'Data Panel Tools',
    content: <Typography>
      This toolbar contains tools related to the work orders in the work
      order list. You can use the &quot;order by&quot;; drop down menu to sort the work orders
      by different criteria (they are sorted by BSI by default). Use the button in the
      middle to export your current view as PNG or CSV. Or use the &quot;Filter&quot; button
      to open the Filter box and update your filters.
    </Typography>,
    placement: 'right',
  },
  {
    target: '#fullscreen-button',
    title: 'Switch to Fullscreen Mode',
    content: <Typography>
      Use this button to open the work order list in full-screen mode. This
      will expand the work order list to cover the entire width of your screen.
      In full-screen mode, you can still use all filters from the filter bar.
      Additionally, you can use all action buttons, see additional details, and
      add notes to work orders.
    </Typography>,
    placement: 'right',
  },
  {
    target: '.homepage',
    title: 'Thank you',
    content: <Typography>
      You have completed the on-screen tutorial!<br /><br />
      For a more in-depth overview, see
      the <a href="/docs/" target="_top" style={{ color: '#fff' }}>documentation</a>.
    </Typography>,
    placement: 'center',
  },
]

export default steps
