import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Atkinson-Hyperlegible',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    login: {
      paper: '#1175C2',
    },
    background: {
      default: '#11224B',
      paper: '#1D4B6D',
      inverted: '#F3F5F6',
      light: '#1D4B6D',
      lighter: '#1D4B6D',
      lightest: '#7c878e',
      dark: '#12161B',
      darker: '#000',
      highlight: '#35C4EC',
    },
    text: {
      primary: '#FFFFFF',
    },
    scale: {
      low: '#E9C406',
      medium: '#E37400',
      high: '#E20000'
    },
    actions: {
      workingOnIt: '#1C912E',
      userWorkingOnIt: '#22BF3B',
      escalated: '#BF7013',
      userEscalated: '#FF9519',
    },
    heatmap: {
      lowest: '#E9C406',
      low: '#E69C03',
      med: '#E37400',
      high: '#E33A00',
      highest: '#E20000',
    }
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        color: 'inherit'
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#35C4EC'
          },
          '&:hover': {
            backgroundColor: '#1D4B6D',
          },
          '&.Mui-selected:hover ': {
            backgroundColor: 'rgba(53, 196, 236, 0.66)'
          },
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            backgroundColor: '#11224B'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            color: '#e0e0e0',
            '&.Mui-disabled': {
              color: '#a0a0a0',
            },
          },
          '& label.Mui-focused': {
            color: '#ffffff',
          },
          '& .Mui-disabled input': {
            "-webkit-text-fill-color": "#a0a0a0",
          },
          input: {
            color: '#e0e0e0',
          },
          fieldset: {
            borderColor: '#e0e0e0',
            color: '#e0e0e0',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#e0e0e0',
              color: '#e0e0e0',

            },
            '&.Mui-focused fieldset': {
              borderColor: '#ffffff',
            },
            '&.Mui-disabled fieldset': {
              borderColor: '#a0a0a0',
            },
          },

        },

      }
    },

  },
  custom: {
    navbarHeight: '3.125rem'
  }
});

export default theme;