import React, { Component, ErrorInfo, ReactNode } from "react";
import { connect } from "react-redux";
import store from "lib/redux"
import { Stack, Typography } from "@mui/material";
import { IAppState } from "storage/app/models";
import { INotesState } from "storage/notes/models";
import IStore from "lib/redux/models";

interface TrackedState {
  app?: Partial<IAppState>,
  notes?: Partial<INotesState>
}

interface Props {
  trackEvent: (message: string, state: TrackedState) => void;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

const mapStateToProps = (state: IStore) => {
  const { app, notes } = state
  // Filter out some of the state to reduce the payload size
  return {
    app: {
      ...app,
      map: undefined,
      data: {
        activeWorkorder: state.app.data.activeWorkorder,
      },
      filtersOptions: undefined,
      geojson: undefined,
      marketClubs: undefined,
      nestedClubs: undefined,
      regionClubs: undefined,
      regionMarkets: undefined
    },
    notes
  }
}

const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
  return {
    // dispatching plain actions
    trackEvent: (message: string, state: TrackedState) => dispatch({
      type: 'TRACKING/TRACK_EVENT',
      payload: { namespace: 'Frontend application crashed', predicate: message, payload: { ...state } }
    }),
  }
}

class ErrorBoundary extends Component<Props & TrackedState, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.props.trackEvent(error.message, { app: this.props.app, notes: this.props.notes });
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
          <Typography variant="h1" fontSize="4rem">Error</Typography>
          <Typography variant="h4">It looks like we&apos;re experiencing some technical difficulties!</Typography>
          <Typography variant="h5">We are sorry for the inconvenience. We are tracking this issue internally and will be looking into it.</Typography>
          <Typography variant="h5">Please try using your browser&apos;s &quot;reload&quot; button to continue using Battleship!</Typography>
        </Stack>
      )

    }

    return this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
