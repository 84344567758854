/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'lib/axios/api';
import { Alert, AlertAPI, AlertsAPIResponse } from 'storage/alerts/models';


/**
 * Get alerts for authenticated user.
 */
export interface GetAlertsServiceResponse extends AlertsAPIResponse { alerts: Alert[] }
export const getAlertsService = async (page?: number): Promise<GetAlertsServiceResponse> => {
  const response: { data: AlertsAPIResponse } = await api.get(`/notifications/`, { params: { page: page } });
  const alerts: Alert[] = response.data.results.map((alert: AlertAPI) => ({
    id: alert.id,
    summary: alert.summary,
    workorders: alert.work_orders,
    region: alert.region ? [alert.region] : undefined,
    market: alert.market ? [alert.market] : undefined,
    club: alert.store_id ? [alert.store_id] : undefined,
    trades: alert.trades ? alert.trades : undefined,
    equipmentTypes: alert.equipment_tag ? [alert.equipment_tag] : undefined,
    createdAt: new Date(alert.created_at),
    updatedAt: new Date(alert.updated_at),
    acknowledged: alert.acknowledged,
    highlighted: undefined,
    targetMaximum: alert.target_maximum,
    ruleName: alert.rule_name,
    ruleThreshold: alert.rule_threshold,
    isSecondThreshold: alert.is_second_threshold,
    secondRuleThreshold: alert.second_rule_threshold,
  }));
  return { ...response.data, alerts };
};

/**
 * Acknowledge an alert.
 */
export const acknowledgeAlertService = async (id: number): Promise<boolean> => {
  const response = await api.put(`/notifications/${id}/`, { acknowledged: true });
  return response.status === 200;
}