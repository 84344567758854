import IStore from 'lib/redux/models';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedWorkorders, setData } from 'storage/app/duck';
import { IAppState, Workorder } from 'storage/app/models';
import { Box, Button, Card, CardContent, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import Spinner from 'components/Spinner';
import WorkorderItem from './WorkorderItem';
import { RenderSCLinkedWorkOrder } from 'components/WorkorderDetail/LinkedWorkorders';

interface LinkedWorkordersModalProps {
  open: boolean;
  onClose: () => void;
  workorder: Workorder;
}

const LinkedWorkordersModal: FC<LinkedWorkordersModalProps> = ({ open, onClose, workorder }) => {
  const dispatch = useDispatch();
  const loading = useSelector<IStore, IAppState["loadingLinkedWorkorders"]>(state => state.app.loadingLinkedWorkorders);
  const linkedWorkorderOriginal = useSelector<IStore, Workorder[] | undefined>(state => state.app.data.linkedWorkorderOriginal);
  const linkedWorkorderFollowUp = useSelector<IStore, Workorder[] | undefined>(state => state.app.data.linkedWorkorderFollowUp);
  const originalWOs: number[] = workorder.linked_work_orders_original ? workorder.linked_work_orders_original.map((wo: string) => parseInt(wo)) : []
  const followUpWOs: number[] = workorder.linked_work_orders_follow_up ? workorder.linked_work_orders_follow_up.map((wo: string) => parseInt(wo)) : []
  const scLinkedWorkorderOriginal = originalWOs.reduce((acc: number[], cur: number) => {
    if (!linkedWorkorderOriginal?.find(wo => wo.id === cur)) {
      return [...acc, cur]
    }
    return acc
  }, [])
  const scLinkedWorkorderFollowUp = followUpWOs.reduce((acc: number[], cur: number) => {
    if (!linkedWorkorderFollowUp?.find(wo => wo.id === cur)) {
      return [...acc, cur]
    }
    return acc
  }, [])

  useEffect(() => {
    if ((originalWOs.length > 0 || followUpWOs.length > 0) && open) {
      dispatch(setData({
        linkedWorkorderOriginal: undefined,
        linkedWorkorderFollowUp: undefined,
      }))
      dispatch(getLinkedWorkorders({ original: originalWOs, followUp: followUpWOs }))
    } else if (open) {
      dispatch(setData({
        linkedWorkorderOriginal: undefined,
        linkedWorkorderFollowUp: undefined,
      }))
    }
  }, [workorder.id, open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50rem',
        backgroundColor: theme.palette.background.default,
      }}>
        <Button
          variant='text'
          size='small'
          onClick={onClose}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            {workorder.id} | Linked Work Orders
          </Typography>
          <Stack spacing={2} maxHeight='80svh' minHeight='10svh' width='32rem' sx={{ overflowY: 'auto', margin: 'auto' }}>
            {loading && <Spinner isVisible dimBackground />}
            <Box sx={{
              overflowX: 'hidden',
              p: '0.5rem',
              pl: '0',
              mr: '0.25rem',
            }}>
              {originalWOs.length > 0 && <>
                <Typography variant="h6" sx={{ ml: '1rem', mb: '0.5rem' }}>Original</Typography>
                {linkedWorkorderOriginal?.map((workorder: Workorder) => <WorkorderItem key={workorder.id} workorder={workorder} />)}
                {scLinkedWorkorderOriginal.map((wo: number, index) => <RenderSCLinkedWorkOrder key={index} id={wo} />)}
              </>}
              {followUpWOs.length > 0 && <>
                <Typography variant="h6" sx={{ mt: '0.5rem', ml: '1rem', mb: '0.5rem' }}>Follow-up</Typography>
                {linkedWorkorderFollowUp?.map((workorder: Workorder) => <WorkorderItem key={workorder.id} workorder={workorder} />)}
                {scLinkedWorkorderFollowUp.map((wo: number, index) => <RenderSCLinkedWorkOrder key={index} id={wo} />)}
              </>}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default LinkedWorkordersModal;