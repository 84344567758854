// Models
import { IAction, IBaseState } from 'lib/redux/models'

export enum ENotesActionTypes {
  FAILURE = 'NOTES/FAILURE',
  FULFILL = 'NOTES/FULFILL',
  REQUEST = 'NOTES/REQUEST',
  SUCCESS = 'NOTES/SUCCESS',
  GET_NOTES = 'NOTES/GET_NOTES',
  SET_NOTES = 'NOTES/SET_NOTES',
  ADD_NOTE = 'NOTES/ADD_NOTE',
  SAVE_NOTE = 'NOTES/SAVE_NOTE',
  BATCH_SAVE_NOTE = 'NOTES/BATCH_SAVE_NOTE',
  GET_RECIPIENTS = 'NOTES/GET_RECIPIENTS',
  SET_RECIPIENTS = 'NOTES/SET_RECIPIENTS',
}

// Data

export interface Note {
  id: number
  number: number
  noteData?: string | number
  createdDate: string
  createdBy: string
  actionRequired: boolean
}

export interface NotesResponse {
  data: Note[]
}

export interface Recipient {
  email: string
  username: string
  recipient_type: number
}

export interface RecipientsResponse {
  data: Recipient[]
}

// Actions

export interface IActionGetWorkorderNotes extends IAction {
  type: ENotesActionTypes.GET_NOTES
  payload: {
    workorderId: number
  }
}

export interface IActionSetWorkorderNotes extends IAction {
  type: ENotesActionTypes.SET_NOTES
  payload: {
    workorderId: number
    notes: Note[]
  }
}

export interface IActionAddWorkorderNote extends IAction {
  type: ENotesActionTypes.ADD_NOTE
  payload: {
    workorderId: number
    note: string
  }
}

export interface IActionSaveWorkorderNote extends IAction {
  type: ENotesActionTypes.SAVE_NOTE
  payload: {
    workorderId: number
    note: string
    recipients: Recipient["email"][]
    gainExp: () => IAction
    setNewNote: (note: string) => void
  }
}

export interface IActionBatchSaveWorkorderNote extends IAction {
  type: ENotesActionTypes.ADD_NOTE
  payload: {
    workorders: number[]
    note: string
    recipientType: number
    gainExp: () => IAction
    setNewNote: (note: string) => void
  }
}

export interface IActionGetWorkorderRecipients extends IAction {
  type: ENotesActionTypes.GET_RECIPIENTS
  payload: number
}

export interface IActionSetWorkorderRecipients extends IAction {
  type: ENotesActionTypes.SET_RECIPIENTS
  payload: {
    workorderId: string
    recipients: Recipient[]
  }
}

// State

export interface INotesState extends IBaseState {
  recipients: Record<string, Recipient[]>
  data: Record<string, Note[]>
  loadingNotes: boolean
  loadingRecipients: boolean
}
