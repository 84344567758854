// Models
import IStore, { IAction } from 'lib/redux/models';
import { combineReducers, Reducer } from 'redux';
import appStoreReducer from 'storage/app/duck';
import authStoreReducer from 'storage/auth/duck';
import notesStoreReducer from 'storage/notes/duck';
import alertsStoreReducer from 'storage/alerts/duck';
import tutorialReducer from 'storage/tutorial/duck';
import trackingReducer from 'storage/tracking/duck';

const appReducer: Reducer<IStore, IAction> = combineReducers({
  app: appStoreReducer,
  auth: authStoreReducer,
  notes: notesStoreReducer,
  alerts: alertsStoreReducer,
  tutorial: tutorialReducer,
  tracking: trackingReducer
});

export default appReducer;
