import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Chip,
  IconButton,
  Portal,
  Stack,
  TextField,
  Tooltip,
  Button,
  Menu,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorkorder,
  selectFilters,
  setActiveWorkorder,
  setWorkorders,
} from 'storage/app/duck';
import { IAppState } from 'storage/app/models';
import IStore from 'lib/redux/models';
import Spinner from 'components/Spinner/Spinner';
import { trackEvent } from 'storage/tracking/duck';
import theme from 'theme';
import CancelIcon from '@mui/icons-material/Cancel';
import { isTablet } from 'utils/utils';
import SearchIcon from '@mui/icons-material/Search';

const WorkorderFinder: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const map = useSelector<IStore, IAppState['map']>((state) => state.app.map);
  const loading = useSelector<IStore, IAppState['loadingWorkorder']>(
    (state) => state.app.loadingWorkorder,
  );
  const [search, setSearch] = useState<string>('');
  const [workorderIds, setWorkorderIds] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const ipad = isTablet();
  const [searchMenuAnchorEl, setSearchMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const searchMenuOpen = Boolean(searchMenuAnchorEl);

  useEffect(() => {
    if (filters.workorders === undefined && filters.workorder === undefined) {
      clearWorkorderIds();
    } else if (filters.workorders && workorderIds.length === 0) {
      setWorkorderIds(filters.workorders.map((wo) => wo.toString()));
    }
  }, [filters.workorders, filters.workorder]);

  useEffect(() => {
    if (errorMessage) {
      const maxTimeout = Math.max(errorMessage.length * 90, 5000);
      setTimeout(() => {
        setErrorMessage('');
      }, maxTimeout);
    }
  }, [errorMessage]);

  const handleChangeSearch = (value: string | null) => {
    if (value) {
      setSearch(value);
    } else {
      setSearch('');
    }
  };

  const handleFindWorkorder = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey) {
      const parsedSearch = search
        .replaceAll(';', ',')
        .replaceAll(' ', ',')
        .replaceAll('\n', ',');
      const possibleWorkorderIds = parsedSearch
        .split(',')
        .map((id) => id.replace(/\D/g, ''));
      if (
        possibleWorkorderIds &&
        possibleWorkorderIds.some((id) => /^\d+$/.test(id) && id.length === 9)
      ) {
        const ids = possibleWorkorderIds.filter(
          (id) => /^\d+$/.test(id) && id.length === 9,
        );
        if (ids.length > 1) {
          dispatch(setWorkorders(ids.map((id) => parseInt(id))));
          dispatch(setActiveWorkorder(undefined));
        } else {
          dispatch(getWorkorder({ id: parseInt(ids[0], 10), filters }));
        }
        dispatch(
          trackEvent({
            namespace: 'Search WO',
            predicate: 'Filter bar',
            value: ids.join(', '),
          }),
        );
        setWorkorderIds(ids);
        setSearch('');
      } else {
        setErrorMessage('Please enter a valid work order ID');
      }
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };

  const clearWorkorderIds = () => {
    setWorkorderIds([]);
    dispatch(setWorkorders(undefined));
  };

  const handleSearchMenuClose = () => {
    setSearchMenuAnchorEl(null);
  };

  const handleSearchMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSearchMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      {ipad ? (
        <>
          <Button
            id="search-menu-button"
            aria-controls={searchMenuOpen ? 'search-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={searchMenuOpen ? 'true' : undefined}
            onClick={handleSearchMenuClick}
            sx={{
              minWidth: '3rem',
            }}>
            <SearchIcon />
            {workorderIds.length >= 1 && (
              <Chip
                label={workorderIds.length}
                size="small"
                variant="outlined"
                sx={{
                  ml: 1,
                  backgroundColor: workorderIds.length
                    ? theme.palette.background.highlight
                    : undefined,
                }}
              />
            )}
          </Button>
          <Menu
            id="search-menu"
            anchorEl={searchMenuAnchorEl}
            open={searchMenuOpen}
            onClose={handleSearchMenuClose}
            MenuListProps={{
              'aria-labelledby': 'search-menu-button',
              style: { padding: 0 },
            }}>
            <Stack alignItems="center" sx={{ minWidth: '12rem' }}>
              <TextField
                id="workorder-finder"
                name="search-workorder"
                value={search}
                onChange={(e) => handleChangeSearch(e.target.value)}
                onKeyDown={(e) => handleFindWorkorder(e)}
                multiline
                variant="standard"
                placeholder="Search work orders ID"
                inputProps={{
                  style: {
                    width: '10rem',
                    minHeight: '1.5rem',
                  },
                }}
                InputProps={{
                  sx: {
                    background: theme.palette.background.paper,
                    paddingLeft: '0.5rem',
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton
                      disabled={loading}
                      onClick={() =>
                        handleFindWorkorder({
                          code: 'Enter',
                          shiftKey: false,
                        } as React.KeyboardEvent<HTMLDivElement>)
                      }>
                      <Spinner isVisible={loading} />
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  ),
                }}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center">
                {workorderIds.length >= 1 && (
                  <Chip
                    label={workorderIds[0]}
                    size="small"
                    variant="outlined"
                    sx={{
                      ml: 1,
                      backgroundColor: theme.palette.background.highlight,
                    }}
                  />
                )}
                {workorderIds.length >= 2 && (
                  <Tooltip
                    title={workorderIds.slice(1).map((id) => (
                      <>
                        - {id}
                        <br />
                      </>
                    ))}
                    placement="bottom">
                    <Chip
                      label={`+ ${workorderIds.length - 1}`}
                      size="small"
                      variant="outlined"
                      sx={{
                        ml: 1,
                        backgroundColor: theme.palette.background.highlight,
                      }}
                    />
                  </Tooltip>
                )}
                {workorderIds.length >= 1 && (
                  <IconButton
                    disabled={loading}
                    onClick={() => clearWorkorderIds()}>
                    <CancelIcon sx={{ color: '#fff' }} />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Menu>
        </>
      ) : (
        <Stack direction="row" alignItems="center">
          <Stack maxHeight="3rem">
            <TextField
              id="workorder-finder"
              name="search-workorder"
              value={search}
              onChange={(e) => handleChangeSearch(e.target.value)}
              onKeyDown={(e) => handleFindWorkorder(e)}
              multiline
              variant="standard"
              placeholder="Search work orders ID"
              inputProps={{
                style: {
                  marginTop: '0.25rem',
                  width: '10rem',
                  ...(map ? {} : { height: '1.5rem' }),
                },
              }}
              InputProps={{
                sx: {
                  background: theme.palette.background.paper,
                  paddingLeft: '0.5rem',
                  paddingTop: '0.15rem',
                  baddingBottom: '0.15rem',
                },
                disableUnderline: true,
                endAdornment: (
                  <IconButton
                    disabled={loading}
                    onClick={() =>
                      handleFindWorkorder({
                        code: 'Enter',
                        shiftKey: false,
                      } as React.KeyboardEvent<HTMLDivElement>)
                    }>
                    <Spinner isVisible={loading} />
                    <KeyboardArrowRightIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            {workorderIds.length >= 1 && (
              <Chip
                label={workorderIds[0]}
                size="small"
                variant="outlined"
                sx={{
                  ml: 1,
                  backgroundColor: theme.palette.background.highlight,
                }}
              />
            )}
            {workorderIds.length >= 2 && (
              <Tooltip
                title={workorderIds.slice(1).map((id) => (
                  <>
                    - {id}
                    <br />
                  </>
                ))}
                placement="bottom">
                <Chip
                  label={`+ ${workorderIds.length - 1}`}
                  size="small"
                  variant="outlined"
                  sx={{
                    ml: 1,
                    backgroundColor: theme.palette.background.highlight,
                  }}
                />
              </Tooltip>
            )}
            {workorderIds.length >= 1 && (
              <IconButton
                disabled={loading}
                onClick={() => clearWorkorderIds()}>
                <CancelIcon sx={{ color: '#fff' }} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      {errorMessage && (
        <Portal container={() => document.querySelector('#root')}>
          <Alert
            severity="error"
            variant="filled"
            sx={{
              position: 'fixed',
              bottom: '0.5rem',
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 3000,
            }}
            onClick={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        </Portal>
      )}
    </>
  );
};

export default WorkorderFinder;
