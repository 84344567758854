import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAppState, Workorder } from 'storage/app/models';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import {
  toggleLinkedWOPanelOpen,
  getLinkedWorkorders,
  setData,
} from 'storage/app/duck';
import {
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Portal,
  Card,
} from '@mui/material';
import theme from 'theme';
import Spinner from 'components/Spinner/Spinner';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import WorkorderItem from 'components/WorkorderList/WorkorderItem';
import { isTablet } from 'utils/utils';

export const RenderSCLinkedWorkOrder: FC<{ id: number }> = ({ id }) => {
  const metadata = useSelector<IStore, IAppState['metadata']>(
    (state) => state.app.metadata,
  );

  return (
    <Card
      sx={{
        margin: '0 0.25rem 0.25rem 0.25rem',
        padding: '0.5rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Typography margin={0}>Work Order {id}</Typography>
      <Tooltip title="Open in Service Channel" placement="top" arrow>
        <IconButton
          size="small"
          href={`${metadata.sc_portal_url}/sc/wo/Workorders/index?id=${id}`}
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={ServiceChannelIcon}
            width="26"
            height="26"
            alt="Service Channel"
          />
        </IconButton>
      </Tooltip>
    </Card>
  );
};

interface LinkedWorkordersProps {
  workorderData: Workorder;
}

const LinkedWorkorders: FC<LinkedWorkordersProps> = ({ workorderData }) => {
  const dispatch = useDispatch();
  const loading = useSelector<IStore, IAppState['loadingLinkedWorkorders']>(
    (state) => state.app.loadingLinkedWorkorders,
  );
  const isPanelOpen = useSelector<IStore, IAppState['isLinkedWOPanelOpen']>(
    (state) => state.app.isLinkedWOPanelOpen,
  );
  const linkedWorkorderOriginal = useSelector<IStore, Workorder[] | undefined>(
    (state) => state.app.data.linkedWorkorderOriginal,
  );
  const linkedWorkorderFollowUp = useSelector<IStore, Workorder[] | undefined>(
    (state) => state.app.data.linkedWorkorderFollowUp,
  );
  const originalWOs: number[] = workorderData.linked_work_orders_original
    ? workorderData.linked_work_orders_original.map((wo: string) =>
        parseInt(wo),
      )
    : [];
  const followUpWOs: number[] = workorderData.linked_work_orders_follow_up
    ? workorderData.linked_work_orders_follow_up.map((wo: string) =>
        parseInt(wo),
      )
    : [];
  const scLinkedWorkorderOriginal = originalWOs.reduce(
    (acc: number[], cur: number) => {
      if (!linkedWorkorderOriginal?.find((wo) => wo.id === cur)) {
        return [...acc, cur];
      }
      return acc;
    },
    [],
  );
  const scLinkedWorkorderFollowUp = followUpWOs.reduce(
    (acc: number[], cur: number) => {
      if (!linkedWorkorderFollowUp?.find((wo) => wo.id === cur)) {
        return [...acc, cur];
      }
      return acc;
    },
    [],
  );
  const linkedWOs = [...originalWOs, ...followUpWOs];
  const ipad = isTablet();
  const panelWidth = ipad ? '26rem' : '32rem';

  useEffect(() => {
    if (isPanelOpen && linkedWOs.length === 0) {
      dispatch(toggleLinkedWOPanelOpen());
    }
  }, [linkedWOs]);

  const handleOpenPanel = () => {
    dispatch(toggleLinkedWOPanelOpen());
  };

  useEffect(() => {
    if ((originalWOs.length > 0 || followUpWOs.length > 0) && isPanelOpen) {
      dispatch(
        setData({
          linkedWorkorderOriginal: undefined,
          linkedWorkorderFollowUp: undefined,
        }),
      );
      dispatch(
        getLinkedWorkorders({ original: originalWOs, followUp: followUpWOs }),
      );
    } else if (isPanelOpen) {
      dispatch(
        setData({
          linkedWorkorderOriginal: undefined,
          linkedWorkorderFollowUp: undefined,
        }),
      );
      dispatch(toggleLinkedWOPanelOpen());
    }
  }, [workorderData.id, isPanelOpen]);

  return (
    <Stack
      className="linked-workorders"
      sx={{ pt: linkedWOs.length ? '1rem' : undefined }}>
      {linkedWOs.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenPanel}
          disabled={!linkedWOs.length}
          fullWidth
          endIcon={
            linkedWOs.length > 0 &&
            (isPanelOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            ))
          }
          sx={{
            color: !linkedWOs.length
              ? `${theme.palette.text.primary} !important`
              : undefined,
          }}>
          {isPanelOpen
            ? `Hide linked work orders (${linkedWOs.length})`
            : `View linked work orders (${linkedWOs.length})`}
        </Button>
      )}
      {isPanelOpen && (
        <Portal container={() => document.querySelector('#side-panel')}>
          <Stack
            sx={{
              position: 'absolute',
              top: '0',
              left: panelWidth,
              width: panelWidth,
              bottom: 0,
              zIndex: 1001,
              backgroundColor: theme.palette.background.default,
            }}>
            {loading && <Spinner isVisible dimBackground />}
            <Box
              sx={{
                overflowX: 'hidden',
                p: '0.5rem',
                pl: '0',
                mr: '0.25rem',
              }}>
              {originalWOs.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ ml: '1rem', mb: '0.5rem' }}>
                    Original
                  </Typography>
                  {linkedWorkorderOriginal?.map((workorder: Workorder) => (
                    <WorkorderItem key={workorder.id} workorder={workorder} />
                  ))}
                  {scLinkedWorkorderOriginal.map((wo: number, index) => (
                    <RenderSCLinkedWorkOrder key={index} id={wo} />
                  ))}
                </>
              )}
              {followUpWOs.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    sx={{ mt: '0.5rem', ml: '1rem', mb: '0.5rem' }}>
                    Follow-up
                  </Typography>
                  {linkedWorkorderFollowUp?.map((workorder: Workorder) => (
                    <WorkorderItem key={workorder.id} workorder={workorder} />
                  ))}
                  {scLinkedWorkorderFollowUp.map((wo: number, index) => (
                    <RenderSCLinkedWorkOrder key={index} id={wo} />
                  ))}
                </>
              )}
            </Box>
            <Tooltip
              title={'Hide linked work orders panel'}
              placement="right"
              arrow>
              <Button
                variant="contained"
                onClick={handleOpenPanel}
                sx={{
                  position: 'absolute',
                  top: '0.75rem',
                  left: panelWidth,
                  minWidth: '2rem',
                  width: '2rem',
                  height: '3rem',
                  zIndex: 90,
                  backgroundColor: theme.palette.background.light,
                  color: 'white',
                }}>
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Portal>
      )}
    </Stack>
  );
};

export default LinkedWorkorders;
