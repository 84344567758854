import React, { FC, useEffect, useState } from 'react';
import FilterBar from 'components/FilterBar';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyIcon from '@mui/icons-material/Key';
import PlaceIcon from '@mui/icons-material/Place';
import InfoIcon from '@mui/icons-material/Info';
import theme from 'theme';
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Typography, IconButton, Stack, Button, Avatar, Menu, MenuItem } from '@mui/material';
import { IAuthState, IUser, TeamView } from 'storage/auth/models';
import IStore from 'lib/redux/models';
import { logout, sortTeamViews } from 'storage/auth/duck';
import Logo from 'assets/battleship-logo.svg';
import ChangePasswordModal from 'components/ChangePasswordModal';
import { IAppState, IAppStateDerived } from 'storage/app/models';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import DefaultRegionModal from 'components/DefaultRegionModal';
import StarIcon from '@mui/icons-material/Star';
import {
  clearFilters,
  clearState,
  getAggregateData,
  getHeatmapData,
  getWorkorderData,
  selectAggregationLevel,
  selectSortParam,
  selectTeamView,
  setCallDate,
  setDateRange,
  setMarket,
  setRegion,
  updateFilters
} from 'storage/app/duck';
import { start as startTutorial } from 'storage/tutorial/duck';
import UserViewsModal from 'components/UserViewsModal';
import { trackEvent } from 'storage/tracking/duck';
import { FaCrown } from 'react-icons/fa';
import TeamViewsModal, { SavedTeamView } from 'components/TeamViewsModal/TeamViewsModal';
import BsiDashboardModal from 'components/BsiDashboardModal';
import BoxDivider from './BoxDivider';
import Alerts from './Alerts';


const UserMenu: FC<{ user: IUser }> = ({ user }) => {
  const dispatch = useDispatch();
  const docsUrl = window.origin.includes(":3000") ? "http://localhost:8000/docs/" : "/docs/";
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [defaultRegionModalOpen, setDefaultRegionModalOpen] = useState<boolean>(false);
  const [bsiDashboardModalOpen, setBsiDashboardModalOpen] = useState<boolean>(false);
  const [userViewsModalOpen, setUserViewsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (user.is_password_expired) {
      setChangePasswordModalOpen(true);
    }
  }, [user])

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleChangePassword = () => {
    handleUserMenuClose();
    setChangePasswordModalOpen(true);
  }

  const handleStartTutorial = () => {
    handleUserMenuClose();
    dispatch(startTutorial())
  }

  const handleBsiDashboard = () => {
    handleUserMenuClose();
    setBsiDashboardModalOpen(true)
  }

  const handleLeaderboard = () => {
    handleUserMenuClose();
    const btn = document.getElementById('user-stats')?.querySelector('button');
    if (btn) {
      dispatch(trackEvent({ namespace: 'View leaderboard', predicate: 'User menu' }))
      btn.click()
    }
  }

  const handleDefaultRegion = () => {
    handleUserMenuClose();
    setDefaultRegionModalOpen(true)
  }

  const handleLogout = () => {
    handleUserMenuClose();
    dispatch(logout(undefined))
    dispatch(clearState())
  }

  const handleManageViews = () => {
    handleUserMenuClose();
    setUserViewsModalOpen(true)
  }

  return (
    <>
      <Button
        id="user-menu-button"
        aria-controls={userMenuOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={userMenuOpen ? 'true' : undefined}
        onClick={handleUserMenuClick}
      >
        <Avatar
          alt={`${user.first_name} ${user.last_name}`}
          sx={{ textTransform: "uppercase", color: theme.palette.background.default, backgroundColor: theme.palette.primary.main, fontSize: "1rem", pt: '0.1rem' }}
        >
          {user.first_name[0] + user.last_name[0]}
        </Avatar>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={userMenuAnchorEl}
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        sx={{ marginTop: "0.5rem" }}
      >
        <Stack sx={{ minWidth: "12rem" }}>
          <Stack sx={{ padding: "0.25rem 1rem 0.5rem 1rem", borderBottom: `1px solid ${theme.palette.background.darker}`, marginBottom: "0.5rem" }}>
            <Typography>
              {user.first_name} {user.last_name}
            </Typography>
          </Stack>
          {<MenuItem onClick={handleManageViews}>
            <StarIcon sx={{ mr: "0.5rem", mt: "-0.1rem" }} /> My Views
          </MenuItem>}
          <MenuItem onClick={handleUserMenuClose} href={docsUrl} component="a" target="_blank">
            <HelpIcon sx={{ mr: "0.5rem" }} /> Help
          </MenuItem>
          <MenuItem onClick={handleBsiDashboard}>
            <InfoIcon sx={{ mr: "0.5rem" }} /> BSI and data details
          </MenuItem>
          <MenuItem onClick={handleStartTutorial}>
            <TipsAndUpdatesIcon sx={{ mr: "0.5rem" }} /> On-screen tutorial
          </MenuItem>
          <MenuItem onClick={handleLeaderboard}>
            <FaCrown style={{ margin: "0 0.75rem 0 0.25rem" }} /> Leaderboard
          </MenuItem>
          <MenuItem onClick={handleDefaultRegion}>
            <PlaceIcon sx={{ mr: "0.5rem" }} /> Default geography
          </MenuItem>
          <MenuItem onClick={handleChangePassword}>
            <KeyIcon sx={{ mr: "0.5rem" }} /> Change password
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ mr: "0.5rem" }} /> Logout
          </MenuItem>
        </Stack>
      </Menu>
      <ChangePasswordModal open={changePasswordModalOpen} closeModal={() => setChangePasswordModalOpen(false)} />
      <DefaultRegionModal open={defaultRegionModalOpen} closeModal={() => setDefaultRegionModalOpen(false)} />
      <BsiDashboardModal open={bsiDashboardModalOpen} closeModal={() => setBsiDashboardModalOpen(false)} />
      <UserViewsModal open={userViewsModalOpen} closeModal={() => setUserViewsModalOpen(false)} />
    </>
  )
}


const TeamViewsQuickAccess: FC = () => {
  const dispatch = useDispatch();
  const [teamViewsMenuAnchorEl, setTeamViewsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const teamViewsMenuOpen = Boolean(teamViewsMenuAnchorEl);
  const views = useSelector<IStore, IAuthState['teamViews']>((state) => state.auth.teamViews);
  const user = useSelector<IStore, IAuthState['user']>((state) => state.auth.user);
  const [teamViewsModalOpen, setTeamViewsModalOpen] = useState<boolean>(false);
  const haveDefaultView = views?.filter(view => view.is_default).length > 0

  useEffect(() => {
    dispatch(sortTeamViews())
  }, [teamViewsMenuOpen])

  const handleTeamViewsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTeamViewsMenuAnchorEl(event.currentTarget);
  };

  const handleTeamViewsMenuClose = () => {
    setTeamViewsMenuAnchorEl(null);
  };

  const handleSelectView = (view: TeamView) => {
    dispatch(selectTeamView(view.state))
    if (view.state.dateRange && view.state.dateRange.value !== "custom") {
      dispatch(setDateRange(view.state.dateRange))
    } else {
      dispatch(setDateRange({ name: "Custom", value: "custom" }))
      dispatch(setCallDate(view.state.callDate))
    }
    dispatch(trackEvent({ namespace: 'Select team view', predicate: 'User team quick access', payload: { id: view.id } }))
    setTeamViewsMenuAnchorEl(null);
  }

  const handleManageViews = () => {
    setTeamViewsMenuAnchorEl(null);
    setTeamViewsModalOpen(true)
  }

  return (
    <>
      <Button
        id="team-views-menu-button"
        color="secondary"
        aria-controls={teamViewsMenuOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={teamViewsMenuOpen ? 'true' : undefined}
        onClick={handleTeamViewsMenuClick}
      >
        Team Views
        {haveDefaultView && <StarIcon style={{ marginLeft: '0.5rem', marginBottom: '0.15rem' }} />}
      </Button>
      <Menu
        id="team-views-menu"
        anchorEl={teamViewsMenuAnchorEl}
        open={teamViewsMenuOpen}
        onClose={handleTeamViewsMenuClose}
        MenuListProps={{
          'aria-labelledby': 'my-views-menu-button',
          style: { 'maxHeight': 'calc(100svh - 10rem)', 'position': 'relative' }
        }}
        sx={{ marginTop: "0.5rem" }}
      >
        <Stack sx={{ maxHeight: 'calc(100svh - 13rem)', minWidth: "12rem", overflowY: 'auto' }}>
          {views.length === 0 && <Typography sx={{ padding: "1rem", textAlign: 'center', color: 'background.inverted' }}>No saved team views yet</Typography>}
          {views.map(view => <SavedTeamView key={view.id} view={view} selectTeamView={handleSelectView} />)}
        </Stack>
        {user?.team_admin &&
          <Stack >
            <Button onClick={() => handleManageViews()} sx={{mt: "0.5rem"}}>
              Manage views
            </Button>
          </Stack>
        }
      </Menu>
      <TeamViewsModal open={teamViewsModalOpen} closeModal={() => setTeamViewsModalOpen(false)} />
    </>
  )
}


const Navbar: FC = () => {
  const dispatch = useDispatch();
  const { user, loggedIn, teamViews } = useSelector<IStore, IAuthState>((state) => state.auth);
  const { metadata, filters, panelFilters } = useSelector<IStore, IAppState>((state) => state.app);
  const sortParam = useSelector<IStore, IAppStateDerived['sortParam']>(selectSortParam);
  const aggregationLevel = useSelector<IStore, IAppStateDerived['aggregationLevel']>(selectAggregationLevel);

  const isUserLoggedIn = user && loggedIn;
  const [lastBsiUpdate, setLastBsiUpdate] = useState<Date>();

  useEffect(() => {
    if (metadata.bsi_updated_date && metadata.loaded) {
      const bsiUpdatedDate = new Date(metadata.bsi_updated_date);
      if (lastBsiUpdate && lastBsiUpdate.getTime() < bsiUpdatedDate.getTime()) {
        dispatch(getAggregateData({ filters, aggregationLevel }));
        dispatch(getWorkorderData({ filters, panelFilters, sortParam }));
        dispatch(getHeatmapData({ filters }));
      }
      setLastBsiUpdate(bsiUpdatedDate);
    }
  }, [metadata.bsi_updated_date, metadata.loaded])

  const returnToDefaultView = () => {
    dispatch(clearFilters());
    if (user?.default_region) {
      dispatch(setRegion(user.default_region))
    }
    if (user?.default_market) {
      dispatch(setMarket(user.default_market))
    }
    const defaultTeamView = teamViews?.find(view => view.is_default)
    if (defaultTeamView) {
      dispatch(updateFilters(defaultTeamView.state))
      if (defaultTeamView.state.dateRange && defaultTeamView.state.dateRange.value !== "custom") {
        dispatch(setDateRange(defaultTeamView.state.dateRange))
      } else {
        dispatch(setDateRange({ name: "Custom", value: "custom" }))
        dispatch(setCallDate(defaultTeamView.state.callDate))
      }
    }
  }

  return (
    <AppBar
      id="navbar"
      position="static"
      sx={{
        backgroundColor: 'background.lighter',
        zIndex: 1000,
        height: theme.custom.navbarHeight,
        pl: 2,
        pr: 2,
        pt: 0,
        pb: 0
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: '100%'
        }}
      >
        <Stack
          id="logo-container"
          direction="row"
          alignItems="center"
          marginRight="1rem"
          zIndex={1500}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={returnToDefaultView}
          >
            {/* <Logo/> */}
            <img src={Logo} style={{ height: '2.5rem', padding: '0.25rem' }} />
          </IconButton>
          <Alerts />
        </Stack>
        {isUserLoggedIn && <>
          <FilterBar />
          <Stack direction="row" alignItems="stretch" alignSelf="stretch">
            {(user?.team_admin || teamViews.length > 0) && <>
              <TeamViewsQuickAccess />
              <BoxDivider />
            </>}
            <UserMenu user={user} />
          </Stack>
        </>}
      </Stack>
    </AppBar >
  );
};

export default Navbar;
