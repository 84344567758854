import React, { FC, useEffect, useState } from 'react';
import { WorkorderAggregate } from 'storage/app/models';
import { formatDollarsK } from 'utils/utils';
import { Box, Stack, Typography, Tooltip, Button, List, ListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectAggregates, selectAggregationLevel, selectFilters, selectFrequentServiceProviders, setProviders } from 'storage/app/duck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { trackEvent } from 'storage/tracking/duck';
import theme from 'theme';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { isTablet } from 'utils/utils';


const parseDate = (date?: number) => {
  if (date) {
    const dateObj = new Date(date * 1000)
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
  } else {
    return ""
  }
}


interface TruncatedLabelProps {
  label?: string
  options: string[]
  onClick: () => void
}

const TruncatedLabel: FC<TruncatedLabelProps> = ({ label, options, onClick }) => {
  const title = options.join(", ")
  if (title.length > 20) {
    return <Tooltip title={title} placement='left'>
      <Typography sx={{ whiteSpace: "nowrap" }}>
        {label} <Typography component="span" sx={OverviewFilterLinkStyle} onClick={onClick}>
          {title.slice(0, 20)}...
        </Typography>
      </Typography>
    </Tooltip>
  } else {
    return <Typography sx={{ whiteSpace: "nowrap" }}>
      {label} <Typography component="span" sx={OverviewFilterLinkStyle} onClick={onClick}>
        {title}
      </Typography>
    </Typography>
  }
}


const OverviewFilterLinkStyle = {
  cursor: "pointer",
  '&:hover': {
    textDecoration: "underline",
  }
}

const Overview: FC = () => {
  const dispatch = useDispatch()
  const aggregates = useSelector(selectAggregates)
  const frequentServiceProviders = useSelector(selectFrequentServiceProviders)
  const filters = useSelector(selectFilters)
  const aggregationLevel = useSelector(selectAggregationLevel)
  const startDate = parseDate(filters.callDate.start)
  const endDate = parseDate(filters.callDate.end)
  const ipad = isTablet()

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const [isOverviewOpen, setIsOverviewOpen] = useState<boolean>(true)
  const [overviewTitle, setOverviewTitle] = useState<string>()
  const [overviewData, setOverviewData] = useState<WorkorderAggregate>()

  /**
 * Set nation aggregates if aggregation level is nation
 */
  useEffect(() => {
    if (aggregates && aggregates.length > 0) {
      const title = filters.club ? filters.club.length > 1 ? `Multi-club overview (${filters.club.length})` : `Club ${filters.club} overview` :
        filters.market ? filters.market.length > 1 ? `Multi-market overview (${filters.market.length})` : `Market ${filters.market} overview` :
          filters.region ? filters.region.length > 1 ? `Multi-region overview (${filters.region.length})` : `${filters.region} overview` :
            aggregationLevel === "Region" ? "National overview" :
              "Overview";
      setOverviewTitle(title)
      const aggregatedData = aggregates.reduce((acc: WorkorderAggregate, cur) => {
        return {
          ...cur,
          id: 0,
          name: title,
          total_workorders: acc.total_workorders ? acc.total_workorders + cur.total_workorders : cur.total_workorders,
          low_priority_work_orders: acc.low_priority_work_orders ? acc.low_priority_work_orders + cur.low_priority_work_orders : cur.low_priority_work_orders,
          medium_priority_work_orders: acc.medium_priority_work_orders ? acc.medium_priority_work_orders + cur.medium_priority_work_orders : cur.medium_priority_work_orders,
          high_priority_work_orders: acc.high_priority_work_orders ? acc.high_priority_work_orders + cur.high_priority_work_orders : cur.high_priority_work_orders,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          total_nte: (acc.total_nte ? (parseFloat(acc.total_nte as any) + parseFloat(cur.total_nte as any)).toFixed(2) : cur.total_nte) as number,
        }
      }, {} as WorkorderAggregate);
      setOverviewData(aggregatedData)
    } else {
      setOverviewData(undefined)
    }
  }, [aggregationLevel, aggregates])

  const handleOpenFilterMenu = (filterId: string) => {
    dispatch(trackEvent({ namespace: "Open filters", predicate: 'Overview', value: filterId.split('-').slice(1).join('-') }))
    const filterButton = document.getElementById("filters-button")
    if (filterButton) {
      filterButton.click()
      setTimeout(() => {
        const subFilterButton = document.getElementById(filterId)
        if (subFilterButton) {
          subFilterButton.click()
        }
      }, 650)
    }
  }

  const handleSelectProvider = (provider: string) => {
    dispatch(trackEvent({ namespace: "Select provider", predicate: 'Overview', value: provider }))
    dispatch(setProviders([provider]))
  }

  return (
    overviewData ? <Box
      id='aggregates-overview'
      sx={{
        position: 'absolute',
        zIndex: 50,
        right: "1rem",
        top: "1rem",
        minWidth: ipad ? "12rem" : "18rem",
        backgroundColor: 'background.paper',
        opacity: 0.9,
        p: ipad ? 1.5 : 2,
      }}
    >
      <Typography variant="h5" sx={{
        borderBottom: isOverviewOpen ? '1px solid' : undefined,
        pb: isOverviewOpen ? '0.5rem' : undefined,
        mb: isOverviewOpen ? '0.5rem' : undefined,
        lineHeight: ipad ? '1.25rem' : '1.5rem',
        fontSize: ipad ? '1.25rem' : undefined,
      }}>{overviewTitle}</Typography>
      {isOverviewOpen &&
        <>
          <Typography sx={{ whiteSpace: "nowrap" }}>
            From
            <Typography
              component="span"
              sx={OverviewFilterLinkStyle}
              onClick={() => handleOpenFilterMenu("filter-date-range")}
            > {startDate}</Typography> to
            <Typography
              component="span"
              sx={OverviewFilterLinkStyle}
              onClick={() => handleOpenFilterMenu("filter-date-range")}
            > {endDate}</Typography>
          </Typography>
          {filters.categories &&
            <TruncatedLabel label="Categories:" options={filters.categories} onClick={() => handleOpenFilterMenu("filter-categories")} />
          }
          {filters.status &&
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Status: <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-status")}>
                {filters.status.map((status, index, arr) =>
                  index === arr.length - 1 ? status : `${status}, `
                )}</Typography>
            </Typography>
          }
          {filters.trades &&
            <TruncatedLabel label="Trades:" options={filters.trades} onClick={() => handleOpenFilterMenu("filter-trades")} />
          }
          {filters.tradeGroups &&
            <TruncatedLabel label="Trade Groups:" options={filters.tradeGroups} onClick={() => handleOpenFilterMenu("filter-trade-groups")} />
          }
          {filters.providers &&
            <TruncatedLabel label="Providers:" options={filters.providers} onClick={() => handleOpenFilterMenu("filter-providers")} />
          }
          {filters.nte &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-nte")}>
                {filters.nte.name}
              </Typography>
            </Typography>
          }
          {filters.workingOnIt &&
            <TruncatedLabel label="Follow up:" options={[filters.workingOnIt.name as string]} onClick={() => handleOpenFilterMenu("filter-working-on-it")} />
          }
          {filters.escalated &&
            <TruncatedLabel label="Escalated:" options={[filters.escalated.name as string]} onClick={() => handleOpenFilterMenu("filter-escalated")} />
          }
          {filters.notOperationalOnly &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-advanced-filters")}>
                Only non-operational asset
              </Typography>
            </Typography>
          }
          {filters.potentialDuplicateOnly &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-advanced-filters")}>
                Only potential duplicates
              </Typography>
            </Typography>
          }
          {filters.missedETA &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-advanced-filters")}>
                Only scheduled date in the past
              </Typography>
            </Typography>
          }
          {filters.maintenanceRepairOnly &&
            <Typography sx={{ maxWidth: '16rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-advanced-filters")}>
                Displaying Maintenance and Repair
              </Typography>
            </Typography>
          }
          {filters.workingOnItUser && !filters.escalatedUser &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter")}>
                Only work orders I am working on
              </Typography>
            </Typography>
          }
          {!filters.workingOnItUser && filters.escalatedUser &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter")}>
                Only work orders I have escalated
              </Typography>
            </Typography>
          }
          {filters.workingOnItUser && filters.escalatedUser &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter")}>
                Only my work orders with &quot;follow up&quot; and &quot;escalated&quot;
              </Typography>
            </Typography>
          }
          {filters.noActivitySince &&
            <Typography sx={{ maxWidth: '15rem' }}>
              <Typography component="span" sx={OverviewFilterLinkStyle} onClick={() => handleOpenFilterMenu("filter-no-activity-since")}>
                No activity since {filters.noActivitySince.name}
              </Typography>
            </Typography>
          }
          {filters.equipmentTypes &&
            <TruncatedLabel label="Asset Type:" options={filters.equipmentTypes} onClick={() => handleOpenFilterMenu("filter-equipment-types")} />
          }
          {filters.extendedStatus &&
            <TruncatedLabel label="Extended Status:" options={filters.extendedStatus} onClick={() => handleOpenFilterMenu("filter-extended-status")} />
          }
          {filters.problemDescription &&
            <TruncatedLabel label="Problem Description:" options={filters.problemDescription} onClick={() => handleOpenFilterMenu("filter-problem-description")} />
          }
          <Typography variant="h6" sx={{ whiteSpace: "nowrap", borderTop: '1px solid', mt: '0.5rem', pt: '0.5rem', pb: '0.25rem' }}>
            Frequent Service Providers
          </Typography>
          <List disablePadding>
            {frequentServiceProviders?.map((provider, index) =>
              <ListItem key={index} sx={{ paddingY: 0.25, paddingX: 0.5 }}>
                {index + 1}.&nbsp;
                <TruncatedLabel
                  options={[provider.name]}
                  onClick={() => handleSelectProvider(provider.name)}
                />
                &nbsp;- {(provider.percentage * 100).toFixed(1)}%
              </ListItem>
            )}

          </List>
          <Stack direction="row" mt={1} alignItems="center">
            <AttachMoneyIcon style={{ fontSize: ipad ? '1.25rem' : 'auto' }} />
            <Typography sx={{ fontSize: ipad ? '1rem' : '1.25rem', mt: '0.1rem' }} >Total NTE: {formatDollarsK(Math.round(overviewData.total_nte / 1000) * 1000)}</Typography>
          </Stack>
        </>
      }
      <Tooltip
        title={isOverviewOpen ? "Hide overview" : "Show overview"}
        placement='left'
        open={isTooltipOpen}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        arrow
      >
        <Button
          variant='contained'
          onClick={() => { setIsOverviewOpen(!isOverviewOpen); setIsTooltipOpen(false) }}
          sx={{
            position: 'absolute',
            bottom: '-2.5rem',
            right: 0,
            height: '2.5rem',
            backgroundColor: theme.palette.background.light,
            color: 'white',
          }}
        >
          {isOverviewOpen ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
        </Button>
      </Tooltip>
    </Box> : null
  );
};

export default Overview;
