/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
// Libraries
import { SagaIterator } from '@redux-saga/types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  IActionChangePassword,
  IActionDeleteTeamView,
  IActionDeleteUserView,
  IActionLogin,
  IActionLogout,
  IActionResetPassword,
  IActionResetPasswordConfirm,
  IActionSaveTeamView,
  IActionSaveTeamViewAsDefault,
  IActionSaveUserView,
  IActionSetDefaultRegion,
  IAuthState,
  IUser,
  TeamView,
  UserView
} from 'storage/auth/models'
import { 
  Types,
  actions,
  setDefaultRegion,
  addUserView,
  removeUserView,
  setUserStats,
  updateRefreshTimeout,
  setLeaderboard,
  releaseExpiredPasswordLock,
  setNewFeatures,
  addTeamView,
  removeTeamView,
  setTeamViewAsDefault
} from 'storage/auth/duck';
import { 
  changePasswordService,
  deleteTeamViewService,
  deleteUserViewService,
  getLeaderboardService,
  getNewFeaturesService,
  getTeamViewsService,
  getUserStatsService,
  getUserViewsService,
  loginFromTokenService,
  loginUserService,
  logoutService,
  refreshTokenService,
  resetPasswordConfirmService,
  resetPasswordService,
  saveTeamViewAsDefaultService,
  saveTeamViewService,
  saveUserViewService,
  updateDefaultRegionService
} from 'services/auth';
import { APIError, errorHandler } from 'services/app';

const { failure, fulfill, request, success } = actions

export function* loginSaga(action: IActionLogin) {
  yield put(request())
  try {
    const userData: IUser = yield call(loginUserService, action.payload)
    if (userData) {
      if (userData.beta_user) {
        window.location.href = '/beta/' + window.location.search
      } else if (window.location.href.includes('/beta/')) {
        window.location.href = '/' + window.location.search
      }
      yield put(success({
        user: { 
          ...userData,
          default_region: userData.default_region ? userData.default_region : undefined,
          default_market: userData.default_market ? userData.default_market : undefined
        },
        loggedIn: true,
        error: undefined
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure({ message: error.response.data.detail }))
    } else if (error.response.data.email) {
      yield put(failure({ message: error.response.data.email[0] }))
    } else if (error.response.data.password) {
      yield put(failure({ message: error.response.data.password[0] }))
    } else {
      yield put(failure({ message: error.message }))
    }
  } finally {
    yield put(fulfill())
  }
}


export function* loginFromTokenSaga() {
  yield put(request())
  try {
    const userData: IUser = yield call(loginFromTokenService)
    if (userData) {
      if (userData.beta_user && !window.location.pathname.includes('/beta/')) {
        window.location.href = '/beta/' + window.location.search
      } else if (!userData.beta_user && window.location.href.includes('/beta/')) {
        window.location.href = '/' + window.location.search
      }
      yield put(success({
        user: {
          ...userData,
          default_region: userData.default_region ? userData.default_region : undefined,
          default_market: userData.default_market ? userData.default_market : undefined
        },
        loggedIn: true,
        error: undefined
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure({ message: error.response.data.detail }))
    } else if (error.response.data.email) {
      yield put(failure({ message: error.response.data.email[0] }))
    } else if (error.response.data.password) {
      yield put(failure({ message: error.response.data.password[0] }))
    } else {
      yield put(failure({ message: error.message }))
    }
  } finally {
    yield put(fulfill())
  }
}


export function* logoutSaga(action: IActionLogout) {
  yield put(request())
  try {
    const loggedOut: boolean = yield call(logoutService)
    if (loggedOut) {
      yield put(success({
        user: undefined,
        loggedIn: false,
        loggedOut: true,
        error: undefined
      }))
    }
    if (action.payload?.isTimeout) {
      yield put(failure({ message: 'Session has expired' }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}

export function* resetPasswordSaga(action: IActionResetPassword) {
  yield put(request())
  try {
    const response: { [key: string]: any } = yield call(resetPasswordService, action.payload)
    if (response) {
      yield put(success({
        error: undefined,
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure({ message: error.response.data.detail }))
    } else if (error.response.data.email) {
      yield put(failure({ message: error.response.data.email[0] }))
    } else {
      yield put(failure({ message: error.message }))
    }
  } finally {
    yield put(fulfill())
  }
}

export function* resetPasswordConfirmSaga(action: IActionResetPasswordConfirm) {
  yield put(request())
  try {
    const response: { [key: string]: any } = yield call(resetPasswordConfirmService, action.payload)
    if (response) {
      yield put(success({
        error: undefined,
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure({ message: error.response.data.detail }))
    } else if (error.response.data.password) {
      yield put(failure({ message: error.response.data.password[0] }))
    } else {
      yield put(failure({ message: error.message }))
    }
  } finally {
    yield put(fulfill())
  }
}


export function* changePasswordSaga(action: IActionChangePassword) {
  yield put(request())
  try {
    const response: { [key: string]: any } = yield call(changePasswordService, action.payload)
    if (response) {
      yield put(releaseExpiredPasswordLock())
      yield put(success({
        error: undefined,
      }))
    }
  } catch (error: any) {
    if (error?.response.data.detail) {
      yield put(failure({ message: error.response.data.detail }))
    } else if (error.response.data.old_password) {
      yield put(failure({ message: error.response.data.old_password[0] }))
    } else if (error.response.data.new_password) {
      yield put(failure({ message: error.response.data.new_password[0] }))
    } else {
      yield put(failure({ message: error.message }))
    }
  } finally {
    yield put(fulfill())
  }
}


export function* refreshTokenSaga() {
  yield put(request())
  try {
    const user: IUser = yield call(refreshTokenService)
    if (user) {
      if (user.beta_user && !window.location.pathname.includes('/beta/')) {
        window.location.href = '/beta/' + window.location.search
      } else if (!user.beta_user && window.location.href.includes('/beta/')) {
        window.location.href = '/' + window.location.search
      }
      yield put(updateRefreshTimeout({ refresh_token: user.refresh_token, exp: user.exp, iat: user.iat }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* updateDefaultRegionSaga(action: IActionSetDefaultRegion) {
  yield put(request())
  try {
    const updated: boolean = yield call(updateDefaultRegionService, action.payload)
    if (updated) {
      yield put(setDefaultRegion(action.payload))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* getUserViewsSaga() {
  yield put(request())
  try {
    const views: UserView[] = yield call(getUserViewsService)
    if (views) {
      yield put(success({
        views,
        error: undefined,
      }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* saveUserViewSaga(action: IActionSaveUserView) {
  yield put(request())
  try {
    const newView: UserView = yield call(saveUserViewService, action.payload)
    if (newView) {
      yield put(success({
        error: undefined,
      }))
      yield put(addUserView({
        ...newView,
      }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* deleteUserViewSaga(action: IActionDeleteUserView) {
  yield put(request())
  try {
    const deleted: boolean = yield call(deleteUserViewService, action.payload)
    if (deleted) {
      yield put(success({
        error: undefined,
      }))
      yield put(removeUserView(action.payload))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* getTeamViewsSaga() {
  yield put(request())
  try {
    const teamViews: TeamView[] = yield call(getTeamViewsService)
    if (teamViews) {
      yield put(success({
        teamViews,
        error: undefined,
      }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* saveTeamViewSaga(action: IActionSaveTeamView) {
  yield put(request())
  try {
    const newView: TeamView = yield call(saveTeamViewService, action.payload)
    if (newView) {
      yield put(success({
        error: undefined,
      }))
      yield put(addTeamView({
        ...newView,
      }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* deleteTeamViewSaga(action: IActionDeleteTeamView) {
  yield put(request())
  try {
    const deleted: boolean = yield call(deleteTeamViewService, action.payload)
    if (deleted) {
      yield put(success({
        error: undefined,
      }))
      yield put(removeTeamView(action.payload))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* saveTeamViewAsDefaultSaga(action: IActionSaveTeamViewAsDefault) {
  yield put(request())
  try {
    const updated: boolean = yield call(saveTeamViewAsDefaultService, action.payload)
    if (updated) {
      yield put(success({
        error: undefined,
      }))
      yield put(setTeamViewAsDefault(action.payload))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* getUserStatsSaga() {
  yield put(request())
  try {
    const stats: IUser['stats'] = yield call(getUserStatsService)
    if (stats) {
      yield put(setUserStats(stats))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


export function* getLeaderboardSaga() {
  yield put(request())
  try {
    const userRank: IAuthState["leaderboards"] = yield call(getLeaderboardService)
    if (userRank) {
      yield put(setLeaderboard(userRank))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}

export function* getNewFeaturesSaga() {
  yield put(request())
  try {
    const newFeatures: IUser["new_features"] = yield call(getNewFeaturesService)
    if (newFeatures) {
      yield put(setNewFeatures(newFeatures))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}

export default function* authSagas(): SagaIterator {
  yield all([
    takeLatest(Types.LOGIN, loginSaga),
    takeLatest(Types.LOGIN_FROM_TOKEN, loginFromTokenSaga),
    takeLatest(Types.LOGOUT, logoutSaga),
    takeLatest(Types.REFRESH_TOKEN, refreshTokenSaga),
    takeLatest(Types.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(Types.RESET_PASSWORD_CONFIRM, resetPasswordConfirmSaga),
    takeLatest(Types.CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(Types.UPDATE_DEFAULT_REGION, updateDefaultRegionSaga),
    takeLatest(Types.GET_USER_VIEWS, getUserViewsSaga),
    takeLatest(Types.SAVE_USER_VIEW, saveUserViewSaga),
    takeLatest(Types.DELETE_USER_VIEW, deleteUserViewSaga),
    takeLatest(Types.GET_TEAM_VIEWS, getTeamViewsSaga),
    takeLatest(Types.SAVE_TEAM_VIEW, saveTeamViewSaga),
    takeLatest(Types.DELETE_TEAM_VIEW, deleteTeamViewSaga),
    takeLatest(Types.SAVE_TEAM_VIEW_AS_DEFAULT, saveTeamViewAsDefaultSaga),
    takeLatest(Types.GET_USER_STATS, getUserStatsSaga),
    takeLatest(Types.GET_LEADERBOARD, getLeaderboardSaga),
    takeLatest(Types.GET_NEW_FEATURES, getNewFeaturesSaga),
  ])
}
