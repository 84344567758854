import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { getAlerts, acknowledge, setHighlighted, getNextAlerts, } from 'storage/alerts/duck';
import { Alert } from 'storage/alerts/models';
import { Badge, Box, Button, Card, CardContent, Modal, Portal, Stack, Tooltip, Typography } from '@mui/material';
import { GiRadarSweep } from "react-icons/gi";
import CloseIcon from '@mui/icons-material/Close';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import theme from 'theme';
import './Alerts.css';
import { clearFilters, setClub, setMarket, setRegion, updateFilters } from 'storage/app/duck';
import { trackEvent } from 'storage/tracking/duck';
import Spinner from 'components/Spinner';
import { isScrolledIntoView } from 'utils/utils';


interface AlertBoxProps {
  alert: Alert;
  inStackedView?: boolean;
  closeModal?: () => void;
}

const AlertBox: FC<AlertBoxProps> = (props: AlertBoxProps) => {
  const { alert, inStackedView, closeModal } = props;
  const dispatch = useDispatch();
  const predicate = inStackedView ? 'Stacked alerts' : 'Alerts modal';

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!alert.acknowledged && alert.highlighted == undefined && inStackedView) {
      dispatch(setHighlighted({ alertId: alert.id, highlighted: true }));
      timeout = setTimeout(() => {
        dispatch(setHighlighted({ alertId: alert.id, highlighted: false }));
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [])

  const handleReviewWorkOrders = () => {
    if (alert.workorders) {
      dispatch(clearFilters())
      dispatch(updateFilters({
        workorders: alert.workorders,
        trades: alert.trades,
        maintenanceRepairOnly: true,
      }));
      if (alert.club) {
        dispatch(setClub(alert.club));
      } else if (alert.market) {
        dispatch(setMarket(alert.market));
      } else if (alert.region) {
        dispatch(setRegion(alert.region));
      }
    }
    dispatch(trackEvent({ namespace: 'Review work orders from alert', predicate: predicate, payload: { alertId: alert.id } }))
    closeModal && closeModal();
  }

  const handleAcknowledge = () => {
    dispatch(acknowledge({ alertId: alert.id }));
    dispatch(trackEvent({ namespace: 'Acknowledge alert', predicate: predicate, payload: { alertId: alert.id } }))
  }

  const messageFirstThreshold = alert.workorders ?
    `Currently ${alert.workorders.length} open work orders for "${alert.trades?.join(", ")}"` :
    ''
  const messageSecondThreshold = alert.workorders ?
    `More than ${alert.secondRuleThreshold} work orders for "${alert.trades?.join('", "')}"`
    : ''

  const limitMessageFirstThreshold = alert.workorders ?
    `(${alert.workorders.length - alert.targetMaximum} more than target maximum)` :
    ''
  const limitMessageSecondThreshold = alert.workorders ?
    `(${alert.workorders.length} work orders, which is ${alert.workorders.length - alert.targetMaximum} more than target maximum)` :
    ''

  const isRegionalManager = alert.ruleName.includes('Club region')
  const title = `${alert.updatedAt.toLocaleString()} ${isRegionalManager ? ` / Market ${alert.market}` : ''} / Club ${alert.club}`
  const message = alert.isSecondThreshold ? messageSecondThreshold : messageFirstThreshold
  const limitMessage = alert.isSecondThreshold ? limitMessageSecondThreshold : limitMessageFirstThreshold

  return (
    <Box
      className={`alert-item${alert.highlighted && !alert.acknowledged ? ' highlighted' : ''}`}
      sx={{
        backgroundColor: alert.acknowledged ? theme.palette.background.default : undefined,
        color: alert.acknowledged ? theme.palette.text.primary : undefined,
      }}
    >
      <Stack width="100%" direction="row" justifyContent="flex-start" alignItems="center" gap={1} padding="0.1rem">
        {!inStackedView ?
          <>
            <Stack>
              <Stack direction="row" gap={1}>
                <Typography><strong>{title}</strong></Typography>
              </Stack>
              <Stack >
                {alert.workorders && <>
                  <Typography>{message} {limitMessage}</Typography>
                </>}
              </Stack>
            </Stack>
          </> :
          <>
            <Typography><strong>{title}:</strong></Typography>
            {alert.workorders && <>
              <Typography>{message} {limitMessage}</Typography>
            </>
            }
          </>
        }
      </Stack>
      <Stack direction="row" gap={1} paddingRight={inStackedView ? undefined : '0.5rem'}>
        <Tooltip title="Review work orders" placement='left' arrow>
          <Button
            size="small"
            variant="contained"
            onClick={handleReviewWorkOrders}
            sx={{
              minWidth: '2rem',
            }}
          >
            <GpsFixedIcon />
          </Button>
        </Tooltip>
        {!alert.acknowledged &&
          <Button
            size="small"
            variant="contained"
            onClick={handleAcknowledge}
            startIcon={<CheckIcon />}
          >
            <Typography fontSize="0.8rem" lineHeight="0.8rem">
              Acknowledge
            </Typography>
          </Button>
        }
      </Stack>
    </Box>
  )
}

interface RadarTargetProps {
  className: string
  detected: boolean
}

const RadarTarget = (props: RadarTargetProps) => {
  const { className, detected } = props
  const [hasBeenDetected, setHasBeenDetected] = useState(false);

  useEffect(() => {
    if (detected) {
      setHasBeenDetected(true);
    }
  }, [detected])

  return (!detected && hasBeenDetected ?
    <span className={`target ${className}`} /> :
    null
  )
}

interface RadarProps {
  totalAlerts: number
  onClick: () => void
}

const Radar = (props: RadarProps) => {
  const { totalAlerts, onClick } = props
  const intervalRef = useRef<number | undefined>(undefined);
  const [rotation, setRotation] = useState(0);
  const hasAlerts = totalAlerts > 0

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
    if (hasAlerts) {
      intervalRef.current = window.setInterval(() => {
        setRotation(rotation => rotation + 15);
      }, 100);
    } else {
      intervalRef.current = window.setInterval(() => {
        setRotation(rotation => rotation + 15);
      }, 400);
    }

    return () => clearInterval(intervalRef.current);
  }, [hasAlerts])

  return (
    <Button
      className={`alert-icon${hasAlerts ? ' highlighted' : ''}`}
      onClick={onClick}
    >
      <GiRadarSweep size="2rem" style={{ transform: `rotate(${rotation}deg)` }} />
      {hasAlerts && <>
        {totalAlerts >= 1 && <RadarTarget className="target-1" detected={(rotation % 360 === 120)} />}
        {totalAlerts >= 2 && <RadarTarget className="target-2" detected={(rotation % 360 === 225)} />}
        {totalAlerts >= 3 && <RadarTarget className="target-3" detected={(rotation % 360 === 345)} />}
        <Badge badgeContent={totalAlerts} color="error" sx={{ position: 'absolute', top: '0.75rem', right: '-0.33rem' }} />
      </>
      }
    </Button>
  )
}


interface IHeightControllerProps {
  parentRef: React.RefObject<HTMLDivElement>;
}

const HeightController: FC<IHeightControllerProps> = (props: IHeightControllerProps) => {
  const { parentRef } = props;
  const dispatch = useDispatch();
  const map = useSelector((state: IStore) => state.app.map);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleHeight = () => {
    if (parentRef.current) {
      if (isOpen) {
        parentRef.current.style.maxHeight = '8rem';
        setIsOpen(false);
        dispatch(trackEvent({ namespace: 'Collapse stacked alerts', predicate: 'Stacked alerts' }))
      } else {
        parentRef.current.style.maxHeight = '20rem';
        setIsOpen(true);
        dispatch(trackEvent({ namespace: 'Expand stacked alerts', predicate: 'Stacked alerts' }))
      }
      if (map) {
        window.setTimeout(() => {
          map.resize();
        }, 50);
      }
    }
  }

  return (
    <Box position="absolute" bottom="0" left="50%" sx={{ zIndex: 100, transform: 'translate(-50%, 100%)' }}>
      <Button
        variant="contained"
        size="small"
        onClick={handleToggleHeight}
        sx={{
          backgroundColor: theme.palette.background.lighter,
          color: theme.palette.text.primary,
          minWidth: '3.5rem',
        }}
      >
        {isOpen ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
      </Button>
    </Box>
  )
}


const Alerts: FC = () => {
  const dispatch = useDispatch();
  const { alerts, totalAlerts, hasNextPage, pageNumber, loading } = useSelector((state: IStore) => state.alerts)
  const stackedAlertsRef = useRef<HTMLDivElement>(null);
  const map = useSelector((state: IStore) => state.app.map);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    dispatch(getAlerts());
    const interval = window.setInterval(() => {
      dispatch(getAlerts());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (alerts.length < totalAlerts && hasNextPage) {
      window.setTimeout(() => {
        dispatch(getNextAlerts({ page: pageNumber + 1 }))
      }, 1500);
    }
  }, [alerts.length, totalAlerts, hasNextPage]);

  useEffect(() => {
    /* Update map size when not acknowledged alerts change */
    if (map) {
      window.setTimeout(() => {
        map.resize();
      }, 50);
    }
  }, [totalAlerts])

  const handleOpenModal = () => {
    setIsModalOpen(true);
    dispatch(trackEvent({
      namespace: 'Open alerts modal',
      predicate: 'Navbar',
      payload: { totalAlerts: totalAlerts }
    }))
  }

  const handleCloseModal = (predicate: string) => {
    setIsModalOpen(false);
    dispatch(trackEvent({
      namespace: 'Close alerts modal',
      predicate: predicate,
      payload: { totalAlerts: totalAlerts }
    }))
  }

  const handleScroll = () => {
    if (isScrolledIntoView(document.querySelector("#load-more-alerts")) && !loading && hasNextPage) {
      dispatch(getNextAlerts({ page: pageNumber + 1 }))
    }
  }

  return (
    <>
      {totalAlerts > 0 && <Radar totalAlerts={totalAlerts} onClick={handleOpenModal} />}
      <Portal container={() => document.querySelector("#alerts-overview")}>
        <Stack ref={stackedAlertsRef} maxHeight="8rem" sx={{ position: 'relative' }}>
          {totalAlerts >= 3 &&
            <HeightController parentRef={stackedAlertsRef} />
          }
          <Stack sx={{ overflowY: 'auto' }}>
            {alerts?.filter((alert: Alert) => !alert.acknowledged).map((alert: Alert) => (
              <AlertBox key={alert.id} alert={alert} inStackedView />
            ))}
          </Stack>
        </Stack>
      </Portal>
      <Modal open={isModalOpen} onClose={() => handleCloseModal('Modal')}>
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '90%',
          width: '100%',
          minWidth: '32.5rem',
        }}>
          <Button
            variant='text'
            size='small'
            onClick={() => handleCloseModal('Close button')}
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
          >
            <CloseIcon />
          </Button>
          <CardContent >
            <Stack direction="row" justifyContent="flex-start" gap={1} sx={{ mb: '1rem' }}>
              <Typography variant="h4">
                Alerts ({totalAlerts})
              </Typography>
              <Stack width="4rem" position="relative">
                <Spinner isVisible={loading} />
              </Stack>
            </Stack>
            <Box sx={{ overflowY: 'auto', maxHeight: '80svh' }} onScroll={handleScroll}>
              {alerts.map((alert) =>
                <AlertBox key={alert.id} alert={alert} closeModal={() => handleCloseModal('Review work orders')} />
              )}
              {totalAlerts === 0 &&
                <Typography>You don&apos;t have new alerts. Battleship is scaning for new ones in the background.</Typography>
              }
              {hasNextPage &&
                <Stack id="load-more-alerts" width="100%" height="3rem" marginY="0.5rem" position="relative">
                  <Spinner isVisible={loading} />
                </Stack>
              }
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </>

  )
}

export default Alerts