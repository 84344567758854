import React, { FC } from 'react';
import { WorkorderAggregate } from 'storage/app/models';
import { formatDollarsK, formatNumber } from 'utils/utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface DonutTooltipProps {
  className?: string,
  data: WorkorderAggregate,
  title: string,
  x?: number,
  y?: number,
}

const DonutTooltip: FC<DonutTooltipProps> = (props) => {

  return (
    <Box
      className={props.className}
      sx={{
        position: 'fixed',
        zIndex: 200,
        left: props.x,
        top: props.y,
        transform: 'translate(-50%, -110%)',
        minWidth: "14rem",
        backgroundColor: 'background.paper',
        opacity: 0.9,
        p: 2
      }}
    >
      <Typography variant="h5" sx={{ paddingBottom: '0.5rem', marginBottom: '0.25rem', borderBottom: '1px solid white', }}>{props.title}</Typography>
      <Typography variant="h6" sx={{ whiteSpace: "nowrap", paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>{formatNumber(props.data.total_workorders)} work orders</Typography>
      <Stack direction="row" spacing={1}>
        <Box sx={{ backgroundColor: 'scale.high', height: 20, width: 20 }}></Box>
        <Typography>{formatNumber(props.data.high_priority_work_orders)}</Typography>
        <Typography sx={{ whiteSpace: "nowrap" }}>high BSI</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Box sx={{ backgroundColor: 'scale.medium', height: 20, width: 20 }}></Box>
        <Typography>{formatNumber(props.data.medium_priority_work_orders)}</Typography>
        <Typography sx={{ whiteSpace: "nowrap" }}>medium BSI</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Box sx={{ backgroundColor: 'scale.low', height: 20, width: 20 }}></Box>
        <Typography>{formatNumber(props.data.low_priority_work_orders)}</Typography>
        <Typography sx={{ whiteSpace: "nowrap" }}>low BSI</Typography>
      </Stack>
      <Stack direction="row" mt={1} alignItems="center">
        <AttachMoneyIcon />
        <Typography sx={{ fontSize: '1.25rem', mt: '0.1rem' }} >Total NTE: {formatDollarsK(Math.round(props.data.total_nte / 1000) * 1000)}</Typography>
      </Stack>
    </Box>
  );
};

export default DonutTooltip;
