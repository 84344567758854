

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { Card, CardContent, Typography, Modal, Button, Stack, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import { updateDefaultRegion } from 'storage/auth/duck';
import { IAuthState } from 'storage/auth/models';
import FilterMenu, { StringItem } from "../FilterBar/FilterMenu";
import { selectRegionMarkets, selectRegionOptions, setRegion, setMarket } from 'storage/app/duck';
import { IAppState } from 'storage/app/models';
import { safeGetObjectValue } from 'utils/utils';


interface DefaultRegionModalProps {
  open: boolean;
  closeModal: () => void;
}

const DefaultRegionModal: FC<DefaultRegionModalProps> = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector<IStore, IAuthState>((state) => state.auth);
  const regionOptions: StringItem[] = useSelector<IStore, string[]>(selectRegionOptions).map((region) => ({ name: region, value: region }));
  const [selectedRegion, setSelectedRegion] = useState<string[]>()
  const [regionMenuAnchorEl, setRegionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const regionMarkets = useSelector<IStore, IAppState['regionMarkets']>(selectRegionMarkets);
  const marketOptions = regionMarkets && (
    selectedRegion && selectedRegion?.length > 0 ?
      selectedRegion.map((region) => safeGetObjectValue(regionMarkets, region))
        .flat().map((market) => ({ name: market, value: market }))
        .sort((a, b) => a.value - b.value) :
      Object.entries(regionMarkets)
        .map((obj) => obj[1].map((market) => ({ name: market, value: market })))
        .flat()
        .sort((a, b) => a.value - b.value)
  ) || [];
  const [selectedMarket, setSelectedMarket] = useState<number[]>()
  const [marketMenuAnchorEl, setMarketMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (user) {
      setSelectedRegion(user.default_region)
      setSelectedMarket(user.default_market)
      if (!isFirstRender) {
        dispatch(setRegion(user.default_region ? user.default_region : undefined))
        dispatch(setMarket(user.default_market ? user.default_market : undefined))
      } else {
        setIsFirstRender(false)
      }
    }
  }, [user?.default_region, user?.default_market])


  const handleToggleRegionMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (regionMenuAnchorEl) {
      setRegionMenuAnchorEl(null);
    } else {
      setRegionMenuAnchorEl(event.currentTarget);
    }
  }

  const handleToggleMarketMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (marketMenuAnchorEl) {
      setMarketMenuAnchorEl(null);
    } else {
      setMarketMenuAnchorEl(event.currentTarget);
    }
  }

  const handleUpdateDefaultGeography = (region: string[] | null, market: number[] | null) => {
    dispatch(updateDefaultRegion({ default_region: region, default_market: market }));
    props.closeModal()
  }

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{
        position: 'relative',
        minWidth: '38rem',
        overflow: 'visible',
      }}>
        <Button
          variant='text'
          size='small'
          onClick={props.closeModal}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent sx={{
          paddingBottom: '0.5rem !important'
        }}>
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            Change your default geography
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mb: '1rem' }} justifyContent='space-between'>
            <FilterMenu
              id="default-region"
              anchorEl={regionMenuAnchorEl}
              value={selectedRegion}
              multiple
              clearable
              options={regionOptions}
              label={(
                selectedRegion && selectedRegion.length > 0 ?
                  <>
                    Default regions
                    <Chip
                      label={selectedRegion ? selectedRegion.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: selectedRegion ? theme.palette.background.highlight : undefined, }}
                    />
                  </> :
                  'All regions'
              )}
              onClick={handleToggleRegionMenu}
              onClose={() => setRegionMenuAnchorEl(null)}
              onSelect={(options: string[]) => options?.length > 0 ? setSelectedRegion(options) : setSelectedRegion(undefined)}
            />
            <FilterMenu
              id="default-market"
              anchorEl={marketMenuAnchorEl}
              value={selectedMarket}
              multiple
              clearable
              options={marketOptions}
              label={(
                selectedMarket && selectedMarket.length > 0 ?
                  <>
                    Default markets
                    <Chip
                      label={selectedMarket ? selectedMarket.length : 'Any'}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, backgroundColor: selectedMarket ? theme.palette.background.highlight : undefined, }}
                    />
                  </> :
                  'All markets'
              )}
              onClick={handleToggleMarketMenu}
              onClose={() => setMarketMenuAnchorEl(null)}
              onSelect={(options: number[]) => options?.length > 0 ? setSelectedMarket(options) : setSelectedMarket(undefined)}
            />
            <Button variant='contained' onClick={() =>
              handleUpdateDefaultGeography(
                selectedRegion ? selectedRegion : null,
                selectedMarket ? selectedMarket : null
              )
            }>
              Confirm
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DefaultRegionModal;



