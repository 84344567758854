import React, { FC, useEffect, useRef, useState } from 'react';
import './Map.css';
import maplibregl, { DataDrivenPropertyValueSpecification, MapGeoJSONFeature, MapLayerMouseEvent, MapMouseEvent } from 'maplibre-gl';
import mapStyleStamen from '../../assets/stamen-style.json';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { AggregationLevel, IAppState, WorkorderAggregate } from 'storage/app/models';
import { defaultCenter, defaultZoom, selectAggregationLevel, setClub, setHoveredFill, setIsMapLoaded, setLat, setLng, setMap, setMarket, setRegion, setShowCrosshair, setZoom } from 'storage/app/duck';
import { getMarketBoundsFromFeatures, getRegionBoundsById, isEmpty, isNotEmpty, safeGetObjectValue } from 'utils/utils';
import DonutLayer from './DonutLayer';
import Spinner from "components/Spinner";
import { Alert, Box, Button, Portal } from "@mui/material";
import CustomCursor from "components/CustomCursor";
import LastUpdate from './LastUpdate';
import Overview from 'components/Overview';
import UserStats from '../UserStats';
import theme from 'theme';
import { AnyAction } from 'redux';
import Feedback from 'components/Feedback/Feedback';
import ByMakepath from './ByMakepath';

const colorByRegion: DataDrivenPropertyValueSpecification<string> = [
  'case',
  ['==', ['get', 'region'], 'Western'],
  '#6E4A4A',
  ['==', ['get', 'region'], 'North Central'],
  '#4B5962',
  ['==', ['get', 'region'], 'Central'],
  '#566444',
  ['==', ['get', 'region'], 'South Central'],
  '#785A42',
  ['==', ['get', 'region'], 'Mississippi Valley'],
  '#817548',
  ['==', ['get', 'region'], 'Northeast'],
  '#555243',
  ['==', ['get', 'region'], 'Southeast'],
  '#5A4A5E',
  '#777'
];

const Map: FC = () => {
  const dispatch = useDispatch();
  const app = useSelector<IStore, IAppState>(state => state.app);
  const mapRef = useRef<maplibregl.Map>();
  const minZoom = 3;
  const maxZoom = 12;
  const hoveredId = useRef<string | number>();
  const showCrosshairRef = useRef<IAppState["showCrosshair"]>(app.showCrosshair)
  const aggregationLevel = useSelector<IStore>(selectAggregationLevel) as AggregationLevel;
  const aggregatesRef = useRef<WorkorderAggregate[] | undefined>(app.data.aggregates);
  const hoveredFillRef = useRef<WorkorderAggregate | undefined>(app.hoveredFill);
  const multipleFeaturesRef = useRef<MapGeoJSONFeature[]>([]);
  const isCtrlPressedRef = useRef<boolean>(false);
  const isDrawingSelectionRef = useRef<boolean>(false);
  const [isDrawingSelection, setIsDrawingSelection] = useState(false);
  const [emptyAggregates, setEmptyAggregates] = useState(false);

  /**
   * Update crosshair reference state
   */
  useEffect(() => {
    showCrosshairRef.current = app.showCrosshair;
  }, [app.showCrosshair])

  /**
   * Update aggregates reference state
   */
  useEffect(() => {
    aggregatesRef.current = app.data.aggregates;
  }, [app.data.aggregates])

  /**
   * Initialize map layers and event listeners
   */
  useEffect(() => {
    if (!mapRef.current && app.initialized) {
      const map = new maplibregl.Map({
        container: 'map',
        style: mapStyleStamen as maplibregl.StyleSpecification,
        center: [app.lng, app.lat],
        zoom: app.zoom,
        minZoom: minZoom,
        maxZoom: maxZoom,
        minPitch: 0,
        maxPitch: 0,
        dragRotate: false,
        preserveDrawingBuffer: true,
        transformRequest: (url, resourceType) => {
          if (resourceType === 'Tile' && url.includes('tiles/stamen')) {
            return {
              url: window.location.origin.includes("localhost:3000") ?
                `https://tiles.stadiamaps.com${url}` :
                `${window.location.origin}${url}`,
            }
          } else {
            return { url: url }
          }
        }
      });
      map.addControl(new maplibregl.NavigationControl({ showCompass: false }), 'bottom-right');
      mapRef.current = map;
      dispatch(setMap(map));

      map.on('load', () => {
        map.addSource('market-boundaries', {
          data: app.geojson?.markets,
          type: 'geojson',
          generateId: true
        });

        map.addLayer({
          'id': 'market-fills',
          'type': 'fill',
          'source': 'market-boundaries',
          'paint': {
            'fill-color': colorByRegion,
            'fill-opacity': [
              'case',
              ['any', ['boolean', ['feature-state', 'multiselect'], false], ['boolean', ['feature-state', 'hover'], false]],
              0.8,
              0.5
            ]
          },
        });

        map.addLayer({
          'id': 'market-lines',
          'type': 'line',
          'source': 'market-boundaries',
          'paint': {
            'line-color': theme.palette.background.default,
            'line-width': 2,
          },
        });

        map.addLayer({
          'id': 'market-lines-highlight',
          'type': 'line',
          'source': 'market-boundaries',
          'paint': {
            'line-color': theme.palette.background.highlight,
            'line-width': 3,
            'line-opacity': [
              'case',
              ['boolean', ['feature-state', 'multiselect'], false],
              1,
              0
            ],
          },
        });

        map.addSource('region-boundaries', {
          data: app.geojson?.regions,
          type: 'geojson',
          generateId: true
        });

        map.addLayer({
          'id': 'region-fills',
          'type': 'fill',
          'source': 'region-boundaries',
          'paint': {
            'fill-color': colorByRegion,
            'fill-opacity': [
              'case',
              ['any', ['boolean', ['feature-state', 'multiselect'], false], ['boolean', ['feature-state', 'hover'], false]],
              0.8,
              0.5
            ]
          },
        });

        map.addLayer({
          'id': 'region-lines',
          'type': 'line',
          'source': 'region-boundaries',
          'paint': {
            'line-color': theme.palette.background.default,
            'line-width': 1,
          },
        });

        map.addLayer({
          'id': 'region-lines-highlight',
          'type': 'line',
          'source': 'region-boundaries',
          'paint': {
            'line-color': theme.palette.background.highlight,
            'line-width': 3,
            'line-opacity': [
              'case',
              ['boolean', ['feature-state', 'multiselect'], false],
              1,
              0
            ],
          },
        });

        dispatch(setIsMapLoaded(true));
      });

      map.on('styledata', setGeojsonLayerVisibility);

      map.on('click', 'region-fills', handleRegionClick);

      map.on('mousemove', 'region-fills', (e) => {
        if (e.features && e.features.length > 0) {
          if (hoveredId.current !== undefined) {
            map.setFeatureState({ source: 'region-boundaries', id: hoveredId.current }, { hover: false });
          }
          hoveredId.current = e.features[0].id;
          map.setFeatureState({ source: 'region-boundaries', id: hoveredId.current }, { hover: true });
          if (!showCrosshairRef.current) {
            dispatch(setShowCrosshair(true));
          }
          const hoveredFeature = e.features[0];
          const hoveredFill = aggregatesRef.current?.find(agg => agg.name === hoveredFeature.properties?.region)
          if (hoveredFill && !hoveredFillRef.current || hoveredFillRef.current?.id !== hoveredFill?.id) {
            hoveredFillRef.current = hoveredFill
            dispatch(setHoveredFill(hoveredFillRef.current))
          }
        }
      });

      map.on('mouseleave', 'region-fills', () => handleMouseLeaveFill('region-boundaries'));
      map.on('mouseleave', 'market-fills', () => handleMouseLeaveFill('market-boundaries'));
      map.on('dragend', () => setMapView());
      map.on('zoomend', () => setMapView());
    }
  }, [app.initialized]);

  /**
   * Register events that need to be aware of dynamic state changes.
   * Clean up events on dismount.
   */
  useEffect(() => {
    if (mapRef.current) {
      if (app.filters.club) {
        mapRef.current.on('click', goUpAggregationLevel);
        mapRef.current.off('click', 'market-fills', handleMarketClick);
        mapRef.current.on('click', 'market-fills', goUpAggregationLevel);
        mapRef.current.on('mousemove', 'market-fills', handleMarketMousemove);
      } else {
        mapRef.current.on('click', goUpAggregationLevel);
        mapRef.current.off('click', 'market-fills', goUpAggregationLevel);
        mapRef.current.on('click', 'market-fills', handleMarketClick);
        mapRef.current.on('mousemove', 'market-fills', handleMarketMousemove);
      }

    }
    return (() => {
      if (mapRef.current) {
        mapRef.current.off('click', goUpAggregationLevel);
        mapRef.current.off('click', 'market-fills', goUpAggregationLevel);
        mapRef.current.off('click', 'market-fills', handleMarketClick);
        mapRef.current.off('mousemove', 'market-fills', handleMarketMousemove);
      }
    })
  }, [app.initialized, app.filters]);

  /**
   * Updates geojson layer visibility based on aggregation level
   */
  useEffect(() => {
    setGeojsonLayerVisibility();
  }, [app.filters.region, app.filters.market, app.filters.club]);

  /**
   * Zoom to market clubs bounding box when selecting a market
   */
  useEffect(() => {
    if (app.isMapLoaded) {
      handleFitToClubs();
    }
  }, [app.data.aggregates, app.isMapLoaded]);

  useEffect(() => {
    isDrawingSelectionRef.current = isDrawingSelection
  }, [isDrawingSelection]);

  /**
   * Display error message if aggregates are empty
   */
  useEffect(() => {
    if (app.data.aggregates && app.data.aggregates.length === 0 && app.initialized && !app.loading && !app.loadingAggregates) {
      setEmptyAggregates(true);
    } else {
      setEmptyAggregates(false);
    }
  }, [app.data.aggregates, app.initialized, app.loading, app.loadingAggregates])

  const handleMouseLeaveFill = (source: string) => {
    if (mapRef.current) {
      if (hoveredId.current !== undefined) {
        mapRef.current.setFeatureState({ source: source, id: hoveredId.current }, { hover: false });
      }
      hoveredId.current = undefined;
      if (showCrosshairRef.current) {
        dispatch(setShowCrosshair(false));
      }
      if (hoveredFillRef.current) {
        hoveredFillRef.current = undefined
        dispatch(setHoveredFill(undefined))
      }
    }
  }

  const goUpAggregationLevel = (e: MapMouseEvent) => {
    if (mapRef.current && !e.originalEvent.ctrlKey) {
      const map = mapRef.current;
      if (map.queryRenderedFeatures(e.point).length === 1) {
        if (isNotEmpty(app.filters.club)) {
          dispatch(setClub(undefined));
        }
      } else if (map.queryRenderedFeatures(e.point).length === 0) {
        if (isNotEmpty(app.filters.market)) {
          dispatch(setMarket(undefined));
        } else if (isNotEmpty(app.filters.region)) {
          dispatch(setRegion(undefined));
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectMultipleFeatures = (clickedFeature: MapGeoJSONFeature, source: string, key: string, action: (values: any) => AnyAction) => {
    if (mapRef.current) {
      if (!isCtrlPressedRef.current) {
        mapRef.current.getCanvas().addEventListener('keyup', (e) => {
          if (e.key === 'Control') {
            isCtrlPressedRef.current = false;
            const selectedRegions = multipleFeaturesRef.current.reduce((acc, cur) => safeGetObjectValue(cur.properties, key) ? [...acc, safeGetObjectValue(cur.properties, key)] : acc, [] as string[])
            multipleFeaturesRef.current.forEach(feature => {
              mapRef.current && mapRef.current.setFeatureState({ source: source, id: feature.id }, { multiselect: false });
            })
            multipleFeaturesRef.current = []
            dispatch(action(selectedRegions))
          }
        }, { once: true })
        isCtrlPressedRef.current = true
      }
      if (multipleFeaturesRef.current.map(feature => feature.id).includes(clickedFeature.id)) {
        mapRef.current.setFeatureState({ source: source, id: clickedFeature.id }, { multiselect: false });
        multipleFeaturesRef.current = multipleFeaturesRef.current.filter(feature => feature.id !== clickedFeature.id);
      } else {
        mapRef.current.setFeatureState({ source: source, id: clickedFeature.id }, { multiselect: true });
        multipleFeaturesRef.current.push(clickedFeature);
      }
    }
  }

  const handleRegionClick = (e: MapLayerMouseEvent) => {
    if (!isDrawingSelectionRef.current) {
      const clickedFeature = e.features && e.features[0] as MapGeoJSONFeature;
      if (e.originalEvent.ctrlKey && clickedFeature) {
        handleSelectMultipleFeatures(clickedFeature, 'region-boundaries', 'region', setRegion)
      } else if (e.features && e.features.length > 0) {
        dispatch(setRegion([e.features[0].properties?.region]))
      }
    }
  }

  const handleMarketClick = (e: MapLayerMouseEvent) => {
    if (!isDrawingSelectionRef.current) {
      const clickedFeature = e.features && e.features[0] as MapGeoJSONFeature;
      if (e.originalEvent.ctrlKey && clickedFeature) {
        handleSelectMultipleFeatures(clickedFeature, 'market-boundaries', 'market', setMarket)
      } else if (isEmpty(app.filters.market) && e.features && e.features.length > 0) {
        dispatch(setMarket([e.features[0].properties?.market]));
      }
      if (showCrosshairRef.current) {
        dispatch(setShowCrosshair(false));
      }
    }
  };

  const handleMarketMousemove = (e: MapLayerMouseEvent) => {
    if (mapRef.current && e.features && e.features.length > 0) {
      if (hoveredId.current !== undefined) {
        mapRef.current.setFeatureState({ source: 'market-boundaries', id: hoveredId.current }, { hover: false });
      }
      if (isEmpty(app.filters.market)) {
        hoveredId.current = e.features[0].id;
        mapRef.current.setFeatureState({ source: 'market-boundaries', id: hoveredId.current }, { hover: true });
        if (!showCrosshairRef.current) {
          dispatch(setShowCrosshair(true));
        }
        const hoveredFeature = e.features[0];
        const hoveredFill = aggregatesRef.current?.find(agg => agg.id === hoveredFeature.properties?.market)
        if (hoveredFill && !hoveredFillRef.current || hoveredFillRef.current?.id !== hoveredFill?.id) {
          hoveredFillRef.current = hoveredFill
          dispatch(setHoveredFill(hoveredFillRef.current))
        }
      } else {
        if (showCrosshairRef.current) {
          dispatch(setShowCrosshair(false));
        }
      }
    }
  };

  const setMapView = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      dispatch(setLat(center.lat));
      dispatch(setLng(center.lng));
      dispatch(setZoom(mapRef.current.getZoom()));
    }
  };

  const showRegionBoundaries = (map: maplibregl.Map) => {
    map.setLayoutProperty('region-fills', 'visibility', 'visible');
    map.setLayoutProperty('region-lines', 'visibility', 'visible');
    map.setLayoutProperty('region-lines-highlight', 'visibility', 'visible');
    map.setLayoutProperty('market-fills', 'visibility', 'none');
    map.setLayoutProperty('market-lines', 'visibility', 'none');
    map.setLayoutProperty('market-lines-highlight', 'visibility', 'none');
  }

  const showMarketBoundaries = (map: maplibregl.Map) => {
    map.setLayoutProperty('region-fills', 'visibility', 'none');
    map.setLayoutProperty('region-lines', 'visibility', 'none');
    map.setLayoutProperty('region-lines-highlight', 'visibility', 'none');
    map.setLayoutProperty('market-fills', 'visibility', 'visible');
    map.setLayoutProperty('market-lines', 'visibility', 'visible');
    map.setLayoutProperty('market-lines-highlight', 'visibility', 'visible');
  }

  const setGeojsonLayerVisibility = () => {
    if (
      mapRef.current &&
      mapRef.current.getLayer('region-fills') &&
      mapRef.current.getLayer('market-fills') &&
      mapRef.current.getLayer('market-lines')
    ) {
      const map = mapRef.current;
      if (app.filters.market !== undefined) {
        map.setFilter('market-fills', ['match', ['get', 'market'], app.filters.market, true, false]);
        map.setFilter('market-lines', ['match', ['get', 'market'], app.filters.market, true, false]);
        showMarketBoundaries(map);
        if (app.filters.club !== undefined) {
          const clubData = app.data.aggregates?.find(c => app.filters.club?.includes(c.id.toString()));
          if (clubData) {
            const clubCenter = new maplibregl.LngLat(clubData.center_lng, clubData.center_lat);
            map.easeTo({ center: clubCenter, zoom: 12, duration: 2000 });
          }
        }
      } else if (app.filters.region !== undefined && app.geojson?.regions) {
        map.fitBounds(new maplibregl.LngLatBounds(getRegionBoundsById(app.filters.region, app.geojson.regions)), { padding: 48, duration: 2000 });
        map.setFilter('market-fills', ['match', ['get', 'region'], app.filters.region, true, false]);
        map.setFilter('market-lines', ['match', ['get', 'region'], app.filters.region, true, false]);
        showMarketBoundaries(map);
      } else {
        map.easeTo({ center: defaultCenter, zoom: defaultZoom, duration: 2000 });
        showRegionBoundaries(map);
      }
      map.off('styledata', setGeojsonLayerVisibility);
    }
  };

  const handleFitToClubs = () => {
    const map = mapRef.current;
    if (map && app.data.aggregates && app.data.aggregates.length > 0 && app.filters.market !== undefined) {
      const marketFeature = app.geojson?.markets?.features.find(f => f.properties?.market === app.filters.market);
      if (marketFeature && app.filters.club === undefined) {
        map.fitBounds(new maplibregl.LngLatBounds(getMarketBoundsFromFeatures(app.data.aggregates, marketFeature)), { padding: 16 * 8, duration: 2000 });
      } else {
        map.fitBounds(new maplibregl.LngLatBounds(getMarketBoundsFromFeatures(app.data.aggregates)), { padding: 16 * 8, duration: 2000 });
      }
    }
  }

  const handleUndoFilter = () => {
    setEmptyAggregates(false);
    const el = document.querySelector('#timetravel span[aria-label="Go back"] button') as HTMLElement
    if (el) el.click();
  }

  return (
    <Box
      className="map-container"
      component="section"
      sx={{
        flex: 1,
        position: 'relative'
      }}
    >
      <Box id="map" />
      <DonutLayer setIsDrawingSelection={setIsDrawingSelection} />
      <Overview />
      <Feedback />
      <Portal container={() => document.querySelector(".maplibregl-ctrl-bottom-right")}>
        <ByMakepath />
        <LastUpdate />
      </Portal>
      <Spinner isVisible={app.loadingAggregates} />
      {mapRef.current &&
        <CustomCursor map={mapRef.current} isVisible={(app.showCrosshair || (app.hoveredFill && !(aggregationLevel === 'Club'))) ? true : false} />
      }
      {!app.isNotesPanelOpen && <UserStats />}
      <Portal container={() => document.querySelector("#stacked-alerts")}>
        {emptyAggregates &&
          <Alert variant="filled" severity='warning' sx={{ marginTop: "0.5rem", position: 'relative' }} onClick={() => setEmptyAggregates(false)}>
            The filters you have selected don&apos;t have any active work orders.
            <Button variant="contained" onClick={handleUndoFilter}>
              Go back
            </Button>
          </Alert>
        }
      </Portal>
    </Box >
  );
};

export default Map;
