import React, { FC, useEffect, useRef, useState } from 'react';
import './Map.css';
import * as d3 from 'd3';
import * as turf from '@turf/turf'
import { GeoJSONSource, LngLat, MapGeoJSONFeature, MapMouseEvent } from 'maplibre-gl';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { AggregationLevel, IAppState, IAppStateDerived, WorkorderAggregate } from 'storage/app/models';
import { selectAggregateFromWorkorder, selectAggregationLevel, setClub, setHoveredAggregate, setMarket, setRegion, selectAggregates, toggleMapSelection } from 'storage/app/duck';
import DonutChart from './DonutChart';
import DonutTooltip from 'components/DonutTooltip';
import { Feature, LineString, Point, Polygon } from 'geojson';
import CustomMarker from './CustomMarker';
import { addPointToLine, addPointToPolygon, safeGetObjectValue, updateLine, updatePolygon } from 'utils/utils';
import theme from 'theme';
import { Portal } from '@mui/base';
import GestureIcon from '@mui/icons-material/Gesture';
import { Button, Tooltip } from '@mui/material';
import { isTablet } from 'utils/utils';


const minWorkordersRadius = 40;
const maxWorkordersRadius = 60;


const rScale = d3.scaleLinear()
  .range([minWorkordersRadius * 2, maxWorkordersRadius * 2])
  .domain([1, 100])
  .clamp(true);


interface DonutLayerProps {
  setIsDrawingSelection: (value: boolean) => void;
}


const DonutLayer: FC<DonutLayerProps> = ({ setIsDrawingSelection }) => {
  const dispatch = useDispatch();
  const metadata = useSelector<IStore, IAppState['metadata']>(state => state.app.metadata);
  const map = useSelector<IStore, IAppState['map']>(state => state.app.map);
  const filters = useSelector<IStore, IAppState['filters']>(state => state.app.filters);
  const isMapLoaded = useSelector<IStore, IAppState['isMapLoaded']>(state => state.app.isMapLoaded);
  const loadingAggregates = useSelector<IStore, IAppState['loadingAggregates']>(state => state.app.loadingAggregates);
  const isMapSelectionActive = useSelector<IStore, IAppState['isMapSelectionActive']>(state => state.app.isMapSelectionActive);
  const isMapSelectionActiveRef = useRef(isMapSelectionActive);
  const aggregates = useSelector<IStore, IAppState['data']['aggregates']>(selectAggregates);
  const aggregationLevel = useSelector<IStore>(selectAggregationLevel) as AggregationLevel;
  const aggregationLevelRef = useRef<AggregationLevel>();
  const selectedClubRef = useRef<IAppState['filters']['club']>(filters.club);
  const hoveredAggregate = useSelector<IStore, IAppState['hoveredAggregate']>(state => state.app.hoveredAggregate);
  const highlightedAggregate = useSelector<IStore, IAppStateDerived['highlightedAggregate']>(selectAggregateFromWorkorder);
  const [mouseCoords, setMouseCoords] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [sourceReady, setSourceReady] = useState<boolean>(true); `1`
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState<string>("");
  const highlightedCircleRef = useRef<MapGeoJSONFeature>();
  const highlightedFillRef = useRef<MapGeoJSONFeature>();
  const currentSourceRef = useRef<string>(aggregationLevel === "Club" ? "aggregates-clubs" : "aggregates");
  const [renderedFeatures, setRenderedFeatures] = useState<Feature<Point>[]>([]);
  const multiSelectFeatureRef = useRef<Feature<Polygon> | Feature<LineString>>();
  const onlyOneClubAtClubLevel = filters.club?.length === 1 && aggregationLevel === "Club";
  const ipad = isTablet();

  /**
   * Add source and layers for aggregate work order data and clustered club data.
   */
  useEffect(() => {
    aggregationLevelRef.current = aggregationLevel
    if (isMapLoaded && map && !map.getSource('aggregates')) {
      map.addSource('aggregates', {
        data: turf.featureCollection([]),
        type: 'geojson',
        generateId: true,
      });

      map.addSource('aggregates-clubs', {
        data: turf.featureCollection([]),
        type: 'geojson',
        generateId: true,
        cluster: true,
        clusterRadius: 125,
        clusterProperties: {
          // keep separate counts for each magnitude category in a cluster
          name: ['+', ["to-number", ["get", "name"]]],
          max_combined_index: ['+', ["get", "max_combined_index"]],
          low_priority_work_orders: ['+', ["get", "low_priority_work_orders"]],
          medium_priority_work_orders: ['+', ["get", "medium_priority_work_orders"]],
          high_priority_work_orders: ['+', ["get", "high_priority_work_orders"]],
          total_nte: ['+', ["to-number", ["get", "total_nte"]]],
          total_workorders: ['+', ["get", "total_workorders"]],
          clubs: ['concat',
            [
              'concat', ["get", "id"], ","
            ]
          ]
        }
      });

      map.addSource('aggregates-multiselect', {
        data: turf.featureCollection([]),
        type: 'geojson',
      });

      map.addLayer({
        'id': 'aggregate-circles',
        'type': 'circle',
        'source': currentSourceRef.current,
        'paint': {
          'circle-opacity': 0,
          'circle-radius': 25,
        },
      });

      map.addLayer({
        'id': 'aggregate-multiselect',
        'type': 'fill',
        'source': 'aggregates-multiselect',
        'paint': {
          'fill-opacity': 0.5,
          'fill-color': theme.palette.background.default,
        },
      });

      map.addLayer({
        'id': 'aggregate-multiselect-line',
        'type': 'line',
        'source': 'aggregates-multiselect',
        'paint': {
          'line-color': theme.palette.background.default,
          'line-width': 2,
        },
      });

      // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
      map.on('move', updateMarkers);
      map.on('zoomend', updateMarkers);

      map.on('mousedown', handleMouseDown)

      setSourceReady(true);
    }
  }, [isMapLoaded]);

  /**
   * Update Donut Chart Features reference when donutChartFeatures changes
   */
  useEffect(() => {
    currentSourceRef.current = aggregationLevel === "Club" ? 'aggregates-clubs' : 'aggregates';
  }, [aggregationLevel]);

  /**
   * Keep track of the mouse coordinates and store them in redux.
   * These will be used to place the tooltip and custom mouse cursor.
   */
  useEffect(() => {
    const handleWindowMouseMove = (e: MouseEvent) => {
      if (highlightedCircleRef.current) {
        setMouseCoords({
          x: e.clientX,
          y: e.clientY,
        });
      }
    };
    window.addEventListener('mousemove', handleWindowMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, []);

  /**
   * Update DonutTooltip title when hoveredAggregate changes
   */
  useEffect(() => {
    if (hoveredAggregate) {
      if (aggregationLevel === 'Region') {
        setTooltipTitle(`${hoveredAggregate.name}`)
      } else if (aggregationLevel === 'Market') {
        setTooltipTitle(`Market ${hoveredAggregate.id}`)
      } else if (aggregationLevel === 'Club') {
        if (hoveredAggregate.cluster) {
          setTooltipTitle(`${hoveredAggregate.point_count} Clubs`)
        } else {
          setTooltipTitle(`Club ${hoveredAggregate.id}`)
        }
      }
    } else {
      setTooltipTitle("");
    }
  }, [hoveredAggregate]);

  /**
   * Once the donut layer is ready, populate circle layer and draw donut charts based on the current filters.
   * Inner circles are rendered in the 'aggregates' layer while the outer pie slices are rendered as markers.
   * This is done to allow custom react components to be rendered over the map.
   * Update the nodes any time the aggregate data changes.
   */
  useEffect(() => {
    if (map && sourceReady && aggregates && isMapLoaded) {
      const points = turf.featureCollection(
        aggregates.map(d => {
          return turf.point([d.center_lng, d.center_lat], { ...d })
        })
      );
      const aggregatesSource = (aggregationLevelRef.current === "Club" ? map.getSource('aggregates-clubs') : map.getSource('aggregates')) as GeoJSONSource;
      aggregatesSource?.setData(points);
      setTimeout(updateMarkers, 50)
    }
  }, [sourceReady, isMapLoaded, aggregates]);

  /**
   * Hide the current aggregate layers whenever new data is loading
   */
  useEffect(() => {
    if (loadingAggregates && map && sourceReady && map.isStyleLoaded()) {
      map.setLayoutProperty('aggregate-circles', 'visibility', 'none');
    }
    setShowTooltip(false);
  }, [loadingAggregates]);

  /**
   * Updates selected club reference when the filter changes
   */
  useEffect(() => {
    selectedClubRef.current = filters.club;
  }, [filters.club]);

  /**
   * Update the rendered donut charts when the aggregation and donutChartFeatures change
   */
  useEffect(() => {
    if (map && map.getLayer('aggregate-circles') && aggregates) {
      map.setLayoutProperty('aggregate-circles', 'visibility', 'visible');
    }
  }, [aggregates, isMapLoaded])


  /**
   * When a workorder item is hovered, use the corresponding highlighted aggregate
   * data to find the aggregate circle and set it to the hover state. 
   */
  useEffect(() => {
    if (map && highlightedAggregate) {
      if (
        aggregationLevel === AggregationLevel.REGION ||
        aggregationLevel === AggregationLevel.MARKET
      ) {
        const layerName = aggregationLevel === AggregationLevel.REGION ? 'region-fills' : 'market-fills';
        const propKey = aggregationLevel === AggregationLevel.REGION ? 'region' : 'market';
        const dataKey = aggregationLevel === AggregationLevel.REGION ? 'name' : 'id';
        const fills = map.queryRenderedFeatures(undefined, {
          layers: [layerName],
          filter: ['==', ['get', propKey], safeGetObjectValue(highlightedAggregate, dataKey)]
        });
        if (highlightedFillRef.current) {
          map.setFeatureState(highlightedFillRef.current, { hover: false });
        }
        if (fills && fills.length > 0) {
          map.setFeatureState(fills[0], { hover: true });
          highlightedFillRef.current = fills[0];
        }
      }
    } else if (map) {
      if (highlightedFillRef.current) {
        map.setFeatureState(highlightedFillRef.current, { hover: false });
        highlightedFillRef.current = undefined;
      }
    }
  }, [highlightedAggregate]);

  /**
   * Refresh donut layer when any aggrrergate filter changes
   */
  useEffect(() => {
    if (map && aggregationLevel === "Club") {
      setLayerSource("aggregate-circles", "aggregates-clubs");
      setLayerSource("aggregate", "aggregates-clubs");
    } else if (map) {
      setLayerSource("aggregate-circles", "aggregates");
      setLayerSource("aggregate", "aggregates");
    }
    const clubsSource = map?.getSource('aggregates-clubs') as GeoJSONSource;
    if (clubsSource) {
      clubsSource.setData(turf.featureCollection([]));
    }
    const aggSource = map?.getSource('aggregates') as GeoJSONSource;
    if (aggSource) {
      aggSource.setData(turf.featureCollection([]));
    }
    aggregationLevelRef.current = aggregationLevel
  }, [filters.region, filters.market, filters.club, filters.status, filters.trades, filters.callDate, filters.importance, filters.urgency])

  /**
   * Disable map panning when drawing selection
   */
  useEffect(() => {
    if (map) {
      if (isMapSelectionActive) {
        map.dragPan.disable()
      } else {
        map.dragPan.enable()
      }
    }
    isMapSelectionActiveRef.current = isMapSelectionActive
  }, [isMapSelectionActive])

  /**
   * Update Donut Markers on BSI update
   */
  useEffect(() => {
    updateMarkers()
  }, [metadata.bsi_updated_date])

  const handleMouseDown = () => {
    if (isMapSelectionActiveRef.current) {
      if (map) {
        map.on('mousemove', handleMouseMove)
        map.once('mouseup', handleMouseUp)
      }
    }
  }

  const handleMouseMove = (e: MapMouseEvent) => {
    if (!multiSelectFeatureRef.current) {
      multiSelectFeatureRef.current = turf.lineString([[e.lngLat.lng, e.lngLat.lat], [e.lngLat.lng, e.lngLat.lat]])
    }
    if (map) {
      if (!isMapSelectionActiveRef.current) {
        map.off('mousemove', handleMouseMove)
        map.off('mouseup', handleMouseUp)
        multiSelectFeatureRef.current = undefined
        const source = map.getSource('aggregates-multiselect') as GeoJSONSource
        source.setData(turf.featureCollection([]))
      } else {
        const coordinates = multiSelectFeatureRef.current.geometry.type === "LineString" ?
          multiSelectFeatureRef.current.geometry.coordinates :
          multiSelectFeatureRef.current.geometry.coordinates[0]
        const lastCoord = multiSelectFeatureRef.current.geometry.type === "LineString" ? turf.getCoords(coordinates).at(-2) : turf.getCoords(coordinates).at(-3)
        if (lastCoord) {
          const lastPoint = new LngLat(lastCoord[0], lastCoord[1])
          const distance = e.lngLat.distanceTo(lastPoint)
          const maxDist = 1 / (map.getZoom() ** 5) * 25000000
          if (multiSelectFeatureRef.current.geometry.type === "LineString") {
            if (distance > maxDist) {
              if (coordinates.length === 3) {
                multiSelectFeatureRef.current = {
                  ...multiSelectFeatureRef.current,
                  geometry: {
                    ...multiSelectFeatureRef.current.geometry,
                    coordinates: [
                      ...multiSelectFeatureRef.current.geometry.coordinates.slice(0, -1),
                      [e.lngLat.lng, e.lngLat.lat],
                      multiSelectFeatureRef.current.geometry.coordinates[0]
                    ]
                  }
                }
                multiSelectFeatureRef.current = turf.lineStringToPolygon(multiSelectFeatureRef.current) as Feature<Polygon>
                setIsDrawingSelection(true)
              }
              else {
                multiSelectFeatureRef.current = addPointToLine(multiSelectFeatureRef.current as Feature<LineString>, e.lngLat)
              }
            }
            else {
              multiSelectFeatureRef.current = updateLine(multiSelectFeatureRef.current as Feature<LineString>, e.lngLat)
            }
          } else if (multiSelectFeatureRef.current.geometry.type === "Polygon") {
            if (distance > maxDist) {
              multiSelectFeatureRef.current = addPointToPolygon(multiSelectFeatureRef.current as Feature<Polygon>, e.lngLat)
            } else {
              multiSelectFeatureRef.current = updatePolygon(multiSelectFeatureRef.current as Feature<Polygon>, e.lngLat)
            }
          }
          const source = map.getSource('aggregates-multiselect') as GeoJSONSource
          source.setData(multiSelectFeatureRef.current)
        }
      }
    }
  }

  const handleMouseUp = () => {
    if (map) {
      // 
      if (multiSelectFeatureRef.current) {
        if (multiSelectFeatureRef.current.geometry.type === "Polygon") {
          const features = map.queryRenderedFeatures(undefined, { layers: ['aggregate-circles'] }) as Feature<Point>[];
          const selectedFeatures = turf.pointsWithinPolygon(
            turf.featureCollection(features.map((f) => turf.point(f.geometry.coordinates, f.properties))),
            multiSelectFeatureRef.current as Feature<Polygon>,
          )
          if (selectedFeatures.features.length > 0) {
            let dispatched = false
            if (aggregationLevelRef.current === "Club") {
              dispatch(setClub(selectedFeatures.features.reduce((acc: string[], f) =>
                f.properties ? f.properties.id ? [...acc, f.properties?.id] : [...acc, ...f.properties.clubs.slice(0, -1).split(',')] : acc,
                [])))
              dispatched = true
            } else if (aggregationLevelRef.current === "Market") {
              dispatch(setMarket(selectedFeatures.features.map(f => parseInt(f.properties?.id))))
              dispatched = true
            } else if (aggregationLevelRef.current === "Region") {
              dispatch(setRegion(selectedFeatures.features.map(f => f.properties?.name)))
              dispatched = true
            } if (dispatched) {
              map.once('click', () => setIsDrawingSelection(false))
            }
          }
        }
      }
      map.off('mousemove', handleMouseMove)
      multiSelectFeatureRef.current = undefined
      const source = map.getSource('aggregates-multiselect') as GeoJSONSource
      source.setData(turf.featureCollection([]))
    }
  }

  const updateMarkers = () => {
    if (map) {
      const features = map.queryRenderedFeatures(undefined, { layers: ['aggregate-circles'] });
      updateDonutRadius(features.map(f => f.properties.total_workorders as number))
      setRenderedFeatures(features as Feature<Point>[])
    }
  }

  const updateDonutRadius = (totalWorlorders: number[]) => {
    if (map && map.getLayer('aggregate-circles') && totalWorlorders.length > 0) {
      const min = Math.min(...totalWorlorders);
      const max = Math.max(...totalWorlorders);
      if (min !== max) {
        map.setPaintProperty('aggregate-circles', 'circle-radius', [
          'interpolate',
          ['linear'],
          ['get', 'total_workorders'],
          min, minWorkordersRadius,
          max, maxWorkordersRadius
        ]);
        rScale.domain([min, max]);
      } else {
        map.setPaintProperty('aggregate-circles', 'circle-radius', maxWorkordersRadius);
        rScale.domain([max - 1, max]);
      }
    }
  }

  const getFeatureId = (feature: Feature<Point>) => {
    return feature.properties?.cluster ?
      `${aggregationLevelRef.current}-${feature.id}-${feature.properties?.clubs}` :
      `${aggregationLevelRef.current}-${feature.id}-${feature.properties?.id}`
  }

  const setLayerSource = (layerId: string, source: string, sourceLayer?: string) => {
    if (map) {
      const oldLayers = map.getStyle().layers;
      const layerIndex = oldLayers.findIndex(l => l.id === layerId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const layerDef = oldLayers.at(layerIndex) as any;
      const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
      if (layerDef) {
        layerDef.source = source;
        if (sourceLayer) {
          layerDef['source-layer'] = sourceLayer;
        }
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
        if (!map.getLayer(layerDef.id)) {
          map.addLayer(layerDef, before);
        }
      }
    }
  }

  const checkMouseX = () => {
    const max = window.innerWidth - 100;
    if (mouseCoords && mouseCoords.x < max) {
      return mouseCoords.x;
    } else {
      return max;
    }
  };

  const checkMouseY = () => {
    if (mouseCoords && mouseCoords.y > 300) {
      return mouseCoords.y;
    } else {
      return mouseCoords.y + 280;
    }
  };

  const handleDonutClick = (feature: Feature<Point>) => {
    if (map) {
      const properties = feature.properties;
      if (properties) {
        if (properties.aggregation_level === 'Club') {
          dispatch(setClub([properties.id]));
        } else if (properties.aggregation_level === 'Market') {
          dispatch(setMarket([properties.id]));
        } else if (properties.aggregation_level === 'Region') {
          dispatch(setRegion([properties.name]));
        } else if (properties.cluster) {
          const clusterSource = map.getSource('aggregates-clubs') as GeoJSONSource;
          if (clusterSource) {
            clusterSource.getClusterExpansionZoom(properties.cluster_id, (err, zoom) => {
              if (err) return;
              if (zoom) {
                map.easeTo({
                  center: feature.geometry.coordinates as [number, number],
                  zoom: zoom + 0.025,
                  duration: 1000
                });
              }
            });
          }
        }
      }
      if (highlightedCircleRef.current) {
        highlightedCircleRef.current = undefined;
        dispatch(setHoveredAggregate(undefined));
      }
      setShowTooltip(false);
    }
  }

  const handleDonutMouseEnter = (feature: Feature<Point>) => {
    if (map) {
      const hoveredFeature = feature.properties
      if (
        (hoveredFeature?.id !== hoveredAggregate?.id) ||
        (hoveredFeature?.cluster_id && hoveredFeature?.cluster_id !== hoveredAggregate?.cluster_id)
      ) {
        highlightedCircleRef.current = feature as MapGeoJSONFeature;
        dispatch(setHoveredAggregate(hoveredFeature as WorkorderAggregate));
      }
      setShowTooltip(true);
    }
  }

  const handleDonutMouseLeave = () => {
    if (map) {
      highlightedCircleRef.current = undefined;
      dispatch(setHoveredAggregate(undefined));
      setShowTooltip(false);

    }
  }

  const filterDuplicateFeatures = (features: Feature<Point>[]) => {
    return features.filter((feature, index, self) => {
      const id = getFeatureId(feature)
      return index === self.findIndex(f => getFeatureId(f) === id)
    })
  }

  const handleToggleMapSelection = () => {
    setIsDrawingSelection(!isMapSelectionActive)
    dispatch(toggleMapSelection())
  }

  return (
    <>
      {showTooltip && hoveredAggregate ? (
        <DonutTooltip
          data={hoveredAggregate}
          title={tooltipTitle}
          x={checkMouseX()}
          y={checkMouseY()}
        />
      ) : null}
      {!ipad && 
        <Portal container={document.querySelector(".maplibregl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right")}>
          <Tooltip title="Draw selection" placement='left'>
            <Button
              onClick={handleToggleMapSelection}
              sx={{
                pointerEvents: 'all',
                backgroundColor: isMapSelectionActive ? theme.palette.background.highlight : theme.palette.background.paper,
                borderRadius: '4px',
                width: '2.5rem',
                minWidth: '2.5rem',
                height: '2.5rem',
                opacity: .9,
                '&:hover': {
                  backgroundColor: theme.palette.background.default
                }
              }}>
              <GestureIcon />
            </Button>
          </Tooltip>
        </Portal>
      }
      {filterDuplicateFeatures(renderedFeatures).map(feature => {
        const id = getFeatureId(feature)
        const diameter = rScale(feature.properties?.total_workorders)
        const highlight = highlightedCircleRef.current?.id === feature.id ||
          (highlightedAggregate && highlightedAggregate?.id === feature.properties?.id) ||
          (highlightedAggregate && feature.properties?.cluster && feature.properties?.clubs.includes(highlightedAggregate?.id))
        return (
          <CustomMarker key={id} latitude={feature.geometry.coordinates[1]} longitude={feature.geometry.coordinates[0]}>
            <DonutChart
              id={id}
              width={diameter}
              height={diameter}
              feature={feature}
              data={[
                { name: 'Low BSI', value: feature.properties?.low_priority_work_orders },
                { name: 'Medium BSI', value: feature.properties?.medium_priority_work_orders },
                { name: 'High BSI', value: feature.properties?.high_priority_work_orders }
              ]}
              highlight={highlight}
              onClick={handleDonutClick}
              onMouseEnter={handleDonutMouseEnter}
              onMouseLeave={handleDonutMouseLeave}
              clusterCount={feature.properties?.point_count}
              disableHover={onlyOneClubAtClubLevel}
            />
          </CustomMarker>
        )
      })}
    </>
  );
};

export default DonutLayer;
