import React, { FC, useEffect, useRef } from 'react';
import IStore from 'lib/redux/models';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { IAppState, IAppStateDerived, Workorder } from 'storage/app/models';
import './WorkorderList.css';
import WorkorderItem from './WorkorderItem';
import { paginateWorkorderData, selectSortParam, selectTotalWorkorders } from "storage/app/duck";
import { Box, useMediaQuery } from "@mui/material";
import InfiniteLoader from 'react-window-infinite-loader';
import Spinner from 'components/Spinner';
import theme from 'theme';
import WorkorderFullscreenItem from './WorkorderFullscreenItem';
import WorkorderFullscreenItemTablet from './WorkorderFullscreenItemTablet';
import { isTablet } from 'utils/utils';

const RenderItem: FC<ListChildComponentProps> = ({ data, index, style }: { data: Workorder[], index: number, style: React.CSSProperties }) => {
  const workorder = data.at(index);
  if (!workorder) return null;
  return (
    <div key={workorder.id} style={style}>
      <WorkorderItem workorder={workorder} index={index} />
    </div>
  );
};

const RenderFullscreenItem: FC<ListChildComponentProps> = ({ data, index, style }: { data: Workorder[], index: number, style: React.CSSProperties }) => {
  const workorder = data.at(index);
  const ipad = isTablet();
  if (!workorder) return null;
  return (
    <div key={workorder.id} style={{ ...style, display: 'flex', maxWidth: ipad ? 'calc(100vw - 0.25rem)' : 'calc(100vw - 1rem)' }}>
      {!ipad ?
        <WorkorderFullscreenItem workorder={workorder} index={index} /> :
        <WorkorderFullscreenItemTablet workorder={workorder} index={index} />}
    </div >
  );
};

const WorkorderList: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(state => state.app.filters);
  const sortParam = useSelector<IStore, IAppStateDerived['sortParam']>(selectSortParam);
  const workorders = useSelector<IStore, IAppState['data']['workorders']>(state => state.app.data.workorders);
  const totalWorkorders = useSelector<IStore, IAppState['data']['totalWorkorders']>(selectTotalWorkorders);
  const loadingWorkorders = useSelector<IStore, IAppState['loadingWorkorders']>(state => state.app.loadingWorkorders);
  const activeWorkorder = useSelector<IStore, IAppState['data']['activeWorkorder']>(state => state.app.data.activeWorkorder);
  const isFullscreen = useSelector<IStore, IAppState['isFullscreen']>(store => store.app.isFullscreen);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null);
  const isSmallScreen = useMediaQuery('(max-width:1500px)');
  const fontSize = isSmallScreen ? 14 : 16;


  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [sortParam]);

  const handleGetNextWorkorders = () => {
    dispatch(paginateWorkorderData({ filters, sortParam }));
  };

  const hasNextPage = workorders && totalWorkorders && workorders.length < totalWorkorders;
  /**
   * If there are more items to be loaded then add an extra row to hold a loading indicator.
   */
  const itemCount = hasNextPage ? workorders.length + 1 : workorders ? workorders.length : 0;
  const loadMoreItems = loadingWorkorders ? () => null : handleGetNextWorkorders;
  const isItemLoaded = (index: number) => !hasNextPage || index < workorders.length;

  return (
    <Box
      className="workorder-list"
      sx={{
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        transition: '0.3s ease-in-out',
      }}
    >
      <Spinner isVisible={loadingWorkorders && !activeWorkorder} dimBackground />
      {workorders && workorders.length > 0 && (
        <AutoSizer>
          {({ height, width }: { height: number, width: number }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                height && width && <FixedSizeList
                  ref={(el) => {
                    ref(el);
                    scrollRef.current = el;
                  }}
                  onItemsRendered={onItemsRendered}
                  height={height}
                  width={width - (isFullscreen ? 9 : 1)}
                  itemSize={6.15 * fontSize + (22 * (12 / fontSize))}
                  itemCount={workorders.length}
                  itemData={workorders}
                  overscanCount={5}
                >
                  {isFullscreen ? RenderFullscreenItem : RenderItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )}
    </Box>
  );
};

export default WorkorderList;
