import IStore from 'lib/redux/models';
import { Marker } from 'maplibre-gl';
import React, { FC, useRef, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'storage/app/models';
import maplibregl from 'maplibre-gl';
import { createPortal } from 'react-dom';

export type MarkerProps = {
  /** Longitude of the anchor location */
  longitude: number;
  /** Latitude of the anchor location */
  latitude: number;
  children?: React.ReactNode;
};

/* eslint-disable complexity,max-statements */
const CustomMarker: FC<MarkerProps> = (props: MarkerProps) => {
  const map = useSelector<IStore, IAppState['map']>(state => state.app.map);
  const thisRef = useRef({ props });
  thisRef.current.props = props;

  const marker: Marker = useMemo(() => {
    let hasChildren = false;
    React.Children.forEach(props.children, el => {
      if (el) {
        hasChildren = true;
      }
    });
    const options = {
      ...props,
      element: hasChildren ? document.createElement('div') : undefined
    };

    const mk = new maplibregl.Marker(options).setLngLat([props.longitude, props.latitude]);

    return mk;
  }, []);

  useEffect(() => {
    if (map) {
      marker.addTo(map);
    }
    return () => {
      marker.remove();
    };
  }, []);


  if (marker.getLngLat().lng !== props.longitude || marker.getLngLat().lat !== props.latitude) {
    marker.setLngLat([props.longitude, props.latitude]);
  }

  return createPortal(props.children, marker.getElement());
}

export default React.memo(CustomMarker);