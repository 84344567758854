// Models
import { IAction, IBaseState } from 'lib/redux/models'

export enum ETrackingActionTypes {
  FAILURE = 'TRACKING/FAILURE',
  FULFILL = 'TRACKING/FULFILL',
  REQUEST = 'TRACKING/REQUEST',
  SUCCESS = 'TRACKING/SUCCESS',
  TRACK_EVENT = 'TRACKING/TRACK_EVENT',
  SEND_FEEDBACK = 'TRACKING/SEND_FEEDBACK',
}

// Data

export interface TrackedEvent {
  namespace: string
  predicate : string
  value?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>
}

// Actions

export interface IActionTrackEvent extends IAction {
  type: ETrackingActionTypes.TRACK_EVENT
  payload: TrackedEvent
}

export interface IActionSendFeedback extends IAction {
  type: ETrackingActionTypes.SEND_FEEDBACK
  payload: string
}


// State

export interface ITrackingState extends IBaseState {
  history: TrackedEvent[]
}
