import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Joyride, { TooltipRenderProps } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { nextStep, previousStep, stop } from 'storage/tutorial/duck';
import theme from 'theme';
import { TutorialStep } from 'storage/tutorial/models';

interface TooltipProps extends Omit<TooltipRenderProps, "step"> {
  step: TutorialStep;
  stepsLength: number;
  callback: (callback: TutorialStep['callbackFn']) => void;
}

const Tooltip: FC<TooltipProps> = ({ index, step, stepsLength, callback, backProps, closeProps, primaryProps, tooltipProps }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (callback) {
      callback(step.callbackFn)
    };
  }, [])

  const handleNext = () => {
    dispatch(nextStep());
  }

  const handlePrevious = () => {
    dispatch(previousStep());
  }

  const handleClose = () => {
    dispatch(stop());
  }

  return (
    <Card title={step.title ? `${step.title}` : "Tooltip"} {...tooltipProps} sx={{ p: '1rem', minWidth: '20rem', maxWidth: '40rem' }}>
      <Stack >
        <Typography variant="h6">{step.title}</Typography>
        {step.content}
      </Stack>
      {!step.hideFooter &&
        <Stack direction="row" sx={{ mt: '1rem' }} spacing={2}>
          <Button {...closeProps} title={''} variant="contained" onClick={handleClose}>
            {closeProps.title}
          </Button>
          <Stack direction="row" spacing={2} sx={{ ml: 'auto !important' }}>
            {index !== 0 && <Button {...backProps} title={''} variant="contained" onClick={handlePrevious} >
              {backProps.title}
            </Button>}
            <Button {...primaryProps} title={''} variant="contained" onClick={handleNext}>
              {primaryProps.title} ({index + 1}/{stepsLength})
            </Button>
          </Stack>
        </Stack>
      }
    </Card>
  )
}


interface TutorialProps {
  steps: TutorialStep[];
  running: boolean;
  stepIndex: number;
}

const Tutorial: FC<TutorialProps> = ({ running, steps, stepIndex }) => {
  const dispatch = useDispatch();
  const [callbackCalled, setCallbackCalled] = useState(false);

  useEffect(() => {
    setCallbackCalled(false);
  }, [running])

  useEffect(() => {
    setCallbackCalled(false);
  }, [stepIndex])

  const handleStepCallback = (callback: TooltipProps["step"]["callbackFn"]) => {
    if (callback && !callbackCalled) {
      setCallbackCalled(true)
      setTimeout(() => callback(dispatch), 100);
    }
  }

  return (
    <Joyride
      run={running}
      steps={steps}
      stepIndex={stepIndex}
      showProgress
      showSkipButton
      spotlightClicks
      continuous
      styles={{
        options: {
          zIndex: 5000,
          arrowColor: theme.palette.background.paper,
        }
      }}
      tooltipComponent={({ ...props }) =>
        <Tooltip
          {...props}
          callback={handleStepCallback}
          stepsLength={steps.length}
        />
      }
    />
  )
};

export default Tutorial;
