// Libraries
import { SagaIterator } from '@redux-saga/types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { IActionSendFeedback, IActionTrackEvent } from 'storage/tracking/models'
import { Types, actions } from '../duck';
import { trackEventService, sendFeedbackService } from 'services/tracking';
import { APIError, errorHandler } from 'services/app';

const { failure, fulfill, request, success } = actions

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* trackEventSaga(action: IActionTrackEvent) {
  yield put(request())
  try {
    const response: boolean = yield call(trackEventService, action.payload)
    if (response) {
      yield put(success(
        action.payload,
      ))
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* sendFeedbackSaga(action: IActionSendFeedback) {
  yield put(request())
  try {
    yield call(sendFeedbackService, action.payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill())
  }
}

export default function* trackingSagas(): SagaIterator {
  yield all([
    takeLatest(Types.TRACK_EVENT, trackEventSaga),
    takeLatest(Types.SEND_FEEDBACK, sendFeedbackSaga),
  ])
}
