/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
// Libraries
import { SagaIterator } from '@redux-saga/types'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { IActionBatchSaveWorkorderNote, IActionGetWorkorderNotes, IActionGetWorkorderRecipients, IActionSaveWorkorderNote, NotesResponse, Recipient, RecipientsResponse } from 'storage/notes/models'
import { Types, actions, addWorkorderNote, setWorkorderNotes, setRecipients } from '../duck';
import { getWorkorderNotesService, saveWorkorderNoteService, batchSaveWorkorderNoteService, getWorkorderRecipientsService } from 'services/notes';
import { Workorder } from 'storage/app/models';
import { APIError, errorHandler } from 'services/app';
import { safeGetObjectValue } from 'utils/utils';

const { failure, fulfill, request, success } = actions

export function* getWorkorderNotesSaga(action: IActionGetWorkorderNotes) {
  yield put(request("loadingNotes"))
  try {
    const response: NotesResponse["data"] = yield call(getWorkorderNotesService, action.payload)
    if (response) {
      yield put(success({
        error: undefined
      }))
      yield put(setWorkorderNotes({ workorderId: action.payload.workorderId, notes: response }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill("loadingNotes"))
  }
}


/**
 * Saga send a note to a workorder.
 * If successful, the notes array is updated within the workorder item in the data store and the note input is cleared.
 */
export function* saveWorkorderNoteSaga(action: IActionSaveWorkorderNote) {
  yield put(request("loadingNotes"))
  try {
    const saved: boolean = yield call(saveWorkorderNoteService, action.payload);
    if (saved) {
      yield put(addWorkorderNote(action.payload))
      yield put(action.payload.gainExp())
      yield action.payload.setNewNote("")
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill("loadingNotes"))
  }
}


/**
 * Saga to update the working_on_it field for a specific workorder.
 * If successful, the workorder item is updated within the workorder array in the data store and the note input is cleared.
 */
export function* batchSaveWorkorderNoteSaga(action: IActionBatchSaveWorkorderNote) {
  yield put(request("loadingNotes"))
  try {
    const response: Partial<Workorder> = yield call(batchSaveWorkorderNoteService, action.payload);
    if (response) {
      for (const index in response) {
        const workorder = safeGetObjectValue(response, index)
        if (workorder.error === null) {
          yield put(addWorkorderNote({workorderId: workorder.work_order, note: action.payload.note}))
          yield put(action.payload.gainExp())    
        } 
      }
      
      yield action.payload.setNewNote("")
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill("loadingNotes"))
  }
}



export function* getWorkorderRecipientsSaga(action: IActionGetWorkorderRecipients) {
  yield put(request("loadingRecipients"))
  try {
    const response: RecipientsResponse["data"] = yield call(getWorkorderRecipientsService, action.payload)
    const splittedRecipients: Recipient[] = response.reduce((recipients: Recipient[], recipient) => {
      const splitted = recipient.email.split(";")
      return [...recipients, ...splitted.map(email => ({ ...recipient, email }))]
    }, [])
    if (response) {
      yield put(setRecipients({
        workorderId: action.payload.toString(),
        recipients: splittedRecipients
      }))
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)))
  } finally {
    yield put(fulfill("loadingRecipients"))
  }
}


export default function* notesSagas(): SagaIterator {
  yield all([
    takeLatest(Types.GET_NOTES, getWorkorderNotesSaga),
    takeEvery(Types.SAVE_NOTE, saveWorkorderNoteSaga),
    takeEvery(Types.BATCH_SAVE_NOTE, batchSaveWorkorderNoteSaga),
    takeEvery(Types.GET_RECIPIENTS, getWorkorderRecipientsSaga),
  ])
}
