import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import { Modal, Card, CardContent, Button, Typography, TextField, Stack, Portal, Tooltip, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { sendFeedback } from 'storage/tracking/duck';

const Feedback: FC = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [feedback, setFeedback] = useState<string>('');
  const [alert, setAlert] = useState<{ message: string, timeout: number } | undefined>();

  const handleSubmitFeedback = () => {
    dispatch(sendFeedback(feedback))
    setFeedback('')
    setModalOpen(false)
    const timeout = 6000
    setAlert({ message: "Feedback sent!", timeout: timeout })
    window.setTimeout(() => {
      clearAlert();
    }, timeout)
  }

  const clearAlert = () => {
    setAlert(undefined)
  }

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card sx={{
          position: 'relative',
          minWidth: '30.5rem',
          overflow: 'visible',
        }}>
          <Button
            variant='text'
            size='small'
            onClick={() => setModalOpen(false)}
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
          >
            <CloseIcon />
          </Button>
          <CardContent sx={{
            paddingBottom: '0.5rem !important'
          }}>
            <Typography variant="h6" sx={{ mb: '1rem', mr: '3rem' }}>
              Send a feedback to our QA and developer teams
            </Typography>
            <TextField value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder="Feedback" rows={6} multiline fullWidth />
            <Stack direction="row" spacing={2} sx={{ mt: '1rem', mb: '1rem' }} justifyContent='flex-end'>
              <Button variant='contained' onClick={() => handleSubmitFeedback()}>
                Send feedback
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Portal container={document.querySelector(".maplibregl-ctrl-bottom-right")}>
        <Tooltip title="Send feedback" placement='left'>
          <Button
            onClick={() => setModalOpen(true)}
            sx={{
              pointerEvents: 'all',
              borderRadius: '4px',
              width: '2.5rem',
              minWidth: '2.5rem',
              height: '2.5rem',
              opacity: .9,
              backgroundColor: theme.palette.background.paper,
              '&:hover': {
                backgroundColor: theme.palette.background.default
              }
            }}>
            <ReviewsIcon />
          </Button>
        </Tooltip>
      </Portal>
      <Portal container={() => document.querySelector('#stacked-alerts')}>
        {alert &&
          <Alert variant="filled" severity='success' sx={{ marginTop: "0.5rem", position: 'relative' }} onClick={() => clearAlert()} >
            {alert.message}
            <div className='alert-timeout' style={{ animationDuration: `${alert.timeout}ms` }} />
          </Alert>
        }
      </Portal>
    </>
  );
};

export default Feedback;

