import React, { FC, CSSProperties, useState, useEffect } from 'react';
import { Stack, Switch, SwitchProps, Tooltip, Typography } from '@mui/material';
import theme from 'theme';
import { isTablet } from 'utils/utils';


interface ActionSwitchProps {
  label?: string
  labelWidth?: string;
  tooltip: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  backgroundColor: string;
  checked: boolean;
  icon: SwitchProps['icon'];
  havePermission: boolean;
  onClick?: () => void;
  onChange?: (checked: boolean) => void;
  style?: CSSProperties
}

const ActionSwitch: FC<ActionSwitchProps> = ({ label, labelWidth, tooltip, tooltipPlacement, backgroundColor, checked, icon, havePermission, style, onClick, onChange }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const ipad = isTablet();

  useEffect(() => {
    if (onChange && !isFirstRender) {
      onChange(checked)
    } else if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [checked])

  return (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement ? tooltipPlacement : 'top'}
      arrow
    >
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          width: '100%',
          flex: '1',
          p: '0.25rem',
          position: 'relative',
          justifyContent: 'center',
          cursor: havePermission ? 'pointer' : 'default',
        }}
        onClick={onClick}
        style={style}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Switch
          checked={checked}
          icon={icon}
          checkedIcon={icon}
          inputProps={{ value: 'Follow Up' }}
          sx={{
            width: labelWidth ? labelWidth : "4rem",
            height: "2.5rem",
            padding: '0 0.1rem',
            position: 'relative',
            '& .MuiSvgIcon-root': {
              width: 'calc(2.5rem - 6px)',
              height: 'calc(2.5rem - 6px)',
              backgroundColor: 'white',
              padding: '0.33rem',
              borderRadius: '1.5rem',
            },
            '& .MuiSwitch-switchBase': {
              height: '2.5rem',
              transform: 'translateX(0)',
              left: '0.3rem',
              padding: 0,
              cursor: havePermission ? 'pointer' : 'default',
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              left: 'unset',
              right: '0.3rem',
              transform: 'translateX(0)',
            },
            '& .MuiSwitch-track': {
              height: '100%',
              borderRadius: '1.5rem',
              opacity: '1 !important',
              backgroundColor: backgroundColor,
              border: havePermission && isHovering ? `3px solid ${theme.palette.primary.main}` : 'none',
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: backgroundColor,
            },
          }}
        />
        {label &&
          <Typography
            sx={{ 
              position: 'absolute',
              left: checked ? 'unset' : '3.25rem',
              right: checked ? '3.25rem' : 'unset',
              fontSize: ipad ? '0.8rem' : undefined,
            }}>
            {label}
          </Typography>
        }
      </Stack>
    </Tooltip>
  );
};

export default ActionSwitch;
