import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Joyride, { TooltipRenderProps } from 'react-joyride';
import theme from 'theme';
import { NewFeatureStep } from 'storage/tutorial/models';
import { safeGetObjectValue } from 'utils/utils';




interface TooltipProps extends Omit<TooltipRenderProps, "step"> {
  id?: number;
  step: NewFeatureStep;
  stepsLength: number;
  setRunning: (running: boolean) => void;
  setStepIndex: (index: number) => void;
  shouldDisplayNextButton: boolean;
}

const Tooltip: FC<TooltipProps> = ({
  index, step, stepsLength, backProps, closeProps, primaryProps,
  tooltipProps, setRunning, setStepIndex, shouldDisplayNextButton
}) => {

  const handleNext = () => {
    setStepIndex(index + 1);
  }

  const handlePrevious = () => {
    setStepIndex(index - 1);
  }

  const handleClose = () => {
    setRunning(false);
  }

  return (
    <Card title={step.title ? `${step.title}` : "Tooltip"} {...tooltipProps} sx={{ p: '1rem', minWidth: '20rem', maxWidth: '40rem' }}>
      <Stack >
        <Typography variant="h6">{step.title}</Typography>
        {step.content}
      </Stack>
      {!step.hideFooter &&
        <Stack direction="row" sx={{ mt: '1rem' }} spacing={2}>
          <Button {...closeProps} title={''} variant="contained" onClick={handleClose}>
            {closeProps.title}
          </Button>
          <Stack direction="row" spacing={2} sx={{ ml: 'auto !important' }}>
            {index !== 0 && <Button {...backProps} title={''} variant="contained" onClick={handlePrevious} >
              {backProps.title}
            </Button>}
            {shouldDisplayNextButton &&
              <Button {...primaryProps} title={''} variant="contained" onClick={handleNext}>
                {primaryProps.title} ({index + 1}/{stepsLength})
              </Button>
            }
          </Stack>
        </Stack>
      }
    </Card>
  )
}


interface NewFeatureProps {
  steps: NewFeatureStep[];
}

const NewFeature: FC<NewFeatureProps> = ({ steps }) => {
  const [running, setRunning] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [callbackCalled, setCallbackCalled] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const shouldDisplayNextButton = steps.length > 1 && stepIndex < steps.length - 1;


  useEffect(() => {
    setRunning(true);
  }, [])

  useEffect(() => {
    setCallbackCalled(false);
  }, [running])

  useEffect(() => {
    setCallbackCalled(false)
  }, [stepIndex])

  const callbacks = {
    "clickOnElementById": (id: string) => {
      const el = document.getElementById(id);
      if (el) {
        el.click();
        setCallbackCalled(true)
        window.setTimeout(() => setRefresh(refresh + 1), 10)
      }
    },
    "clickOnElementBySelector": (id: string) => {
      const el: HTMLElement | null = document.querySelector(id);
      if (el) {
        el.click();
        setCallbackCalled(true)
        window.setTimeout(() => setRefresh(refresh + 1), 10)
      }
    }
  }

  const handleStepCallback: Joyride['callback'] = (event) => {
    const step = steps[event.index]
    if (step && step.callback_fn && !callbackCalled) {
      Object.keys(step.callback_fn).forEach((callbackName) => {
        if (callbackName in callbacks && step.callback_fn) {
          const params = safeGetObjectValue(step.callback_fn, callbackName)
          setTimeout(() => callbacks[
            callbackName as keyof typeof callbacks
          ](params), 400)
        }
      })
    }
  }

  return (
    <Joyride
      run={running}
      steps={[
        ...steps.map(step => ({ ...step, disableBeacon: step.disable_beacon }))
      ]}
      stepIndex={stepIndex}
      showProgress
      showSkipButton
      spotlightClicks
      continuous
      styles={{
        options: {
          zIndex: 5000,
          arrowColor: theme.palette.background.paper,
        }
      }}
      callback={handleStepCallback}
      tooltipComponent={({ ...props }) =>
        <Tooltip
          {...props}
          stepsLength={steps.length}
          setRunning={setRunning}
          setStepIndex={setStepIndex}
          shouldDisplayNextButton={shouldDisplayNextButton}
        />
      }
    />
  )
};

export default NewFeature;
