import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Attachment, Workorder } from 'storage/app/models';
import { Stack, Button, Modal, Card, CardContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import { getAttachments, setData } from 'storage/app/duck';
import { RenderAttachment } from 'components/WorkorderDetail/WorkorderAttachments';
import IStore from 'lib/redux/models';


interface AttachmentsModalProps {
  open: boolean;
  onClose: () => void;
  workorder: Workorder;
}

const AttachmentsModal: FC<AttachmentsModalProps> = ({ open, onClose, workorder }) => {
  const dispatch = useDispatch();
  const attachments = useSelector<IStore, Attachment[] | undefined>(state => state.app.data.attachments);

  useEffect(() => {
    if (open && workorder.id) {
      if (attachments) {
        dispatch(setData({ attachments: undefined }))
      }
      dispatch(getAttachments(workorder.id))
    }
  }, [open, workorder.id])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50rem',
        backgroundColor: theme.palette.background.default,
      }}>
        <Button
          variant='text'
          size='small'
          onClick={onClose}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            {workorder.id} | Attachments
          </Typography>
          <Stack spacing={2} maxHeight='80svh' minHeight='10svh' width='32rem' sx={{ overflowY: 'auto', margin: 'auto' }}>
            <Stack sx={{ overflowY: 'auto', pr: '0.25rem' }}>
              {attachments?.map((attachment: Attachment, index: number) =>
                <RenderAttachment key={index} {...attachment} />
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  )
}


export default AttachmentsModal;