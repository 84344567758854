/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState, KeyboardEvent, CSSProperties } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import theme from 'theme';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Stack } from '@mui/material';
import { isTablet } from 'utils/utils';


interface CustomMenuItemProps {
  index: number,
  option?: any,
  multiple?: boolean,
  selected?: boolean,
  onSelect: (option: any) => any
  style?: CSSProperties
}

const CustomMenuItem: FC<CustomMenuItemProps> = ({ index, style, option, multiple, selected, onSelect }) => {

  const handleMultiSelectClick = () => {
    onSelect(option)
  }

  if (!multiple) {
    return (
      <MenuItem
        key={index}
        selected={selected}
        onClick={() => onSelect(option)}
        style={style}
      >
        {option.name}
      </MenuItem>
    )
  }
  if (multiple) {
    return (
      <MenuItem
        key={index}
        onClick={handleMultiSelectClick}
        style={style}
        selected={selected}
      >
        <Checkbox
          checked={selected}
          sx={{
            mr: 1,
            '&.MuiCheckbox-root': {
              color: '#fff'
            },
            '&.MuiCheckbox-root.Mui-checked': {
              color: '#fff'
            }
          }}
        />
        <Typography sx={{ pt: '0.1rem' }}>
          {option.name}
        </Typography>
      </MenuItem>
    )
  }
  return null
}

interface RenderItemProps extends ListChildComponentProps {
  value: any
}

const RenderItem: FC<RenderItemProps> = ({ data, index, style }) => {
  const item = data.at(index);
  if (item) return (
    <CustomMenuItem key={data.id} style={style} {...item} index={index} />
  ); else return null;
};


export interface StringItem {
  name: string | number,
  value: string | number | boolean
}

interface FilterMenuProps {
  id?: string
  anchorEl?: Element | (() => Element) | null
  value?: any,
  options: StringItem[],
  label: React.ReactNode,
  multiple?: boolean,
  clearable?: boolean,
  searchable?: boolean,
  applyOnEnter?: boolean,
  alignRight?: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  onClose: ((event: React.MouseEvent, reason?: "backdropClick" | "escapeKeyDown") => void),
  onSelect: (option: any) => any
  onClear?: () => void,
  onApply?: () => void,
  onCancel?: () => void,
  style?: CSSProperties
}

const FilterMenu: FC<FilterMenuProps> = (props) => {
  const [hover, setHover] = useState(false);
  const [menuData, setMenuData] = useState<CustomMenuItemProps[]>([]);
  const [search, setSearch] = useState('');
  const [searchOptions, setSearchOptions] = useState<StringItem[]>();
  const [currentValue, setCurrentValue] = useState(props.value);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuEl = menuRef.current?.querySelector(".MuiMenu-paper")
  const searchInputRef = useRef<HTMLInputElement>(null);
  const open = props.anchorEl ? true : false;
  const ipad = isTablet();

  useEffect(() => {
    const options = props.options
    setSearchOptions(options.filter((option) => {
      if (typeof option.name === 'number') {
        return option.name.toString().includes(search)
      } else {
        return option.name.includes(search)
      }
    }))
  }, [props.options, search])

  useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  useEffect(() => {
    const searchOpts = props.options.filter((option) => {
      if (option.name === undefined) {
        return false
      }
      else if (typeof option.name === 'number') {
        return option.name.toString()?.toLowerCase().includes(search?.toLowerCase())
      } else {
        return option.name?.toLowerCase().includes(search?.toLowerCase())
      }
    })
    const opts = props.searchable ? searchOpts : props.options
    if (opts) {
      setMenuData(opts.map((option, index) => ({
        index: index,
        value: props.multiple ? currentValue : props.value,
        option: option,
        selected: props.multiple ? currentValue?.includes(option.value) : option.value === props.value?.value,
        onSelect: props.multiple ? handleMultiSelect : props.onSelect,
        multiple: props.multiple,
      })))
    }
  }, [currentValue, search, open])

  useEffect(() => {
    if (!open) {
      window.setTimeout(() => setSearch(''), 200)
    }
  }, [open])

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [searchInputRef.current])

  const handleSearchSubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (search === "") {
        props.onSelect(undefined)
      } else if (searchOptions) {
        const firstOption = searchOptions[0]
        if (firstOption) {
          if (props.multiple) {
            if (props.applyOnEnter && currentValue?.length >= 1 && currentValue.includes(firstOption.value)) {
              if (props.onApply) {
                props.onApply()
              }
            } else {
              const newValues = currentValue ? [...currentValue, firstOption.value] : [firstOption.value]
              const uniqueNewValues = Array.from(new Set(newValues))
              setCurrentValue(uniqueNewValues)
              props.onSelect(uniqueNewValues)
            }
          } else {
            props.onSelect(firstOption.value)
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    } else if (e.key === 'Down' || e.key === 'ArrowDown') {
      if (searchOptions) {
        const target = e.target as any
        const stackEl = target?.parentElement?.parentElement?.parentElement
        const firstOptionEl = stackEl?.querySelector('.MuiMenuItem-root')
        if (firstOptionEl) {
          firstOptionEl.focus()
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }

  const handleIgnoreNumberPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const number = parseInt(e.key)
    if (number && number >= 0 && number <= 9) {
      e.stopPropagation();
    }
    // Prevent menu from hijacking input focus when pressing 'f' in the search input field
    if (e.key.toLocaleLowerCase() === 'f') {
      e.stopPropagation();
    }
  }

  const handleKeyDownCapture = (e: KeyboardEvent<HTMLDivElement>) => {
    if (props.searchable) handleIgnoreNumberPress(e)
  }

  const updateMultiSelectValue = (option: StringItem, value: any[], setValue: (v: any[]) => void) => {
    if (!value) setValue([option.value])
    else if (!value?.includes(option.value)) {
      setValue([...value, option.value])
    } else {
      setValue([...value.filter((opt: string | number) => opt !== option.value)])
    }
  }

  const handleMultiSelect = (option: StringItem) => {
    updateMultiSelectValue(option, props.value, props.onSelect)
  }

  const getOptionWidth = (option: StringItem) => {
    const text = document.createElement('span')
    document.body.appendChild(text);
    text.style.fontSize = '1rem'
    text.textContent = option.name.toString();
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    const width = text.clientWidth + 6 * 16
    document.body.removeChild(text);
    return width
  }

  return (
    <>
      <Button
        id={props.id}
        onClick={props.onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          textTransform: 'none',
          color: '#fff',
          fontSize: '1rem',
          '&.MuiButton-root:hover': {
            backgroundColor: 'background.default'
          },
          ...props.style
        }}
      >
        {props.value && props.clearable && (hover || ipad) && (
          <CancelIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (props.onClear) {
                props.onClear()
              } else {
                props.onSelect(undefined)
              }
            }}
            sx={{
              position: 'absolute',
              left: '0.25rem',
              transform: 'translate(0, -1px)'
            }}
          />
        )}
        <Typography ml={3} component="span" fontWeight='bold' noWrap>{props.label}</Typography>
        {!open && <KeyboardArrowDownIcon sx={{ ml: 0.5 }} />}
        {open && <KeyboardArrowUpIcon sx={{ ml: 0.5 }} />}
      </Button>
      <Menu
        ref={menuRef}
        id="filter-menu"
        aria-labelledby="region-button"
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disablePortal
        disableAutoFocusItem
        onKeyDownCapture={handleKeyDownCapture}
        sx={{
          '& .MuiList-root': {
            backgroundColor: undefined,
            paddingTop: props.searchable ? '3rem' : undefined,
          },
          '& .MuiPopover-paper': {
            minWidth: '10rem',
            maxHeight: props.onApply && props.onCancel && props.searchable ? '27rem' :
              (props.onApply && props.onCancel) || props.searchable ? '25rem' : '23rem',
            zIndex: 2000,
          },
          '& .MuiMenuItem-root': {
            justifyContent: props.alignRight ? 'flex-end' : 'flex-start',
          },
          marginTop: '0.25rem',
        }}
      >
        {props.searchable &&
          <TextField
            inputRef={searchInputRef}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleSearchSubmit}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            sx={{ position: 'absolute', top: '0.5rem', left: '0.5rem', right: '0.5rem', div: { pr: 0.5 }, input: { p: 0.5 } }}
          />
        }
        {menuEl &&
          <FixedSizeList
            itemSize={56}
            height={menuData.length * 56 >= 300 ? 300 : menuData.length * 56}
            width={Math.max(...menuData.map((item) => getOptionWidth(item.option)), 160)}
            itemCount={menuData.length}
            itemData={menuData}
          >
            {({ data, index, style }) => RenderItem({ data, index, style, value: props.value })}
          </FixedSizeList>
        }
        {props.onApply && props.onCancel &&
          <Stack flexDirection='row' marginBottom='-0.5rem' sx={{ backgroundColor: theme.palette.background.paper }}>
            <Button variant='contained' sx={{ width: '100%' }} onClick={props.onCancel}>
              Cancel
            </Button>
            <Button variant='contained' sx={{ width: '100%' }} onClick={props.onApply}>
              Apply
            </Button>
          </Stack>
        }
      </Menu >
    </>
  )
}

export default FilterMenu;
