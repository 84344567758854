import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import theme from 'theme';
import { IAppState } from 'storage/app/models';

interface FilterHighlightProps {
  filters: IAppState["filters"]
  children: ReactNode
}

const FilterHighlight: FC<FilterHighlightProps> = (props) => {
  const [regionMenuEl, setRegionMenuEl] = useState<HTMLElement | null>();
  const [marketMenuEl, setMarketMenuEl] = useState<HTMLElement | null>();
  const [clubMenuEl, setClubMenuEl] = useState<HTMLElement | null>();

  useEffect(() => {
    setRegionMenuEl(document.getElementById("region-menu"));
    setMarketMenuEl(document.getElementById("market-menu"));
    setClubMenuEl(document.getElementById("club-menu"));
  }, [props.filters])

  const calculateHighlightWidth = (): string => {
    if (props.filters.club) return '0'
    if (props.filters.market && clubMenuEl) return `${clubMenuEl.clientWidth + 1}px`
    if (props.filters.region && marketMenuEl && clubMenuEl) return `${marketMenuEl.clientWidth + clubMenuEl.clientWidth + 1}px`
    return '100%'
  }

  return (
    <Stack
      id="filter-highlight"
      direction="row"
      alignItems="stretch"
      alignSelf="stretch"
      sx={regionMenuEl && marketMenuEl && {
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: calculateHighlightWidth(),
          display: 'block',
          backgroundColor: theme.palette.background.highlight,
          zIndex: -1,
          transition: `right 2s ease-in-out`
        }
      }} >
      {props.children}
    </Stack>
  )
}

export default FilterHighlight;
